import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  TextField
} from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { XCircle } from 'react-feather';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { SwitchWithLabel } from '../../../components/SwitchWithLabel';
import { TIMEKEY_FORMAT } from '../../../domainTypes/analytics';
import { styled } from '../../../emotion';
import { useBackgroundJobs } from '../../../hooks/useBackgroundJobs';
import { useDialogState } from '../../../hooks/useDialogState';
import { FlexContainer } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { useQueryParam } from '../../../routes';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { Json } from '../../components/Json';
import { SafeExecuteButton } from '../../components/SafeExecuteButton';
import { SpaceSelectorDialog } from '../../components/SpaceSelector';
import { processParallelCapped } from '../../services/denormalization';
import { publishTopic } from '../../services/pubsub';
import { getActiveSpaceIds } from '../../services/space';

const Row = styled('div')((p) => ({
  marginBottom: p.theme.spacing(2)
}));

const queryPg = (query: string, values?: string[], arrayMode?: boolean) =>
  callFirebaseFunction('pg-query', {
    text: query,
    values,
    rowMode: arrayMode ? 'array' : undefined
  });

const DynamicValueRow = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr max-content',
  alignItems: 'center',
  gridColumnGap: p.theme.spacing(1),
  marginBottom: p.theme.spacing(1)
}));

type Params = {
  q: string;
  values: string[];
  arrayMode: boolean;
};

const SeedBySpaceButton: React.FC<{ seedFn: string; args?: object }> = ({
  seedFn,
  args,
  children
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const { addJob } = useBackgroundJobs();
  return (
    <>
      <Button onClick={openDialog}>{children}</Button>
      <SpaceSelectorDialog
        open={dialogOpen}
        onClose={closeDialog}
        onSelect={(s) => {
          const request = () => {
            return callFirebaseFunction(seedFn, {
              spaceId: s.id,
              ...args
            }).then((r) => console.log(`Seed complete for ${s.id}`, r));
          };
          addJob({
            job: request,
            onStart: () => {
              return {
                message: (
                  <>
                    <CircularProgress color="inherit" size={16} /> &nbsp;
                    Seeding for {s.id}...
                  </>
                )
              };
            },
            onSuccess: () => {
              return {
                message: 'Seeding successful! Check console for details.'
              };
            },
            onError: () => {
              return {
                message: 'Seeding failed.'
              };
            }
          });
        }}
      />
    </>
  );
};

// For product details - out of memory
// const ERRORED = ['4eLSOaVOU', '4WhIOCAzT'];

// For page details - out of memory
// const ERRORED = ['4eLSOaVOU'];

const SeedAllButton: React.FC<{
  seedFn: string;
  args?: object;
  parallelization: number;
}> = ({ seedFn, args, parallelization, children }) => {
  const { addJob } = useBackgroundJobs();
  return (
    <SafeExecuteButton
      onClick={async (execute) => {
        const spaceIds = await getActiveSpaceIds();
        console.log(`Seeding ${spaceIds.length} spaces`);
        if (!execute) {
          return;
        }
        const job = async () => {
          const erroredSpaces: string[] = [];
          await processParallelCapped(
            spaceIds.map((spaceId) => async () => {
              // if (!ERRORED.includes(spaceId)) {
              //   return;
              // }
              const i = spaceIds.indexOf(spaceId) + 1;
              console.log(`Seeding ${spaceId} (${i}/${spaceIds.length})`);
              await callFirebaseFunction(seedFn, {
                spaceId,
                ...args
              })
                .then((r) =>
                  console.log(`Seed complete for ${i} ${spaceId}`, r)
                )
                .catch(() => {
                  erroredSpaces.push(spaceId);
                  return console.error(`ERROR for ${spaceId}`);
                });
            }),
            parallelization
          );
          console.log('SPACES_WITH_ERRORS', erroredSpaces);
        };

        addJob({
          job,
          onStart: () => {
            return {
              message: (
                <>
                  <CircularProgress color="inherit" size={16} /> &nbsp; Seeding
                  all spaces...
                </>
              )
            };
          },
          onSuccess: () => {
            return {
              message: 'Seed complete!'
            };
          },
          onError: () => {
            return {
              message: 'Seeding failed.'
            };
          }
        });
      }}
    >
      {children || 'Seed ALL spaces'}
    </SafeExecuteButton>
  );
};

export const PagePostgres = () => {
  const [result, setResult] = useState<{
    time: number;
    res: any;
  } | null>(null);
  const [state, setState] = useQueryParam<Params>(
    'params',
    (p) =>
      p
        ? (JSON.parse(decodeURIComponent(p)) as Params)
        : {
            q: '',
            values: [],
            arrayMode: false
          },
    (p) => encodeURIComponent(JSON.stringify(p))
  );
  return (
    <>
      <Page>
        <Section>
          <Card>
            <Row>
              <TextField
                key="query"
                fullWidth
                // Using value - which would be the right thing to do -
                // causes the cursor to jump strangely.
                // This started to happen after trying to stabilize useRoutes (used by useQueryParam) in commit ce650e86a0e3f16d3662c308f90a28f7c3ab5985
                defaultValue={state.q}
                onChange={(ev) => {
                  setState({
                    ...state,
                    q: ev.target.value
                  });
                }}
                multiline
                rows={15}
                variant="outlined"
              />
            </Row>
            <Row>
              {state.values.map((d, i) => (
                <DynamicValueRow key={i}>
                  <div>${i + 1}</div>
                  <TextField
                    fullWidth
                    value={d}
                    variant="outlined"
                    onChange={(ev) => {
                      const nextValues = state.values.slice();
                      nextValues[i] = ev.target.value;
                      setState({
                        ...state,
                        values: nextValues
                      });
                    }}
                  />

                  <div>
                    <IconButton
                      onClick={() => {
                        const nextValues = state.values.slice();
                        nextValues.splice(i, 1);
                        setState({
                          ...state,
                          values: nextValues
                        });
                      }}
                    >
                      <XCircle size={16} />
                    </IconButton>
                  </div>
                </DynamicValueRow>
              ))}
            </Row>
            <Row>
              <FlexContainer justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const nextValues = state.values.slice();
                    nextValues.push('');
                    setState({
                      ...state,
                      values: nextValues
                    });
                  }}
                >
                  Add value
                </Button>

                <FlexContainer>
                  <SwitchWithLabel
                    label="Array Mode"
                    checked={state.arrayMode}
                    onChange={() =>
                      setState({ ...state, arrayMode: !state.arrayMode })
                    }
                  />

                  <ButtonWithPromise
                    variant="contained"
                    color="primary"
                    pending="Submitting..."
                    disabled={!state.q}
                    onClick={() => {
                      const start = Date.now();
                      return queryPg(
                        state.q,
                        state.values,
                        state.arrayMode
                      ).then((r: any) => {
                        const nextResult = {
                          time: Date.now() - start,
                          res: r
                        };
                        console.log(nextResult);
                        return setResult(nextResult);
                      });
                    }}
                  >
                    Submit
                  </ButtonWithPromise>
                </FlexContainer>
              </FlexContainer>
            </Row>

            <Row>
              <SeedBySpaceButton seedFn="sales-seedSalesDataForSpace">
                Seed sales data by space...
              </SeedBySpaceButton>

              <SeedAllButton
                seedFn="sales-seedSalesDataForSpace"
                parallelization={15}
              />
            </Row>
            <Row>
              <SeedBySpaceButton
                seedFn="pgDenormalization-seedAnalyticsDataForSpace"
                args={{ chunkSize: 10, mode: 'products' }}
              >
                Seed product analytics data by space...
              </SeedBySpaceButton>

              <SeedAllButton
                seedFn="pgDenormalization-seedAnalyticsDataForSpace"
                args={{ chunkSize: 10, mode: 'products' }}
                parallelization={20}
              />
            </Row>
            <Row>
              <SeedBySpaceButton
                seedFn="pgDenormalization-seedAnalyticsDataForSpace"
                args={{ chunkSize: 10, mode: 'pages' }}
              >
                Seed page analytics data by space...
              </SeedBySpaceButton>

              <SeedAllButton
                seedFn="pgDenormalization-seedAnalyticsDataForSpace"
                args={{ chunkSize: 10, mode: 'pages' }}
                parallelization={50}
              />
            </Row>

            <Row>
              <SeedBySpaceButton
                seedFn="pgDenormalization-seedAnalyticsDataForSpace"
                args={{ mode: 'product_docs' }}
              >
                Seed product docs by space...
              </SeedBySpaceButton>

              <SeedAllButton
                seedFn="pgDenormalization-seedAnalyticsDataForSpace"
                args={{ mode: 'product_docs' }}
                parallelization={50}
              />
            </Row>

            <Row>
              <SeedBySpaceButton
                seedFn="pgDenormalization-seedSpace"
                args={{
                  type: 'pages',
                  variant: 'uncompressed'
                }}
              >
                Seed page details by space...
              </SeedBySpaceButton>
              <SeedAllButton
                seedFn="pgDenormalization-seedSpace"
                args={{
                  type: 'pages',
                  variant: 'uncompressed'
                }}
                parallelization={20}
              />
            </Row>

            <Row>
              <SeedBySpaceButton
                seedFn="pgDenormalization-seedSpace"
                args={{
                  type: 'products',
                  variant: 'uncompressed'
                }}
              >
                Seed product details by space...
              </SeedBySpaceButton>
              <SeedAllButton
                seedFn="pgDenormalization-seedSpace"
                args={{
                  type: 'products',
                  variant: 'uncompressed'
                }}
                parallelization={20}
              />
            </Row>

            <Row>
              <Button
                onClick={async () => {
                  // go two days ahead, so that we can also try people from other timezones
                  const tk = moment().subtract(2, 'day').format(TIMEKEY_FORMAT);
                  const spaceId = 'GdFN0qKYa';
                  const tz = 'America/Toronto';
                  // const spaceId = '4oF26UAIvKcsv6YOxO7gtvrifrn2';
                  // const tz = 'Europe/Berlin'
                  const id = `${spaceId}-${tk}-${tz}`.replace('/', '-');
                  await publishTopic({
                    topic: 'pgDenormalization-pushToPageDenormalization',
                    payload: {
                      id,
                      execute: true
                    }
                  });
                }}
              >
                Test page denorm pushing
              </Button>
            </Row>

            <Row>
              <Button
                onClick={async () => {
                  // go two days ahead, so that we can also try people from other timezones
                  const tk = moment().subtract(2, 'day').format(TIMEKEY_FORMAT);
                  const spaceId = 'GdFN0qKYa';
                  const tz = 'America/Toronto';
                  // const spaceId = '4oF26UAIvKcsv6YOxO7gtvrifrn2';
                  // const tz = 'Europe/Berlin'
                  const id = `${spaceId}-${tk}-${tz}`.replace('/', '-');
                  await publishTopic({
                    topic: 'pgDenormalization-pushToProductDenormalization',
                    payload: {
                      id,
                      execute: true
                    }
                  });
                }}
              >
                Test product denorm pushing
              </Button>
            </Row>
          </Card>
        </Section>

        <Section>{result && <Json data={result} />}</Section>
      </Page>
    </>
  );
};
