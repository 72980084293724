import { store, useMappedLoadingValue } from '../../../services/db';
import { FS } from '../../../versions';
import { useCollection } from 'react-firebase-hooks/firestore/';
import { generateToDocFn } from '../../../domainTypes/document';
import { Event, EventCategory } from '../../../domainTypes/event';
import firebase from 'firebase/app';

const toEventDoc = generateToDocFn<Event>();

export type EventsFilterArgs = {
  spaceId: string | null;
  start: firebase.firestore.Timestamp | null;
  end: firebase.firestore.Timestamp | null;
  category: EventCategory | null;
  type: EventCategory | null;
};

export const filterQuery = ({
  spaceId,
  start,
  end,
  category,
  type
}: EventsFilterArgs) => {
  let query = store()
    .collection(FS.events)
    .orderBy('createdAt', 'desc');

  if (spaceId) {
    query = query.where('spaceId', '==', spaceId);
  }
  if (start) {
    query = query.where('createdAt', '>=', start);
  }
  if (end) {
    query = query.where('createdAt', '<', end);
  }
  if (type) {
    query = query.where('type', '==', type);
  }
  if (category) {
    query = query.where('category', '==', category);
  }
  return query;
};

export const useEventsFiltered = (args: EventsFilterArgs) => {
  return useMappedLoadingValue(useCollection(filterQuery(args)), s =>
    s.docs.map(toEventDoc)
  );
};
