import fetch from 'unfetch';
import { useEffect } from 'react';
import { useLoadingValue } from '../../hooks/useLoadingValue';
import { usePromise } from '../../hooks/usePromise';
import { storage } from '../db';

export interface ICloudStorageFile {
  name: string;
  data: File | Blob;
  contentType: string;
  metadata?: { [key: string]: string };
}

type Metadata<T> = {
  bucket: string;
  generation: string;
  metageneration: string;
  fullPath: string;
  name: string;
  size: number;
  timeCreated: string;
  updated: string;
  md5Hash: string;
  cacheControl: string;
  contentDisposition: string;
  contentEncoding: string;
  contentLanguage: string;
  contentType: string;
  customMetadata: T;
};

// these can never be invalidated atm
const DOWNLOAD_URL_CACHE: { [storagePath: string]: string } = {};
const METADATA_CACHE: { [storagePath: string]: Metadata<any> } = {};

export const useFile = <T>(
  storagePath: string,
  mapFn: (data: string) => T
): [T | undefined, boolean, any] => {
  const [url] = useDownloadUrl(storagePath);
  const {
    value,
    loading,
    error,
    setValue,
    setLoading,
    setError
  } = useLoadingValue<T>(undefined, true);
  useEffect(() => {
    if (!url) {
      return;
    }
    const timeout = setTimeout(() => {
      setLoading(true);
    }, 500);
    fetch(url)
      .then(async (response) => {
        clearTimeout(timeout);
        const data = await response.text();
        if (response.ok) {
          setValue(mapFn(data));
        } else {
          setError(data);
        }
      })
      .catch((err) => {
        clearTimeout(timeout);
        setError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return [value, loading, error];
};

export const getStorageRef = (storagePath: string) =>
  storage().ref(storagePath);

export const getDownloadUrl = async (storagePath: string) => {
  const cached = DOWNLOAD_URL_CACHE[storagePath];
  if (cached) {
    return Promise.resolve(cached);
  }
  return getStorageRef(storagePath)
    .getDownloadURL()
    .then((url: string) => {
      DOWNLOAD_URL_CACHE[storagePath] = url;
      return url;
    });
};

export const getFileContent = async (storagePath: string) => {
  const url = await getDownloadUrl(storagePath);
  return fetch(url).then((r) => r.text());
};

export const useFileExists = (storagePath: string) => {
  usePromise(
    () =>
      getDownloadUrl(storagePath).then(
        () => true,
        () => false
      ),
    [storagePath]
  );
};

export const getMetadata = <T>(storagePath: string) => {
  const cached = METADATA_CACHE[storagePath];
  if (cached) {
    return Promise.resolve(cached as Metadata<T>);
  }
  return getStorageRef(storagePath)
    .getMetadata()
    .then((m: Metadata<T>) => {
      METADATA_CACHE[storagePath] = m;
      return m;
    });
};

export const useMetadata = <T>(storagePath: string) => {
  return usePromise(() => getMetadata<T>(storagePath), [storagePath]);
};

export const useDownloadUrl = (storagePath: string) =>
  usePromise(() => getDownloadUrl(storagePath), [storagePath]);

export const useDownloadUrls = (storagePaths: string[]) =>
  usePromise(() => Promise.all(storagePaths.map(getDownloadUrl)), storagePaths);

export const useDownloadUrlsWithCustomMetadata = <T>(
  storagePaths: string[]
) => {
  const zipData = ([urls, metadata]: [string[], Metadata<T>[]]) => {
    const result: { url: string; metadata: Metadata<T> }[] = [];
    for (let i = 0; i < urls.length; i++) {
      result.push({ url: urls[i], metadata: metadata[i] });
    }
    return result;
  };
  const [value, loading, error] = usePromise(
    () =>
      Promise.all([
        Promise.all(storagePaths.map(getDownloadUrl)),
        Promise.all(storagePaths.map((p) => getMetadata<T>(p)))
      ]),
    storagePaths
  );
  const v = value ? zipData(value) : value;
  return [v, loading, error] as [typeof v, typeof loading, typeof error];
};

export const useDownloadUrlWithCustomMetadata = <T>(storagePath: string) => {
  const [url, urlLoading, urlError] = useDownloadUrl(storagePath);
  const [metadata, metadataLoading, metadataError] = useMetadata<T>(
    storagePath
  );

  const v = url && metadata ? { url, metadata } : undefined;
  const loading = urlLoading || metadataLoading;
  const error = urlError || metadataError;
  return [v, loading, error] as [typeof v, typeof loading, typeof error];
};

export const storeFile = async (file: ICloudStorageFile) => {
  const ref = getStorageRef(file.name);

  await ref.put(file.data, {
    contentType: file.contentType,
    customMetadata: file.metadata
  });
};

export const storeAllFiles = (files: ICloudStorageFile[]) => {
  return Promise.all(files.map(storeFile));
};

export const downloadFromCloudStorage = async (storagePath: string) => {
  const url = await getDownloadUrl(storagePath);
  const a = document.createElement('a');
  a.href = url;
  a.download = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// This is used if you are passing a gs:// url, because
// that allows us to specify the bucket in the URL
export const downloadFromCloudStorageWithPath = async (storagePath: string) => {
  const url = await storage().refFromURL(storagePath).getDownloadURL();
  const a = document.createElement('a');
  a.href = url;
  a.download = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
