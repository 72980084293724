import firebase from 'firebase/app';
import { keyBy, mapValues } from 'lodash';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Doc } from '../../../domainTypes/document';
import { getLinksForProduct, IProduct } from '../../../domainTypes/product';
import { useMappedLoadingValue, store } from '../../../services/db';
import { FS } from '../../../versions';
import {
  IProductMap,
  ProductUrlsToIdsV3
} from '../../../domainTypes/productMap';
import { removeTrailingSlash } from '../../../services/url';

const toProductMap = (s: firebase.firestore.DocumentSnapshot): IProductMap => {
  const data: any = s.data() || {};
  if (!data.v) {
    return {
      v: 3,
      data: mapValues(data, (v) => ({ pId: v, pK: '' }))
    };
  }
  if (data.v === 2) {
    return {
      v: 3,
      data: mapValues(data.data, (v) => ({ pId: v, pK: '' }))
    };
  }
  if (data.v === 3) {
    return data;
  }

  return {
    v: 3,
    data: {}
  };
};

const getProductMapQuery = (spaceId: string) => {
  return store().collection(FS.productMaps).doc(spaceId);
};

export const getProductMapBySpaceId = (spaceId: string) => {
  getProductMapQuery(spaceId).get().then(toProductMap);
};

export const useProductMapBySpaceId = (spaceId: string) => {
  return useMappedLoadingValue(
    useDocument(getProductMapQuery(spaceId)),
    toProductMap
  );
};

export const analyzeProductMap = (
  productMap: ProductUrlsToIdsV3,
  products: Doc<IProduct>[]
) => {
  const productsById = keyBy(products, (p) => p.id);
  const productIdsInMap = new Set(Object.values(productMap).map((p) => p.pId));

  const missingInMap: string[] = [];
  const addedInMap: string[] = [];
  const wrongUrl: string[] = [];

  products.forEach((p) => {
    if (!productIdsInMap.has(p.id)) {
      missingInMap.push(p.id);
    }
  });

  Object.keys(productMap).forEach((href) => {
    const p = productMap[href];
    const product = productsById[p.pId];
    if (!product) {
      addedInMap.push(p.pId);
      return;
    }
    const links = getLinksForProduct(product.data);
    if (links.indexOf(removeTrailingSlash(href)) === -1) {
      wrongUrl.push(p.pId);
    }
  });

  return {
    missingInMap,
    addedInMap,
    wrongUrl
  };
};
