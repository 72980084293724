import { Paper } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../components/AdditionalActionsMenu';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { IColumn } from '../../../../../components/Table/Column';
import { Doc } from '../../../../../domainTypes/document';
import {
  ILinkCheckPackage,
  LinkCheckStatus
} from '../../../../../domainTypes/linkCheckV2';
import { CheckStatusChip } from '../../../../../features/LinkCheck/components/CheckStatusChip';
import { toPackageDoc } from '../../../../../features/LinkCheck/service';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Section } from '../../../../../layout/Section';
import { store, useMappedLoadingValue } from '../../../../../services/db';
import { formatDatePrecise } from '../../../../../services/time';
import { FS } from '../../../../../versions';
import { toFirestoreConsole } from '../../../../services/firebase';

type Props = {};

const useQueuedAndRunningPackages = () => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.linkCheckPackages)
        .where('status', 'in', [
          LinkCheckStatus.QUEUED,
          LinkCheckStatus.RUNNING
        ])
    ),
    (s) => s.docs.map(toPackageDoc)
  );
};

type Data = Doc<ILinkCheckPackage>;
type ColumnName =
  | 'id'
  | 'parent'
  | 'status'
  | 'priority'
  | 'targets'
  | 'queuedAt'
  | 'startedAt'
  | 'actions';

type Column = IColumn<Data, ColumnName>;

const Actions = ({ d }: { d: Data }) => {
  const options: AdditionActionsMenuOption[] = useMemo(() => {
    return [
      {
        key: 'openInFirestore',
        label: 'Open in Firestore console',
        externalHref: toFirestoreConsole(FS.linkCheckWorkers, d.id)
      }
    ];
  }, [d]);
  return <AdditionalActionsMenu options={options} />;
};

const COLUMNS: Column[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => d.id,
    sortable: true,
    align: 'left',
    width: 70,
    flexGrow: 1
  },
  {
    key: 'parent',
    head: () => 'Parent',
    cell: (d) => `${d.data.parent.collection} - ${d.data.parent.id}`,
    sortable: true,
    align: 'left',
    width: 120,
    flexGrow: 2
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => <CheckStatusChip status={d.data.status} />,
    sortable: true,
    align: 'center',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'priority',
    head: () => 'Priority',
    cell: (d) => d.data.priority,
    sortable: true,
    align: 'right',
    width: 70,
    flexGrow: 0
  },
  {
    key: 'targets',
    head: () => 'Targets',
    cell: (d) => d.data.targets.length,
    sortable: true,
    align: 'right',
    width: 70,
    flexGrow: 0
  },
  {
    key: 'queuedAt',
    head: () => 'Queued At',
    cell: (d) => formatDatePrecise(d.data.queuedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'startedAt',
    head: () => 'Started At',
    cell: (d) => d.data.startedAt && formatDatePrecise(d.data.startedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    sortable: false,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Data> = {
  id: {
    key: 'id',
    items: { sort: (d) => d.id, dir: 'asc' }
  },
  parent: {
    key: 'parent',
    items: {
      sort: (d) => `${d.data.parent.collection} - ${d.data.parent.id}`,
      dir: 'asc'
    }
  },
  status: {
    key: 'status',
    items: { sort: (d) => d.data.status, dir: 'asc' }
  },
  priority: {
    key: 'priority',
    items: { sort: (d) => d.data.priority, dir: 'desc' }
  },
  targets: {
    key: 'targets',
    items: { sort: (d) => d.data.targets.length, dir: 'desc' }
  },
  queuedAt: {
    key: 'queuedAt',
    items: { sort: (d) => d.data.queuedAt.toMillis(), dir: 'desc' }
  },
  startedAt: {
    key: 'startedAt',
    items: { sort: (d) => d.data.startedAt?.toMillis() || 0, dir: 'desc' }
  }
};

const DEFAULT_SORTER = SORTERS.queuedAt;

export const PageLinkCheckPackages: React.FC<Props> = () => {
  const [ds, loading, error] = useQueuedAndRunningPackages();
  useErrorLogger(error);

  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  return (
    <Section>
      <CanvasBar>Queued {'&'} running packages</CanvasBar>
      <Paper>
        {loading && <Loader height={500} />}
        {ds && (
          <RowsRenderer
            variant="contained"
            columns={COLUMNS}
            rows={ds}
            rowToKey={(d) => d.id}
            sorter={sorter || DEFAULT_SORTER}
            sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
            renderHead={true}
            onHeadClick={(c, d) =>
              setSort([SORTERS[c.key] || DEFAULT_SORTER, d])
            }
            chunkSize={30}
            rootMargin="400px"
            otherProps={undefined}
            rowHeight={ROW_HEIGHTS.dense}
          />
        )}
      </Paper>
    </Section>
  );
};
