import React from 'react';
import { ITagPrototype } from '../../../domainTypes/tag';

import { getColorDef } from '../../../domainTypes/colors';
import { ChipProps, Chip, Tooltip } from '@material-ui/core';

type Props = {
  t: ITagPrototype;
  short?: boolean;
} & Pick<ChipProps, 'clickable' | 'size' | 'variant' | 'disabled' | 'onDelete'>;

export const Tag = ({ t, short, ...other }: Props) => {
  const c = getColorDef(t.color);
  return (
    <Tooltip title={short ? t.name : ''}>
      <Chip
        style={{ color: c.color, backgroundColor: c.bgColor }}
        label={short ? t.name[0] : t.name}
        {...other}
      />
    </Tooltip>
  );
};
