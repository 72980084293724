import { Button, Card, CardContent, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Loader } from '../../../../../components/Loader';
import { usePromise } from '../../../../../hooks/usePromise';
import { Centered } from '../../../../../layout/Centered';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { styled } from '../../../../../emotion';

const HEIGHT = 300;

const Form = styled('form')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  margin-top: ${(p) => p.theme.spacing(4)}px;
`;

const toJson = (t: any) => JSON.stringify(t, null, 2);

const Result = ({ url }: { url: string }) => {
  const [value, loading, error] = usePromise(() => {
    if (!url) {
      return Promise.resolve(null);
    }
    return callFirebaseFunction('scraping-scanPage', { url });
  }, [url]);
  return (
    <Card>
      <CardContent>
        {!url && <Centered height={HEIGHT}>Waiting for input...</Centered>}
        {url && loading && <Loader height={HEIGHT} />}
        {url && error && <code>{toJson(error)}</code>}
        {url && !loading && value && <code>{toJson(value)}</code>}
      </CardContent>
    </Card>
  );
};

export const PageScanTest = () => {
  const [url, setUrl] = useState('');
  const [urlToScan, setUrlToScan] = useState('');
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setUrlToScan(url);
  };
  return (
    <Page>
      <Section>
        <Form onSubmit={onSubmit}>
          <TextField
            value={url}
            placeholder="URL to a single page you want to scan"
            variant="outlined"
            onChange={(ev) => setUrl(ev.target.value)}
          />
          <Button color="primary" variant="contained" type="submit">
            Scan Website
          </Button>
        </Form>
      </Section>
      <Section>
        <Result url={urlToScan} />
      </Section>
    </Page>
  );
};
