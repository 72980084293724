import { generateToDocFn, Doc } from '../../../domainTypes/document';

import {
  ILinkCheckPackage,
  LinkCheckType,
  IProductData
} from '../../../domainTypes/linkCheckV2';
import { ILinkCheckAppContainer } from '../../../domainTypes/linkCheckApp';
import {
  useMappedLoadingValue,
  combineLoadingValues,
  store,
  LoadingValue
} from '../../../services/db';
import {
  useDocumentListeners,
  DocumentListener
} from '../../../services/firecache/documentListener';
import { useProductsBySpaceId } from '../../../services/products';
import { useMemo } from 'react';
import { FS } from '../../../versions';
import { flatten, keyBy } from 'lodash';
import {
  getDestinationUrlForProduct,
  IProduct
} from '../../../domainTypes/product';
import md5 from 'md5';

export const toPackageDoc = generateToDocFn<ILinkCheckPackage>();

export type LinkCheckResultWithProduct = {
  product: Doc<IProduct>;
  result: {
    type: LinkCheckType;
    data: IProductData;
  } | null;
};

export const useLinkCheckResults = (
  d: Doc<ILinkCheckAppContainer>
): LoadingValue<LinkCheckResultWithProduct[]> => {
  const packageListeners = useMemo(() => {
    const ids = Object.keys(d.data.packages);
    return ids.map(
      (id) =>
        new DocumentListener(
          store().collection(FS.linkCheckPackages).doc(id),
          toPackageDoc
        )
    );
  }, [d]);

  return useMappedLoadingValue(
    combineLoadingValues(
      useDocumentListeners(packageListeners),
      useProductsBySpaceId(d.data.spaceId)
    ),
    ([packages, products]) => {
      const results = flatten(
        packages.map((p) => {
          if (!p) {
            return [];
          }
          return p.data.results.map((result) => ({
            type: p.data.type,
            data: result
          }));
        })
      );
      const resultsById = keyBy(results, (r) => r.data.id);

      return products.map((p) => {
        const destUrl = getDestinationUrlForProduct(p.data);
        const r = resultsById[md5(destUrl)] || null;

        return {
          product: p,
          result: r
        };
      });
    }
  );
};
