import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { AlertBox } from '../../../../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../../../../components/ButtonWithPromise';
import { DeletionConfirmation } from '../../../../../../../components/DeletionConfirmation';
import { Form } from '../../../../../../../components/Form';
import { FormField } from '../../../../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../../../../components/Form/FormSubmitButton';
import { formatCurrency } from '../../../../../../../components/Number';
import { DAY_FORMAT } from '../../../../../../../domainTypes/analytics';
import { Doc } from '../../../../../../../domainTypes/document';
import { IPageScreenshot } from '../../../../../../../domainTypes/page';
import {
  IEarning,
  ITrackedSale
} from '../../../../../../../domainTypes/performance';
import { Schedule } from '../../../../../../../domainTypes/schedule';
import { ISpace } from '../../../../../../../domainTypes/space';
import { css } from '../../../../../../../emotion';
import { useDialogState } from '../../../../../../../hooks/useDialogState';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { FlexContainer } from '../../../../../../../layout/Flex';
import { Section } from '../../../../../../../layout/Section';
import { batchDelete, rdb, store } from '../../../../../../../services/db';
import { callFirebaseFunction } from '../../../../../../../services/firebaseFunctions';
import { createScreenshotDoc } from '../../../../../../../services/pageScreenshots';
import { createPin } from '../../../../../../../services/pin';
import { createProductScanInSpace } from '../../../../../../../services/scan';
import {
  getSchedulesForSpaceQuery,
  toScheduleDocs,
  updateOrCreateSchedule
} from '../../../../../../../services/schedules/helper';
import { getActiveDomainUrls } from '../../../../../../../services/space';
import { CF, FS, RDB } from '../../../../../../../versions';
import { ButtonContainer } from '../../../../../../components/ButtonContainer';
import { SafeExecuteButton } from '../../../../../../components/SafeExecuteButton';
import {
  denormalizeAnalyticsForSpace,
  denormalizeAnalyticsForSpaceInTimeframe
} from '../../../../../../services/denormalization';
import { checkSpaceForModifications } from '../../../../../../services/modifications';
import { publishTopic } from '../../../../../../services/pubsub';
import { archiveSpace } from '../../../../../../services/space';

type Props = {
  space: Doc<ISpace>;
};

type ScreenshotCreateProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (url: string) => Promise<Doc<IPageScreenshot>>;
};

const RelinkSalesDialog = ({
  open,
  onClose,
  onSubmit
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (start: number, end: number) => Promise<void>;
}) => {
  const [day, setDay] = useState(moment());
  const submit = () => {
    const start = day.format('x');
    const end = day.clone().add(1, 'd').format('x');
    return onSubmit(parseInt(start), parseInt(end));
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Relink sales from a single day</DialogTitle>
      <DialogContent>
        <DatePicker
          autoOk
          variant="static"
          value={day}
          clearable={false}
          disableFuture={true}
          onChange={(m) => setDay(m!.clone())}
        />
        <AlertBox variant="pending">
          Just specify the day - it will be invoked in the correct TZ
          automatically.
        </AlertBox>
      </DialogContent>
      <DialogActions>
        <ButtonContainer>
          <Button onClick={onClose}>Cancel</Button>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            onClick={submit}
            pending="Relinking sales..."
          >
            Relink sales
          </ButtonWithPromise>
        </ButtonContainer>
      </DialogActions>
    </Dialog>
  );
};

const DenormalizeSingleDayDialog = ({
  open,
  onClose,
  onSubmit
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (start: string, end: string) => Promise<void>;
}) => {
  const [day, setDay] = useState(moment());
  const submit = () => {
    const start = day.format(DAY_FORMAT);
    const end = day.clone().add(1, 'd').format(DAY_FORMAT);
    return onSubmit(start, end);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Denormalize a single day</DialogTitle>
      <DialogContent>
        <DatePicker
          autoOk
          variant="static"
          value={day}
          clearable={false}
          disableFuture={true}
          onChange={(m) => setDay(m!.clone())}
        />
        <AlertBox variant="pending">
          Just specify the day - it will be invoked in the correct TZ
          automatically.
        </AlertBox>
      </DialogContent>
      <DialogActions>
        <ButtonContainer>
          <Button onClick={onClose}>Cancel</Button>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            onClick={submit}
            pending="Denormalizing..."
          >
            Denormalize
          </ButtonWithPromise>
        </ButtonContainer>
      </DialogActions>
    </Dialog>
  );
};

const ScreenshotCreateDialog = ({
  open,
  onClose,
  onSubmit
}: ScreenshotCreateProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = () => {
    enqueueSnackbar('Screenshot created', { variant: 'success' });
    onClose();
  };
  const onError = () => {
    enqueueSnackbar('Failed to create screenshot', { variant: 'error' });
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <DialogTitle>Create a new screenshot</DialogTitle>
      <DialogContent>
        <Form
          initialValues={{ url: '' }}
          onSubmit={({ url }) => onSubmit(url).then(onSuccess, onError)}
          className={css((t) => ({
            minWidth: 500,
            width: '100%'
          }))}
        >
          {({ submitting, values }) => (
            <>
              <FormField name="url" type="input">
                {({ input }) => (
                  <TextField
                    {...input}
                    required
                    label="Page URL"
                    margin="normal"
                    fullWidth={true}
                  />
                )}
              </FormField>
              <div
                className={css((t) => ({
                  marginTop: t.spacing() * 6,
                  display: 'flex',
                  justifyContent: 'center'
                }))}
              >
                <FormSubmitButton
                  disabled={!values.url}
                  className={css((t) => ({ width: '100%' }))}
                  submitting={submitting}
                  submitComponent="Creating..."
                >
                  Create
                </FormSubmitButton>
              </div>
            </>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const RemoveScreenshots: React.FC<{ spaceId: string }> = ({ spaceId }) => {
  const [open, setOpen] = useState(false);
  const remove = async () => {
    if (!spaceId) {
      throw new Error('No space id!');
    }
    await store()
      .collection(FS.pageScreenshots)
      .where('spaceId', '==', spaceId)
      .get()
      .then((s) => {
        return batchDelete(
          FS.pageScreenshots,
          s.docs.map((d) => d.id)
        );
      });

    await rdb().ref([RDB.pageQueue, spaceId].join('/')).set(null);
  };
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        Remove all screenshot data
      </Button>
      <DeletionConfirmation
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={remove}
        keyword={spaceId}
      />
    </>
  );
};

const ArchiveAction = ({ spaceId }: { spaceId: string }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [requiredPin, setRequiredPin] = useState(createPin(6));
  const [pin, setPin] = useState('');
  useEffect(() => {
    if (dialogOpen) {
      setPin('');
      setRequiredPin(createPin(6));
    }
  }, [dialogOpen]);
  const archive = (remove = false) => {
    return archiveSpace(spaceId, remove).then(closeDialog);
  };
  return (
    <>
      <Button variant="outlined" color="secondary" onClick={openDialog}>
        Archive
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Archive space?</DialogTitle>
        <DialogContent>
          <p>
            Enter the pin <strong>{requiredPin}</strong> to approve removal.
          </p>
          <div>
            <TextField
              value={pin}
              fullWidth={true}
              placeholder="Pin"
              onChange={(ev) => setPin(ev.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonWithPromise
            variant="contained"
            color="secondary"
            onClick={() => archive(true)}
            pending="Archiving..."
            disabled={requiredPin !== pin}
          >
            Create archive and delete
          </ButtonWithPromise>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            onClick={() => archive()}
            pending="Archiving"
          >
            Create test archive
          </ButtonWithPromise>
          <Button onClick={closeDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const updateTimezoneOnAllSchedules = async (
  space: Doc<ISpace>,
  execute: boolean
) => {
  const docs = await getSchedulesForSpaceQuery(space.id)
    .get()
    .then(toScheduleDocs);
  const toUpdate = docs.reduce<Doc<Schedule>[]>((m, d) => {
    if (d.data.frequency.type !== 'NONE') {
      d.data.frequency.tz = space.data.config.tz;
      m.push(d);
    }
    return m;
  }, []);

  console.log('toUpdate', toUpdate);
  if (execute) {
    await Promise.all(
      toUpdate.map((d) => updateOrCreateSchedule(d.id, d.data))
    );
  }
};

export const Actions: React.FC<Props> = ({ space }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [screenshotDialogOpen, setScreenshotDialogOpen] = useState(false);
  const [singleDayDenormOpen, setSingleDayDenormOpen] = useState(false);
  const [relinkSalesOpen, setRelinkSalesOpen] = useState(false);

  const [denormalizationParams, setDenormalizationParams] = useState<{
    start: string;
    end: string;
    execute: boolean;
  }>({
    start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    execute: true
  });

  return (
    <Section>
      <CanvasBar>Actions</CanvasBar>
      <Card>
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Analytics
          </Typography>
          <ButtonContainer>
            <ButtonWithPromise
              variant="outlined"
              onClick={() =>
                denormalizeAnalyticsForSpace(space.data).then(() => {
                  enqueueSnackbar('Denormalization successful!', {
                    variant: 'success'
                  });
                })
              }
              pending="Denormalizing..."
            >
              Denormalize Analytics
            </ButtonWithPromise>

            <Button
              variant="outlined"
              color="default"
              onClick={() => setSingleDayDenormOpen(true)}
            >
              Denormalize Analytics for single day...
            </Button>
            <ButtonWithPromise
              variant="outlined"
              pending="Refreshing..."
              onClick={() =>
                callFirebaseFunction(
                  CF.trackingConfig_v2.refreshTrackingConfig,
                  {
                    spaceId: space.id
                  }
                )
              }
            >
              Refresh Tracking Config V2 (Redis)
            </ButtonWithPromise>

            <ButtonWithPromise
              variant="outlined"
              pending="Refreshing..."
              onClick={() =>
                callFirebaseFunction(
                  'trackingConfig_v2-refreshApiTokenVerificationData',
                  {
                    spaceId: space.id
                  }
                )
              }
            >
              Refresh Api Token Verfication Data (Redis)
            </ButtonWithPromise>
          </ButtonContainer>
          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Products
          </Typography>
          <ButtonContainer>
            <DenormalizeSingleDayDialog
              open={singleDayDenormOpen}
              onClose={() => setSingleDayDenormOpen(false)}
              onSubmit={(start, end) =>
                denormalizeAnalyticsForSpaceInTimeframe(space.id, {
                  start,
                  end,
                  tz: space.data.config.tz || ''
                })
              }
            />

            <SafeExecuteButton
              variant="outlined"
              onClick={(execute) =>
                callFirebaseFunction(
                  'products-updateDestinationsUrlsInSpace ',
                  { spaceId: space.id, execute }
                )
              }
            >
              Update Product Destination URLs...
            </SafeExecuteButton>
            <SafeExecuteButton
              variant="outlined"
              onClick={(execute) =>
                callFirebaseFunction('products-seedProductsToBqForSpace', {
                  spaceId: space.id,
                  execute
                })
              }
            >
              Seed product data to BigQuery...
            </SafeExecuteButton>
          </ButtonContainer>
          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Daily Email Reports
          </Typography>
          <ButtonContainer>
            <ButtonWithPromise
              variant="outlined"
              pending="Testing..."
              onClick={() =>
                callFirebaseFunction('analytics-testCreateReport', {
                  spaceId: space.id
                }).then((r) => console.log('REPORT', r))
              }
            >
              Test REPORT mail (sends to console)
            </ButtonWithPromise>
            <Button
              variant="outlined"
              onClick={() => {
                const end = moment.tz(space.data.config.tz).startOf('d');
                const start = end.clone().subtract(1, 'd');
                callFirebaseFunction('reporting-_dailyEarningsReportDebug', {
                  spaceId: space.id,
                  start: start.valueOf(),
                  end: end.valueOf(),
                  tz: space.data.config.tz || 'UTC'
                }).then((stats: any) => {
                  const total = stats.networks.reduce(
                    (r: number, s: { earnings: IEarning }) =>
                      r + s.earnings.total,
                    0
                  );
                  console.log({
                    start,
                    end,
                    stats,
                    total: formatCurrency(total, 'USD')
                  });
                });
              }}
            >
              Debug earnings report
            </Button>
          </ButtonContainer>
          <br />

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Page Queue
          </Typography>
          <ButtonContainer>
            <ButtonWithPromise
              variant="outlined"
              onClick={() => checkSpaceForModifications(space.id, false)}
              pending="Checking..."
            >
              Check for new revisions and queue screenshots
            </ButtonWithPromise>
            <ButtonWithPromise
              variant="outlined"
              onClick={() => checkSpaceForModifications(space.id, true)}
              pending="Checking..."
              color="secondary"
            >
              Check for revisions and force-take new screenshots
            </ButtonWithPromise>
            <ButtonWithPromise
              variant="outlined"
              onClick={() =>
                callFirebaseFunction('pages-resumeQueue', {
                  spaceId: space.id
                })
              }
              pending="Resuming..."
            >
              Resume current queue
            </ButtonWithPromise>

            <Button
              variant="outlined"
              onClick={() => setScreenshotDialogOpen(true)}
            >
              Create a screenshot...
            </Button>

            <ScreenshotCreateDialog
              open={screenshotDialogOpen}
              onClose={() => setScreenshotDialogOpen(false)}
              onSubmit={(url) =>
                createScreenshotDoc(space.id, url, 'AFFILIMATE')
              }
            />
            <RemoveScreenshots spaceId={space.id} />
          </ButtonContainer>
          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Site Scan
          </Typography>
          <ButtonContainer>
            <ButtonWithPromise
              variant="outlined"
              onClick={() =>
                createProductScanInSpace(
                  space.id,
                  getActiveDomainUrls(space.data),
                  'DOMAIN',
                  'PARTIAL',
                  'AFFILIMATE'
                )
              }
              pending="Starting..."
            >
              Start partial site scan
            </ButtonWithPromise>
          </ButtonContainer>
          <br />

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Sales
          </Typography>
          <ButtonContainer>
            <RelinkSalesDialog
              open={relinkSalesOpen}
              onClose={() => setRelinkSalesOpen(false)}
              onSubmit={async (start, end) => {
                await callFirebaseFunction<Doc<ITrackedSale>[]>(
                  CF.reporting.relinkSales,
                  {
                    spaceId: space.id,
                    start,
                    end,
                    execute: true
                  }
                );
              }}
            />
            <Button
              variant="outlined"
              color="default"
              onClick={() => setRelinkSalesOpen(true)}
            >
              Relink sales for a single day...
            </Button>
          </ButtonContainer>

          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Schedules
          </Typography>
          <ButtonContainer>
            <SafeExecuteButton
              variant="outlined"
              onClick={(execute) =>
                updateTimezoneOnAllSchedules(space, execute)
              }
            >
              Update Timezone on all schedules
            </SafeExecuteButton>
          </ButtonContainer>

          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Authors
          </Typography>
          <ButtonContainer>
            <ButtonWithPromise
              variant="outlined"
              onClick={async () => {
                return await callFirebaseFunction(
                  CF.tags.synchroniseAuthorTags,
                  {
                    spaceId: space.id
                  }
                );
              }}
              pending="Syncing ..."
            >
              Sync Authors
            </ButtonWithPromise>
          </ButtonContainer>
          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            Space
          </Typography>
          <ButtonContainer>
            <Button
              variant="outlined"
              onClick={() =>
                store().collection(FS.spaces).doc(space.id).update({
                  active: !space.data.active
                })
              }
            >
              {space.data.active ? 'Deactivate' : 'Activate'}
            </Button>
            <ArchiveAction spaceId={space.id} />
          </ButtonContainer>
        </CardContent>
      </Card>

      <Card>
        <Typography variant="h6">
          Manual denormalizations with new extraction system
        </Typography>
        <br />
        <FlexContainer marginBottom={1}>
          <TextField
            fullWidth
            label="TF start"
            variant="outlined"
            value={denormalizationParams.start}
            onChange={(ev) =>
              setDenormalizationParams((x) => ({
                ...x,
                start: ev.target.value
              }))
            }
          />
          <TextField
            fullWidth
            label="TF end"
            variant="outlined"
            value={denormalizationParams.end}
            onChange={(ev) =>
              setDenormalizationParams((x) => ({
                ...x,
                end: ev.target.value
              }))
            }
          />

          <FormControlLabel
            control={
              <Switch
                checked={denormalizationParams.execute}
                color="primary"
                onChange={(ev) => {
                  setDenormalizationParams((x) => ({
                    ...x,
                    execute: ev.target.checked
                  }));
                }}
              />
            }
            label="Execute"
          />
        </FlexContainer>

        <ButtonContainer>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending="let's go"
            onClick={async () => {
              const tz = space.data.config.tz;
              const params = {
                spaceId: space.id,
                tf: {
                  start: denormalizationParams.start,
                  end: denormalizationParams.end,
                  tz
                },
                execute: denormalizationParams.execute
              };
              await publishTopic({
                topic: 'denormalization-identifyMissingLinksAndAssignIds',
                payload: params
              });
            }}
          >
            Assign product/link ids
          </ButtonWithPromise>

          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending="let's go"
            onClick={async () => {
              const tz = space.data.config.tz;
              const params = {
                spaces: [
                  {
                    spaceId: space.id,
                    tz
                  }
                ],
                tz,
                timeframe: {
                  start: denormalizationParams.start,
                  end: denormalizationParams.end,
                  tz
                },
                execute: denormalizationParams.execute
              };
              console.log(params);
              await publishTopic({
                topic: 'denormalization-denormalizeWithNewQuery',
                payload: params
              });
            }}
          >
            Denormalize with new query
          </ButtonWithPromise>

          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending="let's go"
            onClick={async () => {
              const tz = space.data.config.tz;
              const params = {
                spaces: [
                  {
                    spaceId: space.id,
                    tz
                  }
                ],
                tz,
                timeframe: {
                  start: denormalizationParams.start,
                  end: denormalizationParams.end,
                  tz
                },
                execute: denormalizationParams.execute
              };
              console.log(params);
              await publishTopic({
                topic: 'denormalization-denormalizeWithNewQueryAsync',
                payload: params
              });
            }}
          >
            Denormalize with new query ASYNC
          </ButtonWithPromise>

          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending="let's go"
            onClick={async () => {
              const tz = space.data.config.tz;
              const params = {
                spaceId: space.id,
                tf: {
                  start: denormalizationParams.start,
                  end: denormalizationParams.end,
                  tz
                },
                execute: denormalizationParams.execute
              };
              console.log(params);
              await publishTopic({
                topic:
                  'denormalization-denormalizeExternalClicksEventsForSpace',
                payload: params
              });
            }}
          >
            Denormalize external click events
          </ButtonWithPromise>

          {/* <ButtonWithPromise
            pending="let's go"
            onClick={async () => {
              await publishTopic({
                topic: 'denormalization-distributeMissingProducts',
                payload: {
                  payload: {
                    fileName:
                      'denormalizations/missingProducts/WOO2CVcgO-2023-09-07-2023-09-08'
                  }
                }
              });
            }}
          >
            Distribute
          </ButtonWithPromise> */}
        </ButtonContainer>
      </Card>
    </Section>
  );
};
