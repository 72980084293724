import { Timestamp } from './time';

export type FullStoryMetadata = {
  uid: string;
  displayName: string;
  email: string;
  numSessions: string; // it's actually a number
  firstSeen: string | null; // ISO date
  lastSeen: string | null; // ISO date
};

export type UserEngagementSessions = {
  firstSeen: Timestamp | null;
  lastSeen: Timestamp | null;
  count: number;
};

export interface IUserEngagement {
  sessions: UserEngagementSessions & {
    lastUpdate: Timestamp | null; // as we denormalize, that's also good to know
  };
}

export const EMPTY_USER_ENGAGEMENT = (): IUserEngagement => ({
  sessions: {
    firstSeen: null,
    lastSeen: null,
    count: 0,
    lastUpdate: null
  }
});

export const mergeSessionData = (sessionData: UserEngagementSessions[]) => {
  return sessionData.reduce<UserEngagementSessions>((m, s) => {
    return {
      firstSeen:
        m.firstSeen === null
          ? s.firstSeen
          : s.firstSeen === null
          ? m.firstSeen
          : m.firstSeen.toMillis() < s.firstSeen.toMillis()
          ? m.firstSeen
          : s.firstSeen,
      lastSeen:
        m.lastSeen === null
          ? s.lastSeen
          : s.lastSeen === null
          ? m.lastSeen
          : m.lastSeen.toMillis() > s.lastSeen.toMillis()
          ? m.lastSeen
          : s.lastSeen,
      count: m.count + s.count
    };
  }, EMPTY_USER_ENGAGEMENT().sessions);
};
