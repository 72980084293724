import { Card, CardContent, Chip, Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { InfoTabBody, InfoTabLabel } from '../../../../components/InfoTabs';
import { Loader } from '../../../../components/Loader';
import { PartnerLogo } from '../../../../components/PartnerLogo';
import { ProgressRing } from '../../../../components/ProgressRing';
import { Stopwatch } from '../../../../components/Stopwatch';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../components/Table';
import { IColumn } from '../../../../components/Table/Column';
import { Doc } from '../../../../domainTypes/document';
import { EMPTY_OBJ } from '../../../../domainTypes/emptyConstants';
import { ILinkCheckAppContainer } from '../../../../domainTypes/linkCheckApp';
import {
  LinkCheckStatus,
  ProductDataStatus
} from '../../../../domainTypes/linkCheckV2';
import { styled } from '../../../../emotion';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { CanvasBar } from '../../../../layout/Canvas';
import { Centered } from '../../../../layout/Centered';
import { Section } from '../../../../layout/Section';
import { useQueryParam } from '../../../../routes';
import { getCurrentPartnerForProduct } from '../../../../services/partner';
import {
  formatDate,
  getTimeElapsed,
  toMoment
} from '../../../../services/time';
import { LinkCheckResultWithProduct, useLinkCheckResults } from '../../service';
import { CheckStatusChip } from '../CheckStatusChip';
import { ProductLinkCell } from '../../../Links/pages/Overview/components/ProductLinkCell';

type Props = {
  d: Doc<ILinkCheckAppContainer>;
};

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

const NumberGrid = styled('div')`
  display: grid;
  margin-top: ${(p) => p.theme.spacing(3)}px;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  grid-template-columns: 1fr 1fr;

  div {
    text-align: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

const Tracker = ({ d }: { d: Doc<ILinkCheckAppContainer> }) => {
  const { status, counts } = d.data;
  return (
    <Section>
      <CanvasBar>
        Check status <CheckStatusChip status={status} />
      </CanvasBar>
      <Card>
        <CardContent>
          <Grid style={{ textAlign: 'center' }}>
            <Typography variant="body2">
              {d.data.startedAt &&
                d.data.finishedAt &&
                `Finished in ${getTimeElapsed(
                  toMoment(d.data.startedAt),
                  toMoment(d.data.finishedAt)
                )} seconds`}
              {d.data.startedAt && !d.data.finishedAt && (
                <span>
                  Running for <Stopwatch start={toMoment(d.data.startedAt)} />{' '}
                  seconds...
                </span>
              )}
            </Typography>
            <Typography variant="body2">
              Queued at {formatDate(toMoment(d.data.queuedAt))}
            </Typography>
          </Grid>
          <Grid>
            <Centered height={200}>
              {status === 'QUEUED' ? (
                <Typography paragraph>Waiting for a free worker...</Typography>
              ) : (
                <ProgressRing
                  done={counts.done}
                  pending={counts.pending}
                  unknown={counts.unsupported}
                  error={0}
                />
              )}
            </Centered>
            <NumberGrid>
              <div>
                <InfoTabLabel>Total products</InfoTabLabel>
                <InfoTabBody>{counts.total}</InfoTabBody>
              </div>
              <div>
                <InfoTabLabel>Checks pending</InfoTabLabel>
                <InfoTabBody>{counts.pending}</InfoTabBody>
              </div>
              <div>
                <InfoTabLabel>Checked products</InfoTabLabel>
                <InfoTabBody>{counts.done}</InfoTabBody>
              </div>
              <div>
                <InfoTabLabel>Not supported yet</InfoTabLabel>
                <InfoTabBody>{counts.unsupported}</InfoTabBody>
              </div>
            </NumberGrid>
          </Grid>
        </CardContent>
      </Card>
    </Section>
  );
};

type ColumnName = 'partner' | 'name' | 'trueName' | 'status';
type Data = LinkCheckResultWithProduct;
type Column = IColumn<Data, ColumnName>;

export const COLUMNS: Column[] = [
  {
    key: 'partner',
    head: () => 'Partner',
    cell: (d) => {
      const partner = getCurrentPartnerForProduct(d.product.data);
      return <PartnerLogo partner={partner} />;
    },
    align: 'center',
    width: 50,
    flexGrow: 0,
    sortable: true
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => <ProductLinkCell p={d.product} />,
    align: 'left',
    width: 200,
    flexGrow: 3,
    sortable: true
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => d.result?.data.status || 'Not supported yet',
    align: 'center',
    width: 120,
    flexGrow: 0,
    sortable: true
  }
];

const SORTERS: ItemSorters<Data> = {
  partner: {
    key: 'partner',
    items: {
      sort: (d) => getCurrentPartnerForProduct(d.product.data).name,
      dir: 'asc'
    }
  },
  name: {
    key: 'name',
    items: { sort: (d) => d.product.data.name, dir: 'asc' }
  },
  status: {
    key: 'status',
    items: { sort: (d) => d.result?.data.status || 'ZZZ', dir: 'asc' }
  }
};

type PreSelector = {
  key: string;
  label: (count: number) => React.ReactNode;
  predicate: (d: LinkCheckResultWithProduct) => boolean;
};

const withCount = (t: string) => (count: number) => `${t} (${count})`;

const PRE_SELECTORS: PreSelector[] = [
  {
    key: 'all',
    label: withCount('All'),
    predicate: () => true
  },
  {
    key: 'ok',
    label: withCount('Good links'),
    predicate: (d) =>
      d.result?.data.status === ProductDataStatus.OK ||
      d.result?.data.status === ProductDataStatus.WARNING
  },
  {
    key: 'error',
    label: withCount('Links with issues'),
    predicate: (d) => d.result?.data.status === ProductDataStatus.ERROR
  },
  {
    key: 'notSupported',
    label: withCount('Not supported yet'),
    predicate: (d) => !d.result
  }
];

const DEFAULT_PRE_SELECTOR = PRE_SELECTORS[0];

const PreSelectorChip = ({
  p,
  onClick,
  onDelete,
  selected,
  count
}: {
  p: PreSelector;
  onClick: (preset: PreSelector) => void;
  onDelete?: () => void;
  selected: boolean;
  count: number;
}) => {
  return (
    <Chip
      color="primary"
      variant={selected ? 'default' : 'outlined'}
      label={p.label(count)}
      onClick={() => onClick(p)}
      onDelete={selected && onDelete ? () => onDelete() : undefined}
    />
  );
};

const Results = ({ d }: { d: Doc<ILinkCheckAppContainer> }) => {
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [preselector, setPreselector] = useQueryParam<PreSelector>(
    'preselector',
    (p) => PRE_SELECTORS.find((x) => x.key === p) || DEFAULT_PRE_SELECTOR,
    (x) => (x === DEFAULT_PRE_SELECTOR ? undefined : x.key)
  );
  const [ds, loading, error] = useLinkCheckResults(d);
  useErrorLogger(error);

  const groupedByPreselector = useMemo<{
    [key: string]: LinkCheckResultWithProduct[];
  }>(() => {
    if (!ds) {
      return EMPTY_OBJ;
    }
    return ds.reduce<{ [key: string]: LinkCheckResultWithProduct[] }>(
      (m, d) => {
        PRE_SELECTORS.forEach((p) => {
          const list = (m[p.key] = m[p.key] || []);
          if (p.predicate(d)) {
            list.push(d);
          }
        });
        return m;
      },
      {}
    );
  }, [ds]);

  const rows = groupedByPreselector[preselector.key];
  console.log(rows);

  return (
    <>
      {rows && (
        <TableToolbar>
          <TableToolbarSection>
            {PRE_SELECTORS.map((p) => (
              <PreSelectorChip
                key={p.key}
                p={p}
                count={groupedByPreselector[p.key]?.length || 0}
                onClick={setPreselector}
                selected={p === preselector}
                onDelete={
                  p !== DEFAULT_PRE_SELECTOR
                    ? () => setPreselector(DEFAULT_PRE_SELECTOR)
                    : undefined
                }
              />
            ))}
          </TableToolbarSection>
        </TableToolbar>
      )}
      <Paper>
        {loading && <Loader height={500} />}
        {rows && (
          <RowsRenderer
            variant="contained"
            columns={COLUMNS}
            rows={rows}
            rowToKey={(d) => d.product.id}
            sorter={sorter || SORTERS.name}
            sortDirection={sorter ? dir : SORTERS.name.items.dir}
            renderHead={true}
            onHeadClick={(c, d) => setSort([SORTERS[c.key] || SORTERS.name, d])}
            chunkSize={30}
            rootMargin="400px"
            otherProps={undefined}
            rowHeight={ROW_HEIGHTS.airy}
          />
        )}
      </Paper>
    </>
  );
};

const isDone = (d: ILinkCheckAppContainer) =>
  d.status === LinkCheckStatus.SUCCESS;

export const LinkCheckDetails: React.FC<Props> = ({ d }) => {
  return (
    <>
      <Tracker d={d} />
      {isDone(d.data) && <Results d={d} />}
    </>
  );
};
