import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as jsondiffpatch from 'jsondiffpatch';
import React, { useEffect, useMemo } from 'react';
import fetch from 'unfetch';
import { Loader } from '../../../../../components/Loader';
import {
  Paginator,
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import {
  IAmazonProductData,
  LinkCheckTarget,
  ProductDataStatus
} from '../../../../../domainTypes/linkCheckV2';
import {
  GetItemsError,
  Item,
  GetVariationsResponse
} from '../../../../../domainTypes/linkCheckV2/api/amazon';
import { styled } from '../../../../../emotion';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { useLoadingValue } from '../../../../../hooks/useLoadingValue';
import {
  useNumberQueryParam,
  useQueryParam,
  useBooleanQueryParam
} from '../../../../../routes';
import { LoadingValue } from '../../../../../services/db';
import { getStorageRef } from '../../../../../services/storage';
import { Json } from '../../../../components/Json';
import { LinkExternal } from '../../../../components/LinkExternal';
import { uniqBy } from 'lodash';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

type Props = {
  file: string;
};

const getFile = async (fileName: string) => {
  const url = await getStorageRef(
    `/linkCheck/debug/${fileName}`
  ).getDownloadURL();
  const json = await fetch(url).then((r) => r.json());
  return json;
};

type Data = {
  target: LinkCheckTarget;
  parsed: IAmazonProductData;
  previouslyParsed: IAmazonProductData;
  response: {
    item?: Item;
    variations?: GetVariationsResponse;
    error?: GetItemsError;
    otherError?: string;
  };
};

type Stats = {
  total: number;
  before: {
    unknown: number;
    error: number;
  };
  after: {
    unknown: number;
    error: number;
  };
};

const EMPTY_STATS = (): Stats => ({
  total: 0,
  before: {
    unknown: 0,
    error: 0
  },
  after: {
    unknown: 0,
    error: 0
  }
});

const useFile = (fileName: string): LoadingValue<Data[]> => {
  const { value, loading, error, setValue, setError } = useLoadingValue<
    Data[]
  >();
  useEffect(() => {
    getFile(fileName).then(setValue, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  return [value, loading, error];
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${(p) => p.theme.spacing(6)}px;
`;

const RowContainer = styled('div')`
  margin-top: ${(p) => p.theme.spacing(6)}px;
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
  max-width: calc(100vw - 256px - 72px);
`;

const Row = ({ d }: { d: Data }) => {
  return (
    <>
      <RowContainer>
        <LinkExternal href={d.parsed.pureUrl || d.parsed.destUrl} />
        <Grid>
          <div>
            <h4>Previous Result</h4>
            <Json data={d.previouslyParsed} />
          </div>
          <div>
            <h4>Amazon Response</h4>
            <Json data={d.response} />
          </div>
          <div>
            <h4>Diff</h4>
            <Json data={jsondiffpatch.diff(d.previouslyParsed, d.parsed)} />
          </div>
        </Grid>
      </RowContainer>
      <hr />
    </>
  );
};

type Option = {
  label: React.ReactNode;
  key: 'unknown' | 'error';
};

const SELECTORS: Option[] = [
  {
    label: 'Unknown',
    key: 'unknown'
  },
  {
    label: 'Error',
    key: 'error'
  }
];
const DEFAULT_SELECTOR = SELECTORS[1];

const PAGE_SIZE = 100;
const Toolbar = (props: {
  page: number;
  setPage: (nextPage: number) => void;
  selector: Option;
  setSelector: (nextSelector: Option) => void;
  totalRows: number;
  unique: boolean;
  setUnique: (nextUnique: boolean) => void;
  focusCurrent: boolean;
  setFocusCurrent: (next: boolean) => void;
}) => (
  <TableToolbar>
    <TableToolbarSection>
      <ToggleButtonGroup
        exclusive
        value={props.selector}
        onChange={(ev, nextSelector) => props.setSelector(nextSelector)}
      >
        {SELECTORS.map((s) => (
          <ToggleButton key={s.key} value={s}>
            {s.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <ToggleButtonGroup
        exclusive
        value={props.focusCurrent}
        onChange={(ev, next) => props.setFocusCurrent(next)}
      >
        <ToggleButton value={false}>Previous</ToggleButton>
        <ToggleButton value={true}>Current</ToggleButton>
      </ToggleButtonGroup>

      <FormControlLabel
        label="Unique rows"
        control={
          <Checkbox
            checked={props.unique}
            onChange={(ev) => props.setUnique(ev.target.checked)}
          />
        }
      />
    </TableToolbarSection>
    <TableToolbarSection>
      <Paginator
        totalCount={props.totalRows}
        rowsPerPage={PAGE_SIZE}
        page={props.page - 1}
        onChangePage={(nextPage) => props.setPage(nextPage + 1)}
      />
    </TableToolbarSection>
  </TableToolbar>
);

export const PageLinkCheckDebugDetails: React.FC<Props> = ({ file }) => {
  const [page, setPage] = useNumberQueryParam('p', 1);
  const [uniqueRows, setUniqueRows] = useBooleanQueryParam('unique', true);
  const [focusCurrent, setFocusCurrent] = useBooleanQueryParam('current', true);
  const [selector, setSelector] = useQueryParam<Option>(
    'selector',
    (p) => SELECTORS.find((x) => x.key === p) || DEFAULT_SELECTOR,
    (x) => (x === DEFAULT_SELECTOR ? undefined : x.key)
  );

  const [ds, loading, error] = useFile(file);
  useErrorLogger(error);
  console.log(file, ds, loading);

  const data = useMemo(() => {
    const result: {
      stats: Stats;
      unknown: Data[];
      error: Data[];
    } = {
      stats: EMPTY_STATS(),
      unknown: [],
      error: []
    };

    if (!ds) {
      return result;
    }

    (uniqueRows
      ? uniqBy(ds, (d) => d.parsed.pureUrl || d.parsed.destUrl)
      : ds
    ).forEach((d) => {
      const prevStatus = d.previouslyParsed.status;
      const currStatus = d.parsed.status;
      if (prevStatus === ProductDataStatus.UNKNOWN) {
        result.stats.before.unknown++;
        if (!focusCurrent) {
          result.unknown.push(d);
        }
      }

      if (prevStatus === ProductDataStatus.ERROR) {
        result.stats.before.error++;
        if (!focusCurrent) {
          result.error.push(d);
        }
      }

      if (currStatus === ProductDataStatus.UNKNOWN) {
        result.stats.after.unknown++;
        if (focusCurrent) {
          result.unknown.push(d);
        }
      }

      if (currStatus === ProductDataStatus.ERROR) {
        result.stats.after.error++;
        if (focusCurrent) {
          result.error.push(d);
        }
      }
      result.stats.total++;
    });

    return result;
  }, [ds, uniqueRows, focusCurrent]);

  const allRows = data[selector.key];
  const rows = useMemo(() => {
    const curr = (page - 1) * PAGE_SIZE;
    return allRows.slice(curr, curr + PAGE_SIZE);
  }, [allRows, page]);

  if (loading) {
    return <Loader height={500} />;
  }

  return (
    <>
      <h2>Stats</h2>
      <Json data={data.stats} />

      <Toolbar
        page={page}
        setPage={setPage}
        selector={selector}
        setSelector={setSelector}
        totalRows={allRows.length}
        unique={uniqueRows}
        setUnique={setUniqueRows}
        focusCurrent={focusCurrent}
        setFocusCurrent={setFocusCurrent}
      />

      <h2>{selector.label}</h2>
      {rows.map((d, i) => (
        <Row key={i} d={d} />
      ))}

      <Toolbar
        page={page}
        setPage={setPage}
        selector={selector}
        setSelector={setSelector}
        totalRows={allRows.length}
        unique={uniqueRows}
        setUnique={setUniqueRows}
        focusCurrent={focusCurrent}
        setFocusCurrent={setFocusCurrent}
      />
    </>
  );
};
