import { DBSchema, openDB } from 'idb';
import { Doc } from '../../../domainTypes/document';
import { ITrackingStatsHourly } from '../../../domainTypes/monitoring';
import * as ENV from '../../../env.json';

interface IDBSchema extends DBSchema {
  trackingStatsHourly: {
    key: string;
    value: Doc<ITrackingStatsHourly>;
  };
}

const NAME = `affilimate-admin-idb-1-${ENV.env.name
  .toLowerCase()
  .replace(' ', '_')}`;
const VERSION = 2;

export const idb = openDB<IDBSchema>(NAME, VERSION, {
  upgrade: (db, oldVersion) => {
    if (oldVersion === 0) {
      db.createObjectStore('trackingStatsHourly', { keyPath: 'id' });
    }
    if (oldVersion === 1) {
      db.deleteObjectStore('trackingStatsHourly');
      db.createObjectStore('trackingStatsHourly', { keyPath: 'id' });
    }
  }
});
