import React from 'react';
import { Minus as IconStable } from 'react-feather';
import { ONE_DAY } from '../../../../../../services/time';
import { ReactComponent as Arrow } from '../../../../../../icons/arrow.svg';
const IconDown = (props: any) => <Arrow {...props} />;

const IconSlightDown = (props: any) => (
  <Arrow
    {...props}
    style={{ ...props.style, marginRight: '6px', transform: 'rotate(45deg)' }}
  />
);

const IconUp = (props: any) => (
  <Arrow {...props} style={{ ...props.style, transform: 'rotate(180deg)' }} />
);

const IconSlightUp = (props: any) => (
  <Arrow {...props} style={{ ...props.style, transform: 'rotate(220deg)' }} />
);

const COLORS = {
  DOWN: '#ff4d4f',
  SLIGHT_DOWN: '#ff9c6e',
  STABLE: '#F6CC1B',
  SLIGHT_UP: '#95de64',
  UP: '#52c41a'
};

const ICON_CONFIGS = [
  {
    icon: IconDown,
    color: () => COLORS.DOWN
  },
  {
    icon: IconSlightDown,
    color: () => COLORS.SLIGHT_DOWN,
    style: { transform: 'scale(-1, 1)' }
  },
  {
    icon: IconStable,
    color: () => COLORS.STABLE
  },
  {
    icon: IconSlightUp,
    color: () => COLORS.SLIGHT_UP
  },
  {
    icon: IconUp,
    color: () => COLORS.UP
  }
];

const getTrend = (d: number) => {
  if (d === 0) {
    return 0;
  }
  if (d > 0) {
    return d > ONE_DAY ? 2 : 1;
  }
  return d < -ONE_DAY ? -2 : -1;
};

export const DeployDistance = ({ d }: { d: number }) => {
  const arrowSize = 12;
  const trend = getTrend(d);
  const config = ICON_CONFIGS[trend + 2];
  const Icon = config.icon;
  const color = config.color();

  return (
    <Icon
      height={`${arrowSize}px`}
      width={`${arrowSize}px`}
      style={{ color, fill: color }}
    />
  );
};
