import { compact, difference, groupBy, keyBy, mergeWith, sortBy } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
  EMPTY_COUNTER,
  ICounter,
  mergeCounter,
  reduceCounters,
  safeMergeCounter
} from '../../../domainTypes/analytics';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  hourKeyToTimeKey,
  HOURKEY_FORMAT,
  HourlyCounter,
  ITrackingStatsDaily,
  ITrackingStatsHourly
} from '../../../domainTypes/monitoring';
import { usePromise } from '../../../hooks/usePromise';
import { store } from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import {
  createDeduplicationCache,
  logIdbError
} from '../../../services/idb-tools';
import { FS } from '../../../versions';
import { processParallelCapped } from '../../services/denormalization';
import { idb } from '../../services/idb';

const IDB_STORE = 'trackingStatsHourly';
const toTrackingStatsDoc = generateToDocFn<ITrackingStatsHourly>();

const getIdRange = (start: moment.Moment, end: moment.Moment): string[] => {
  const result: string[] = [];
  // These hourly stats have unfortunate hourKey mechanics.
  // E.g. the hourKey 10 represents the time between 10 and 11.
  // We therefore need to shift everything by one hour, otherwise
  // we end up with an off by one error.
  let s = start.clone().utc().startOf('h').add(1, 'h');
  const e = end.clone().utc().startOf('h').add(1, 'h');
  console.log('id range', s.clone(), e, s.format(HOURKEY_FORMAT));
  while (s.isBefore(e)) {
    result.push(s.format(HOURKEY_FORMAT));
    s.add(1, 'h');
  }
  return result;
};

const idbCache = createDeduplicationCache();
const dbCache = createDeduplicationCache();

const getTrackingStatsFromIdb = async (ids: string[]) => {
  return Promise.all(
    ids.map((id) =>
      idbCache
        .getOr(IDB_STORE, id, async () => (await idb).get(IDB_STORE, id))
        .then((x) => (x as Doc<ITrackingStatsHourly>) || null)
    )
  ).then(compact);
};

const saveTrackingStatsInIdb = async (docs: Doc<ITrackingStatsHourly>[]) => {
  try {
    const tx = (await idb).transaction(IDB_STORE, 'readwrite');
    for (const doc of docs) {
      await tx.store.put(doc as any);
    }
    await tx.done;
  } catch (err) {
    logIdbError('ERR', err);
  }
};

const getTrackingStatsFromDb = async (ids: string[]) => {
  return Promise.all(
    ids.map((id) =>
      dbCache.getOr(FS._trackingStatsHourly, id, () =>
        store()
          .collection(FS._trackingStatsHourly)
          .doc(id)
          .get()
          .then((s) => {
            return s.exists ? toTrackingStatsDoc(s) : null;
          })
      )
    )
  ).then(compact);
};

export const getTrackingStats = async (
  start: moment.Moment,
  end: moment.Moment
): Promise<Doc<ITrackingStatsHourly>[]> => {
  const ids = getIdRange(start, end);
  console.log(ids);
  const fromIdb = await getTrackingStatsFromIdb(ids);
  const rest = difference(
    ids,
    fromIdb.map((d) => d.id)
  );
  const newDocs = await getTrackingStatsFromDb(rest);
  await saveTrackingStatsInIdb(newDocs);
  const idbDict = keyBy(fromIdb, (d) => d.id);
  const newDict = keyBy(newDocs, (d) => d.id);
  return ids.map(
    (id) =>
      idbDict[id] ||
      newDict[id] || {
        id,
        collection: FS._trackingStatsHourly,
        data: {
          hourKey: id,
          total: EMPTY_COUNTER(),
          bySpace: {}
        }
      }
  );
};

const createStartAndEnd = (
  now: moment.Moment,
  duration: moment.Duration,
  fullDaysOnly: boolean
): { start: moment.Moment; end: moment.Moment } => {
  const end = fullDaysOnly ? now.clone().startOf('d') : now.clone();
  return {
    start: end.clone().subtract(duration),
    end
  };
};

const MIN = 8;

// can't deal with duration changes at the moment
export const useTrackingStats = (
  duration: moment.Duration,
  fullDaysOnly: boolean,
  listeners: any[] = []
) => {
  const [state, setState] = useState<{
    start: moment.Moment;
    end: moment.Moment;
  }>(createStartAndEnd(moment().utc(), duration, fullDaysOnly));

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().utc();
      if (state.end.minute() < MIN || state.end.hour() !== now.hour()) {
        if (now.minute() >= MIN) {
          setState(createStartAndEnd(now, duration, fullDaysOnly));
        }
      }
    }, 1000 * 60);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return usePromise(
    () =>
      getTrackingStats(state.start, state.end).then((ds) =>
        ds.map((d) => d.data)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, ...listeners]
  );
};

export const aggregateHourlyCounters = (
  counters: HourlyCounter[]
): { timeKey: string; counts: ICounter }[] => {
  const grouped = groupBy(counters, (s) => hourKeyToTimeKey(s.hourKey));
  return sortBy(
    Object.entries(grouped).map(([timeKey, hourlyStats]) => {
      return hourlyStats.reduce<{ timeKey: string; counts: ICounter }>(
        (m, s) => {
          m.counts = mergeCounter(m.counts, s.counts);
          return m;
        },
        {
          timeKey,
          counts: EMPTY_COUNTER()
        }
      );
    }),
    (s) => s.timeKey
  );
};

export const aggregateToDailyStats = (
  stats: ITrackingStatsHourly[]
): ITrackingStatsDaily[] => {
  const grouped = groupBy(stats, (s) => hourKeyToTimeKey(s.hourKey));
  return sortBy(
    Object.entries(grouped).map(([timeKey, hourlyStats]) => {
      return hourlyStats.reduce<ITrackingStatsDaily>(
        (m, s) => {
          m.total = mergeCounter(m.total, s.total);
          m.bySpace = mergeWith(m.bySpace, s.bySpace, safeMergeCounter);
          return m;
        },
        {
          timeKey,
          total: EMPTY_COUNTER(),
          bySpace: {}
        }
      );
    }),
    (s) => s.timeKey
  );
};

export const aggregateTotals = <T extends { total: ICounter }>(
  ts: T[]
): ICounter => reduceCounters(ts.map((t) => t.total));

export const seedTrackingStats = () => {
  const ds: number[] = [];
  const end = moment();
  const start = end.clone().subtract(1, 'd');
  while (start.isSameOrBefore(end)) {
    ds.push(start.valueOf());
    start.add(1, 'h');
  }

  return processParallelCapped(
    ds.map((d) => () =>
      callFirebaseFunction('monitoring-denormalizeSpecificHour', { d })
    ),
    15
  );
};
