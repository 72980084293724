import firebase from 'firebase/app';
import { Doc, toDoc } from '../../../domainTypes/document';
import { batchSet, store, batchSetDocs } from '../../../services/db';

const LIMIT = 100;

export const touchAllInCollection = async <T extends {}>(
  collection: string,
  toDoc: (d: firebase.firestore.QueryDocumentSnapshot) => Doc<T>,
  execute: boolean
) => {
  const docs = await store()
    .collection(collection)
    .get()
    .then((s) => s.docs.map(toDoc));
  console.log(docs);
  if (execute) {
    await batchSetDocs(docs);
  }
};

export const migrateCollection = async <T extends {}>(
  collection: string,
  migrateFn: (d: T) => T
) => {
  return safeMigrateCollection(collection, migrateFn, true);
};

export const safeMigrateCollection = async <T extends {}>(
  collection: string,
  migrateFn: (d: T) => T | void, // return void to keep item untouched.
  execute: boolean,
  limit: number = LIMIT
) => {
  const migrate = async (
    snapshot: firebase.firestore.DocumentSnapshot | null
  ): Promise<void> => {
    let query = store().collection(collection).limit(limit);
    if (snapshot) {
      query = query.startAfter(snapshot);
    }
    const s = await query.get();
    if (s.empty) {
      return;
    }

    const docs = s.docs.map((d) => toDoc<T>(d));
    const { touched, untouched } = docs.reduce<{
      touched: Doc<T>[];
      untouched: Doc<T>[];
    }>(
      (m, d) => {
        const nextData = migrateFn(d.data);
        if (nextData) {
          m.touched.push({ ...d, data: nextData });
        } else {
          m.untouched.push(d);
        }
        return m;
      },
      { touched: [], untouched: [] }
    );

    console.log('touched: ', touched);
    console.log('untouched: ', untouched);

    if (execute) {
      await batchSet(collection, touched);
    }
    return migrate(s.docs[s.docs.length - 1]);
  };
  return migrate(null);
};
