import { Card, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { usePromise } from '../../../hooks/usePromise';
import { FlexContainer } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { getUser } from '../../../services/user';

export const PageAdminRoles = () => {
  const [userId, setUserId] = useState('');
  const [user] = usePromise(async () => {
    if (!userId) {
      return null;
    }
    return getUser(userId);
  }, [userId]);
  return (
    <Page>
      <Section>
        <Card>
          <TextField
            label="User ID"
            variant="outlined"
            fullWidth
            value={userId}
            onChange={(ev) => setUserId(ev.target.value)}
          />
          {user && user.data && (
            <>
              <p>{user.data.email}</p>
              <FlexContainer>
                <ButtonWithPromise
                  color="primary"
                  variant="contained"
                  pending="Granting..."
                  onClick={() =>
                    callFirebaseFunction('admin-grantAdminClaim', {
                      userId: user.id
                    })
                  }
                >
                  Grant admin role
                </ButtonWithPromise>
                <ButtonWithPromise
                  color="secondary"
                  variant="contained"
                  pending="Revoking..."
                  onClick={() =>
                    callFirebaseFunction('admin-revokeAdminClaim', {
                      userId: user.id
                    })
                  }
                >
                  Revoke admin role
                </ButtonWithPromise>

                <ButtonWithPromise
                  color="primary"
                  variant="contained"
                  pending="Granting..."
                  onClick={() =>
                    callFirebaseFunction('admin-grantImpersonatorClaim', {
                      userId: user.id
                    })
                  }
                >
                  Grant impersator role
                </ButtonWithPromise>
                <ButtonWithPromise
                  color="secondary"
                  variant="contained"
                  pending="Revoking..."
                  onClick={() =>
                    callFirebaseFunction('admin-revokeImpersonatorClaim', {
                      userId: user.id
                    })
                  }
                >
                  Revoke impersonator role
                </ButtonWithPromise>
              </FlexContainer>
            </>
          )}
        </Card>
      </Section>
    </Page>
  );
};
