import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as React from 'react';
import { Search as IconSearch } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { styled } from '../../../emotion';
import * as ENV from '../../../env.json';
import { NAV } from '../../sitemap';

const styles = (theme: any) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white
  },
  list: {
    flex: 1,
    backgroundColor: 'rgb(34, 34, 34)'
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)'
  },
  itemCategory: {
    backgroundColor: '#000',
    paddingTop: 16,
    paddingBottom: 16
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)'
    }
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default'
  },
  itemActiveItem: {
    color: '#FFF'
  },
  active: {
    color: '#FFF',
    fontWeight: 700,
    '& $itemActionable': {
      fontWeight: 700,
      color: '#FFF'
    },
    '& $itemPrimary': {
      fontWeight: 700,
      color: '#FFF'
    }
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize
  },
  divider: {
    marginTop: theme.spacing() * 2
  }
});

const EnvFooter = styled<'div', { safe: boolean }>('div')`
  background-color: ${(p) => (p.safe ? '#232f3e' : '#f5222d')};
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  color: ${(p) => (p.safe ? 'rgba(255, 255, 255, 0.7)' : 'white')};
  font-weight: 700;
  box-shadow: 0 1px 0 #404854 inset;
  border-radius: 4px;
  margin-left: 12px;
  display: inline-block;
  padding: 3px 6px;
`;

function Navigator(props: any) {
  const { classes, ...other } = props;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding className={classes.list}>
        <ListItem
          className={classNames(
            classes.firebase,
            classes.item,
            classes.itemCategory
          )}
        >
          <strong>Admin</strong>
          <EnvFooter safe={ENV.env.safe}>{ENV.env.name}</EnvFooter>
        </ListItem>
        {false && (
          <ListItem className={classNames(classes.item, classes.itemCategory)}>
            <ListItemIcon>
              <IconSearch />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary
              }}
            >
              Search your products
            </ListItemText>
          </ListItem>
        )}
        {NAV.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, route, disabled }) =>
              disabled ? (
                <ListItem
                  button
                  dense
                  key={childId}
                  className={classNames(classes.item, classes.disabled)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              ) : (
                <NavLink
                  to={route}
                  key={childId}
                  activeClassName={classes.active}
                >
                  <ListItem
                    button
                    dense
                    key={childId}
                    className={classNames(classes.item, classes.itemActionable)}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary
                      }}
                    >
                      {childId}
                    </ListItemText>
                  </ListItem>
                </NavLink>
              )
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
