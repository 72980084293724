import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { orderBy } from 'lodash';
import md5 from 'md5';
import React, { useState } from 'react';
import { Form } from '../../../../../components/Form';
import { FormField } from '../../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../../components/Form/FormSubmitButton';
import { Loader } from '../../../../../components/Loader';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../domainTypes/document';
import {
  IProductScanSpeedRestriction,
  PRODUCT_SCAN_SPEEDS
} from '../../../../../domainTypes/productScan';
import { css } from '../../../../../emotion';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { SortDirection } from '../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { rdb, toRef, useRdbList } from '../../../../../services/db';
import { removeTrailingSlash } from '../../../../../services/url';
import { RDB } from '../../../../../versions';

type Props = {};

type SortKey = 'url' | 'maxSpeed';

const sortFn = (
  ds: Doc<IProductScanSpeedRestriction>[],
  sortBy: SortKey,
  direction: SortDirection
) => {
  return orderBy(ds, (d) => d.data[sortBy], direction);
};

const HEIGHT = 500;

const COLUMNS: IColumn<Doc<IProductScanSpeedRestriction>, SortKey>[] = [
  {
    key: 'url',
    head: () => 'Domain',
    cell: (d) => <div>{d.data.url}</div>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 300,
    flexGrow: 2
  },
  {
    key: 'maxSpeed',
    head: () => 'Max Speed',
    cell: (d) => d.data.maxSpeed,
    align: 'right',
    sortable: false,
    defaultDirection: 'asc',
    width: 200,
    flexGrow: 1
  }
];

const EMPTY_MODEL: IProductScanSpeedRestriction = { url: '', maxSpeed: 'SLOW' };

const getRef = (url: string) =>
  rdb().ref(
    toRef(RDB.productScanSpeedRestrictions, md5(removeTrailingSlash(url)))
  );

const useRestrictions = () =>
  useRdbList<IProductScanSpeedRestriction>(
    rdb().ref(toRef(RDB.productScanSpeedRestrictions))
  );

const storeRestriction = (restr: IProductScanSpeedRestriction) => {
  const url = removeTrailingSlash(restr.url);
  return getRef(url).set({
    ...restr,
    url
  });
};

const removeRestriction = (url: string) => {
  return getRef(url).set(null);
};

type EditModel = {
  restriction: IProductScanSpeedRestriction;
  removable: boolean;
};

const EditDialog: React.FC<{
  model: EditModel | null;
  open: boolean;
  onClose: () => void;
}> = ({ model, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  if (!model) {
    return null;
  }

  const onSuccess = (msg: string) => {
    onClose();
    enqueueSnackbar(msg, { variant: 'success' });
  };

  const remove = (url: string) =>
    removeRestriction(url).then(() => onSuccess('Restriction removed'));

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Set speed restriction</DialogTitle>
      <Form
        initialValues={model.restriction}
        onSubmit={({ url, maxSpeed }) => {
          return storeRestriction({
            url,
            maxSpeed
          }).then(() => onSuccess('Restriction saved'));
        }}
      >
        {({ submitting, values }) => (
          <>
            <DialogContent>
              <FormField name="url" type="input">
                {({ input }) => (
                  <TextField
                    {...input}
                    required
                    label="Domain"
                    margin="normal"
                    className={css((t) => ({ width: 400 }))}
                  />
                )}
              </FormField>
              <FormField name="maxSpeed" type="input">
                {({ input }) => (
                  <FormControl margin="normal">
                    <Select
                      value={input.value}
                      onChange={input.onChange}
                      input={
                        <Input
                          classes={{ root: css(() => ({ marginTop: 17 })) }}
                        />
                      }
                    >
                      {PRODUCT_SCAN_SPEEDS.map((speed) => (
                        <MenuItem key={speed} value={speed}>
                          {speed}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </FormField>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              {model.removable && (
                <Button
                  disabled={!values.url}
                  onClick={() => remove(values.url)}
                  variant="contained"
                  color="secondary"
                >
                  Remove restriction
                </Button>
              )}
              <FormSubmitButton
                disabled={!values.url}
                submitting={submitting}
                submitComponent="Saving..."
              >
                Save
              </FormSubmitButton>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export const PageSpeedRestrictions: React.FC<Props> = () => {
  const [restrictions, loading] = useRestrictions();
  const [model, setModel] = useState<EditModel | null>(null);
  const dialogOpen = !!model;
  const closeDialog = () => setModel(null);
  return (
    <Page width="M">
      <CanvasBar>
        <div>Max speed per domain (click row to edit)</div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() =>
            setModel({ restriction: EMPTY_MODEL, removable: false })
          }
        >
          Add rule
        </Button>
      </CanvasBar>
      <Section>
        <Card>
          {loading && <Loader height={HEIGHT} />}
          {!loading && restrictions && (
            <VirtualizedSortableTable
              rows={restrictions}
              columns={COLUMNS}
              cellProps={undefined}
              height={HEIGHT}
              margin="dense"
              sortFn={sortFn}
              initialSortColumn={COLUMNS[0]}
              onRowClick={(doc) =>
                setModel({ restriction: doc.data, removable: true })
              }
            />
          )}
        </Card>
      </Section>

      <EditDialog model={model} open={dialogOpen} onClose={closeDialog} />
    </Page>
  );
};
