import { AppBar } from '@material-ui/core';
import React from 'react';

type Props = {};

export const TopNav: React.FC<Props> = ({ children }) => {
  return (
    <AppBar
      style={{ paddingLeft: 224, backgroundColor: '#222', color: '#FFF' }}
    >
      {children}
    </AppBar>
  );
};
