import React from 'react';
import { ISpace } from '../../../domainTypes/space';
import { getActiveDomainUrls } from '../../../services/space';
import { LinkExternal } from '../LinkExternal';

type Props = {
  space: ISpace;
};

export const SpaceLink: React.FC<Props> = ({ space }) => {
  const name = getActiveDomainUrls(space).join(', ') || space.id;
  return <LinkExternal href={`/spaces/${space.id}`}>{name}</LinkExternal>;
};
