import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { Loader } from '../../../../../components/Loader';
import { PartnerLogoWithName } from '../../../../../components/PartnerLogo';
import { IPartner } from '../../../../../domainTypes/partners';
import {
  ReportingApiResult,
  SECRET_CATEGORY
} from '../../../../../domainTypes/reporting';
import { styled } from '../../../../../emotion';
import { API_REPORT_HANDLERS } from '../../../../../features/PerformanceNew/services/handlers';
import { IApiReportHandler } from '../../../../../features/PerformanceNew/services/handlers/types';
import { getApiPreview } from '../../../../../features/PerformanceNew/services/report';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { store, useMappedLoadingValue } from '../../../../../services/db';
import { getKnownPartnerForKey } from '../../../../../services/partner';
import { toSecretDoc } from '../../../../../services/secret';
import { FS } from '../../../../../versions';
import { processParallelCapped } from '../../../../services/denormalization';

type Props = {};

const HandlerGrid = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: stretch;
  justify-items: stretch;
  padding: 0 ${(p) => p.theme.spacing(1)}px;

  > * {
    padding: ${(p) => p.theme.spacing(1)}px;
  }
`;

const LAlign = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RAlign = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const useAllSecretsForHandler = (handlerName: string) => {
  return useMappedLoadingValue(
    useCollection(
      store().collection(FS.secretsPublic).where('name', '==', handlerName)
    ),
    (s) =>
      s.docs.map(toSecretDoc).filter((d) => d.data.category === SECRET_CATEGORY)
  );
};

const RetriggerDialog = ({
  open,
  onClose,
  h,
  partner
}: {
  open: boolean;
  onClose: () => void;
  h: IApiReportHandler;
  partner: IPartner;
}) => {
  const [secrets, loading] = useAllSecretsForHandler(h.configName);
  const [reports, setReports] = useState<ReportingApiResult[] | null>(null);
  const end = moment();
  const start = end.clone().subtract(12, 'months');
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div>
          <PartnerLogoWithName partner={partner} />
        </div>
      </DialogTitle>
      <DialogContent>
        {loading && <Loader height={300} />}
        {secrets && !reports && <div>{secrets.length} setups found.</div>}
        {reports && <div>Check the console to look into results.</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {!reports && (
          <ButtonWithPromise
            pending="This is gonna take a while... Check the console"
            disabled={!secrets}
            variant="contained"
            color="primary"
            onClick={async () => {
              if (!secrets) {
                return;
              }
              await processParallelCapped(
                secrets.map((s) => () => {
                  console.log(
                    `Getting report for ${h.configName} in ${s.data.spaceId} with instanceId ${s.data.instanceId}`
                  );
                  return getApiPreview({
                    spaceId: s.data.spaceId,
                    handler: h.configName,
                    integrationId: s.data.instanceId,
                    start: start.valueOf(),
                    end: end.valueOf()
                  });
                }),
                2
              ).then((reports) => {
                console.log('REPORTS', reports);
                setReports(reports);
              });
            }}
          >
            Trigger all imports for the last year
          </ButtonWithPromise>
        )}
        {reports && (
          <>
            <Button variant="contained" onClick={() => setReports(null)}>
              Reset
            </Button>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending={'Saving...'}
              onClick={async () => {
                return null;
              }}
            >
              Commit results
            </ButtonWithPromise>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const HandlerRow = ({ h }: { h: IApiReportHandler }) => {
  const partner = getKnownPartnerForKey(h.partnerKey);
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  if (!partner) {
    // should never happen
    return null;
  }
  return (
    <>
      <LAlign>
        <PartnerLogoWithName partner={partner} />
      </LAlign>
      <RAlign>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={openDialog}
        >
          Run...
        </Button>
      </RAlign>
      {dialogOpen && (
        <RetriggerDialog
          open={dialogOpen}
          onClose={closeDialog}
          h={h}
          partner={partner}
        />
      )}
    </>
  );
};

export const PagePerformanceRetrigger: React.FC<Props> = () => {
  // Take all api handlers
  // allow to retrigger from a modal, which tells you how many integrations are going to be run
  // Problem: We will need to stagger this, otherwise the APIs might get overwhelmed
  return (
    <Page width="M">
      <Section>
        <CanvasBar>
          <div>API Handlers</div>
        </CanvasBar>
        <Card>
          <HandlerGrid>
            {API_REPORT_HANDLERS.map((h) => (
              <HandlerRow key={h.partnerKey} h={h}></HandlerRow>
            ))}
          </HandlerGrid>
        </Card>
      </Section>
    </Page>
  );
};
