import React from 'react';
import { Link } from 'react-router-dom';
import { IDeployJobWithNo } from '../../../../../domainTypes/deployment';
import { useBuild } from '../../service';

export const BuildNumber: React.FC<{ job: IDeployJobWithNo }> = ({ job }) => {
  const [build, loading] = useBuild(job.buildId);
  if (loading) {
    return <div>...</div>;
  }
  if (!loading && build) {
    return (
      <Link
        to={`/deployments/builds/${build.id}`}
        style={{ textDecoration: 'underline' }}
      >
        #{build.data.no}
      </Link>
    );
  }
  return null;
};
