import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import React from 'react';
import { ITagCategory } from '../../../domainTypes/tag';
import { styled } from '../../../emotion';
import { Color } from '../../../domainTypes/colors';
import { ColorPicker } from '../../ColorPicker';
import { Form } from '../../Form';
import { FormField } from '../../Form/FormField';
import { FormSubmitButton } from '../../Form/FormSubmitButton';
import { required } from '../../Form/validators';

const FormRow = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing()}px;
  gap: ${(p) => p.theme.spacing()}px;
`;

type Values = {
  name: string;
  description: string;
  color: Color;
  category: ITagCategory;
};

type Props = {
  open: boolean;
  initialValues: Values;
  onSubmit: (values: Values) => Promise<any>;
  onClose: () => void;
  title: React.ReactNode;
  label: React.ReactNode;
  submitComponent: React.ReactNode;
};

export const TagPrototypeFormDialog = ({
  open,
  initialValues,
  onSubmit,
  onClose,
  title,
  label,
  submitComponent
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <Form<Values> initialValues={initialValues} onSubmit={onSubmit}>
        {({ submitting, invalid }) => {
          return (
            <>
              <DialogContent>
                <FormRow>
                  <FormField<Values['color']> name="color" validate={required}>
                    {({ input }) => (
                      <ColorPicker
                        size="input"
                        noMargin={true}
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </FormField>
                  <FormField<Values['name']> name="name" validate={required}>
                    {({ input }) => (
                      <TextField
                        label="Name"
                        fullWidth
                        value={input.value}
                        onChange={input.onChange}
                        variant="outlined"
                      />
                    )}
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField<Values['description']> name="description">
                    {({ input }) => (
                      <TextField
                        style={{ minWidth: 450 }}
                        label="Description"
                        multiline
                        fullWidth
                        rows="3"
                        value={input.value}
                        onChange={input.onChange}
                        variant="outlined"
                      />
                    )}
                  </FormField>
                </FormRow>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <FormSubmitButton
                  variant="contained"
                  color="primary"
                  submitting={submitting}
                  submitComponent={submitComponent}
                  disabled={invalid}
                >
                  {label}
                </FormSubmitButton>
              </DialogActions>
            </>
          );
        }}
      </Form>
    </Dialog>
  );
};
