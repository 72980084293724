import { Button, Dialog, DialogContent, Tooltip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { Check as IconCheck, X as IconCross } from 'react-feather';
import { Loader } from '../../../../../../../components/Loader';
import { IColumn } from '../../../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../../../domainTypes/document';
import { Schedule } from '../../../../../../../domainTypes/schedule';
import { css } from '../../../../../../../emotion';
import { SortDirection } from '../../../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Centered } from '../../../../../../../layout/Centered';
import { Section } from '../../../../../../../layout/Section';
import { useSchedulesBySpaceId } from '../../../../../../../services/schedules';
import { updateOrCreateSchedule } from '../../../../../../../services/schedules/helper';
import {
  formatDatePrecise,
  toMoment
} from '../../../../../../../services/time';
import { Details } from './Details';

type Props = {
  spaceId: string;
};

type SortKey = 'id' | 'type' | 'frequency' | 'nextRun' | 'lastRun' | 'active';

const sortFn = (
  ds: Doc<Schedule>[],
  sortBy: SortKey,
  direction: SortDirection
) => {
  return orderBy(
    ds,
    (d) => {
      if (sortBy === 'type') {
        return d.data.type;
      }
      if (sortBy === 'frequency') {
        return d.data.frequency.type;
      }
      if (sortBy === 'active') {
        return d.data.active;
      }
      if (sortBy === 'nextRun') {
        return d.data.nextRun && toMoment(d.data.nextRun);
      }
      if (sortBy === 'lastRun') {
        return d.data.lastRun && toMoment(d.data.lastRun.date);
      }
      return d.id;
    },
    direction
  );
};

const HEIGHT = 300;

const ScheduleType = ({ d }: { d: Schedule }) => {
  if (d.type === 'SALES_API_FETCH') {
    return (
      <div>
        {d.type} ({d.config.handler})
      </div>
    );
  }
  return <div>{d.type}</div>;
};

const COLUMNS: IColumn<Doc<Schedule>, SortKey>[] = [
  {
    key: 'type',
    head: () => 'Type',
    cell: (d) => <ScheduleType d={d.data} />,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 160,
    flexGrow: 2
  },
  {
    key: 'nextRun',
    head: () => 'Next',
    cell: (d) =>
      d.data.nextRun && (
        <Tooltip title={formatDatePrecise(d.data.nextRun)}>
          <span>{toMoment(d.data.nextRun).fromNow()}</span>
        </Tooltip>
      ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'lastRun',
    head: () => 'Last',
    cell: (d) =>
      d.data.lastRun && (
        <Tooltip title={formatDatePrecise(d.data.lastRun.date)}>
          <span
            className={css((t) => ({
              color: d.data.lastRun
                ? d.data.lastRun.status === 'OK'
                  ? t.custom.colors.success.main
                  : t.custom.colors.error.main
                : 'inherit'
            }))}
          >
            {toMoment(d.data.lastRun.date).fromNow()}
          </span>
        </Tooltip>
      ),
    align: 'left',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'frequency',
    head: () => 'Type',
    cell: (d) => <div>{d.data.frequency.type}</div>,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'active',
    head: () => 'Active',
    cell: (d) => (d.data.active ? <IconCheck /> : <IconCross />),
    align: 'center',
    sortable: false,
    defaultDirection: 'asc',
    width: 80,
    flexGrow: 1
  }
];

const SchedulesInner = ({ spaceId }: { spaceId: string }) => {
  const [docs] = useSchedulesBySpaceId(spaceId);
  const [selected, setSelected] = useState<Doc<Schedule> | null>(null);
  const onUpdate = (schedule: Doc<Schedule>) =>
    updateOrCreateSchedule(schedule.id, schedule.data).then(() => {
      if (selected && selected.id === schedule.id) {
        setSelected(schedule);
      }
    });

  return (
    <Section>
      <CanvasBar>
        <div>Schedules</div>
      </CanvasBar>
      <Card>
        {!docs && <Loader height={HEIGHT} />}
        {docs && (
          <VirtualizedSortableTable
            rows={docs}
            columns={COLUMNS}
            cellProps={undefined}
            height={HEIGHT}
            margin="normal"
            sortFn={sortFn}
            initialSortColumn={COLUMNS[1]}
            onRowClick={(doc) => setSelected(doc)}
          />
        )}
      </Card>
      <Dialog open={!!selected} onClose={() => setSelected(null)}>
        <DialogContent>
          {selected && <Details doc={selected} onUpdate={onUpdate} />}
        </DialogContent>
      </Dialog>
    </Section>
  );
};

export const Schedules: React.FC<Props> = ({ spaceId }) => {
  const [reveal, setReveal] = useState(false);
  return (
    <Section>
      <CanvasBar>
        <div>Schedules</div>
      </CanvasBar>
      <Card>
        {reveal ? (
          <SchedulesInner spaceId={spaceId} />
        ) : (
          <Centered>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setReveal(true)}
            >
              Load Data
            </Button>
          </Centered>
        )}
      </Card>
    </Section>
  );
};
