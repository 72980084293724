import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { AutocompleteTags } from '../../../../../components/Tags/AutocompleteTags';
import { saveTagsOnDocs } from '../../../../../components/Tags/service';
import { Doc } from '../../../../../domainTypes/document';
import {
  IPotentialUser,
  SPACE_ID
} from '../../../../../domainTypes/potentialUser';
import {
  addTagsOnDoc,
  ITagPrototype,
  replaceTagsOnDoc
} from '../../../../../domainTypes/tag';
import { now } from '../../../../../services/time';
import { useCurrentAdminUser } from '../../../../services/auth';
import { toSafeUrl } from '../../service';

const retag = (
  docs: Doc<IPotentialUser>[],
  userId: string,
  urls: string[],
  tagIds: string[],
  additive: boolean
) => {
  const set = new Set(urls);
  const fn = additive ? addTagsOnDoc : replaceTagsOnDoc;
  const toUpdate = docs
    .filter((d) => set.has(d.data.url))
    .map((d) => fn(d, tagIds, userId, now()));
  return saveTagsOnDocs(toUpdate);
};

export const RetagDialog = ({
  open,
  onClose,
  docs = []
}: {
  open: boolean;
  onClose: () => void;
  docs: void | Doc<IPotentialUser>[];
}) => {
  const [urls, setUrls] = useState('');
  const [additive, setAdditive] = useState(true);
  const { userId } = useCurrentAdminUser();
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = useState<ITagPrototype[]>([]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Add potential users</DialogTitle>
      <DialogContent>
        <TextField
          style={{ minWidth: 450 }}
          label="Website URLs"
          multiline
          fullWidth
          rows="10"
          value={urls}
          onChange={(ev) => setUrls(ev.target.value)}
          variant="outlined"
        />
        <div>
          <sub>Enter urls separated by newlines</sub>
        </div>

        <br />

        <AutocompleteTags
          spaceId={SPACE_ID}
          userId={userId}
          category="BLOG_TOPIC"
          value={tags}
          onChange={setTags}
          fullWidth={true}
        />

        <br />
        <FormControlLabel
          label="Add instead of replace"
          control={
            <Checkbox
              checked={additive}
              onChange={(ev) => setAdditive(ev.target.checked)}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending="Retagging..."
          disabled={!docs}
          onClick={() =>
            retag(
              docs || [],
              userId,
              urls.trim().split('\n').map(toSafeUrl),
              tags.map((t) => t.id),
              additive
            ).then((updated) => {
              enqueueSnackbar(
                `Re-tagged ${updated.length} of ${(docs || []).length}`
              );
              // onClose();
              setTags([]);
              setUrls('');
            })
          }
        >
          Retag
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};
