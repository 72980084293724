import React from 'react';
import { Loader } from '../../../../../components/Loader';
import { Doc } from '../../../../../domainTypes/document';
import { ILinkCheckAppContainer } from '../../../../../domainTypes/linkCheckApp';
import { getSpaceDomainNames, ISpace } from '../../../../../domainTypes/space';
import { LinkCheckDetails } from '../../../../../features/LinkCheck/components/Details';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { Centered } from '../../../../../layout/Centered';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useSpace } from '../../../../services/space';
import { useAppCheck } from '../../service';

type Props = {
  checkId: string;
};

const Main = ({
  d,
  space
}: {
  d: Doc<ILinkCheckAppContainer>;
  space: Doc<ISpace>;
}) => {
  return (
    <>
      <h3>{getSpaceDomainNames(space.data)}</h3>
      <Section>
        <LinkCheckDetails d={d} />
      </Section>
    </>
  );
};

const Container = ({ d }: { d: Doc<ILinkCheckAppContainer> }) => {
  const [space, loading] = useSpace(d.data.spaceId);

  return (
    <>
      {loading && <Loader height={500} />}
      {space && <Main d={d} space={space} />}
    </>
  );
};

export const PageLinkCheckAppCheckDetails: React.FC<Props> = ({ checkId }) => {
  const [d, loading, error] = useAppCheck(checkId);
  useErrorLogger(error);

  return (
    <Page>
      {loading && <Loader height={500} />}
      {!loading && !d && <Centered height={500}>Not found.</Centered>}
      {d && <Container d={d} />}
    </Page>
  );
};
