import React from 'react';
import { Loader } from '../../../components/Loader';
import { Centered } from '../../../layout/Centered';
import {
  InfoTabs,
  InfoTab,
  InfoTabLabel,
  InfoTabBody
} from '../../../components/InfoTabs';
import { Card } from '@material-ui/core';

export interface IStatsBarItem {
  label: React.ReactNode;
  value: number;
  onClick?: () => void;
}

type Props = {
  data: IStatsBarItem[] | void;
  loading: boolean;
  error: any;
};

const HEIGHT = 83;

const Body: React.FC<Props> = ({ data, loading, error }) => {
  if (loading) {
    return <Loader height={HEIGHT} />;
  }
  if (error || !data) {
    return <Centered height={HEIGHT}>Something went wrong</Centered>;
  }
  return (
    <Centered>
      <InfoTabs>
        {data.map((d, i) => (
          <InfoTab key={i} onClick={d.onClick}>
            <InfoTabLabel>{d.label}</InfoTabLabel>
            <InfoTabBody>{d.value}</InfoTabBody>
          </InfoTab>
        ))}
      </InfoTabs>
    </Centered>
  );
};

export const StatsBar: React.FC<Props> = props => {
  return (
    <Card>
      <Body {...props} />
    </Card>
  );
};
