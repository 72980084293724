import shortid from 'shortid';
import { Type, Schedule } from '../../../domainTypes/schedule';
import { getSpaces } from '../space';
import { store } from '../../../services/db';
import { FS } from '../../../versions';
import {
  toScheduleDoc,
  updateOrCreateSchedule
} from '../../../services/schedules/helper';
import { keyBy } from 'lodash';
import { Doc } from '../../../domainTypes/document';

export const activatesScheduleForEveryone = async (
  type: Type,
  createNew: (spaceId: string, createdBy: string, tz: string) => Schedule,
  execute: boolean
) => {
  const activeSpaces = await getSpaces().then((ss) =>
    ss.filter((s) => s.data.active)
  );
  const alreadySetup = await store()
    .collection(FS.schedules)
    .where('type', '==', type)
    .get()
    .then((s) => s.docs.map(toScheduleDoc));

  const bySpaceId = keyBy(alreadySetup, (d) => d.data.spaceId);

  const toUpdate = activeSpaces.map((s) => {
    const previouslySetup = bySpaceId[s.id];

    const schedule: Doc<Schedule> = previouslySetup || {
      id: shortid(),
      collection: FS.schedules,
      data: createNew(s.id, 'AFFILIMATE', s.data.config.tz || 'UTC')
    };

    schedule.data.active = true;
    return schedule;
  });

  console.log(toUpdate);
  if (execute) {
    await Promise.all(
      toUpdate.map((d) => updateOrCreateSchedule(d.id, d.data))
    );
  }
};
