import { Paper } from '@material-ui/core';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  IDeployBuild,
  IDeployBuildWithNo
} from '../../../../../domainTypes/deployment';
import { css, styled } from '../../../../../emotion';
import { statusToVariant } from '../../service';
import { Duration } from '../Duration';
import { ServiceName } from '../ServiceName';
import { Sha } from '../Sha';

type Props = {
  build: IDeployBuildWithNo;
};

type Variant = 'success' | 'error' | 'pending' | 'secondary';

const formatTs = (ts: number) => moment(ts).format('YYYY/MM/DD HH:mm:ss');

const Outer = styled<'div', { variant: Variant }>('div')`
  border-left: 16px solid ${(p) => p.theme.custom.colors[p.variant].main};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const Body = styled('div')`
  padding: ${(p) => p.theme.spacing()}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled('div')`
  border-top: 1px solid lightgray;
  padding: ${(p) => p.theme.spacing(0.5)}px ${(p) => p.theme.spacing()}px;
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
`;

const BuildNumber = styled('div')`
  width: 60px;
  color: ${(p) => p.theme.palette.text.secondary};
`;

const BuildName = styled('div')`
  flex: 4;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

const Timestamp = ({ build }: { build: IDeployBuild }) => (
  <span>{formatTs(build.finishedAt || build.startedAt)}</span>
);

const Executor = styled('div')`
  min-width: 100px;
`;

const BuildInfo = styled('div')`
  flex: 1;
  min-width: 100px;
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  display: flex;
  justify-content: flex-end;
`;

export const BuildMetadata: React.FC<Props> = ({ build }) => {
  return (
    <Paper className={css((t) => ({ marginBottom: t.spacing(2) }))}>
      <Outer variant={statusToVariant(build.status)}>
        <Body>
          <BuildNumber>
            <Link to={`/deployments/builds/${build.id}`}>#{build.no}</Link>
          </BuildNumber>
          <BuildName>
            {sortBy(build.jobs, (j) => j.name).map((j) => (
              <div key={j.id}>
                <ServiceName env={build.env} name={j.name} />
              </div>
            ))}
          </BuildName>
          <BuildInfo>
            <Sha sha={build.sha} />
          </BuildInfo>
        </Body>
        <Footer>
          <Executor>{build.executor}</Executor>
          <div>
            <Timestamp build={build} /> -{' '}
            <Duration a={build.startedAt} b={build.finishedAt} />
          </div>
        </Footer>
      </Outer>
    </Paper>
  );
};
