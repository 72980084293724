import React, { useMemo } from 'react';
import { ITagPrototype } from '../../../domainTypes/tag';
import { ChipProps } from '@material-ui/core/Chip';
import { styled } from '../../../emotion';
import { Tag } from '../Tag';
import { useTagPrototypes } from '../service';
import { compact } from 'lodash';

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > :not(:last-child) {
    margin-left: ${p => p.theme.spacing(0.25)}px;
  }
`;

type CommonProps = {
  short?: boolean;
} & Pick<ChipProps, 'clickable' | 'size' | 'variant' | 'disabled'>;

export const TagList = ({
  tags,
  ...other
}: {
  tags: ITagPrototype[];
} & CommonProps) => {
  return (
    <Container>
      {tags.map(t => (
        <Tag key={t.id} t={t} {...other} />
      ))}
    </Container>
  );
};

export const TagListAsync = ({
  tagIds,
  ...other
}: { tagIds: string[] } & CommonProps) => {
  // TODO add nicer loading state, taking the number of ids and their size into account
  const [ts, loading, error] = useTagPrototypes(tagIds);
  // discard ones we couldn't find
  const tags = useMemo(() => (ts ? compact(ts).map(t => t.data) : ts), [ts]);
  if (loading || error || !tags) {
    return null;
  }
  return <TagList tags={tags} {...other} />;
};
