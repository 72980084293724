import * as ENVS from '../../../env.json';

export const getLogsLinkForFn = (
  fnName: string,
  region = 'us-central1',
  duration = '15M'
) => {
  const projectId = ENVS.firebase.projectId;
  const url = `https://console.cloud.google.com/logs/query;query=resource.type%3D%22cloud_function%22%0Aresource.labels.function_name%3D%22${fnName}%22%0Aresource.labels.region%3D%22${region}%22;duration=PT${duration}?project=${projectId}`;
  return url;
};
