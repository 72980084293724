import { mergeWith } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import {
  FORMATTERS,
  ITimeBasedCounter
} from '../../../../../components/Charts/TrafficBiaxialChart';
import {
  ICounter,
  safeMergeCounter
} from '../../../../../domainTypes/analytics';
import { HOUR_KEY_FORMATTERS } from '../../../../../features/Dashboard/components/DomainSummary/RealtimeReporting';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useMappedLoadingValue } from '../../../../../services/db';
import { ButtonContainer } from '../../../../components/ButtonContainer';
import { MonitoringChartCard } from '../../components/Chart';
import { MonitoringList } from '../../components/List';
import {
  aggregateToDailyStats,
  seedTrackingStats,
  useTrackingStats
} from '../../service';

const SEED = false;

type Props = {};

interface IChartData extends ITimeBasedCounter {
  bySpace: { [spaceId: string]: ICounter };
}

const toListData = (data: IChartData[]) =>
  data.reduce<{ [spaceId: string]: ICounter }>((m, s) => {
    return mergeWith(m, s.bySpace, safeMergeCounter);
  }, {});

const CurrentDay = () => {
  const [stats, loading] = useMappedLoadingValue(
    useTrackingStats(moment.duration(2, 'd'), false),
    (ds) =>
      ds.map<IChartData>((d) => ({
        ts: d.hourKey,
        pageViews: d.total.pageViews,
        served: d.total.served,
        viewed: d.total.viewed,
        clicked: d.total.clicked,
        bySpace: d.bySpace
      }))
  );

  return (
    <MonitoringChartCard
      heading="Last 48 hours"
      data={stats}
      loading={loading}
      formatters={HOUR_KEY_FORMATTERS}
      expandable={() => stats && <MonitoringList data={toListData(stats)} />}
    />
  );
};

const CurrentTwoWeeks = () => {
  const [stats, loading] = useMappedLoadingValue(
    useTrackingStats(moment.duration(2, 'w'), true),
    (ds) =>
      aggregateToDailyStats(ds).map<IChartData>((d) => ({
        ts: d.timeKey,
        pageViews: d.total.pageViews,
        served: d.total.served,
        viewed: d.total.viewed,
        clicked: d.total.clicked,
        bySpace: d.bySpace
      }))
  );
  return (
    <MonitoringChartCard
      heading="Last 14 days"
      data={stats}
      loading={loading}
      formatters={FORMATTERS.TIME_KEY}
      expandable={() => stats && <MonitoringList data={toListData(stats)} />}
    />
  );
};

export const PageMonitoringOverview: React.FC<Props> = () => {
  return (
    <Page>
      <Section>
        <CurrentDay />
      </Section>

      <Section>
        <CurrentTwoWeeks />
      </Section>

      {SEED && (
        <Section>
          <ButtonContainer>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending="Seeding..."
              onClick={seedTrackingStats}
            >
              Seed
            </ButtonWithPromise>
          </ButtonContainer>
        </Section>
      )}
    </Page>
  );
};
