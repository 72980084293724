import { Paper } from '@material-ui/core';
import { sortBy } from 'lodash';
import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { JobStatusBadge } from '../../../components/JobStatus';
import styled from '../../../emotion/styled';
import { useMappedLoadingValue } from '../../../services/db';
import { jobCollection, toJobDoc } from '../../../services/jobs';
import { toMoment } from '../../../services/time';

export const useJobs = () => {
  return useMappedLoadingValue(useCollection(jobCollection()), (s) =>
    s.docs.map((d) => toJobDoc(d))
  );
};

const JobTable = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  & > div {
    padding: 12px;
  }
`;

export const PageJobs = () => {
  const [jobs, loading] = useJobs();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!jobs) {
    return <div>No jobs</div>;
  }

  const sortedJobs = sortBy(jobs, (j) => j.data.status).reverse();

  return (
    <Paper>
      <JobTable>
        <div>Space ID</div>
        <div>Handler</div>
        <div>Date range</div>
        <div>Status</div>
        <div>Progress</div>
        {sortedJobs.map((j) => (
          <React.Fragment key={j.id}>
            <div>{j.data.spaceId}</div>
            <div>{j.data.handler}</div>
            <div>
              {toMoment(j.data.range.start).format('L')} &ndash;{' '}
              {toMoment(j.data.range.end).format('L')}
            </div>
            <div>
              <JobStatusBadge job={j.data} />
            </div>
            <div>{j.data.progress.percentage}%</div>
          </React.Fragment>
        ))}
      </JobTable>
    </Paper>
  );
};
