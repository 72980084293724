import { Doc } from '../../../../domainTypes/document';
import { IPageSummary } from '../../../../domainTypes/page';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { CF } from '../../../../versions';
import { processParallelCapped } from '../../../services/denormalization';
import { getSpaceIds } from '../../../services/space';

export const seedPageSummaries = async (execute: boolean): Promise<void> => {
  const spaceIds = await getSpaceIds();
  await processParallelCapped(
    spaceIds.map(spaceId => async () => {
      const docs = await callFirebaseFunction<Doc<IPageSummary>[]>(
        CF.pageSummaries.seedSpace,
        {
          spaceId,
          execute
        }
      );

      console.log(spaceId, docs);

      return;
    }),
    10
  );
  return;
};
