import React from 'react';
import { IDeployJob } from '../../../../../domainTypes/deployment';
import { Centered } from '../../../../../layout/Centered';
import { useFile } from '../../../../../services/storage';
import { Ansi, AnsiContainer } from '../../../../components/Ansi';

type Props = { job: IDeployJob };

const HEIGHT = 700;

const Logs: React.FC<Props> = ({ job }) => {
  const [value, loading, error] = useFile(job.logs, t => t);
  if (error) {
    return (
      <AnsiContainer>
        <Centered height={HEIGHT}>Failed to retrieve logs</Centered>
      </AnsiContainer>
    );
  }

  if (loading || !value) {
    return (
      <AnsiContainer>
        <Centered height={HEIGHT}>Loading logs...</Centered>
      </AnsiContainer>
    );
  }

  return <Ansi ansi={value} maxHeight={HEIGHT} scrollToBottom={true} />;
};

export const LogViewer: React.FC<Props> = ({ job }) => {
  if (job.status === 'PENDING') {
    return (
      <AnsiContainer>
        <Centered height={HEIGHT}>Waiting for job to finish...</Centered>
      </AnsiContainer>
    );
  }

  return <Logs job={job} />;
};
