import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import {
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS
} from '../../../../../components/GroupableList';
import { Number, toPercent } from '../../../../../components/Number';
import {
  PartnerLogo,
  PartnerLogoWithName
} from '../../../../../components/PartnerLogo';
import { IColumn } from '../../../../../components/Table/Column';
import { Doc } from '../../../../../domainTypes/document';
import { NOOP } from '../../../../../domainTypes/emptyConstants';
import {
  IPotentialUser,
  IPotentialUserPartner
} from '../../../../../domainTypes/potentialUser';
import { styled } from '../../../../../emotion';
import { withStoppedPropagation } from '../../../../../helpers';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { SortDirection } from '../../../../../hooks/useSort';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../../services/partner';
import {
  PartnerDistributionChart,
  YField,
  Y_FIELDS
} from '../../components/PartnerDistributionChart';
import { getScanResult } from '../../service';

type Data = Doc<IPotentialUser>;

type PartnerData = IPotentialUserPartner & { percentage: number };
type PartnerColumnName =
  | 'partner'
  | 'pages'
  | 'products'
  | 'links'
  | 'cloaked'
  | 'percentage';
type PartnerColumn = IColumn<PartnerData, PartnerColumnName>;
const PARTNER_COLUMNS: PartnerColumn[] = [
  {
    key: 'partner',
    head: () => 'Partner',
    cell: (d) => (
      <PartnerLogoWithName
        partner={
          getKnownPartnerForKey(d.partnerKey) ||
          constructPartnerForKey(d.partnerKey)
        }
      />
    ),
    sortable: true,
    align: 'left',
    width: 400,
    flexGrow: 3
  },
  {
    key: 'pages',
    head: () => 'Pages',
    cell: (d) => d.counts.pages,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'products',
    head: () => 'Products',
    cell: (d) => d.counts.products,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'cloaked',
    head: () => 'Cloaked',
    cell: (d) => d.counts.cloaked,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'links',
    head: () => 'Links',
    cell: (d) => d.counts.links,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'percentage',
    head: () => '% of total',
    cell: (d) => <Number format="percent" digits={2} n={d.percentage} />,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  }
];

const PARTNER_SORTERS: ItemSorters<PartnerData> = {
  partner: {
    key: 'partner',
    items: { sort: (d) => d.partnerKey, dir: 'asc' }
  },
  pages: {
    key: 'pages',
    items: {
      sort: (d) => d.counts.pages,
      dir: 'desc'
    }
  },
  products: {
    key: 'products',
    items: {
      sort: (d) => d.counts.products,
      dir: 'desc'
    }
  },
  links: {
    key: 'links',
    items: {
      sort: (d) => d.counts.links,
      dir: 'desc'
    }
  },
  cloaked: {
    key: 'cloaked',
    items: {
      sort: (d) => d.counts.cloaked,
      dir: 'desc'
    }
  },
  percentage: {
    key: 'percentage',
    items: {
      sort: (d) => d.percentage,
      dir: 'desc'
    }
  }
};

const PartnersDialog = ({
  open,
  onClose,
  url,
  partners
}: {
  open: boolean;
  onClose: () => void;
  url: string;
  partners: IPotentialUserPartner[];
}) => {
  const [[sorter, dir], setSort] = useState<
    [ItemSorter<PartnerData>, SortDirection | undefined]
  >([PARTNER_SORTERS.links, undefined]);
  const total = partners.reduce((m, p) => m + p.counts.links, 0);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ style: { width: 1010 } }}
      onClick={withStoppedPropagation(NOOP)}
    >
      <DialogTitle>Partners for {url}</DialogTitle>
      <DialogContent>
        <PartnerDistributionChart
          ds={partners}
          y={
            Y_FIELDS.includes(sorter.key as YField)
              ? (sorter.key as YField)
              : 'links'
          }
          dir={Y_FIELDS.includes(sorter.key as YField) ? dir : 'desc'}
          maxPartners={25}
        />
        <RowsRenderer
          variant="plain"
          columns={PARTNER_COLUMNS}
          rows={partners.map((p) => ({
            ...p,
            percentage: toPercent(p.counts.links, total)
          }))}
          rowToKey={(d) => d.partnerKey}
          sorter={sorter}
          sortDirection={dir}
          renderHead={true}
          onHeadClick={(c, d) =>
            setSort([PARTNER_SORTERS[c.key] || PARTNER_SORTERS.links, d])
          }
          chunkSize={100}
          rootMargin="400px"
          rowHeight={ROW_HEIGHTS.dense}
          otherProps={undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const PartnersContainer = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const Partners = ({ d }: { d: Data }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const partners = getScanResult(d, (r) => r.partners);
  if (!partners) {
    return null;
  }
  const sorted = sortBy(partners, (p) => -p.counts.links);
  return (
    <>
      <PartnersContainer onClick={withStoppedPropagation(openDialog)}>
        {sorted.slice(0, 25).map((p) => {
          const partner =
            getKnownPartnerForKey(p.partnerKey) ||
            constructPartnerForKey(p.partnerKey);
          return (
            <Tooltip
              key={partner.key}
              title={`${partner.name}: ${p.counts.pages} | ${p.counts.products} | ${p.counts.links} | ${p.counts.cloaked}`}
            >
              <span>
                <PartnerLogo partner={partner} />
              </span>
            </Tooltip>
          );
        })}
      </PartnersContainer>
      <PartnersDialog
        open={dialogOpen}
        onClose={closeDialog}
        url={d.data.url}
        partners={partners}
      />
    </>
  );
};
