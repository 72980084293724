import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@material-ui/core';
import { identity } from 'lodash';
import React, { useState } from 'react';
import { Check as IconCheck, Trash as IconTrash } from 'react-feather';
import { ButtonWithPromise } from '../../../../../../../components/ButtonWithPromise';
import { Loader } from '../../../../../../../components/Loader';
import { IColumn } from '../../../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../../../domainTypes/document';
import { ISpace } from '../../../../../../../domainTypes/space';
import { IUser } from '../../../../../../../domainTypes/user';
import { usePromise } from '../../../../../../../hooks/usePromise';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import { store } from '../../../../../../../services/db';
import { callFirebaseFunction } from '../../../../../../../services/firebaseFunctions';
import { getUsersInSpace, toUserDoc } from '../../../../../../../services/user';
import { CF, FS } from '../../../../../../../versions';
import { ButtonContainer } from '../../../../../../components/ButtonContainer';

type SpaceProps = {
  space: Doc<ISpace>;
};

type SortKey = 'id' | 'email' | 'deleted' | 'actions';

type CellProps = { spaceId: string };

const sortFn = identity;

const HEIGHT = 200;

export const RemoveMemberDialog = ({
  target,
  open,
  onClose,
  onRemove
}: {
  target: React.ReactNode;
  open: boolean;
  onClose: () => void;
  onRemove: () => Promise<void>;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove member from space</DialogTitle>
      <DialogContent>Please confirm removal of {target}.</DialogContent>
      <DialogActions>
        <ButtonContainer>
          <Button onClick={onClose}>Cancel</Button>
          <ButtonWithPromise
            variant="contained"
            color="secondary"
            onClick={() => onRemove().then(() => onClose)}
            pending="Removing..."
          >
            Remove
          </ButtonWithPromise>
        </ButtonContainer>
      </DialogActions>
    </Dialog>
  );
};

export const AddMemberDialog = ({
  open,
  onClose,
  onAdd
}: {
  open: boolean;
  onClose: () => void;
  onAdd: (userId: string) => Promise<void>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState('');
  const close = () => {
    setEmail('');
    onClose();
  };
  const onSubmit = () => {
    return store()
      .collection(FS.users)
      .where('email', '==', email)
      .get()
      .then((s) => {
        if (s.empty) {
          enqueueSnackbar('User not found!', {
            variant: 'error'
          });
          return Promise.resolve();
        }
        const users = s.docs.map(toUserDoc);
        if (users.length > 1) {
          console.error('MULTIPLE USERS', users);
          enqueueSnackbar('Multiple users found! Check console.', {
            variant: 'error'
          });
          return Promise.resolve();
        }

        return onAdd(users[0].id).then(
          () => {
            enqueueSnackbar('Member successfully added!', {
              variant: 'success'
            });
            close();
          },
          () => {
            enqueueSnackbar('Something went wrong!', { variant: 'error' });
          }
        );
      });
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Add member to space</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          value={email}
          fullWidth={true}
          onChange={(ev) => setEmail(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <ButtonContainer>
          <Button onClick={close}>Cancel</Button>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            disabled={!email}
            onClick={() => onSubmit()}
            pending="Adding..."
          >
            Add
          </ButtonWithPromise>
        </ButtonContainer>
      </DialogActions>
    </Dialog>
  );
};

const RowActions = ({
  user,
  spaceId
}: {
  user: Doc<IUser | null>;
  spaceId: string;
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  return (
    <>
      <IconButton size="small" onClick={() => setConfirmOpen(true)}>
        <IconTrash size={20} />
      </IconButton>
      <RemoveMemberDialog
        target={user.data ? user.data.email : 'DELETED USER'}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onRemove={() =>
          callFirebaseFunction(CF.space.removeMemberFromSpace, {
            userId: user.id,
            spaceId
          })
        }
      />
    </>
  );
};

const COLUMNS: IColumn<Doc<IUser | null>, SortKey, CellProps>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <div>{d.id}</div>,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'email',
    head: () => 'Email',
    cell: (d) => d.data && <div>{d.data.email}</div>,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 200,
    flexGrow: 4
  },
  {
    key: 'deleted',
    head: () => 'Deleted',
    cell: (d) => !d.data && <IconCheck />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d, p) => <RowActions user={d} spaceId={p.spaceId} />,
    align: 'right',
    sortable: false,
    defaultDirection: 'asc',
    width: 50,
    flexGrow: 1
  }
];

export const Members = ({ space }: SpaceProps) => {
  const [value] = usePromise(() => getUsersInSpace(space), [space]);
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  return (
    <Section>
      <CanvasBar>
        <div>Members</div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setAddMemberOpen(true)}
        >
          Add member...
        </Button>
      </CanvasBar>
      <Card>
        {!value && <Loader height={HEIGHT} />}
        {value && (
          <VirtualizedSortableTable
            rows={value}
            columns={COLUMNS}
            cellProps={{ spaceId: space.id }}
            height={HEIGHT}
            margin="normal"
            sortFn={sortFn}
            initialSortColumn={COLUMNS[1]}
          />
        )}
      </Card>

      <AddMemberDialog
        open={addMemberOpen}
        onClose={() => setAddMemberOpen(false)}
        onAdd={(userId) =>
          callFirebaseFunction(CF.space.addMemberToSpace, {
            spaceId: space.id,
            userId
          })
        }
      />
    </Section>
  );
};
