import { IconButton, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import {
  ChevronLeft as IconKeyboardArrowLeft,
  ChevronRight as IconKeyboardArrowRight
} from 'react-feather';
import { css } from '../../emotion';
import { useHotKeys } from '../../hooks/useHotkeys';
import * as tracking from '../../tracking';

export type IPage = {
  label: string;
  url: string;
};

const NO_NEXT_NO_PREV = {
  nextPage: null,
  prevPage: null
};

export const getPrevAndNextPage = <T extends {}>(
  list: T[],
  currentIndex: number,
  toUrl: (item: T) => string,
  toLabel: (item: T) => string
): { prevPage: IPage | null; nextPage: IPage | null } => {
  if (currentIndex === -1) {
    return NO_NEXT_NO_PREV;
  }
  const prev = list[currentIndex - 1];
  const next = list[currentIndex + 1];

  return {
    prevPage: prev ? { url: toUrl(prev), label: toLabel(prev) } : null,
    nextPage: next ? { url: toUrl(next), label: toLabel(next) } : null
  };
};

export const NavigationArrows = ({
  prevPage,
  nextPage,
  toNextPage
}: {
  prevPage: IPage | null;
  nextPage: IPage | null;
  toNextPage: (page: IPage) => void;
}) => {
  const prevText = prevPage ? prevPage.label : '';
  const nextText = nextPage ? nextPage.label : '';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goPrev = useCallback(() => prevPage && toNextPage(prevPage), [
    prevPage
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goNext = useCallback(() => nextPage && toNextPage(nextPage), [
    nextPage
  ]);

  useHotKeys(
    [
      {
        keys: 'left',
        handler: goPrev
      },
      {
        keys: 'right',
        handler: goNext
      }
    ],
    [goPrev, goNext]
  );

  const classes = {
    root: css((t) => ({
      color: '#FFF'
    }))
  };

  return (
    <div>
      <Tooltip title={prevText} placement="top">
        <IconButton
          aria-label={prevText}
          disabled={prevPage === null}
          onClick={() => {
            tracking.sendEvent({
              category: tracking.toAppCategory(),
              action: 'Click',
              label: 'Previous page arrow'
            });
            return goPrev();
          }}
          classes={classes}
        >
          <IconKeyboardArrowLeft />
        </IconButton>
      </Tooltip>
      <Tooltip title={nextText} placement="top">
        <IconButton
          aria-label={nextText}
          disabled={nextPage === null}
          onClick={() => {
            tracking.sendEvent({
              category: tracking.toAppCategory(),
              action: 'Click',
              label: 'Next page arrow'
            });
            return goNext();
          }}
          classes={classes}
        >
          <IconKeyboardArrowRight />
        </IconButton>
      </Tooltip>
    </div>
  );
};
