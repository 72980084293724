import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select
} from '@material-ui/core';
import { groupBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { Check as IconCheck } from 'react-feather';
import { Loader } from '../../../../../../../components/Loader';
import { PartnerLogoWithName } from '../../../../../../../components/PartnerLogo';
import { IColumn } from '../../../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../../../domainTypes/document';
import { PARTNERS } from '../../../../../../../domainTypes/partners';
import {
  ReportingApiResult,
  SECRET_CATEGORY
} from '../../../../../../../domainTypes/reporting';
import { ISecretWithTs } from '../../../../../../../domainTypes/secret';
import { ISpace } from '../../../../../../../domainTypes/space';
import { css } from '../../../../../../../emotion';
import { ApiImportResults } from '../../../../../../../features/PerformanceNew/components/ApiImportResults';
import { API_REPORT_HANDLERS } from '../../../../../../../features/PerformanceNew/services/handlers';
import { IApiReportHandler } from '../../../../../../../features/PerformanceNew/services/handlers/types';
import { getApiPreview } from '../../../../../../../features/PerformanceNew/services/report';
import { useDialogState } from '../../../../../../../hooks/useDialogState';
import { useLoadingValue } from '../../../../../../../hooks/useLoadingValue';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Centered } from '../../../../../../../layout/Centered';
import { Section } from '../../../../../../../layout/Section';
import { useSecrets } from '../../../../../../../services/secret';
import { MomentRange } from '../../../../../../../services/time';

type Props = {
  space: Doc<ISpace>;
};

const HEIGHT = 300;

type RowData = {
  space: ISpace;
  handler: IApiReportHandler;
  secret: Doc<ISecretWithTs> | null;
};

// also show some stats here maybe?
type SortKey = 'name' | 'setup' | 'integrationId' | 'actions';

type MomentRangeOption = {
  label: string;
  value: MomentRange;
};

const DIALOG_STYLE = {
  INITIAL: () =>
    css(() => ({
      width: '250px',
      maxHeight: '90%'
    })),
  LARGE: () =>
    css(() => ({
      width: 'calc(100vw - 100px)',
      maxHeight: '90%'
    }))
};

const RunDialog = ({
  d,
  open,
  secret,
  onClose
}: {
  d: RowData;
  open: boolean;
  secret: Doc<ISecretWithTs> | null;
  onClose: () => void;
}) => {
  const options: MomentRangeOption[] = useMemo(() => {
    const end = moment();
    return [
      {
        label: 'Last month',
        value: { start: end.clone().subtract(1, 'M'), end }
      },
      {
        label: 'Last 3 months',
        value: { start: end.clone().subtract(3, 'M'), end }
      },
      {
        label: 'Last 6 months',
        value: { start: end.clone().subtract(6, 'M'), end }
      },
      {
        label: 'Last 12 months',
        value: { start: end.clone().subtract(12, 'M'), end }
      }
    ];
  }, []);

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const {
    value,
    loading,
    error,
    setLoading,
    setValue,
    setError
  } = useLoadingValue<ReportingApiResult | null>(null, false);
  const selectionMode = !value && !loading && !error;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      classes={{
        paper: selectionMode ? DIALOG_STYLE.INITIAL() : DIALOG_STYLE.LARGE()
      }}
    >
      <DialogTitle>Run Import</DialogTitle>
      {selectionMode && (
        <>
          <DialogContent>
            <Select
              fullWidth={true}
              value={options.indexOf(selectedOption)}
              onChange={(ev) =>
                setSelectedOption(options[Number(ev.target.value)])
              }
            >
              {options.map((o, i) => (
                <MenuItem key={i} value={i}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  if (!secret) {
                    setError('no secret');
                    return;
                  }
                  getApiPreview({
                    spaceId: d.space.id,
                    handler: d.handler.configName,
                    integrationId: secret.data.instanceId,
                    start: selectedOption.value.start.valueOf(),
                    end: selectedOption.value.end.valueOf()
                  }).then((result) => {
                    if (result.errorType || result.errorMsg || !result.report) {
                      console.log('ERROR', result);
                      setError(result.errorType);
                    } else {
                      setValue(result);
                    }
                  });
                }}
              >
                Run
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      )}
      {!selectionMode && loading && (
        <>
          <DialogContent>
            <Loader />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Abort</Button>
          </DialogActions>
        </>
      )}
      {!selectionMode && value && (
        <ApiImportResults
          userId="AFFILIMATE"
          space={d.space}
          result={value}
          onClose={onClose}
          onDone={onClose}
          currency="USD"
          height={700}
        />
      )}
      {!selectionMode && error && <Centered height={700}>KAPUTT</Centered>}
    </Dialog>
  );
};

const Actions = ({ d }: { d: RowData }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [v, setV] = useState(0);
  const close = () => {
    closeDialog();
    setV((x) => x + 1);
  };
  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={openDialog}
        disabled={!d.secret}
      >
        Run...
      </Button>
      <RunDialog
        d={d}
        secret={d.secret}
        open={dialogOpen}
        onClose={close}
        key={v}
      />
    </>
  );
};

const COLUMNS: IColumn<RowData, SortKey>[] = [
  {
    key: 'name',
    head: () => 'Handler',
    cell: (d) => {
      const { partnerKey } = d.handler;
      const partner = PARTNERS.find((p) => p.key === partnerKey);
      if (!partner) {
        return partnerKey;
      }
      return <PartnerLogoWithName partner={partner} maxLength={25} />;
    },
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'name',
    head: () => 'Setup',
    cell: (d) => !!d.secret && <IconCheck />,
    align: 'center',
    sortable: false,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'integrationId',
    head: () => 'Integration ID',
    cell: (d) => !!d.secret && d.secret.data.instanceId,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    align: 'right',
    sortable: false,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  }
];

const HandlerList = ({
  space,
  secrets
}: {
  space: ISpace;
  secrets: Doc<ISecretWithTs>[];
}) => {
  const secretsByName = useMemo(() => groupBy(secrets, (s) => s.data.name), [
    secrets
  ]);

  const rows = API_REPORT_HANDLERS.reduce<RowData[]>((result, handler) => {
    const secretByName = secretsByName[handler.configName];
    if (!secretByName) {
      return result;
    }
    const bySecret = secretByName.map((s) => ({
      space,
      handler,
      secret: s
    }));
    return [...result, ...bySecret];
  }, []);

  return (
    <VirtualizedSortableTable
      rows={rows}
      columns={COLUMNS}
      cellProps={undefined}
      height={HEIGHT}
      margin="normal"
      sortFn={(t) => t}
      initialSortColumn={COLUMNS[0]}
    />
  );
};

export const ApiIntegrations: React.FC<Props> = ({ space }) => {
  const [secrets, loading] = useSecrets(space.id, SECRET_CATEGORY);
  return (
    <Section>
      <CanvasBar>API Integrations</CanvasBar>
      <Card>
        {loading && <Loader height={HEIGHT} />}
        {!loading && secrets && (
          <HandlerList space={space.data} secrets={secrets} />
        )}
      </Card>
    </Section>
  );
};
