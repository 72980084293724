import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import { chunk, partition } from 'lodash';
import md5 from 'md5';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { AutocompleteTags } from '../../../../../components/Tags/AutocompleteTags';
import { Doc } from '../../../../../domainTypes/document';
import {
  IPotentialUser,
  SPACE_ID
} from '../../../../../domainTypes/potentialUser';
import { ITagPrototype } from '../../../../../domainTypes/tag';
import { wait } from '../../../../../helpers';
import { batchSet } from '../../../../../services/db';
import { now } from '../../../../../services/time';
import { FS } from '../../../../../versions';
import { useCurrentAdminUser } from '../../../../services/auth';
import { rescanAll, toSafeUrl } from '../../service';

const createNewPotentialUsers = async (
  createdBy: string,
  urls: string[],
  tagIds: string[]
) => {
  if (!urls.length) {
    return;
  }
  const chunks = chunk(urls, 10);
  for (const c of chunks) {
    if (chunks.indexOf(c) !== 0) {
      await wait(2000);
    }
    const createdAt = now();
    const docs: Doc<IPotentialUser>[] = c.map((u) => {
      const url = toSafeUrl(u);
      return {
        id: md5(url),
        collection: FS.potentialUsers,
        data: {
          url,
          createdAt,
          createdBy,
          scan: null,
          status: 'LEAD',
          tags: tagIds.map((id) => ({ id, createdAt, createdBy })),
          tagIds
        }
      };
    });
    await batchSet(FS.potentialUsers, docs);
  }
};

export const CreateDialog = ({
  open,
  onClose,
  knownEntries = []
}: {
  open: boolean;
  onClose: () => void;
  knownEntries: void | Doc<IPotentialUser>[];
}) => {
  const [urls, setUrls] = useState('');
  const [override, setOverride] = useState(false);
  const { userId } = useCurrentAdminUser();
  const { enqueueSnackbar } = useSnackbar();
  const [tags, setTags] = useState<ITagPrototype[]>([]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Add potential users</DialogTitle>
      <DialogContent>
        <AutocompleteTags
          spaceId={SPACE_ID}
          userId={userId}
          category="BLOG_TOPIC"
          value={tags}
          onChange={setTags}
          fullWidth={true}
        />

        <br />

        <TextField
          style={{ minWidth: 450 }}
          label="Website URLs"
          multiline
          fullWidth
          rows="10"
          value={urls}
          onChange={(ev) => setUrls(ev.target.value)}
          variant="outlined"
        />
        <div>
          <sub>Enter urls separated by newlines</sub>
        </div>

        <br />
        <FormControlLabel
          label="Rescan existing entries"
          control={
            <Checkbox
              checked={override}
              onChange={(ev) => setOverride(ev.target.checked)}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending="Adding..."
          onClick={async () => {
            const cleanedUrls = urls.trim().split('\n').map(toSafeUrl);

            const knownEntriesByUrl = Object.fromEntries(
              knownEntries.map((k) => [k.data.url, k])
            );
            const knownUrls = new Set(Object.keys(knownEntriesByUrl));
            const [known, unknown] = partition(cleanedUrls, (u) =>
              knownUrls.has(u)
            );
            const promises: Promise<any>[] = [];
            if (override) {
              promises.push(rescanAll(known.map((u) => knownEntriesByUrl[u])));
            } else {
              if (known.length) {
                enqueueSnackbar(
                  `${known.length} of ${cleanedUrls.length} already scanned`,
                  { variant: 'info' }
                );
              }
            }
            promises.push(
              createNewPotentialUsers(
                userId,
                unknown,
                tags.map((t) => t.id)
              )
            );
            await Promise.all(promises);
            onClose();
            setUrls('');
          }}
        >
          Add
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};
