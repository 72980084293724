import { Button, Card } from '@material-ui/core';
import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Doc } from '../../../../../../../domainTypes/document';
import { ISpace } from '../../../../../../../domainTypes/space';
import { ReportsTable } from '../../../../../../../features/PerformanceNew/components/ReportsTable';
import {
  getReportsQuery,
  toReportDoc
} from '../../../../../../../features/PerformanceNew/services/report';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Centered } from '../../../../../../../layout/Centered';
import { Section } from '../../../../../../../layout/Section';
import { useMappedLoadingValue } from '../../../../../../../services/db';
import { guessCurrentTimezone } from '../../../../../../../services/time';

type Props = {
  space: Doc<ISpace>;
};

const HEIGHT = 300;

const Inner = ({ space }: Props) => {
  const [reports, loading] = useMappedLoadingValue(
    useCollection(
      getReportsQuery(space.id).orderBy('createdAt', 'desc').limit(30)
    ),
    (s) => s.docs.map(toReportDoc)
  );
  return (
    <Section>
      <CanvasBar>Performance Reports</CanvasBar>
      <Card>
        <ReportsTable
          reports={reports}
          loading={loading}
          height={HEIGHT}
          currency="USD"
          tz={guessCurrentTimezone()}
        />
      </Card>
    </Section>
  );
};

export const PerformanceReports: React.FC<Props> = ({ space }) => {
  const [reveal, setReveal] = useState(false);
  return (
    <Section>
      <CanvasBar>Performance Reports</CanvasBar>
      <Card>
        {reveal ? (
          <Inner space={space} />
        ) : (
          <Centered>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setReveal(true)}
            >
              Load Data
            </Button>
          </Centered>
        )}
      </Card>
    </Section>
  );
};
