import { AffiliateCookie } from './cookie';
import firebase from 'firebase/app';
import { BillingStatus, PlanFrequency, PlanType } from '../billing';
import { CurrencyCode } from '../currency';

export const COMMISSION_PERCENTAGE = 20;

export type IAffiliation = {
  spaceId: string;
  affiliateId: string;
  trackingId?: string;
  createdAt: firebase.firestore.Timestamp;
  commissionPercentage: number;

  referrer: string;
  userAgent: string;
  clickDate: firebase.firestore.Timestamp | null;
};

export type IAffiliationLead = {
  email: string;
  cookie: AffiliateCookie;
};

export type IAnonymizedAffiliationStatus = {
  uid: string;
  affiliateId: string;
  trackingId?: string;
  createdAt: firebase.firestore.Timestamp;
  commissionPercentage: number;
  status: BillingStatus;
  subscription?: {
    frequency: PlanFrequency;
    plan: PlanType;
    price: number; // cents
    currency: CurrencyCode;
  };
};
