import { capitalize } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterSelect,
  ChartFooterBarItem
} from '../../../../../components/Charts/ChartCard';
import { exportRowsToFile } from '../../../../../components/Exporter/service';
import {
  applySorterOnItems,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { PartnerLogoWithName } from '../../../../../components/PartnerLogo';
import { IColumn } from '../../../../../components/Table/Column';
import { Doc } from '../../../../../domainTypes/document';
import {
  IPotentialUser,
  IPotentialUserStatus
} from '../../../../../domainTypes/potentialUser';
import { styled } from '../../../../../emotion';
import { SortDirection } from '../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Section } from '../../../../../layout/Section';
import {
  useNumberQueryParam,
  useTypedStringQueryParam
} from '../../../../../routes';
import { useMappedLoadingValue } from '../../../../../services/db';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../../services/partner';
import { CoverageBar } from '../../components/CoverageBar';
import {
  PartnerDistributionChart,
  YField,
  Y_FIELDS
} from '../../components/PartnerDistributionChart';
import {
  StatusSelector,
  useStatusQueryParam
} from '../../components/StatusSelector';
import { getScanResult, usePotentialUsers } from '../../service';

type PartnerStatus = 'known' | 'unknown' | 'any';
const PARTNER_STATUSES: PartnerStatus[] = ['any', 'known', 'unknown'];

type Stat = {
  partnerKey: string;
  counts: {
    pages: number;
    links: number;
    products: number;
    cloaked: number;
    blogs: number;
  };
};

const usePartnerStats = (statuses: Set<IPotentialUserStatus>) => {
  const mapper = useCallback(
    (ps: Doc<IPotentialUser>[]) => {
      const stats = ps.reduce<{ [partnerKey: string]: Stat }>((m, p) => {
        if (!statuses.has(p.data.status)) {
          return m;
        }
        const partners = getScanResult(p, (r) => r.partners) || [];
        partners.forEach(({ partnerKey, counts }) => {
          const stat = (m[partnerKey] = m[partnerKey] || {
            partnerKey,
            counts: { pages: 0, links: 0, products: 0, cloaked: 0, blogs: 0 }
          });
          stat.counts.blogs += 1;
          stat.counts.pages += counts.pages;
          stat.counts.products += counts.products;
          stat.counts.links += counts.links;
          stat.counts.cloaked += counts.cloaked || 0;
        });

        return m;
      }, {});
      return Object.values(stats);
    },
    [statuses]
  );
  return useMappedLoadingValue(usePotentialUsers(), mapper, true);
};

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const downloadCsv = (partnerStatus: PartnerStatus, ds: Stat[]) => {
  return exportRowsToFile(
    `partners_${partnerStatus}`,
    ds,
    undefined,
    [
      {
        key: 'partner',
        head: () => 'Partner',
        cell: (d) => d.partnerKey.replace(/_/g, '.')
      },

      {
        key: 'blogs',
        head: () => 'Blogs',
        cell: (d) => d.partnerKey.replace(/_/g, '.')
      },
      {
        key: 'pages',
        head: () => 'Pages',
        cell: (d) => d.counts.pages
      },
      {
        key: 'products',
        head: () => 'Products',
        cell: (d) => d.counts.products
      },
      {
        key: 'links',
        head: () => 'Links',
        cell: (d) => d.counts.links
      },
      {
        key: 'cloaked',
        head: () => 'Cloaked',
        cell: (d) => d.counts.cloaked
      }
    ],
    'csv'
  );
};

const PartnersSection = ({ ds }: { ds: Stat[] }) => {
  const [partnerStatus, setPartnerStatus] = useTypedStringQueryParam<
    PartnerStatus
  >('parnterStatus', 'any');
  const [maxPartners, setMaxPartners] = useNumberQueryParam('maxPartners', 50);
  const [[s, dir], setSort] = useSortQueryParam('y', SORTERS);
  const sorter = s || SORTERS.links;
  const y: YField = Y_FIELDS.includes(sorter.key as YField)
    ? (sorter.key as YField)
    : 'links';

  const xs = useMemo(
    () =>
      partnerStatus === 'any'
        ? ds
        : ds.filter(
            (d) =>
              partnerStatus ===
              (!!getKnownPartnerForKey(d.partnerKey) ? 'known' : 'unknown')
          ),
    [ds, partnerStatus]
  );

  return (
    <Section>
      <CanvasBar>
        <div>Partners</div>
        <ButtonWithPromise
          size="small"
          pending="Exporting..."
          variant="contained"
          color="primary"
          onClick={() =>
            downloadCsv(partnerStatus, applySorterOnItems(sorter, xs, dir))
          }
        >
          Export CSV
        </ButtonWithPromise>
      </CanvasBar>
      <ChartCard noMaximize>
        <PartnerDistributionChart
          ds={xs}
          maxPartners={maxPartners}
          y={y}
          dir={Y_FIELDS.includes(sorter.key as YField) ? dir : 'desc'}
        />

        <ChartCardFooter>
          <ChartCardFooterBar>
            <ChartFooterBarItem>
              <ChartCardFooterSelect
                value={y}
                onChange={(nextY) => {
                  const nextSorter = SORTERS[nextY] || SORTERS.links;
                  setSort([nextSorter, nextSorter.items.dir]);
                }}
                options={Y_FIELDS.map((field) => ({
                  label: capitalize(field),
                  value: field
                }))}
              />
            </ChartFooterBarItem>
            <ChartFooterBarItem>
              <ChartCardFooterSelect
                value={partnerStatus}
                onChange={setPartnerStatus}
                options={PARTNER_STATUSES.map((s) => ({
                  label: `${s === 'any' ? 'All' : capitalize(s)} partners`,
                  value: s
                }))}
              />
              <ChartCardFooterSelect
                value={
                  Y_FIELDS.includes(sorter.key as YField)
                    ? dir || sorter.items.dir
                    : 'desc'
                }
                onChange={(nextDir) => {
                  setSort([sorter, nextDir]);
                }}
                options={(['asc', 'desc'] as SortDirection[]).map((x) => ({
                  label: capitalize(x),
                  value: x
                }))}
              />
              <ChartCardFooterSelect
                value={maxPartners}
                onChange={setMaxPartners}
                options={[25, 50, 75, 100].map((x) => ({
                  label: `${x}`,
                  value: x
                }))}
              />
            </ChartFooterBarItem>
          </ChartCardFooterBar>
        </ChartCardFooter>
      </ChartCard>
      <RowsRenderer
        variant="contained"
        columns={COLUMNS}
        rows={xs}
        rowToKey={(d) => d.partnerKey}
        sorter={sorter}
        sortDirection={dir}
        renderHead={true}
        onHeadClick={(c, d) => setSort([SORTERS[c.key] || SORTERS.links, d])}
        chunkSize={100}
        rootMargin="400px"
        rowHeight={ROW_HEIGHTS.dense}
        otherProps={undefined}
      />
    </Section>
  );
};

type ColumnName =
  | 'partner'
  | 'blogs'
  | 'pages'
  | 'products'
  | 'links'
  | 'cloaked'
  | 'percentage';
type Column = IColumn<Stat, ColumnName>;
const COLUMNS: Column[] = [
  {
    key: 'partner',
    head: () => 'Partner',
    cell: (d) => (
      <PartnerLogoWithName
        partner={
          getKnownPartnerForKey(d.partnerKey) ||
          constructPartnerForKey(d.partnerKey)
        }
      />
    ),
    sortable: true,
    align: 'left',
    width: 400,
    flexGrow: 3
  },
  {
    key: 'blogs',
    head: () => 'Blogs',
    cell: (d) => d.counts.blogs,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'pages',
    head: () => 'Pages',
    cell: (d) => d.counts.pages,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'products',
    head: () => 'Products',
    cell: (d) => d.counts.products,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'cloaked',
    head: () => 'Cloaked',
    cell: (d) => d.counts.cloaked,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'links',
    head: () => 'Links',
    cell: (d) => d.counts.links,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Stat> = {
  partner: {
    key: 'partner',
    items: { sort: (d) => d.partnerKey, dir: 'asc' }
  },
  blogs: {
    key: 'blogs',
    items: {
      sort: (d) => d.counts.blogs,
      dir: 'desc'
    }
  },
  pages: {
    key: 'pages',
    items: {
      sort: (d) => d.counts.pages,
      dir: 'desc'
    }
  },
  products: {
    key: 'products',
    items: {
      sort: (d) => d.counts.products,
      dir: 'desc'
    }
  },
  links: {
    key: 'links',
    items: {
      sort: (d) => d.counts.links,
      dir: 'desc'
    }
  },
  cloaked: {
    key: 'cloaked',
    items: {
      sort: (d) => d.counts.cloaked,
      dir: 'desc'
    }
  }
};

export const PageAcquisitionPartnerOverview = () => {
  const [statuses, setStatuses] = useStatusQueryParam('status');
  const [stats, loading] = usePartnerStats(statuses);

  if (loading || !stats) {
    return <Loader height={500} />;
  }

  return (
    <>
      <Section>
        <Container>
          <CoverageBar ds={stats} width="100%" height={20} />
          <StatusSelector value={statuses} onChange={setStatuses} />
        </Container>
      </Section>

      <PartnersSection ds={stats} />
    </>
  );
};
