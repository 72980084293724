import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  TextField,
  Tooltip
} from '@material-ui/core';
import { groupBy, keyBy, partition, sortBy, zip } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Check as IconCheck, X as IconCross } from 'react-feather';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { Loader } from '../../../../../components/Loader';
import { IColumn } from '../../../../../components/Table/Column';
import {
  VirtualizedSortableTable,
  VirtualizedSortableTableProps
} from '../../../../../components/Table/VirtualizedSortable';
import { isLockedOut } from '../../../../../domainTypes/billing';
import { Doc } from '../../../../../domainTypes/document';
import { Schedule } from '../../../../../domainTypes/schedule';
import { getSpaceDomainNames, ISpace } from '../../../../../domainTypes/space';
import { css, styled } from '../../../../../emotion';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { batchDeleteDocs, batchSet, store } from '../../../../../services/db';
import { pluralize } from '../../../../../services/pluralize';
import { useAllSchedules } from '../../../../../services/schedules';
import {
  getNextRunDate,
  toScheduleDocs
} from '../../../../../services/schedules/helper';
import {
  formatDatePrecise,
  HUMAN_DATE,
  now,
  toMoment
} from '../../../../../services/time';
import { FS } from '../../../../../versions';
import { SafeExecuteButton } from '../../../../components/SafeExecuteButton';
import { useSpaces } from '../../../../services/space';

const MINUTE_WHEN_SCHEDULES_RUN = 12;

type SortKey =
  | 'space'
  | 'type'
  | 'frequency'
  | 'nextRun'
  | 'lastRun'
  | 'active';

type ScheduleWithSpace = {
  space: ISpace;
  d: Doc<Schedule>;
};

type Data = {
  scheduledFor: moment.Moment;
  rows: ScheduleWithSpace[];
};

const ScheduleType = ({ d }: { d: Schedule }) => {
  if (d.type === 'SALES_API_FETCH') {
    return (
      <div>
        {d.type} ({d.config.handler})
      </div>
    );
  }
  return <div>{d.type}</div>;
};

const COLUMNS: IColumn<ScheduleWithSpace, SortKey>[] = [
  {
    key: 'space',
    head: () => 'Space',
    cell: ({ space }) => {
      if (!space) {
        return null;
      }
      return <div>{getSpaceDomainNames(space)}</div>;
    },
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 160,
    flexGrow: 2
  },
  {
    key: 'type',
    head: () => 'Type',
    cell: ({ d }) => <ScheduleType d={d.data} />,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 130,
    flexGrow: 1
  },
  {
    key: 'nextRun',
    head: () => 'Next',
    cell: ({ d }) =>
      d.data.nextRun && (
        <Tooltip title={formatDatePrecise(d.data.nextRun)}>
          <span>{toMoment(d.data.nextRun).fromNow()}</span>
        </Tooltip>
      ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'lastRun',
    head: () => 'Last',
    cell: ({ d }) =>
      d.data.lastRun && (
        <Tooltip title={formatDatePrecise(d.data.lastRun.date)}>
          <span
            className={css((t) => ({
              color: d.data.lastRun
                ? d.data.lastRun.status === 'OK'
                  ? t.custom.colors.success.main
                  : t.custom.colors.error.main
                : 'inherit'
            }))}
          >
            {toMoment(d.data.lastRun.date).fromNow()}
          </span>
        </Tooltip>
      ),
    align: 'left',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'frequency',
    head: () => 'Type',
    cell: ({ d }) => <div>{d.data.frequency.type}</div>,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'active',
    head: () => 'Active',
    cell: ({ d }) => (d.data.active ? <IconCheck /> : <IconCross />),
    align: 'center',
    sortable: false,
    defaultDirection: 'asc',
    width: 80,
    flexGrow: 1
  }
];

const ActionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const skipOverdueSchedules = async (ds: Doc<Schedule>[], execute: boolean) => {
  const n = moment();
  const nextDs = ds.map((d) => ({
    ...d,
    data: {
      ...d.data,
      nextRun: getNextRunDate(n, d.data.frequency)
    }
  }));
  console.log(zip(nextDs, ds));
  if (execute) {
    await batchSet(FS.schedules, nextDs);
  }
};

const fixTrialReminderDates = async (execute: boolean) => {
  const schedules = await store()
    .collection(FS.schedules)
    .where('frequency.type', '==', 'NONE')
    .get()
    .then(toScheduleDocs);
  const alreadyRun = schedules.filter(
    (s) => !!s.data.lastRun && s.data.lastRun.status === 'OK'
  );
  const updated = alreadyRun.map((s) => ({
    ...s,
    data: { ...s.data, nextRun: null }
  }));
  console.log(updated);
  if (execute) {
    await batchSet(FS.schedules, updated);
  }
};

const deduplicateTrialReminders = async (execute: boolean) => {
  const docs = await store()
    .collection(FS.schedules)
    .where('type', '==', 'TRIAL_REMINDER')
    .where('nextRun', '<', now())
    .get()
    .then(toScheduleDocs);

  const reminders = docs.filter((d) => !!d.data.nextRun);
  const bySpace = groupBy(reminders, (r) => r.data.spaceId);
  const toDelete: Doc<Schedule>[] = [];
  Object.values(bySpace).forEach((rs) => {
    if (rs.length < 2) {
      return;
    }
    const sorted = sortBy(rs, (r) =>
      r.data.nextRun ? r.data.nextRun.toMillis() : -Infinity
    );
    sorted.pop();
    toDelete.push(...sorted);
  });

  console.log('Deleting TRIAL_REMINDERs', toDelete);

  if (execute) {
    await batchDeleteDocs(toDelete);
  }
};

type CleanupDialogState = {
  type: string;
  type2: string;
  foundDocs: Doc<Schedule>[] | null;
};
const INITIAL_CLEANUP_STATE: CleanupDialogState = {
  type: '',
  type2: '',
  foundDocs: null
};

const CleanupDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [state, setState] = useState<CleanupDialogState>(INITIAL_CLEANUP_STATE);
  const close = () => {
    setState(INITIAL_CLEANUP_STATE);
    onClose();
  };

  const retrieveDocs = async () => {
    const { type, type2 } = state;
    if (type === type2) {
      await store()
        .collection(FS.schedules)
        .where('type', '==', type)
        .get()
        .then(toScheduleDocs)
        .then((docs) => {
          console.log(docs);
          setState((s) => ({ ...s, foundDocs: docs }));
        });
    }
  };

  const removeDocs = async () => {
    if (state.foundDocs) {
      await batchDeleteDocs(state.foundDocs).then(close);
    }
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Cleanup Schedules</DialogTitle>
      <DialogContent>
        <TextField
          label="Type"
          variant="outlined"
          fullWidth
          value={state.type}
          onChange={(ev) => {
            const type = ev.target.value;
            setState((s) => ({ ...s, type, foundDocs: null }));
          }}
        />
        <TextField
          label="Confirm Type"
          variant="outlined"
          fullWidth
          value={state.type2}
          onChange={(ev) => {
            const type2 = ev.target.value;
            setState((s) => ({ ...s, type2, foundDocs: null }));
          }}
        />

        {state.foundDocs && <p>{state.foundDocs.length} doc(s) found</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        {!state.foundDocs?.length && (
          <ButtonWithPromise
            disabled={state.type !== state.type2}
            onClick={retrieveDocs}
            pending="Removing..."
            variant="contained"
            color="primary"
          >
            Retrieve docs
          </ButtonWithPromise>
        )}
        {!!state.foundDocs?.length && (
          <ButtonWithPromise
            onClick={removeDocs}
            pending="Removing..."
            variant="contained"
            color="secondary"
          >
            Remove docs
          </ButtonWithPromise>
        )}
      </DialogActions>
    </Dialog>
  );
};

const Actions = ({
  overdue,
  excludeFutureTrialReminders,
  onChangeExcludeFutureTrialReminders
}: {
  overdue: Doc<Schedule>[];

  excludeFutureTrialReminders: boolean;
  onChangeExcludeFutureTrialReminders: (nextState: boolean) => void;
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  return (
    <ActionsContainer>
      <FormControlLabel
        label="Hide future trial reminders"
        control={
          <Checkbox
            checked={excludeFutureTrialReminders}
            onChange={(ev) =>
              onChangeExcludeFutureTrialReminders(ev.target.checked)
            }
          />
        }
      />
      <SafeExecuteButton onClick={(execute) => fixTrialReminderDates(execute)}>
        Fix TRIAL_REMINDER dates
      </SafeExecuteButton>

      <SafeExecuteButton
        onClick={(execute) => deduplicateTrialReminders(execute)}
      >
        Deduplicate TRIAL_REMINDERs
      </SafeExecuteButton>

      <Button variant="contained" color="primary" onClick={openDialog}>
        Cleanup schedules...
      </Button>
      <CleanupDialog open={dialogOpen} onClose={closeDialog} />

      <SafeExecuteButton
        variant="contained"
        color="secondary"
        onClick={(execute) => skipOverdueSchedules(overdue, execute)}
        disabled={!overdue.length}
      >
        Skip overdue {pluralize('schedule', overdue.length, true)}
      </SafeExecuteButton>
    </ActionsContainer>
  );
};

const KEY_FORMAT = 'YYYYDDDDHH';

const SchedulesSection = ({
  label,
  rows,
  height,
  rowClassNameFn
}: {
  label: string;
  rows: ScheduleWithSpace[];
  height: number;
  rowClassNameFn?: VirtualizedSortableTableProps<
    ScheduleWithSpace,
    SortKey
  >['rowClassNameFn'];
}) => {
  return (
    <Section>
      <CanvasBar>
        <div>{label}</div>
        <div>{rows.length}</div>
      </CanvasBar>
      <Paper>
        <VirtualizedSortableTable
          rows={rows}
          columns={COLUMNS}
          cellProps={undefined}
          height={height}
          margin="dense"
          sortFn={(t) => t}
          initialSortColumn={COLUMNS[0]}
          rowClassNameFn={rowClassNameFn}
        />
      </Paper>
    </Section>
  );
};

const getNextScheduleRunDate = () => {
  const n = moment();
  if (n.minute() >= MINUTE_WHEN_SCHEDULES_RUN) {
    return n.minute(MINUTE_WHEN_SCHEDULES_RUN).add(1, 'h');
  }
  return n.minute(MINUTE_WHEN_SCHEDULES_RUN);
};

const partitionByActivation = (ds: ScheduleWithSpace[], now: number) => {
  return ds.reduce<{
    active: ScheduleWithSpace[];
    inactive: ScheduleWithSpace[];
    lockout: ScheduleWithSpace[];
  }>(
    (m, d) => {
      if (!d.d.data.ignoreSpaceLockout && isLockedOut(d.space, now)) {
        m.lockout.push(d);
      } else if (d.d.data.active) {
        m.active.push(d);
      } else {
        m.inactive.push(d);
      }
      return m;
    },
    { active: [], inactive: [], lockout: [] }
  );
};

const isOverdue = (d: Doc<Schedule>, previousRun: number) => {
  return d.data.nextRun && d.data.nextRun.toMillis() < previousRun;
};

export const Schedules = ({
  ds,
  spaces
}: {
  ds: Doc<Schedule>[];
  spaces: ISpace[];
}) => {
  const [
    excludeFutureTrialReminders,
    setExcludeFutureTrialReminders
  ] = useState(true);
  const spacesById = keyBy(spaces, (s) => s.id);
  const schedulesWithSpace = ds.map((d) => ({
    space: spacesById[d.data.spaceId],
    d
  }));

  const [concludedSchedules, openSchedules] = partition(
    schedulesWithSpace,
    (sws) => !sws.d.data.nextRun
  );

  const nextRun = getNextScheduleRunDate();
  const nextRunMs = nextRun.valueOf();
  const previousRunMs = nextRun.clone().subtract(1, 'h').valueOf();

  const { active, inactive, lockout } = partitionByActivation(
    openSchedules,
    nextRunMs
  );

  const [next, future] = partition(
    active,
    ({ d }) => d.data.nextRun && d.data.nextRun.toMillis() < nextRunMs
  );

  const futureGroup = groupBy(
    excludeFutureTrialReminders
      ? future.filter((f) => f.d.data.type !== 'TRIAL_REMINDER')
      : future,
    ({ d }) => {
      if (!d.data.nextRun) {
        return 'UNKNOWN';
      }
      return toMoment(d.data.nextRun).format(KEY_FORMAT);
    }
  );

  const futureData = sortBy(
    Object.entries(futureGroup).map<Data>(([k, schedules]) => {
      return {
        scheduledFor: moment(k, KEY_FORMAT).minute(MINUTE_WHEN_SCHEDULES_RUN),
        rows: sortBy(
          schedules,
          ({ d }) => d.data.nextRun && d.data.nextRun.toMillis()
        )
      };
    }),
    (d) => d.scheduledFor.valueOf()
  );

  return (
    <>
      <Actions
        excludeFutureTrialReminders={excludeFutureTrialReminders}
        onChangeExcludeFutureTrialReminders={setExcludeFutureTrialReminders}
        overdue={next
          .map(({ d }) => d)
          .filter((d) => isOverdue(d, previousRunMs))}
      />
      <SchedulesSection
        label={`NEXT RUN: ${nextRun.format(HUMAN_DATE)}`}
        rows={next}
        height={500}
        rowClassNameFn={(d) =>
          d && isOverdue(d.d, previousRunMs)
            ? { backgroundColor: '#fff6ce' }
            : {}
        }
      />
      {futureData.map((d) => (
        <SchedulesSection
          key={d.scheduledFor.format(KEY_FORMAT)}
          label={d.scheduledFor.format(HUMAN_DATE)}
          rows={d.rows}
          height={160}
        />
      ))}
      <SchedulesSection label={'Inactive'} rows={inactive} height={400} />
      <SchedulesSection label={'Lockouts'} rows={lockout} height={400} />
      <SchedulesSection
        label={'Concluded'}
        rows={concludedSchedules}
        height={400}
      />
    </>
  );
};

export const PageSchedulesOps = () => {
  const [docs] = useAllSchedules();
  const [spaces] = useSpaces();
  return (
    <Page>
      {(!docs || !spaces) && <Loader />}
      {docs && spaces && (
        <Schedules ds={docs} spaces={spaces.map((s) => s.data)} />
      )}
    </Page>
  );
};
