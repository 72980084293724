import { Button, Grid } from '@material-ui/core';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../../components/AlertBox';
import { Loader } from '../../../../../components/Loader';
import { NavigationArrows } from '../../../../../components/NavigationArrows';
import { Doc } from '../../../../../domainTypes/document';
import {
  getSpaceDomainNames,
  ISpace,
  toArchivePath
} from '../../../../../domainTypes/space';
import { styled } from '../../../../../emotion';
import { useRouter } from '../../../../../hooks/useRouter';
import { Centered } from '../../../../../layout/Centered';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { formatBytes } from '../../../../../services/file';
import { useDownloadUrlWithCustomMetadata } from '../../../../../services/storage';
import { PRECISE_DATE } from '../../../../../services/time';
import {
  AnchoredSections,
  AnchorLinks,
  ISection
} from '../../../../layout/AnchoredSection';
import { TopNav } from '../../../../layout/TopNav';
import { useSpace, useSpaces } from '../../../../services/space';
import { Actions } from './components/Actions';
import { Affiliates } from './components/Affiliates';
import { ApiIntegrations } from './components/ApiIntegrations';
import { AutoLabelling } from './components/AutoLabelling';
import { Billing } from './components/Billing';
import { Data } from './components/Data';
import { Features } from './components/Features';
import { ImpersonationLink } from './components/ImpersonationLink';
import { LinkChecks } from './components/LinkChecks';
import { Members } from './components/Members';
import { Monitoring } from './components/Monitoring';
import { PageQueue } from './components/PageQueue';
import { Pages } from './components/Pages';
import { PerformanceReports } from './components/PerformanceReports';
import { Products } from './components/Products';
import { Schedules } from './components/Schedules';
import { Warnings } from './components/Warnings';

type Props = {
  spaceId: string;
};

const NO_NEXT_NO_PREV = {
  nextPage: null,
  prevPage: null
};
const useSpacePosition = (spaceId: string) => {
  const [docs] = useSpaces();
  if (!docs) {
    return NO_NEXT_NO_PREV;
  }

  const toUrl = (d: Doc<ISpace>) => `/spaces/${d.id}`;

  const sortedDocs = sortBy(docs, (d) => getSpaceDomainNames(d.data));

  const i = sortedDocs.findIndex((d) => d.id === spaceId);
  if (i === -1) {
    return NO_NEXT_NO_PREV;
  }

  const prev = sortedDocs[i - 1];
  const next = sortedDocs[i + 1];

  return {
    prevPage: prev
      ? { url: toUrl(prev), label: getSpaceDomainNames(prev.data) }
      : null,
    nextPage: next
      ? { url: toUrl(next), label: getSpaceDomainNames(next.data) }
      : null
  };
};

const Header = styled('h2')`
  display: flex;
  align-items: center;
  color: white;

  a {
    color: white;
  }

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const NavHeader = ({ title }: { title: string }) => {
  return (
    <Header>
      <Link to="/spaces">Spaces</Link>
      <ChevronRight />
      <div>{title}</div>
    </Header>
  );
};

const ArchiveBody = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const NoSpace = ({ spaceId }: { spaceId: string }) => {
  const [archived, loading] = useDownloadUrlWithCustomMetadata<{
    name: string;
    archivedAt: string;
  }>(toArchivePath(spaceId));
  if (loading) {
    return <Loader />;
  }
  if (!archived) {
    return <Centered>Space not found.</Centered>;
  }

  const { metadata, url } = archived;

  return (
    <>
      <TopNav>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <NavHeader title={`${metadata.name} - ARCHIVED`} />
          </Grid>
        </Grid>
      </TopNav>
      <Page style={{ marginTop: 50 }}>
        <ArchiveBody>
          <p>
            Archived on{' '}
            {moment(metadata.customMetadata.archivedAt).format(PRECISE_DATE)}
          </p>
          <p>
            <Button
              href={url}
              download
              target="_blank"
              variant="contained"
              color="primary"
            >
              Download space ({formatBytes(archived.metadata.size)})
            </Button>
          </p>
        </ArchiveBody>
      </Page>
    </>
  );
};

export const PageSpaceDetails = ({ spaceId }: Props) => {
  const { history } = useRouter();
  const [doc, loading] = useSpace(spaceId);
  const { prevPage, nextPage } = useSpacePosition(spaceId);
  if (loading) {
    return <Loader />;
  }
  if (!doc) {
    return <NoSpace spaceId={spaceId} />;
  }

  const SECTIONS: ISection[] = [
    {
      anchor: 'warnings',
      label: 'Warnings',
      el: <Warnings space={doc} />,
      noLink: true
    },
    { anchor: 'billing', label: 'Billing', el: <Billing space={doc.data} /> },
    {
      anchor: 'monitoring',
      label: 'Monitoring',
      el: <Monitoring spaceId={doc.id} />
    },
    { anchor: 'team', label: 'Team', el: <Members space={doc} /> },

    {
      anchor: 'features',
      label: 'Features',
      el: <Features space={doc.data} />
    },
    {
      anchor: 'perfReports',
      label: 'Reporting',
      el: <PerformanceReports space={doc} />
    },
    {
      anchor: 'apiIntegrations',
      label: 'Integrations',
      el: <ApiIntegrations space={doc} />
    },
    {
      anchor: 'smartLabels',
      label: 'Smart Labels',
      el: <AutoLabelling spaceId={doc.id} />
    },
    { anchor: 'pages', label: 'Pages', el: <Pages spaceId={doc.id} /> },
    {
      anchor: 'pageQueue',
      label: 'Page Queue',
      el: <PageQueue spaceId={doc.id} />
    },
    {
      anchor: 'linkChecks',
      label: 'Link Checks',
      el: <LinkChecks spaceId={doc.id} />
    },
    {
      anchor: 'affiliates',
      label: 'Referrals',
      el: <Affiliates space={doc} />
    },
    {
      anchor: 'products',
      label: 'Links',
      el: <Products spaceId={doc.id} />
    },
    {
      anchor: 'schedules',
      label: 'Schedules',
      el: <Schedules spaceId={doc.id} />
    },
    { anchor: 'actions', label: 'Actions', el: <Actions space={doc} /> },
    { anchor: 'data', label: 'Data', el: <Data space={doc} /> }
  ];

  return (
    <>
      <TopNav>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <NavHeader title={getSpaceDomainNames(doc.data)} />
          </Grid>
          <Grid item>
            <ImpersonationLink spaceId={spaceId} />
          </Grid>
          <Grid item>
            <NavigationArrows
              prevPage={prevPage}
              nextPage={nextPage}
              toNextPage={(page) => history.push(page.url)}
            />
          </Grid>
        </Grid>
        <AnchorLinks sections={SECTIONS} offset={100} />
      </TopNav>
      <Page style={{ marginTop: 50 }}>
        {!doc.data.active && (
          <Section>
            <AlertBox variant="error">SPACE DEACTIVATED</AlertBox>
          </Section>
        )}
        <AnchoredSections sections={SECTIONS} />
      </Page>
    </>
  );
};
