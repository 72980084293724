import { DateTimePicker } from '@material-ui/pickers';
// import { sortBy } from 'lodash';
import React from 'react';
// import {
//   EVENT_CATEGORIES,
//   EVENT_TYPES
// } from '../../../../../domainTypes/event';
// import { ISpace } from '../../../../../domainTypes/space';
import { styled } from '../../../../../emotion';
import { fromMoment, toMoment } from '../../../../../services/time';
// import { getSpaces, matchSpace } from '../../../../services/space';
import { EventsFilterArgs } from '../../service';

type Props = {
  fixedSpace?: boolean;
  value: EventsFilterArgs;
  onChange: (nextValue: EventsFilterArgs) => void;
};

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${p => p.theme.spacing(2)}px;
`;

//       {!fixedSpace && (
//         <AutocompleteSingle
//           value={value.spaceId}
//           onChange={spaceId => change({ spaceId })}
//           label="Space"
//           loadOptions={input => {
//             console.log('loading');
//             return getSpaces().then(spaces =>
//               sortBy(
//                 spaces
//                   .filter(s => !input || matchSpace(s.data, input))
//                   .map<IOption>(s => ({
//                     value: s.id,
//                     label: `${getSpaceDomainNames(s.data) || 'zzzzzz'} - ${s.id}`
//                   })),
//                 o => o.label
//               )
//             );
//           }}
//         />
//       )}
//       <AutocompleteSingle
//         label="Category"
//         value={value.category}
//         onChange={category => change({ category })}
//         loadOptions={input =>
//           Promise.resolve(
//             EVENT_CATEGORIES.filter(
//               e => !input || e.toLowerCase().indexOf(input.toLowerCase()) !== -1
//             ).map(e => ({ value: e, label: e }))
//           )
//         }
//       />
//       <AutocompleteSingle
//         label="Type"
//         value={value.type}
//         onChange={type => change({ type })}
//         loadOptions={input =>
//           Promise.resolve(
//             EVENT_TYPES.filter(
//               e => !input || e.toLowerCase().indexOf(input.toLowerCase()) !== 1
//             ).map(e => ({ value: e, label: e }))
//           )
//         }
//       />

export const FilterBar: React.FC<Props> = ({ value, onChange, fixedSpace }) => {
  const change = (v: Partial<EventsFilterArgs>) => onChange({ ...value, ...v });
  return (
    <Container>
      <DateTimePicker
        value={value.start ? toMoment(value.start) : null}
        clearable={true}
        onChange={m => change({ start: m ? fromMoment(m.startOf('d')) : null })}
        label="From"
        showTodayButton
        ampm={false}
      />
      <DateTimePicker
        value={value.end ? toMoment(value.end) : null}
        clearable={true}
        onChange={m => change({ end: m ? fromMoment(m.endOf('d')) : null })}
        label="To"
        showTodayButton
        ampm={false}
      />
    </Container>
  );
};
