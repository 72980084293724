import AnsiUp from 'ansi_up';
import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '../../../emotion';
import './style.css';

type Props = {
  ansi: string;
  maxHeight?: number;
  scrollToBottom?: boolean;
};

export const AnsiContainer = styled<'pre', { maxHeight?: number }>('pre')`
  background-color: rgb(29, 31, 33);
  color: #efefef;
  max-height: ${p => (p.maxHeight ? `${p.maxHeight}px` : 'auto')};
  overflow: auto;
  padding: ${p => p.theme.spacing(3)}px;
  white-space: pre-line;
`;

export const Ansi: React.FC<Props> = ({ ansi, maxHeight, scrollToBottom }) => {
  const [node, setNode] = useState<HTMLPreElement | null>(null);
  const html = useMemo(() => {
    const transformer = new AnsiUp();
    transformer.use_classes = true;
    return transformer.ansi_to_html(ansi);
  }, [ansi]);

  useEffect(() => {
    if (scrollToBottom && node) {
      node.scrollTop = node.scrollHeight;
    }
  }, [node, html, scrollToBottom]);

  return (
    <AnsiContainer
      ref={setNode}
      maxHeight={maxHeight}
      className="tomorrow-night-eighties-ansi-theme"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
