import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { InlineLoader } from '../../../../../components/Loader';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../domainTypes/document';
import {
  NotificationPrototype,
  ReleaseNotification
} from '../../../../../domainTypes/notifications';
import {
  notificationCollection,
  toNotificationDoc
} from '../../../../../features/Notifications/services';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { useMappedLoadingValue } from '../../../../../services/db';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { HUMAN_DATE, toMoment } from '../../../../../services/time';

const RELEASE_NOTIFICATIONS: NotificationPrototype<ReleaseNotification>[] = [
  { category: 'Release', type: 'Release_Notifications' },
  { category: 'Release', type: 'Release_AffiliateProgram' },
  { category: 'Release', type: 'Release_AvantLinkIntegration' },
  { category: 'Release', type: 'Release_PepperjamIntegration' },
  { category: 'Release', type: 'Release_ExtensionKlook' },
  { category: 'Release', type: 'Release_LabelRules' }
];

const distribute = (prototype: NotificationPrototype<ReleaseNotification>) => {
  return callFirebaseFunction<Doc<Notification>[]>(
    'notifications-distributeNotification',
    {
      prototype,
      createdAt: Date.now(),
      distributePerSpace: false,
      userIds: 'EVERYONE'
    }
  );
};

const useReleaseNotificationDistributionDate = (
  prototype: NotificationPrototype<ReleaseNotification>
) => {
  // we assume that a release notification is always unique and created at
  // the same time for everyone.
  // determining whether it has been released before can therefore be done by just
  // checking if any exists
  return useMappedLoadingValue(
    useCollection(
      notificationCollection().where('type', '==', prototype.type).limit(1)
    ),
    (s) => {
      if (!s.docs.length) {
        return null;
      }
      return toNotificationDoc(s.docs[0]).data.createdAt;
    }
  );
};

type TableKey = 'name' | 'actions';
type TableData = NotificationPrototype<ReleaseNotification>;

const Actions = ({ d }: { d: TableData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [releaseDate, loading] = useReleaseNotificationDistributionDate(d);
  const { dialogOpen, openDialog, closeDialog } = useDialogState();

  const release = () =>
    distribute(d).then(
      (ns) => {
        enqueueSnackbar(`${ns.length} notifications distributed!`, {
          variant: 'success'
        });
      },
      (err) => {
        console.log(err);
        enqueueSnackbar('Distribution failed!', { variant: 'error' });
      }
    );

  if (loading) {
    return <InlineLoader />;
  }
  if (releaseDate) {
    return (
      <>
        <Button onClick={openDialog}>
          {toMoment(releaseDate).format(HUMAN_DATE)}
        </Button>
        <Dialog open={dialogOpen} onClose={closeDialog}>
          <DialogTitle>Re-distribute?</DialogTitle>
          <DialogContent>
            This notification was already distributed. Are you sure you want to
            continue?
          </DialogContent>
          <DialogActions>
            <ButtonWithPromise
              variant="contained"
              color="secondary"
              onClick={() => release().then(() => closeDialog())}
              pending="Distributing..."
            >
              Redistribute
            </ButtonWithPromise>
            <Button variant="contained" color="primary" onClick={closeDialog}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return (
    <ButtonWithPromise
      variant="contained"
      color="primary"
      size="small"
      onClick={release}
      pending="Distributing..."
    >
      Distribute
    </ButtonWithPromise>
  );
};

const COLUMNS: IColumn<TableData, TableKey>[] = [
  {
    key: 'name',
    head: () => 'Feature',
    cell: (d) => d.type.replace(/^Release_/, ''),
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    align: 'right',
    sortable: false,
    defaultDirection: 'asc',
    width: 200,
    flexGrow: 2
  }
];

export const ReleaseNotificationsTable = () => {
  return (
    <VirtualizedSortableTable
      rows={RELEASE_NOTIFICATIONS}
      columns={COLUMNS}
      cellProps={undefined}
      height={400}
      margin="dense"
      sortFn={(t) => t}
      initialSortColumn={COLUMNS[0]}
    />
  );
};
