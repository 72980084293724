import React from 'react';
import { HashLink } from 'react-router-hash-link';

export const AnchorLink: React.FC<{
  anchor: string;
  to?: string;
  offset?: number;
  title?: string;
  smooth?: boolean;
}> = ({
  anchor,
  to = '',
  smooth = false,
  offset = 0,
  title = '',
  children
}) => {
  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -offset;
    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: smooth ? 'smooth' : 'auto'
    });
  };
  return (
    <HashLink
      to={`${to}#${anchor}`}
      scroll={scrollWithOffset}
      smooth
      title={title || ''}
    >
      {children}
    </HashLink>
  );
};
