import { Button, Grid } from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Loader } from '../../../../../components/Loader';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Centered } from '../../../../../layout/Centered';
import { Page } from '../../../../../layout/Page';
import { store, useMappedLoadingValue } from '../../../../../services/db';
import { FS } from '../../../../../versions';
import { BuildMetadata } from '../../components/BuildMetadata';
import { toBuildDoc } from '../../service';

type Props = {};

const getBuildsQuery = (env: string, limit: number) =>
  store()
    .collection(FS._deploymentBuilds)
    .where('env', '==', env)
    .orderBy('startedAt', 'desc')
    .limit(limit);

const useBuilds = (env: string, limit: number) => {
  return useMappedLoadingValue(useCollection(getBuildsQuery(env, limit)), s =>
    s.docs.map(toBuildDoc)
  );
};

const Builds: React.FC<{ env: string }> = ({ env }) => {
  const [limit, setLimit] = useState(10);
  const [builds, loading] = useBuilds(env, limit);

  return (
    <>
      <CanvasBar>{capitalize(env)}</CanvasBar>
      {loading && !builds && <Loader height={500} />}
      {builds && (
        <div>
          {builds.map(b => (
            <BuildMetadata key={b.id} build={b.data} />
          ))}
          <Centered>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setLimit(l => l + 10)}
            >
              Show more
            </Button>
          </Centered>
        </div>
      )}
    </>
  );
};

export const PageDeploymentBuilds: React.FC<Props> = () => {
  return (
    <Page>
      <Grid container alignItems="stretch" spacing={2}>
        <Grid item md={6} sm={12}>
          <Builds env="prod" />
        </Grid>
        <Grid item md={6} sm={12}>
          <Builds env="dev" />
        </Grid>
      </Grid>
    </Page>
  );
};
