import { generateToDocFn } from '../../../domainTypes/document';
import { IUserEngagement } from '../../../domainTypes/userEngagement';
import { store } from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { FS } from '../../../versions';
import { getAllUserIds } from '../user';

const toUserEngagementDoc = generateToDocFn<IUserEngagement>();

const collection = () => store().collection(FS.userEngagement);

const getListener = createSingleCollectionListenerStore(
  () => new CollectionListener(collection(), toUserEngagementDoc)
);

export const useAllUserEngagementData = () =>
  useCollectionListener(getListener());

export const updateUserEngagementData = (userIds: string[]) =>
  callFirebaseFunction('userEngagement-updateSessionData ', {
    userIds
  });

export const updateAllUserEngagementData = async () => {
  const userIds = await getAllUserIds();
  return updateUserEngagementData(userIds);
};
