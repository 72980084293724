import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { Doc } from '../../../../../../../domainTypes/document';
import { ISpace } from '../../../../../../../domainTypes/space';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import { FS } from '../../../../../../../versions';
import { Json } from '../../../../../../components/Json';
import { LinkExternal } from '../../../../../../components/LinkExternal';
import { toFirestoreConsole } from '../../../../../../services/firebase';

type Props = {
  space: Doc<ISpace>;
};

export const Data: React.FC<Props> = ({ space }) => {
  return (
    <Section>
      <CanvasBar>
        <div>Data</div>
        <LinkExternal href={toFirestoreConsole(FS.spaces, space.id)}>
          Open in Firestore console
        </LinkExternal>
      </CanvasBar>
      <Card>
        <CardContent>
          <Json
            data={space}
            shouldCollapse={({ namespace }) =>
              namespace.length > 3 && namespace[2] === 'permissions'
            }
          />
        </CardContent>
      </Card>
    </Section>
  );
};
