import DateFnsUtils from '@date-io/moment'; // choose your lib
import { createStyles, Theme, withStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from 'emotion-theming';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps
} from 'react-router-dom';
import '../00_init';
import { BackgroundJobsProvider } from '../hooks/useBackgroundJobs';
import { HotkeysProvider } from '../hooks/useHotkeys';
import { AnalyticsV2CacheProvider } from '../services/analyticsV2/cache';
import { COMBINED_THEME, MUI_THEME } from '../themes';
import { Login } from './features/Login';
import Header from './layout/Header';
import Navigator from './layout/Navigator';
import { CurrentUserProvider, signOut, useAdminLogin } from './services/auth';
import { LOOSE_PAGES, PAGES } from './sitemap';

const drawerWidth = 212;

const styles = createStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '212px 1fr',
    minHeight: '100vh'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appContent: {
    display: 'block'
  },
  mainContent: {
    display: 'block',
    padding: '12px 36px 0',
    background: '#eaeff1'
  }
}));

const AppThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={MUI_THEME}>
    {(() => {
      return (
        // @ts-ignore
        <ThemeProvider theme={COMBINED_THEME}>{children}</ThemeProvider>
      );
    })()}
  </MuiThemeProvider>
);

const AppContext: React.FC<{ userId: string }> = ({ userId, children }) => {
  return (
    <CurrentUserProvider userId={userId}>
      <HotkeysProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider maxSnack={3}>
            <BackgroundJobsProvider>
              <AppThemeProvider>{children}</AppThemeProvider>
            </BackgroundJobsProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </HotkeysProvider>
    </CurrentUserProvider>
  );
};

const App = ({ classes }: { classes: any }) => {
  const { adminUser, loading, error } = useAdminLogin();
  const [drawerOpen, toggleDrawer] = useState(false);

  if (error) {
    console.log(error);
    setTimeout(() => signOut(), 2000);
    return <div>Error</div>;
  }

  if (loading) {
    return null;
  }

  if (!adminUser) {
    return (
      <AppThemeProvider>
        <Login />
      </AppThemeProvider>
    );
  }

  return (
    <AppContext userId={adminUser.uid}>
      <AnalyticsV2CacheProvider>
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <nav className={classes.drawer}>
              <Hidden smUp implementation="js">
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={drawerOpen}
                  onClose={() => toggleDrawer(!drawerOpen)}
                />
              </Hidden>
              <Hidden xsDown implementation="css">
                <Navigator PaperProps={{ style: { width: drawerWidth } }} />
              </Hidden>
            </nav>

            <div className={classes.appContent}>
              <Route path="/" exact render={() => <Redirect to="/spaces" />} />
              {PAGES.map((page) => {
                const tabPaths = page.tabs.map((t) => t.path);
                return (
                  <Route
                    key={page.path}
                    exact
                    path={`${page.path}/:tab(${tabPaths.join('|')})?`}
                    render={(props: RouteComponentProps) => {
                      const activeTab =
                        page.tabs.find(
                          (t) => (props.match.params as any)['tab'] === t.path
                        ) || page.tabs[0];
                      const tabIndex = page.tabs.indexOf(activeTab);
                      return (
                        <>
                          <Header
                            onDrawerToggle={() => toggleDrawer(!drawerOpen)}
                            adminUser={adminUser}
                            title={page.title}
                            tabs={page.tabs.map((t) => t.label)}
                            activeTabIndex={tabIndex}
                            onChange={(e: any, i: number) => {
                              const nextTab = page.tabs[i];
                              if (!nextTab) {
                                return;
                              }
                              props.history.push(
                                `${page.path}/${nextTab.path}`
                              );
                            }}
                          />
                          <main className={classes.mainContent}>
                            {activeTab.render(props)}
                          </main>
                        </>
                      );
                    }}
                  />
                );
              })}
              {LOOSE_PAGES.map((p) => (
                <Route
                  key={p.path}
                  path={p.path}
                  exact
                  render={(props) => (
                    <main className={classes.mainContent}>
                      {p.render(props)}
                    </main>
                  )}
                />
              ))}
            </div>
          </div>
        </Router>
      </AnalyticsV2CacheProvider>
    </AppContext>
  );
};

export default withStyles(styles)(App);
