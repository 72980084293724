import moment from 'moment-timezone';
import React from 'react';
import { Stopwatch } from '../../../../../components/Stopwatch';
import { getTimeElapsed } from '../../../../../services/time';

export const Duration: React.FC<{ a: number; b?: number }> = ({ a, b }) =>
  b ? (
    <span>{getTimeElapsed(moment(a), moment(b))}</span>
  ) : (
    <Stopwatch start={moment(a)} />
  );
