import { sortBy } from 'lodash';
import shortid from 'shortid';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import { ITagCategory, ITaggable, ITagPrototype } from '../../domainTypes/tag';
import {
  batchUpdateDocsFromPartials,
  setDoc,
  store,
  updateDoc,
  useMappedLoadingValue
} from '../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListenersFromStore
} from '../../services/firecache/documentListener';
import { FS } from '../../versions';

const toTagProtoypeDoc = generateToDocFn<ITagPrototype>();
const prototypeCollection = () => store().collection(FS.tagPrototypes);

export const createTagPrototype = async (
  tag: Omit<ITagPrototype, 'id' | 'editedAt' | 'editedBy'>
) => {
  const id = shortid();
  const doc: Doc<ITagPrototype> = {
    id,
    collection: FS.tagPrototypes,
    data: {
      id,
      ...tag,
      editedBy: tag.createdBy,
      editedAt: tag.createdAt
    }
  };
  return setDoc(doc);
};

export const editTagPrototype = async (tag: ITagPrototype) => {
  const doc: Doc<ITagPrototype> = {
    id: tag.id,
    collection: FS.tagPrototypes,
    data: tag
  };
  await setDoc(doc);
  return tag;
};

export const saveTagsOnDoc = async <T extends ITaggable>(doc: Doc<T>) => {
  return updateDoc(
    doc,
    (d) =>
      ({
        tags: d.tags,
        tagIds: d.tagIds
        // why is this type not accepted??
      } as Partial<T>)
  );
};

export const saveTagsOnDocs = async <T extends ITaggable>(docs: Doc<T>[]) => {
  return batchUpdateDocsFromPartials(
    docs.map((d) => {
      const partial: ITaggable = {
        tags: d.data.tags,
        tagIds: d.data.tagIds
      };
      return {
        ...d,
        data: partial
      };
    })
  );
};

const DELIMITER = '@@@';
const toPrototypeCollectionKey = (spaceId: string, category: ITagCategory) =>
  [spaceId, category].join(DELIMITER);
const getListenerBySpaceAndCategory = createCollectionListenerStore((key) => {
  const [spaceId, category] = key.split(DELIMITER);
  return new CollectionListener(
    prototypeCollection()
      .where('spaceId', '==', spaceId)
      .where('category', '==', category),
    toTagProtoypeDoc
  );
});

export const getTagPrototypesForCategory = (
  spaceId: string,
  category: ITagCategory
) => {
  const key = toPrototypeCollectionKey(spaceId, category);
  return getListenerBySpaceAndCategory(key).get();
};

export const useTagPrototypesForCategory = (
  spaceId: string,
  category: ITagCategory
) => {
  const key = toPrototypeCollectionKey(spaceId, category);
  return useMappedLoadingValue(
    useCollectionListener(getListenerBySpaceAndCategory(key)),
    (ps) => sortBy(ps, (p) => p.data.name)
  );
};

const prototypeStore = createDocumentListenerGetter(
  (id) => prototypeCollection().doc(id),
  toTagProtoypeDoc
);

export const useTagPrototypes = (ids: string[]) =>
  useDocumentListenersFromStore(prototypeStore, ids);
