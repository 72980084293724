import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React from 'react';
import { Loader } from '../../../../../components/Loader';
import { Doc } from '../../../../../domainTypes/document';
import { IProductScanResult } from '../../../../../domainTypes/productScan';
import { ScanStatus } from '../../../../../features/Scan/components/ScanStatus';
import { Centered } from '../../../../../layout/Centered';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { formatDate, toMoment } from '../../../../../services/time';
import { SafeExecuteButton } from '../../../../components/SafeExecuteButton';

const HEIGHT = 400;

type Props = {
  docs: Doc<IProductScanResult>[];
  loading: boolean;
  actionCell: {
    title: React.ReactNode;
    component: React.ComponentType<{ doc: Doc<IProductScanResult> }>;
  };
};

export const List: React.FC<Props> = ({ docs, loading, actionCell }) => {
  return (
    <>
      <SafeExecuteButton
        variant="outlined"
        style={{ float: 'right' }}
        onClick={(execute) => {
          return callFirebaseFunction(
            'scraping-removeAllProductScansOlderThanNDays',
            {
              maxAgeInDaysAllowed: 30,
              execute,
              offset: 0,
              limit: 10
            }
          ).then((x) => console.log(x));
        }}
      >
        Cleanup old scans...
      </SafeExecuteButton>
      {loading && <Loader height={HEIGHT} />}
      {!loading && !docs.length && (
        <Centered height={HEIGHT}>No scans found.</Centered>
      )}
      {!loading && !!docs.length && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Domain or Page</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="center">{actionCell.title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((doc) => {
              return (
                <TableRow key={doc.id}>
                  <TableCell>
                    {doc.data.targets.map((t) => t.url).join(', ')}
                  </TableCell>
                  <TableCell>
                    <ScanStatus status={doc.data.status} />
                  </TableCell>
                  <TableCell>
                    {formatDate(toMoment(doc.data.createdAt))}
                  </TableCell>
                  <TableCell align="center">
                    <actionCell.component doc={doc} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};
