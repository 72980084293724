import {
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { ChevronDown, ChevronRight, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader';
import {
  getPrevAndNextPage,
  NavigationArrows
} from '../../../../../components/NavigationArrows';
import { autoHeightRow } from '../../../../../components/Table';
import { IDeployJobWithNo } from '../../../../../domainTypes/deployment';
import { Doc } from '../../../../../domainTypes/document';
import { styled } from '../../../../../emotion';
import { useRouter } from '../../../../../hooks/useRouter';
import { Centered } from '../../../../../layout/Centered';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { TopNav } from '../../../../layout/TopNav';
import { BuildNumber } from '../../components/BuildNumber';
import { Duration } from '../../components/Duration';
import { LogViewer } from '../../components/LogViewer';
import { Sha } from '../../components/Sha';
import { Status } from '../../components/Status';
import { SERVICES, useServiceHistory } from '../../service';

type Props = {
  env: string;
  name: string;
};
const formatTs = (ts: number) => moment(ts).format('YYYY/MM/DD HH:mm:ss');

const JobRow: React.FC<{ job: Doc<IDeployJobWithNo> }> = ({ job }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>{job.data.no}</TableCell>
        <TableCell align="center">
          <Status status={job.data.status} />
        </TableCell>
        <TableCell>
          <BuildNumber job={job.data} />
        </TableCell>
        <TableCell>{job.data.executor}</TableCell>
        <TableCell>
          <span>{formatTs(job.data.finishedAt || job.data.startedAt)}</span> -{' '}
          <Duration a={job.data.startedAt} b={job.data.finishedAt} />
        </TableCell>
        <TableCell>
          <Sha sha={job.data.sha} />
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setExpanded((t) => !t)}>
            {expanded && <ChevronUp />}
            {!expanded && <ChevronDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <TableCell colSpan={7}>
            <LogViewer job={job.data} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const ServiceHistory: React.FC<Props> = ({ env, name }) => {
  const [history, loading] = useServiceHistory(env, name, 10);
  const autoHeight = autoHeightRow();

  if (loading) {
    return <Loader height={500} />;
  }
  if (!loading && !history) {
    return <Centered>No jobs found.</Centered>;
  }

  if (!loading && history) {
    return (
      <Table>
        <TableHead>
          <TableRow classes={autoHeight}>
            <TableCell>Version</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell>Build</TableCell>
            <TableCell>Executor</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>SHA</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((job) => (
            <JobRow job={job} key={job.id} />
          ))}
        </TableBody>
      </Table>
    );
  }

  return null;
};

const Header = styled('h2')`
  display: flex;
  align-items: center;

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

export const PageServiceDetails: React.FC<Props> = ({ env, name }) => {
  const { history } = useRouter();
  const { prevPage, nextPage } = getPrevAndNextPage(
    SERVICES,
    SERVICES.findIndex((t) => t === name),
    (t) => `/deployments/services/${env}/${t}`,
    (t) => t
  );
  return (
    <>
      <TopNav>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Header>
              <Link to="/deployments/services">Services ({env})</Link>
              <ChevronRight />
              <div>{name}</div>
            </Header>
          </Grid>
          <Grid item />
          <Grid item>
            <NavigationArrows
              prevPage={prevPage}
              nextPage={nextPage}
              toNextPage={(page) => history.push(page.url)}
            />
          </Grid>
        </Grid>
      </TopNav>
      <Page style={{ marginTop: 50 }}>
        <Section>
          <Card>
            <ServiceHistory env={env} name={name} />
          </Card>
        </Section>
      </Page>
    </>
  );
};
