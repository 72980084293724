import React, { useState, useEffect } from 'react';
import {
  ITaggable,
  ITagPrototype,
  ITagCategory,
  replaceTagsOnDoc
} from '../../../domainTypes/tag';
import { Doc } from '../../../domainTypes/document';
import { useTagPrototypes, saveTagsOnDoc } from '../service';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { useMappedLoadingValue } from '../../../services/db';
import { compact } from 'lodash';
import { AutocompleteTags } from '../AutocompleteTags';
import { now } from '../../../services/time';
import { ButtonWithPromise } from '../../ButtonWithPromise';
import { areShallowEqualArrays } from '../../../services/equality';

type Props<T extends ITaggable> = {
  d: Doc<T>;
  onClose: () => void;
  category: ITagCategory;
  spaceId: string;
  userId: string;
};

export const TaggableEntityEditDialogBody = <T extends ITaggable>({
  d,
  onClose,
  category,
  spaceId,
  userId
}: Props<T>) => {
  const [protos, loading] = useMappedLoadingValue(
    useTagPrototypes(d.data.tagIds),
    compact
  );
  const [v, setV] = useState<ITagPrototype[]>(
    protos ? protos.map(p => p.data) : []
  );

  useEffect(() => {
    setV(protos ? protos.map(p => p.data) : []);
  }, [protos]);
  if (!protos || loading) {
    return null;
  }
  return (
    <>
      <DialogTitle>Edit tags</DialogTitle>
      <DialogContent>
        <AutocompleteTags
          value={v}
          onChange={setV}
          category={category}
          spaceId={spaceId}
          userId={userId}
          autoFocus
          style={{ minWidth: 300 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          disabled={areShallowEqualArrays(
            d.data.tagIds,
            v.map(x => x.id)
          )}
          onClick={async () => {
            await saveTagsOnDoc(
              replaceTagsOnDoc(
                d,
                v.map(x => x.id),
                userId,
                now()
              )
            );
            onClose();
          }}
          pending="Updating..."
        >
          Edit
        </ButtonWithPromise>
      </DialogActions>
    </>
  );
};
