import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import {
  screenshotCollection,
  toPageScreenshotDoc
} from '../../../services/pageScreenshots';

export const getScreenshotsInSpaceQuery = (spaceId: string) => {
  return screenshotCollection().where('spaceId', '==', spaceId);
};

const getCache = createCollectionListenerStore(
  spaceId =>
    new CollectionListener(
      getScreenshotsInSpaceQuery(spaceId),
      toPageScreenshotDoc
    )
);

export const useScreenshotsInSpace = (spaceId: string) =>
  useCollectionListener(getCache(spaceId));
