import queryString from 'query-string';
import { createRoutes } from '../../../domainTypes/routes';
import * as ENV from '../../../env.json';

export const getAppUrl = () => {
  const p = ENV.domains.app;
  return process.env.NODE_ENV === 'development' ? p.local : p.public;
};

export const ROUTES = createRoutes(
  (path, query) => ({
    path: `${getAppUrl()}${path}`,
    query
  }),
  queryString.stringify
);
