import React from 'react';
import { styled } from '../../emotion';
import { Tooltip } from '@material-ui/core';
import { formatNumber, toPercent } from '../Number';

type BarPart = {
  label: string;
  value: number;
  color: string;
};

type Props = {
  width: number | string;
  height: number | string;
  parts: BarPart[];
};

const toPx = (x: number | string) => (typeof x === 'number' ? `${x}px` : x);

const Container = styled<
  'div',
  { width: number | string; height: number | string }
>('div')`
  display: flex;
  width: ${p => toPx(p.width)};
  height: ${p => toPx(p.height)};
  border-radius: ${p => p.theme.custom.border.radius()}px;
`;

const Part = styled<'div', { width: number; color: string }>('div')`
  width: ${p => p.width}%;
  height: 100%;
  background-color: ${p => p.color};
`;

export const SimpleBar = ({ width, height, parts }: Props) => {
  const total = parts.reduce((m, p) => m + p.value, 0);
  return (
    <Container width={width} height={height}>
      {parts.map((p, i) => (
        <Tooltip
          key={i}
          title={`${p.label} (${p.value} - ${formatNumber({
            n: toPercent(p.value, total),
            format: 'percent',
            digits: 1
          })})`}
        >
          <Part color={p.color} width={toPercent(p.value, total) * 100} />
        </Tooltip>
      ))}
    </Container>
  );
};
