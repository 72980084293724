import React from 'react';
import { Tag as IconTag } from 'react-feather';
import { ITagCategory } from '../../../domainTypes/tag';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip
} from '../../MultiSelector';
import { useTagPrototypesForCategory } from '../service';
import { Tag } from '../Tag';

type Props = {
  value: Set<string> | null;
  onChange: (nextValue: Set<string> | null) => void;
  spaceId: string;
  category: ITagCategory;
  short: boolean;
};
const LABEL = 'Filter by tag';

export const TagPrototypeSelector = ({
  value,
  onChange,
  spaceId,
  category,
  short
}: Props) => {
  const [tags = []] = useTagPrototypesForCategory(spaceId, category);
  return (
    <MultiSelector
      value={value || new Set(tags.map(t => t.id))}
      onChange={nextValue =>
        onChange(nextValue.size === tags.length ? null : nextValue)
      }
      legend={LABEL}
      options={tags.map(p => ({
        label: <Tag t={p.data} size="small" />,
        value: p.id
      }))}
      allOption={<strong>All tags</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={!!value && !!tags.length}
        onDelete={() => onChange(null)}
        label={short ? <IconTag size={18} /> : LABEL}
        appliedLabel={getAppliedLabel(
          'tags',
          !!value ? tags.filter(t => value.has(t.id)).map(t => t.data.name) : []
        )}
      />
    </MultiSelector>
  );
};
