import { capitalize, keyBy } from 'lodash';
import React from 'react';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../components/AdditionalActionsMenu';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterSelect,
  ChartFooterBarItem
} from '../../../../../components/Charts/ChartCard';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { IColumn } from '../../../../../components/Table/Column';
import { TagPrototypeEditDialog } from '../../../../../components/Tags/PrototypeEditDialog';
import { useTagPrototypesForCategory } from '../../../../../components/Tags/service';
import { TagOption } from '../../../../../components/Tags/TagOption';
import { SPACE_ID } from '../../../../../domainTypes/potentialUser';
import { ITagPrototype } from '../../../../../domainTypes/tag';
import { SortDirection } from '../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Section } from '../../../../../layout/Section';
import {
  combineLoadingValues,
  useMappedLoadingValue
} from '../../../../../services/db';
import { useCurrentAdminUser } from '../../../../services/auth';
import {
  TagDistributionChart,
  YField,
  Y_FIELDS
} from '../../components/TagDistributionChart';
import { getScanResult, usePotentialUsers } from '../../service';

type Stat = {
  tag: ITagPrototype;
  counts: {
    pages: number;
    links: number;
    products: number;
    cloaked: number;
    blogs: number;
  };
};

type ColumnName =
  | 'tag'
  | 'blogs'
  | 'pages'
  | 'products'
  | 'links'
  | 'cloaked'
  | 'actions';

type Column = IColumn<Stat, ColumnName>;

const Actions = ({ d }: { d: Stat }) => {
  const { userId } = useCurrentAdminUser();
  const options: AdditionActionsMenuOption[] = [
    {
      key: 'editTag',
      label: 'Edit tag',
      // this is gonna create a double dialog...
      // we should remove the wrapping dialog?
      // on the other hand it doesn't look like it's a problem really...
      dialog: ({ onClose }) => (
        <TagPrototypeEditDialog
          tag={d.tag}
          open={true}
          onClose={onClose}
          onDone={onClose}
          userId={userId}
        />
      )
    }
  ];

  return <AdditionalActionsMenu options={options} />;
};

const COLUMNS: Column[] = [
  {
    key: 'tag',
    head: () => 'Tag',
    cell: (d) => <TagOption p={d.tag} />,
    sortable: true,
    align: 'left',
    width: 200,
    flexGrow: 1
  },
  {
    key: 'blogs',
    head: () => 'Blogs',
    cell: (d) => d.counts.blogs,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'pages',
    head: () => 'Pages',
    cell: (d) => d.counts.pages,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'products',
    head: () => 'Products',
    cell: (d) => d.counts.products,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'cloaked',
    head: () => 'Cloaked',
    cell: (d) => d.counts.cloaked,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'links',
    head: () => 'Links',
    cell: (d) => d.counts.links,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Stat> = {
  partner: {
    key: 'tag',
    items: { sort: (d) => d.tag.name, dir: 'asc' }
  },
  blogs: {
    key: 'blogs',
    items: {
      sort: (d) => d.counts.blogs,
      dir: 'desc'
    }
  },
  pages: {
    key: 'pages',
    items: {
      sort: (d) => d.counts.pages,
      dir: 'desc'
    }
  },
  products: {
    key: 'products',
    items: {
      sort: (d) => d.counts.products,
      dir: 'desc'
    }
  },
  links: {
    key: 'links',
    items: {
      sort: (d) => d.counts.links,
      dir: 'desc'
    }
  },
  cloaked: {
    key: 'cloaked',
    items: {
      sort: (d) => d.counts.cloaked,
      dir: 'desc'
    }
  }
};

const useTagStats = () => {
  return useMappedLoadingValue(
    combineLoadingValues(
      usePotentialUsers(),
      useTagPrototypesForCategory(SPACE_ID, 'BLOG_TOPIC')
    ),
    ([ps, ts]) => {
      const tagsById = keyBy(ts, (t) => t.id);
      const stats = ps.reduce<{ [tagId: string]: Stat }>((m, p) => {
        const counts = getScanResult(p, (r) => r.counts);
        p.data.tagIds.forEach((tId) => {
          if (!counts) {
            return;
          }
          const tag = tagsById[tId];
          if (!tag) {
            return;
          }
          const stat: Stat = (m[tId] = m[tId] || {
            tag: tag.data,
            counts: { pages: 0, links: 0, products: 0, cloaked: 0, blogs: 0 }
          });
          stat.counts.blogs += 1;
          stat.counts.pages += counts.pages;
          stat.counts.products += counts.products;
          stat.counts.links += counts.links;
          stat.counts.cloaked += counts.cloaked || 0;
        });
        return m;
      }, {});
      return Object.values(stats);
    }
  );
};

const TagsSection = ({ ds }: { ds: Stat[] }) => {
  const [[s, dir], setSort] = useSortQueryParam('y', SORTERS);
  const sorter = s || SORTERS.links;
  const y: YField = Y_FIELDS.includes(sorter.key as YField)
    ? (sorter.key as YField)
    : 'links';

  const xs = ds;

  return (
    <Section>
      <CanvasBar>Tags</CanvasBar>
      <ChartCard noMaximize>
        <TagDistributionChart
          ds={xs}
          y={y}
          dir={Y_FIELDS.includes(sorter.key as YField) ? dir : 'desc'}
        />

        <ChartCardFooter>
          <ChartCardFooterBar>
            <ChartFooterBarItem>
              <ChartCardFooterSelect
                value={y}
                onChange={(nextY) => {
                  const nextSorter = SORTERS[nextY] || SORTERS.links;
                  setSort([nextSorter, nextSorter.items.dir]);
                }}
                options={Y_FIELDS.map((field) => ({
                  label: capitalize(field),
                  value: field
                }))}
              />
            </ChartFooterBarItem>
            <ChartFooterBarItem>
              <ChartCardFooterSelect
                value={
                  Y_FIELDS.includes(sorter.key as YField)
                    ? dir || sorter.items.dir
                    : 'desc'
                }
                onChange={(nextDir) => {
                  setSort([sorter, nextDir]);
                }}
                options={(['asc', 'desc'] as SortDirection[]).map((x) => ({
                  label: capitalize(x),
                  value: x
                }))}
              />
            </ChartFooterBarItem>
          </ChartCardFooterBar>
        </ChartCardFooter>
      </ChartCard>
      <RowsRenderer
        variant="contained"
        columns={COLUMNS}
        rows={xs}
        rowToKey={(d) => d.tag.id}
        sorter={sorter}
        sortDirection={dir}
        renderHead={true}
        onHeadClick={(c, d) => setSort([SORTERS[c.key] || SORTERS.links, d])}
        chunkSize={100}
        rootMargin="400px"
        rowHeight={ROW_HEIGHTS.dense}
        otherProps={undefined}
      />
    </Section>
  );
};

export const PageAcquisitionTagOverview = () => {
  const [stats, loading] = useTagStats();

  if (loading || !stats) {
    return <Loader height={500} />;
  }

  return <TagsSection ds={stats} />;
};
