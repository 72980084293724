import { Card, CardContent, IconButton, Switch } from '@material-ui/core';
import { SortDirection } from '@material-ui/core/TableCell';
import { orderBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Arrow } from '../../../../components/Arrow';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { Loader } from '../../../../components/Loader';
import { SearchInput } from '../../../../components/SearchInput';
import { TableToolbar } from '../../../../components/Table';
import { IColumn } from '../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../components/Table/VirtualizedSortable';
import { ISpace } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { Page } from '../../../../layout/Page';
import { Section } from '../../../../layout/Section';
import { getActiveDomainUrls } from '../../../../services/space';
import {
  getDashboardData,
  IFeatureDashboardListItem,
  setFeature,
  setFeatureForSpaces
} from '../../../services/features';
import { matchSpace, useSpaces } from '../../../services/space';

type Props = {};

type SortKey = 'id' | 'domain' | 'enabled';

const Actions = styled('div')`
  > * {
    margin-left: ${(p) => p.theme.spacing()}px;
  }
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled('h2')`
  min-width: 300px;
`;

type DetailsRow = {
  space: ISpace;
  enabled: boolean;
  toggle: () => Promise<any>;
};

const COLUMNS: IColumn<DetailsRow, SortKey>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <div>{d.space.id}</div>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'domain',
    head: () => 'Domains',
    cell: (d) => getActiveDomainUrls(d.space).join(', '),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'enabled',
    head: () => 'Enabled',
    cell: (d) => (
      <Switch checked={d.enabled} color="default" onChange={() => d.toggle()} />
    ),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  }
];

const sortFn = (
  ds: DetailsRow[],
  sortBy: SortKey,
  direction: SortDirection
): DetailsRow[] => {
  return orderBy(
    ds,
    (d) => {
      if (sortBy === 'enabled') {
        return d.enabled;
      }
      if (sortBy === 'domain') {
        return getActiveDomainUrls(d.space)[0];
      }
      return d.space.id;
    },
    direction
  );
};

const DashboardItemDetails: React.FC<{
  data: IFeatureDashboardListItem;
  changeOne: (space: ISpace, enabled: boolean) => Promise<any>;
}> = ({ data, changeOne }) => {
  const spacesWithState = useMemo(
    () => [
      ...data.spaces.enabled.map((space) => ({
        space,
        enabled: true,
        toggle: () => changeOne(space, false)
      })),
      ...data.spaces.disabled.map((space) => ({
        space,
        enabled: false,
        toggle: () => changeOne(space, true)
      }))
    ],
    [data, changeOne]
  );
  const [term, setTerm] = useState('');
  const filteredSpaces = useMemo(
    () =>
      term
        ? spacesWithState.filter((s) => matchSpace(s.space, term))
        : spacesWithState,
    [spacesWithState, term]
  );
  return (
    <>
      <TableToolbar>
        <SearchInput value={term} onChange={setTerm} autoFocus={true} />
      </TableToolbar>
      <VirtualizedSortableTable
        rows={filteredSpaces}
        columns={COLUMNS}
        cellProps={undefined}
        height={500}
        margin="normal"
        sortFn={sortFn}
        initialSortColumn={COLUMNS[2]}
        rowClassNameFn={(doc) => {
          if (!doc) {
            return {};
          }
          return { backgroundColor: doc.enabled ? '#eeffe2' : '#ffd9d9' };
        }}
      />
    </>
  );
};

const DashboardItem: React.FC<{
  data: IFeatureDashboardListItem;
  changeAll: (enabled: boolean) => Promise<any>;
  changeOne: (space: ISpace, enabled: boolean) => Promise<any>;
}> = ({ data, changeAll, changeOne }) => {
  const { feature, counts } = data;
  const [expanded, setExpanded] = useState(false);
  return (
    <Section>
      <Card>
        <CardContent>
          <Header>
            <Heading>{feature.displayName}</Heading>
            <div>
              {feature.category} - {counts.enabled}/{counts.total} - default:{' '}
              {feature.defaultValue ? 'true' : 'false'}
            </div>
            <Actions>
              <ButtonWithPromise
                variant="contained"
                color="secondary"
                onClick={() => changeAll(false)}
                pending="Disabling..."
              >
                Disable for everyone
              </ButtonWithPromise>
              <ButtonWithPromise
                variant="contained"
                color="primary"
                onClick={() => changeAll(true)}
                pending="Enabling..."
              >
                Enable for everyone
              </ButtonWithPromise>
              <IconButton onClick={() => setExpanded((e) => !e)}>
                <Arrow dir={expanded ? 'UP' : 'DOWN'} />
              </IconButton>
            </Actions>
          </Header>
          {feature.description && <p>{feature.description}</p>}
          {expanded && (
            <DashboardItemDetails data={data} changeOne={changeOne} />
          )}
        </CardContent>
      </Card>
    </Section>
  );
};

const Dashboard: React.FC<{
  spaces: ISpace[];
}> = ({ spaces }) => {
  const data = getDashboardData(spaces);
  return (
    <>
      {data.map((d) => (
        <DashboardItem
          key={d.name}
          data={d}
          changeAll={(enabled) => setFeatureForSpaces(spaces, d.name, enabled)}
          changeOne={(space, enabled) => setFeature(space, d.name, enabled)}
        />
      ))}
    </>
  );
};

export const PageFeatureFlags: React.FC<Props> = () => {
  const [spaces, loading] = useSpaces();
  return (
    <Page>
      {loading && <Loader height={500} />}
      {!loading && spaces && <Dashboard spaces={spaces.map((s) => s.data)} />}
    </Page>
  );
};
