import { Card } from '@material-ui/core';
import { compact } from 'lodash';
import React, { useCallback } from 'react';
import {
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import { CustomTooltip } from '../../../../../components/Charts/CustomTooltip';
import { Loader } from '../../../../../components/Loader';
import { IDENTITY } from '../../../../../domainTypes/emptyConstants';
import { styled } from '../../../../../emotion';
import { Section } from '../../../../../layout/Section';
import { useColorsSpectrumPrimary } from '../../../../../services/color';
import { useMappedLoadingValue } from '../../../../../services/db';
import {
  getAverage,
  getMedian,
  getPercentile
} from '../../../../../services/math';
import { msToMinsAndSecs } from '../../../../../services/time';
import { useTheme } from '../../../../../themes';
import { usePotentialUsers } from '../../service';
import {
  IPotentialUserStatus,
  IPotentialUser
} from '../../../../../domainTypes/potentialUser';
import { Doc } from '../../../../../domainTypes/document';
import {
  useStatusQueryParam,
  StatusSelector
} from '../../components/StatusSelector';
import { CanvasBar } from '../../../../../layout/Canvas';

type ScanStat = {
  url: string;
  duration: number;
  pages: number;
  timePerPage: number;
  links: number;
  status: IPotentialUserStatus | null;
};

const useScanStats = (statuses: Set<IPotentialUserStatus>) => {
  const mapper = useCallback(
    (ps: Doc<IPotentialUser>[]) =>
      compact(
        ps.map((p) => {
          const { scan, status } = p.data;
          if (!scan || !scan.result || !statuses.has(status)) {
            return null;
          }
          const stat: ScanStat = {
            url: p.data.url,
            duration: scan.result.duration,
            pages: scan.result.counts.pages,
            timePerPage: scan.result.duration / scan.result.counts.pages,
            links: scan.result.counts.links,
            status: p.data.status
          };
          return stat;
        })
      ),
    [statuses]
  );
  return useMappedLoadingValue(usePotentialUsers(), mapper, true);
};

const formatter = (v: number, n: string) => {
  if (n === 'Duration') {
    return [msToMinsAndSecs(v), n];
  }
  return [v, n];
};

const StatGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${(p) => p.theme.spacing(12)}px;
  align-items: center;
`;

const StatRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatHeading = styled('div')`
  font-weight: bolder;
  font-size: ${(p) => p.theme.custom.fontSize.l}px;
  margin-bottom: ${(p) => p.theme.spacing()}px;
`;

const Stat = ({
  label,
  ds,
  toValue,
  format = IDENTITY
}: {
  label: string;
  ds: ScanStat[];
  toValue: (d: ScanStat) => number;
  format?: (x: number) => React.ReactNode;
}) => {
  const xs = ds.map(toValue);
  const rows: { l: string; v: number }[] = [
    {
      l: 'Min',
      v: Math.min(...xs)
    },
    {
      l: 'Med',
      v: getMedian(xs)
    },
    {
      l: 'Avg',
      v: getAverage(xs)
    },
    {
      l: 'Max',
      v: Math.max(...xs)
    }
  ];
  return (
    <Card>
      <StatHeading>{label}</StatHeading>
      {rows.map((r, i) => (
        <StatRow key={i}>
          <div>{r.l}</div>
          <div>{format(r.v)}</div>
        </StatRow>
      ))}
    </Card>
  );
};

export const PageAcquisitionScanStats = () => {
  const [statuses, setStatuses] = useStatusQueryParam('status');
  const [stats, loading] = useScanStats(statuses);
  const [primaryColor] = useColorsSpectrumPrimary();
  const t = useTheme();
  if (loading || !stats) {
    return <Loader height={500} />;
  }

  const timesPerPage = stats.map((s) => s.timePerPage);
  const percentiles = [0.25, 0.75].map((x) => getPercentile(x, timesPerPage));

  const getColor = (s: ScanStat) => {
    const c = t.custom.colors;
    const isPaying = s.status === 'PAYING';
    if (s.timePerPage < percentiles[0]) {
      return isPaying ? c.success.light : c.success.dark;
    }
    if (s.timePerPage < percentiles[1]) {
      return isPaying ? c.pending.light : c.pending.dark;
    }
    return isPaying ? c.error.light : c.error.dark;
  };
  return (
    <>
      <Section>
        <CanvasBar>
          <div />
          <StatusSelector value={statuses} onChange={setStatuses} />
        </CanvasBar>
        <StatGrid>
          <Stat
            label="Scan Time"
            ds={stats}
            toValue={(s) => s.duration}
            format={msToMinsAndSecs}
          />
          <Stat
            label="Time per page"
            ds={stats}
            toValue={(s) => s.timePerPage}
            format={(x) => `${Math.round(x)}ms`}
          />
          <Stat
            label="Pages"
            ds={stats}
            toValue={(s) => s.pages}
            format={Math.round}
          />
          <Stat
            label="Links"
            ds={stats}
            toValue={(s) => s.links}
            format={Math.round}
          />
        </StatGrid>
      </Section>
      <Section>
        <Card>
          <ResponsiveContainer width="98%" aspect={3}>
            <ScatterChart data={stats}>
              <CartesianGrid />
              <XAxis
                type="number"
                dataKey="duration"
                name="Duration"
                label={{ value: 'Duration', position: 'insideBottom', dy: 8 }}
                tickFormatter={(v: number) => msToMinsAndSecs(v)}
              />
              <YAxis
                type="number"
                dataKey="pages"
                name="Pages"
                scale="sqrt"
                label={{ value: 'Pages', angle: -90, position: 'insideLeft' }}
              />
              <ZAxis
                type="number"
                dataKey="links"
                name="Links"
                range={[10, 350]}
              />
              <Scatter data={stats} fill={primaryColor}>
                {stats.map((s) => (
                  <Cell key={s.url} fill={getColor(s)} />
                ))}
              </Scatter>
              <Tooltip
                separator=": "
                content={(p: any) => (
                  <CustomTooltip
                    {...p}
                    customLabel={(d: ScanStat | null) => d && d.url}
                  />
                )}
                formatter={(v, n) => formatter(v as number, n)}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </Card>
        <div style={{ height: 300 }} />
      </Section>
    </>
  );
};
