import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { every, flatten, fromPairs, groupBy, partition } from 'lodash';
import React from 'react';
import { AlertBox } from '../../../../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../../../../components/ButtonWithPromise';
import { SelectionBox } from '../../../../../../../components/SelectionBox';
import { Doc } from '../../../../../../../domainTypes/document';
import { IProduct } from '../../../../../../../domainTypes/product';
import { ISpace } from '../../../../../../../domainTypes/space';
import { styled } from '../../../../../../../emotion';
import { useDialogState } from '../../../../../../../hooks/useDialogState';
import { useDictionary } from '../../../../../../../hooks/useDictionary';
import { Section } from '../../../../../../../layout/Section';
import { pluralize } from '../../../../../../../services/pluralize';
import {
  deleteProductsOld,
  useProductsBySpaceId
} from '../../../../../../../services/products';
import { ButtonContainer } from '../../../../../../components/ButtonContainer';
import { LinkExternal } from '../../../../../../components/LinkExternal';
import { useProductMapBySpaceId } from '../../../../../../services/productMap';
import { ROUTES } from '../../../../../../services/routes';

type Props = {
  space: Doc<ISpace>;
};

const AlertboxInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type DuplicateProduct = {
  tracked: Doc<IProduct> | null;
  untracked: Doc<IProduct>[];
};

const ProductLink = ({ p }: { p: Doc<IProduct> }) => (
  <LinkExternal href={ROUTES.products.details.url(p.id)}>
    {p.data.name}
  </LinkExternal>
);

const getAllTrue = (dict: { [key: string]: boolean }): string[] => {
  return Object.entries(dict).reduce<string[]>((m, [k, v]) => {
    if (v) {
      m.push(k);
    }
    return m;
  }, []);
};

const DuplicateProductsCleanupDialog = ({
  open,
  onClose,
  duplicates,
  spaceId
}: {
  open: boolean;
  onClose: () => void;
  duplicates: DuplicateProduct[];
  spaceId: string;
}) => {
  const { dictionary: selected, merge: mergeSelected } = useDictionary<
    boolean
  >();

  const setSelected = (pIds: string[], nextState: boolean) =>
    mergeSelected(
      fromPairs(
        pIds.map<[string, boolean]>((pId) => [pId, nextState])
      )
    );

  const selectedIds = getAllTrue(selected);
  const allUntrackedDeletables = flatten(
    duplicates.map((d) => (d.tracked ? d.untracked : []))
  );
  const allUntrackedDeltableIds = allUntrackedDeletables.map((p) => p.id);
  const allSelected = every(allUntrackedDeltableIds, (id) => !!selected[id]);

  const onDelete = () =>
    deleteProductsOld(
      spaceId,
      allUntrackedDeletables.filter((p) => !!selected[p.id])
    );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>Cleanup duplicate products</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <SelectionBox
                  value={allSelected}
                  indeterminate={!allSelected && !!selectedIds.length}
                  onChange={() =>
                    setSelected(allUntrackedDeltableIds, !allSelected)
                  }
                />
              </TableCell>
              <TableCell>Tracked</TableCell>
              <TableCell>Untracked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {duplicates.map((d) => {
              const untrackedIds = d.untracked.map((u) => u.id);
              const pSelected = every(untrackedIds, (id) => !!selected[id]);
              return (
                <TableRow key={untrackedIds.join('-')}>
                  <TableCell padding="checkbox">
                    <SelectionBox
                      value={pSelected}
                      onChange={() => setSelected(untrackedIds, !pSelected)}
                    />
                  </TableCell>
                  <TableCell>
                    {d.tracked ? (
                      <ProductLink p={d.tracked} />
                    ) : (
                      <strong>UNTRACKED</strong>
                    )}
                  </TableCell>
                  <TableCell>
                    <ul>
                      {d.untracked.map((p) => (
                        <li key={p.id}>
                          <ProductLink p={p} />
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <ButtonContainer>
          <ButtonWithPromise
            variant="contained"
            color="secondary"
            disabled={!selectedIds.length}
            onClick={onDelete}
            pending="Deleting..."
          >
            Delete {pluralize('untracked products', selectedIds.length, true)}
          </ButtonWithPromise>
        </ButtonContainer>
      </DialogActions>
    </Dialog>
  );
};

const DuplicateProducts: React.FC<{ spaceId: string }> = ({ spaceId }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);

  const [products] = useProductsBySpaceId(spaceId);
  const [productMap] = useProductMapBySpaceId(spaceId);

  if (!products || !productMap) {
    return null;
  }
  const grouped = groupBy(products, (p) => p.data.url);
  const duplicateList = Object.values(grouped).filter((ds) => ds.length > 1);
  if (!duplicateList.length) {
    return null;
  }

  const duplicates = duplicateList.map<DuplicateProduct>((docs) => {
    const p = productMap.data[docs[0].data.url];
    const [tracked, untracked] = partition(docs, (d) => p && d.id === p.pId);
    return {
      tracked: tracked[0] || null,
      untracked
    };
  });

  console.log('Duplicate products');
  console.log(
    'Check out window.duplicateProducts for more details',
    duplicateList
  );
  (window as any).duplicateProducts = duplicates;

  return (
    <>
      <AlertBox variant="error">
        <AlertboxInner>
          <div>Duplicate products found!</div>
          <Button onClick={openDialog}>Fix...</Button>
        </AlertboxInner>
      </AlertBox>
      <DuplicateProductsCleanupDialog
        spaceId={spaceId}
        open={dialogOpen}
        onClose={closeDialog}
        duplicates={duplicates}
      />
    </>
  );
};

export const Warnings: React.FC<Props> = ({ space }) => {
  return (
    <Section>
      <DuplicateProducts spaceId={space.id} />
    </Section>
  );
};
