import { Button, Card, Dialog, DialogContent } from '@material-ui/core';
import { groupBy, maxBy, orderBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { ButtonWithPromise } from '../../../../../../../components/ButtonWithPromise';
import { Loader } from '../../../../../../../components/Loader';
import { IColumn } from '../../../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../../../domainTypes/document';
import {
  IPageMetadata,
  IPageScreenshot
} from '../../../../../../../domainTypes/page';
import { SortDirection } from '../../../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Centered } from '../../../../../../../layout/Centered';
import { Section } from '../../../../../../../layout/Section';
import { getPathName } from '../../../../../../../services/pages';
import { formatDatePrecise } from '../../../../../../../services/time';
import { AffilimateTheme } from '../../../../../../../themes';
import { Json } from '../../../../../../components/Json';
import { usePageMetadataInSpace } from '../../../../../../services/page';
import { requeueItems, useQueue } from '../../../../../../services/pageQueue';
import { useScreenshotsInSpace } from '../../../../../../services/screenshot';

type Props = {
  spaceId: string;
};

const HEIGHT = 400;

type PageWithScreenshots = {
  page: Doc<IPageMetadata>;
  screenshots: Doc<IPageScreenshot>[];
};

const usePagesWithScreenshots = (spaceId: string) => {
  const [pages, loadingPages, errorPages] = usePageMetadataInSpace(spaceId);
  const [
    screenshots,
    loadingScreenshots,
    errorScreenshots
  ] = useScreenshotsInSpace(spaceId);

  const pagesWithScreenshots = useMemo<void | PageWithScreenshots[]>(() => {
    if (!pages || !screenshots) {
      return undefined;
    }

    const byUrl = groupBy(screenshots, (s) => s.data.url);
    return pages.map((p) => ({
      page: p,
      screenshots: byUrl[p.data.url] || []
    }));
  }, [pages, screenshots]);

  return [
    pagesWithScreenshots,
    loadingPages || loadingScreenshots,
    errorPages || errorScreenshots
  ] as [void | PageWithScreenshots[], boolean, void | Error];
};

type SortKey =
  | 'url'
  | 'revisions'
  | 'lastRevision'
  | 'screenshots'
  | 'lastScreenshot';

const getLastScreenshot = (d: PageWithScreenshots) => {
  const lastScreenshot = maxBy(
    d.screenshots.filter((s) => s.data.status === 'DONE'),
    (s) => s.data.finishedAt && s.data.finishedAt.toMillis()
  );
  return lastScreenshot || null;
};

const getLastRevision = (d: PageWithScreenshots) => {
  const { revisions: revs } = d.page.data;
  return revs.length ? revs[revs.length - 1] : null;
};

const COLUMNS: IColumn<PageWithScreenshots, SortKey>[] = [
  {
    key: 'url',
    head: () => 'URL',
    cell: (d) => <div>{getPathName(d.page.data.url)}</div>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 120,
    flexGrow: 2
  },
  {
    key: 'revisions',
    head: () => 'Revisions',
    cell: (d) => d.page.data.revisions.length,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'lastRevision',
    head: () => 'Last Revision',
    cell: (d) => {
      const lastRev = getLastRevision(d);
      return lastRev ? formatDatePrecise(lastRev.lastModifiedAt) : null;
    },
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'screenshots',
    head: () => 'Screenshots',
    cell: (d) => d.screenshots.length,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'lastScreenshot',
    head: () => 'Last Screenshot',
    cell: (d) => {
      const lastSs = getLastScreenshot(d);
      return lastSs && lastSs.data.finishedAt
        ? formatDatePrecise(lastSs.data.finishedAt)
        : null;
    },
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  }
];

const sortFn = (
  ds: PageWithScreenshots[],
  sortBy: SortKey,
  direction: SortDirection
) => {
  return orderBy(
    ds,
    (d) => {
      if (sortBy === 'revisions') {
        return d.page.data.revisions.length;
      }
      if (sortBy === 'screenshots') {
        return d.screenshots.length;
      }
      if (sortBy === 'lastRevision') {
        const lastRev = getLastRevision(d);
        return lastRev ? lastRev.lastModifiedAt.toMillis() : 0;
      }
      if (sortBy === 'lastScreenshot') {
        const lastSs = getLastScreenshot(d);
        return lastSs && lastSs.data.finishedAt
          ? lastSs.data.finishedAt.toMillis()
          : 0;
      }
      return d.page.data.url;
    },
    direction
  );
};

const hasScreenshotForLatestRevision = (d: PageWithScreenshots) => {
  const lastRevision = d.page.data.revisions[d.page.data.revisions.length - 1];
  if (!lastRevision) {
    return false;
  }
  return !!d.screenshots.find(
    (s) =>
      s.data.pageModifiedAt &&
      s.data.status === 'DONE' &&
      s.data.pageModifiedAt.isEqual(lastRevision.lastModifiedAt)
  );
};

const getRowColor = (
  d: PageWithScreenshots | null,
  t: AffilimateTheme
): string | null => {
  if (!d) {
    return null;
  }
  const lastRevision = d.page.data.revisions[d.page.data.revisions.length - 1];
  if (!lastRevision) {
    return '#fff6ce';
  }
  return hasScreenshotForLatestRevision(d) ? '#eeffe2' : '#ffd9d9';
};

const PagesInner = ({ spaceId }: { spaceId: string }) => {
  const [pagesWithScreenshots, loading] = usePagesWithScreenshots(spaceId);
  const [queueItems] = useQueue(spaceId);
  const [selected, setSelected] = useState<PageWithScreenshots | null>(null);

  const pagesWithoutScreenshots = pagesWithScreenshots
    ? pagesWithScreenshots.filter((d) => !hasScreenshotForLatestRevision(d))
    : [];
  const getQueueItems = (pages: PageWithScreenshots[]) => {
    if (!queueItems) {
      return Promise.resolve();
    }
    const missingUrls = pages.map((p) => p.page.data.url);
    const itemsToRequeue = queueItems.filter((q) =>
      missingUrls.includes(q.data.url)
    );

    return requeueItems(itemsToRequeue);
  };

  return (
    <Section>
      <CanvasBar>
        <div>
          {pagesWithScreenshots
            ? `Pages (${pagesWithScreenshots.length})`
            : 'Pages'}
        </div>

        <div>
          <span>
            <ButtonWithPromise
              variant="outlined"
              color="primary"
              pending="Requeuing..."
              size="small"
              onClick={() => {
                return getQueueItems(pagesWithoutScreenshots);
              }}
            >
              Requeue missing
            </ButtonWithPromise>{' '}
          </span>
          <span>
            {pagesWithScreenshots
              ? `Missing Screenshots: ${pagesWithoutScreenshots.length}`
              : null}
          </span>
        </div>
      </CanvasBar>
      <Card>
        {loading && <Loader height={HEIGHT} />}
        {!loading && pagesWithScreenshots && (
          <VirtualizedSortableTable
            rows={pagesWithScreenshots}
            columns={COLUMNS}
            cellProps={undefined}
            height={HEIGHT}
            margin="normal"
            sortFn={sortFn}
            initialSortColumn={COLUMNS[0]}
            rowClassNameFn={(d, t) => {
              const backgroundColor = getRowColor(d, t);
              return backgroundColor ? { backgroundColor } : {};
            }}
            onRowClick={setSelected}
          />
        )}
      </Card>
      <Dialog open={!!selected} onClose={() => setSelected(null)}>
        <DialogContent>
          <Json
            data={selected}
            shouldCollapse={({ namespace }) => namespace.length > 5}
          />
        </DialogContent>
      </Dialog>
    </Section>
  );
};

export const Pages: React.FC<Props> = ({ spaceId }) => {
  const [reveal, setReveal] = useState(false);
  return (
    <Section>
      <CanvasBar>
        <div>Pages</div>
      </CanvasBar>
      <Card>
        {reveal ? (
          <PagesInner spaceId={spaceId} />
        ) : (
          <Centered>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setReveal(true)}
            >
              Load Data
            </Button>
          </Centered>
        )}
      </Card>
    </Section>
  );
};
