import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { css } from '../../emotion';
import { useDialogState } from '../../hooks/useDialogState';
import { ButtonWithPromise } from '../ButtonWithPromise';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<any>;
  keyword: string;
  dialogTitle?: React.ReactNode;
  prompt?: React.ReactNode;
  buttonAction?: React.ReactNode;
};

export const DeletionConfirmation: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  keyword,
  dialogTitle = 'Are you sure?',
  prompt = 'Confirm this deletion by typing:',
  buttonAction = 'Delete'
}) => {
  const [keywordInput, setKeywordInput] = useState('');
  const confirm = async () => {
    const result = await onConfirm();
    onClose();
    return result;
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle
        classes={{
          root: css((t) => ({
            background: t.custom.colors.error.main,
            color: t.custom.colors.error.contrastText
          }))
        }}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent
        className={css((t) => ({ paddingTop: `${t.spacing(2)}px !important` }))}
      >
        <Typography component="p">
          {prompt} <strong>{keyword}</strong>
        </Typography>
        <TextField
          fullWidth={true}
          value={keywordInput}
          onChange={(ev) => setKeywordInput(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="secondary"
          disabled={keywordInput !== keyword}
          pending="Deleting..."
          onClick={confirm}
        >
          {buttonAction}
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};
export const DeleteButton = ({
  keyword = 'DELETE',
  dialogTitle = 'Delete?',
  buttonAction = 'Delete',
  prompt,
  onDelete,
  ...props
}: Omit<ButtonProps, 'onClick'> & {
  keyword?: string;
  dialogTitle?: string;
  prompt?: string;
  buttonAction?: string;
  onDelete: () => Promise<any>;
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  return (
    <>
      <Button {...props} onClick={openDialog} />
      <DeletionConfirmation
        open={dialogOpen}
        onClose={closeDialog}
        onConfirm={onDelete}
        keyword={keyword}
        dialogTitle={dialogTitle}
        buttonAction={buttonAction}
        prompt={prompt}
      />
    </>
  );
};
