import { Card, CardContent, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { Loader } from '../../../../../components/Loader';
import { IChromeExtensionMetadata } from '../../../../../domainTypes/chromeExtension';
import { Doc } from '../../../../../domainTypes/document';
import { styled } from '../../../../../emotion';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useExtensionMetadata } from '../../../../../services/chromeExtension';
import { store } from '../../../../../services/db';
import { FS } from '../../../../../versions';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Body = ({ metadata }: { metadata: Doc<IChromeExtensionMetadata> }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [v, setV] = useState(metadata.data.requiredVersion);
  const updateVersion = () =>
    store()
      .collection(FS.chromeExtensionMetadata)
      .doc(metadata.id)
      .set({ ...metadata.data, requiredVersion: v })
      .catch(err => {
        console.log(err);
        enqueueSnackbar('Failed to update', { variant: 'error' });
      });
  return (
    <Container>
      <TextField
        label="Required Version"
        type="text"
        value={v}
        onChange={ev => setV(ev.target.value.trim())}
      />
      <ButtonWithPromise
        disabled={v === metadata.data.requiredVersion}
        pending="Updating..."
        variant="contained"
        color="primary"
        onClick={() => updateVersion()}
      >
        Update
      </ButtonWithPromise>
    </Container>
  );
};

export const PageChromeExtensionOps: React.FC = () => {
  const [metadata, loading] = useExtensionMetadata();
  return (
    <Page>
      <Section>
        <Card>
          <CardContent>
            {loading && <Loader height={100} />}
            {metadata && <Body metadata={metadata} />}
          </CardContent>
        </Card>
      </Section>
    </Page>
  );
};
