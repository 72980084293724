import * as ENVS from '../../../env.json';
export const getCloudStorageLink = (path: string, bucket?: string) => {
  const parts = [
    'https://console.cloud.google.com',
    'storage/browser/_details',
    bucket || ENVS.firebase.storageBucket,
    path
  ].join('/');
  const q = [['project', ENVS.firebase.projectId]]
    .map((x) => x.join('='))
    .join('&');
  return `${parts}?${q}`;
};

export const getCloudStorageDirLink = (dirPath: string, bucket?: string) => {
  const parts = [
    'https://console.cloud.google.com',
    'storage/browser',
    bucket || ENVS.firebase.storageBucket,
    dirPath
  ].join('/');
  const q = [['project', ENVS.firebase.projectId]]
    .map((x) => x.join('='))
    .join('&');
  return `${parts}?${q}`;
};
