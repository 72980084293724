import { Button, Card, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Loader } from '../../../../../../../components/Loader';
import { IColumn } from '../../../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../../../components/Table/VirtualizedSortable';
import { getBillingStatus } from '../../../../../../../domainTypes/billing';
import { Doc } from '../../../../../../../domainTypes/document';
import {
  getSpaceDomainNames,
  ISpace
} from '../../../../../../../domainTypes/space';
import { useDialogState } from '../../../../../../../hooks/useDialogState';
import { SortDirection } from '../../../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Centered } from '../../../../../../../layout/Centered';
import { Section } from '../../../../../../../layout/Section';
import { formatDatePrecise } from '../../../../../../../services/time';
import { SpaceLink } from '../../../../../../components/SpaceLink';
import { SpaceSelectorDialog } from '../../../../../../components/SpaceSelector';
import {
  createAffiliation,
  IReferral,
  useAffiliate,
  useReferrals
} from '../../../../../../services/affiliates';

type Props = {
  space: Doc<ISpace>;
};

const Affiliate = ({ space }: Props) => {
  const height = 70;
  const [affiliate, loading, err] = useAffiliate(space.id);
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const { enqueueSnackbar } = useSnackbar();
  if (loading) {
    return <Loader height={height} />;
  }
  if (err) {
    console.log(err);
    return <Centered height={height}>Something went wrong.</Centered>;
  }
  if (!affiliate) {
    return (
      <>
        <div style={{ margin: '24px auto', textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={openDialog}>
            Mark as referred account
          </Button>
          <br />
          <br />
          <Typography color="textSecondary" variant="body2" component="p">
            Choose an account to give credit for referring this user.
          </Typography>
        </div>
        <SpaceSelectorDialog
          open={dialogOpen}
          onClose={closeDialog}
          onSelect={(s) => {
            if (space.id === s.id) {
              enqueueSnackbar('Space cannot refer itself', {
                variant: 'error'
              });
              return;
            }
            createAffiliation(space.id, s.data.affiliateId).then(() =>
              enqueueSnackbar('Referral created', { variant: 'success' })
            );
          }}
        />
      </>
    );
  }
  return (
    <Centered height={height}>
      <div>
        Referred by <SpaceLink space={affiliate.space.data} />
      </div>
    </Centered>
  );
};

type SortKey = 'name' | 'plan' | 'status' | 'createdAt';

const COLUMNS: IColumn<IReferral, SortKey>[] = [
  {
    key: 'name',
    head: () => 'Space',
    cell: (d) => <SpaceLink space={d.space.data} />,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 300,
    flexGrow: 2
  },
  {
    key: 'plan',
    head: () => 'Plan',
    cell: (d) => d.space.data.billing.activePlan,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => getBillingStatus(d.space.data, Date.now()),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'createdAt',
    head: () => 'Referral created at',
    cell: (d) => formatDatePrecise(d.affiliation.data.createdAt),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  }
];

const sortFn = (rows: IReferral[], sortBy: SortKey, direction: SortDirection) =>
  orderBy(
    rows,
    (r) => {
      if (sortBy === 'name') {
        return getSpaceDomainNames(r.space.data);
      }

      if (sortBy === 'status') {
        const status = getBillingStatus(r.space.data, Date.now());
        if (
          status === 'PAYING' ||
          status === 'FREE' ||
          status === 'SUBSCRIPTION_GRACE'
        ) {
          return 0;
        }
        if (status === 'TRIAL_EXPIRED' || status === 'SUBSCRIPTION_EXPIRED') {
          return 2;
        }
        return 1;
      }
      if (sortBy === 'plan') {
        return r.space.data.billing.activePlan || 'AAA';
      }
      return r.affiliation.data.createdAt.toMillis();
    },
    direction
  );

const Referrals = ({ space }: Props) => {
  const height = 300;
  const [referrals, loading, err] = useReferrals(space.data.affiliateId);
  if (loading) {
    return <Loader height={300} />;
  }
  if (err) {
    console.log(err);
    return <Centered height={300}>Something went wrong.</Centered>;
  }
  if (!referrals) {
    return null;
  }

  return (
    <VirtualizedSortableTable
      rows={referrals}
      columns={COLUMNS}
      cellProps={undefined}
      height={height}
      margin="normal"
      sortFn={sortFn}
      initialSortColumn={COLUMNS[COLUMNS.length - 1]}
      initialSortDirection={'desc'}
    />
  );
};

export const Affiliates: React.FC<Props> = ({ space }) => {
  return (
    <div>
      <Section>
        <CanvasBar>Referred by</CanvasBar>
        <Card>
          <Affiliate space={space} />
        </Card>
      </Section>
      <Section>
        <CanvasBar>Referrals by this user</CanvasBar>
        <Card>
          <Referrals space={space} />
        </Card>
      </Section>
    </div>
  );
};
