import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  env: string;
  name: string;
};

export const ServiceName: React.FC<Props> = ({ env, name }) => {
  return (
    <Link to={`/deployments/services/${env}/${name}`}>
      <strong>{name}</strong>{' '}
    </Link>
  );
};
