import React from 'react';
import { ITagCategory, ITagPrototype } from '../../../domainTypes/tag';
import { now } from '../../../services/time';
import { Color } from '../../../domainTypes/colors';
import { TagPrototypeFormDialog } from '../PrototypeForm';
import { createTagPrototype } from '../service';

type Values = {
  name: string;
  description: string;
  color: Color;
  category: ITagCategory;
};

export const TagPrototypeCreateDialog = ({
  open,
  onClose,
  onCreate,
  initialTagName = '',
  spaceId,
  userId,
  category
}: {
  open: boolean;
  onClose: () => void;
  onCreate: (tag: ITagPrototype) => void;
  initialTagName?: string;
  spaceId: string;
  userId: string;
  category: ITagCategory;
}) => {
  const initialValues: Values = {
    name: initialTagName,
    description: '',
    color: 'red',
    category
  };

  const onSubmit = async (vs: Values) => {
    const tag = await createTagPrototype({
      spaceId,
      createdBy: userId,
      createdAt: now(),
      ...vs,
      parents: [],
      children: []
    });
    onCreate(tag.data);
  };

  return (
    <TagPrototypeFormDialog
      open={open}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      title="Create new tag"
      label="Create"
      submitComponent="Creating..."
    />
  );
};
