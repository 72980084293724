import { Card } from '@material-ui/core';
import React from 'react';
import { CanvasBar } from '../../../layout/Canvas';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { ReleaseNotificationsTable } from './components/ReleaseNotificatonsTable';
import { TestNotifications } from './components/TestNotifications';

export const PageNotifications = () => {
  return (
    <Page>
      <Section>
        <CanvasBar>Release Notifications</CanvasBar>
        <Card>
          <ReleaseNotificationsTable />
        </Card>
      </Section>
      <Section>
        <CanvasBar>Test Notifications</CanvasBar>
        <Card>
          <TestNotifications />
        </Card>
      </Section>
    </Page>
  );
};
