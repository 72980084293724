import { TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Form } from '../../../components/Form';
import { FormField } from '../../../components/Form/FormField';
import { FormSubmitButton } from '../../../components/Form/FormSubmitButton';
import { LinkExternal } from '../../../components/LinkExternal';
import { Centered } from '../../../layout/Centered';
import { FlexContainer, FlexItem } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { shortenLink } from '../../../services/linkShortener';

export const PageLinkShortener = () => {
  const [shortUrl, setShortUrl] = useState('');
  return (
    <Page width="L">
      <Form
        initialValues={{ url: '', spaceId: '4oF26UAIvKcsv6YOxO7gtvrifrn2' }}
        onSubmit={(values) =>
          shortenLink({
            spaceId: values.spaceId,
            url: values.url
          }).then((res) => {
            console.log('Shorten link response', res);
            return setShortUrl(res.shortUrl);
          })
        }
      >
        {({ submitting, values }) => (
          <FlexContainer fullWidth>
            <FlexItem flex={1}>
              <FormField<string> name="spaceId">
                {({ input }) => (
                  <TextField
                    variant="outlined"
                    label="Space ID"
                    {...input}
                    fullWidth
                    disabled
                  />
                )}
              </FormField>
            </FlexItem>
            <FlexItem flex={2}>
              <FormField<string> name="url">
                {({ input }) => (
                  <TextField
                    variant="outlined"
                    label="URL"
                    {...input}
                    fullWidth
                    autoFocus
                  />
                )}
              </FormField>
            </FlexItem>
            <FormSubmitButton
              submitComponent="Shortening..."
              submitting={submitting}
              variant="contained"
              disabled={!values.url || !values.url.startsWith('http')}
              color="primary"
            >
              Shorten
            </FormSubmitButton>
          </FlexContainer>
        )}
      </Form>

      {shortUrl && (
        <Centered height={200}>
          <Typography variant="h6">
            <LinkExternal href={shortUrl} color="primary" />
          </Typography>
        </Centered>
      )}
    </Page>
  );
};
