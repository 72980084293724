import React from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import {
  Notification,
  NotificationPrototype,
  ReleaseNotification,
  ScanNewResults,
  TestDummy
} from '../../../../../domainTypes/notifications';
import { styled } from '../../../../../emotion';
import { batchSet } from '../../../../../services/db';
import { now } from '../../../../../services/time';
import { FS } from '../../../../../versions';
import { getUserIdsInSpace, useTestSpace } from '../../../../services/space';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import shortid from 'shortid';

const TEST_DUMMY: NotificationPrototype<TestDummy> = {
  category: 'Test',
  type: 'Test_Dummy',
  data: { x: 1 }
};

const AVANTLINk: NotificationPrototype<ReleaseNotification> = {
  category: 'Release',
  type: 'Release_AvantLinkIntegration'
};

const PEPPERJAM: NotificationPrototype<ReleaseNotification> = {
  category: 'Release',
  type: 'Release_PepperjamIntegration'
};

const LABEL_RULES: NotificationPrototype<ReleaseNotification> = {
  category: 'Release',
  type: 'Release_LabelRules'
};

const SCAN_NEW_RESULTS: NotificationPrototype<ScanNewResults> = {
  category: 'Scan',
  type: 'Scan_NewResults',
  data: {
    scanId: '-LxNQyliJahHrxbtsUrm',
    counts: {
      products: 5,
      links: 11,
      pages: 2
    }
  }
};

const TEST_UID = 'TEST';

const PROTOTYPES: {
  uid: () => string;
  prototype: NotificationPrototype<any>;
}[] = [
  {
    uid: () => shortid(),
    prototype: TEST_DUMMY
  },
  {
    uid: () => shortid(),
    prototype: AVANTLINk
  },
  {
    uid: () => shortid(),
    prototype: PEPPERJAM
  },
  {
    uid: () => shortid(),
    prototype: LABEL_RULES
  },
  {
    uid: () => TEST_UID,
    prototype: SCAN_NEW_RESULTS
  }
];

const Container = styled('div')`
  display: flex;
  > * {
    margin-right: ${p => p.theme.spacing()}px;
    min-width: 128px;
  }
`;

const toNotificationId = (n: Notification) =>
  [n.category, n.type, n.userId, n.spaceId, n.uid].join('-');

export const createNotification = <T extends Notification>(
  spaceId: string,
  userId: string,
  prototype: NotificationPrototype<T>,
  uid: string
  // how to type this?
): T =>
  ({
    ...prototype,
    spaceId,
    userId,
    createdAt: now(),
    uid,
    seenAt: null
  } as T);

export const TestNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [space] = useTestSpace();
  if (!space) {
    return null;
  }
  return (
    <Container>
      {PROTOTYPES.map(({ prototype, uid }, i) => {
        return (
          <ButtonWithPromise
            key={i}
            pending={prototype.type}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const notifications = getUserIdsInSpace(space.data).map(userId =>
                createNotification(space.id, userId, prototype, uid())
              );
              const docs = notifications.map(data => ({
                id: toNotificationId(data),
                collection: FS.notifications,
                data
              }));
              return batchSet(FS.notifications, docs).then(
                () => {
                  enqueueSnackbar('Distribution successful!', {
                    variant: 'success'
                  });
                },
                () => {
                  enqueueSnackbar('Distribution failed!', { variant: 'error' });
                }
              );
            }}
          >
            {prototype.type}
          </ButtonWithPromise>
        );
      })}
    </Container>
  );
};
