import React from 'react';
import { Doc } from '../../domainTypes/document';
import { IProduct } from '../../domainTypes/product';
import { findProductsByName } from '../../services/products';
import { AutocompleteAsync } from '../AutocompleteAsync';

interface IProps {
  selected?: Doc<IProduct>;
  onSelect: (product: Doc<IProduct>) => void;
  findProducts?: (input: string) => Promise<Doc<IProduct>[]>;
  placeholder?: string;
}

export const AutocompleteProduct = ({
  selected,
  onSelect,
  findProducts = findProductsByName,
  placeholder = 'Search your products'
}: IProps) => {
  const findFn = (input: string) =>
    findProducts(input).then(docs =>
      docs.map(d => ({
        label: d.data.name,
        value: d
      }))
    );

  return (
    <AutocompleteAsync
      selected={selected}
      placeholder={placeholder}
      loadOptions={findFn}
      onSelect={o => onSelect(o.value)}
    />
  );
};
