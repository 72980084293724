import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { last, sortBy, times } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { AlertBox } from '../../../components/AlertBox';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { Chip } from '../../../components/Chip';
import { DeleteButton } from '../../../components/DeletionConfirmation';
import { DialogActionsHeader } from '../../../components/DialogActionsHeader';
import { DialogActionsWithSlots } from '../../../components/DialogActionsWithSlots';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../components/GroupableList';
import { Loader } from '../../../components/Loader';
import { SearchInput } from '../../../components/SearchInput';
import { IColumn } from '../../../components/Table/Column';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import { EtlProcessSales, ISOString } from '../../../domainTypes/etl';
import { styled } from '../../../emotion';
import { CanvasBar } from '../../../layout/Canvas';
import { FlexContainer } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { useStringQueryParam } from '../../../routes';
import { removeDoc, store } from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { ISOTimeRange } from '../../../services/time';
import { Json } from '../../components/Json';
import { LinkExternal } from '../../components/LinkExternal';
import { publishInstruction } from '../../services/pubsub';
import { getCloudStorageLink } from '../../services/storage';

const toEtlProcessDoc = generateToDocFn<EtlProcessSales>((d) => {
  d.skippedRows = d.skippedRows || 0;
  return d;
});
const collection = () => store().collection('etlProcessSalesV1');
const etlStore = createCollectionListenerStore(
  () => new CollectionListener(collection(), toEtlProcessDoc)
);
const useEtlProcesses = () => useCollectionListener(etlStore(''));

const EltStatusChip = ({ d }: { d: Doc<EtlProcessSales> }) => {
  return (
    <Chip
      variant={d.data.running ? 'outlined' : 'default'}
      label={d.data.step}
      type={
        d.data.step === 'DONE'
          ? 'SUCCESS'
          : d.data.step === 'LOAD'
          ? 'PENDING'
          : d.data.step === 'ERROR'
          ? 'ERROR'
          : 'NONE'
      }
    />
  );
};

const addToIsoDate = (t: ISOString, value: number, unit: 'h' | 'w' | 'd') => {
  return moment(t).utc().add(value, unit).toISOString();
};
const addToRange = (
  range: ISOTimeRange,
  value: number,
  unit: 'h' | 'w' | 'd'
) => {
  return {
    start: addToIsoDate(range.start, value, unit),
    end: addToIsoDate(range.end, value, unit)
  };
};

const StartNewProcess = ({
  value,
  onChange
}: {
  value: ISOTimeRange;
  onChange: (nextValue: ISOTimeRange) => void;
}) => {
  return (
    <FlexContainer direction="column" alignItems="flex-start" fullWidth>
      <FlexContainer fullWidth>
        <TextField
          fullWidth
          label="Start"
          value={value.start}
          onChange={(ev) => onChange({ ...value, start: ev.target.value })}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="End"
          value={value.end}
          onChange={(ev) => onChange({ ...value, end: ev.target.value })}
          variant="outlined"
        />
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending="..."
          onClick={async () => {
            await publishInstruction({
              topic: 'dataMigration-salesToClickhouse',
              payload: {
                range: value,
                labels: ['2nd'],
                insert: false
              }
            });
          }}
        >
          GO
        </ButtonWithPromise>
      </FlexContainer>
      <FlexContainer alignItems="flex-start">
        <Button
          variant="outlined"
          size="small"
          onClick={() => onChange(addToRange(value, -3, 'd'))}
        >
          Minus 3 days
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => onChange(addToRange(value, 3, 'd'))}
        >
          Plus 3 days
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => onChange(addToRange(value, -1, 'w'))}
        >
          Minus 1 week
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

const numberFormatter = new Intl.NumberFormat();

type D = Doc<EtlProcessSales>;
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => d.id,
    align: 'left',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'step',
    head: () => 'Step',
    cell: (d) => {
      return <EltStatusChip d={d} />;
    },
    align: 'left',
    width: 75
  },
  {
    key: 'labels',
    head: () => 'Labels',
    cell: (d) => (
      <Typography variant="caption">{d.data.labels.join(', ')}</Typography>
    ),
    align: 'left',
    width: 75
  },
  {
    key: 'rangeStart',
    head: () => 'Start',
    cell: (d) => moment(d.data.range.start).utc().format('YYYY-MM-DD'),
    align: 'left',
    width: 100,
    sortable: true
  },
  {
    key: 'rangeEnd',
    head: () => 'End',
    cell: (d) => moment(d.data.range.end).utc().format('YYYY-MM-DD'),
    align: 'left',
    width: 100
  },
  {
    key: 'rows',
    head: () => 'Rows',
    cell: (d) => numberFormatter.format(d.data.rows),
    align: 'left',
    width: 100,
    sortable: true
  },
  {
    key: 'skippedRows',
    head: () => 'Skipped Rows',
    cell: (d) => numberFormatter.format(d.data.skippedRows),
    align: 'left',
    width: 100,
    sortable: true
  }
];

const SORTERS: ItemSorters<D> = {
  rangeStart: {
    key: 'rangeStart',
    items: { sort: (d) => new Date(d.data.range.start).valueOf(), dir: 'asc' }
  },
  rangeEnd: {
    key: 'rangeEnd',
    items: { sort: (d) => new Date(d.data.range.end).valueOf(), dir: 'asc' }
  },
  rows: {
    key: 'rows',
    items: { sort: (d) => d.data.rows, dir: 'desc' }
  },
  skippedRows: {
    key: 'skippedRows',
    items: { sort: (d) => d.data.skippedRows, dir: 'desc' }
  }
};
const DEFAULT_SORTER = SORTERS.rangeStart;

const rowToKey = (d: D) => d.id;

const ProcessDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: Doc<EtlProcessSales>;
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogActionsHeader
        onClose={onClose}
        left={
          <>
            <EltStatusChip d={d} />
            <Typography variant="h6">
              {d.id} - {d.data.range.start} {'->'} {d.data.range.end}
            </Typography>
          </>
        }
      />
      <DialogContent>
        <FlexContainer fullWidth marginBottom={2}>
          <LinkExternal href={getCloudStorageLink(d.data.file)}>
            {d.data.file}
          </LinkExternal>
        </FlexContainer>
        <Json data={d.data} />
      </DialogContent>
      <DialogActionsWithSlots
        left={
          <DeleteButton
            variant="outlined"
            color="secondary"
            onDelete={async () => {
              await removeDoc(d);
            }}
          >
            Delete
          </DeleteButton>
        }
        right={
          <FlexContainer justifyContent="flex-end">
            <Button onClick={onClose}>Close</Button>
          </FlexContainer>
        }
      />
    </Dialog>
  );
};

const createWholeYearParts = (year: number) => {
  console.log(moment().month());

  const parts: ISOTimeRange[] = [];
  times(12, (i) => {
    const start = moment().utc().year(year).month(i).startOf('month');

    const end = start.clone().add(1, 'month');
    const ten = start.clone().add(10, 'd');
    const twenty = start.clone().add(20, 'd');
    parts.push(
      {
        start: start.toISOString(),
        end: ten.toISOString()
      },
      {
        start: ten.toISOString(),
        end: twenty.toISOString()
      },
      {
        start: twenty.toISOString(),
        end: end.toISOString()
      }
    );
  });

  return parts;
};

const WholeYearGrid = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridColumnGap: p.theme.spacing(1),
  gridRowGap: p.theme.spacing(1)
}));

const YEAR = 2022;
const LABEL = '2022';
const PARTS = createWholeYearParts(YEAR);
const ExtractWholeYear = ({ ds }: { ds: Doc<EtlProcessSales>[] }) => {
  const withCurrentLabel = ds.filter((d) => d.data.labels.includes(LABEL));
  const partsAndTheirStatus = PARTS.map((p) => {
    const presentPart = withCurrentLabel.find(
      (x) => x.data.range.start === p.start && x.data.range.end === p.end
    );
    return {
      ...p,
      data: presentPart
    };
  });

  return (
    <WholeYearGrid>
      {partsAndTheirStatus.map((p) => {
        return (
          <Button
            key={p.start}
            variant="contained"
            disabled={!!p.data}
            onClick={async () => {
              const range: ISOTimeRange = { start: p.start, end: p.end };
              await publishInstruction({
                topic: 'dataMigration-salesToClickhouse',
                payload: {
                  range,
                  labels: [LABEL],
                  insert: false
                }
              });
              console.log(`EXTRACT STARTED - ${range.start} -> ${range.end}`);
            }}
          >
            <FlexContainer>
              {p.data && <EltStatusChip d={p.data} />}
              <div>
                {moment(p.start).format('YYYY-MM-DD')} -{' '}
                {moment(p.end).format('YYYY-MM-DD')}
              </div>
            </FlexContainer>
          </Button>
        );
      })}
    </WholeYearGrid>
  );
};

// This is not useful anymore - but we might want to run a version
// of this code again in the future, so I am keeping it around for now
const SHOW_AUTO_EXTRACT = false;
const AutoRunExtract = ({ ds }: { ds: Doc<EtlProcessSales>[] }) => {
  const [state, setState] = useState<{
    active: boolean;
    label: string;
  }>({ active: false, label: '2nd' });

  const ref = useRef({
    extractScheduled: false
  });

  useEffect(() => {
    if (!state.active) {
      return;
    }
    const isExtracting = !!ds.find((d) => d.data.step === 'EXTRACT');
    if (!isExtracting && !ref.current.extractScheduled) {
      const start = last(
        sortBy(
          ds.filter((d) => moment.utc(d.data.range.start).month() < 10),
          (d) => d.data.range.end
        )
      )?.data.range.end;
      if (!start) {
        return;
      }
      console.log('SCHEDULE EXTRACT');
      const startM = moment.utc(start);
      const endM =
        startM.date() >= 20
          ? startM.clone().add(1, 'month').startOf('month')
          : startM.clone().add(10, 'd');
      const range = { start: startM.toISOString(), end: endM.toISOString() };

      // 0-indexed! so 10 is november
      if (startM.month() >= 10 && startM.year() >= 2023) {
        console.log('November 2023 reached - aborting');
        return;
      }
      setTimeout(() => {
        publishInstruction({
          topic: 'dataMigration-salesToClickhouse',
          payload: {
            range,
            labels: state.label ? [state.label] : [],
            insert: true
          }
        }).then(() =>
          console.log(`EXTRACT STARTED - ${range.start} -> ${range.end}`)
        );
      }, 3000); // wait a bit to give some breathing room
      ref.current.extractScheduled = true;
    }

    if (isExtracting) {
      ref.current.extractScheduled = false;
    }
  }, [ds, state]);

  return (
    <FlexContainer justifyContent="flex-start">
      {state.active && (
        <>
          <Chip type="SUCCESS" label="EXTRACTION RUNNING" />
          <Button onClick={() => setState((x) => ({ ...x, active: false }))}>
            Deactivate
          </Button>
        </>
      )}
      {!state.active && (
        <>
          <Chip type="NONE" label="EXTRACTION STOPPED" />
          <Button onClick={() => setState((x) => ({ ...x, active: true }))}>
            Activate
          </Button>

          <TextField
            size="small"
            variant="outlined"
            label="Label"
            value={state.label}
            onChange={(ev) =>
              setState((x) => ({ ...x, label: ev.target.value }))
            }
          />
        </>
      )}
    </FlexContainer>
  );
};

const Body = ({ ds }: { ds: Doc<EtlProcessSales>[] }) => {
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [selectedProcessId, setSelectedProcessId] = useStringQueryParam(
    'process',
    ''
  );
  const selectedProcess = useMemo(
    () => ds.find((d) => d.id === selectedProcessId) || null,
    [selectedProcessId, ds]
  );

  const [nextRange, setNextRange] = useState(
    sortBy(ds, (d) => -new Date(d.data.range.start).valueOf())[0]?.data
      .range || {
      start: '',
      end: ''
    }
  );

  const [showWholeYearButtons, setShowWholeYearButtons] = useState(false);
  const [labelFilter, setLabelFilter] = useState('');

  const filteredDs = useMemo(() => {
    if (!labelFilter) {
      return ds;
    }
    return ds.filter((d) => d.data.labels.includes(labelFilter));
  }, [ds, labelFilter]);

  return (
    <Page>
      <Section>
        <StartNewProcess value={nextRange} onChange={setNextRange} />
      </Section>
      {SHOW_AUTO_EXTRACT && (
        <Section>
          <FlexContainer justifyContent="space-between">
            <AutoRunExtract ds={ds} />
          </FlexContainer>
        </Section>
      )}
      <Section>
        <FlexContainer>
          <Typography variant="h5">Extract whole year {YEAR}</Typography>
          <IconButton onClick={() => setShowWholeYearButtons((x) => !x)}>
            {showWholeYearButtons ? (
              <ChevronUp size={16} />
            ) : (
              <ChevronDown size={16} />
            )}
          </IconButton>
        </FlexContainer>
        {showWholeYearButtons && (
          <>
            <AlertBox variant="pending">
              <p>
                The buttons below are reasonable slices to start extraction
                process for a whole year. The year variable (in this case {YEAR}
                ) is hardcoded and can be changed in code if necessary. This
                Year variable is also used as a label for each of the extraction
                processes.
              </p>
              <p>
                This is not fully-automated by design, as it's always a bit
                tricky to implement. A full year doesn't have THAT many parts.
                Click them one by one and parallelize according to taste. 3-4
                running in parallel is probably alright.
              </p>
              <p>
                Each button will display it's status after the the extraction
                process started.
              </p>
              <p>
                They will not LOAD into Clickhouse automatically - for this see{' '}
                <LinkExternal href="https://slab.affilimate.com/posts/seamless-reseeding-of-sales-data-to-clickhouse-c37b8e16">
                  this post in Slab.
                </LinkExternal>
              </p>
            </AlertBox>
            <FlexContainer justifyContent="space-between">
              <ExtractWholeYear ds={ds} />
            </FlexContainer>
          </>
        )}
      </Section>
      <Section>
        <CanvasBar>
          <SearchInput
            placeholder="Filter by label"
            value={labelFilter}
            onChange={setLabelFilter}
          />
        </CanvasBar>
        <RowsRenderer
          variant="contained"
          rows={filteredDs}
          columns={COLUMNS}
          rowToKey={rowToKey}
          sorter={sorter || DEFAULT_SORTER}
          sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
          onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
          chunkSize={100}
          rootMargin="400px"
          rowHeight={ROW_HEIGHTS.dense}
          renderHead
          otherProps={undefined}
          onRowClick={(d) => setSelectedProcessId(d.id)}
        />
      </Section>

      {selectedProcess && (
        <ProcessDialog
          open={true}
          onClose={() => setSelectedProcessId('')}
          d={selectedProcess}
        />
      )}
    </Page>
  );
};

export const PageClickhouseSeedSales = () => {
  const [ds] = useEtlProcesses();
  if (!ds) {
    return <Loader height={300} />;
  }
  return <Body ds={ds} />;
};
