import React from 'react';
import { styled } from '../../../emotion';
import { Fragment } from 'react';
import { AnchorLink } from '../../../components/AnchorLink';

export interface ISection {
  anchor: string;
  label: string;
  el: React.ReactNode;
  noLink?: boolean;
}

const AnchorContainer = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  span {
    display: inline-block;
    margin: 0 6px;
  }

  a {
    padding: 4px 6px;
  }

  a:hover {
    background-color: #333;
    border-radius: 99px;
  }
`;

export const AnchoredSection = ({ s }: { s: ISection }) => {
  return <div id={s.anchor}>{s.el}</div>;
};

export const AnchorLinks = ({
  sections,
  offset
}: {
  sections: ISection[];
  offset: number;
}) => {
  return (
    <AnchorContainer>
      {sections
        .filter((s) => !s.noLink)
        .map((s, i) => (
          <Fragment key={s.anchor}>
            {i !== 0 && <span />}
            <AnchorLink anchor={s.anchor} offset={offset} title={s.label}>
              {s.label}
            </AnchorLink>
          </Fragment>
        ))}
    </AnchorContainer>
  );
};

export const AnchoredSections = ({ sections }: { sections: ISection[] }) => {
  return (
    <>
      {sections.map((s) => (
        <AnchoredSection key={s.anchor} s={s} />
      ))}
    </>
  );
};
