import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { X as CancelIcon } from 'react-feather';
import React from 'react';
// @ts-ignore
import { Async } from 'react-select';

function NoOptionsMessage(props: any) {
  return (
    <Typography color="textSecondary" {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: any) {
  return (
    <TextField
      fullWidth
      label="Test"
      InputProps={{
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props: any) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props: any) {
  return (
    <Typography color="textSecondary" {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue(props: any) {
  return <Typography {...props.innerProps}>{props.children}</Typography>;
}

function ValueContainer(props: any) {
  return <div>{props.children}</div>;
}

function MultiValue(props: any) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props: any) {
  return (
    <Paper square {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export interface IOption {
  label: string;
  value: any;
}

interface IProps {
  selected?: any | null;
  loadOptions: (inputValue: string) => Promise<IOption[]>;
  placeholder?: string;
  onSelect: (selected: any) => void;
}

function IntegrationReactSelect({
  selected,
  onSelect,
  loadOptions,
  placeholder
}: IProps) {
  return (
    <div>
      <NoSsr>
        <Async
          components={components}
          value={selected}
          cacheOptions
          defaultOptions
          onChange={onSelect}
          loadOptions={loadOptions}
          placeholder={placeholder}
        />
      </NoSsr>
    </div>
  );
}

export const AutocompleteAsync = IntegrationReactSelect;
