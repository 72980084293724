import { Doc, toDocD } from '../../../domainTypes/document';
import { IProductScan } from '../../../domainTypes/productScan';
import { rdb, toRef } from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { normalizeProductScan } from '../../../services/scan';
import { RDB } from '../../../versions';

export const getScanRaw = (spaceId: string, scanId: string) => {
  return rdb()
    .ref(toRef(RDB.productScans, spaceId, scanId))
    .once('value')
    .then(snapshot => {
      if (snapshot && snapshot.exists()) {
        return toDocD(snapshot, normalizeProductScan);
      }
      return null;
    });
};

export const migrateScan = async (
  doc: Doc<IProductScan>,
  spaceToMigrateTo: string
): Promise<Doc<IProductScan>> => {
  return callFirebaseFunction('scraping-migrateScan', {
    spaceId: doc.data.spaceId,
    scanId: doc.id,
    spaceToMigrateToId: spaceToMigrateTo
  });
};
