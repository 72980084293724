import React from 'react';
import { Loader } from '../../../../../components/Loader';
import { SPACE_ID } from '../../../../../domainTypes/potentialUser';
import { ScanObserver } from '../../../../../features/Scan/pages/ProductScanDetail/ScanObserver';
import { useScan } from '../../../../../services/scan';
import { LinkExternal } from '../../../../components/LinkExternal';

export const PageAcquisitionScanDetails = ({ scanId }: { scanId: string }) => {
  const [scan, loading] = useScan(SPACE_ID, scanId);

  return (
    <>
      <h2>
        {scan ? (
          <LinkExternal href={scan.data.targets.map((t) => t.url).join(', ')} />
        ) : null}
      </h2>
      {loading && <Loader height="100%" />}
      {!scan && null}
      {scan && <ScanObserver userId="xxx" scan={scan} />}
    </>
  );
};
