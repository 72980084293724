import { ProductScanStatus } from './productScan';
import { ITaggable } from './tag';
import { Timestamp } from './time';

export const SPACE_ID = 'POTENTIAL_USERS';

export interface IPotentialUserCounts {
  pages: number;
  links: number;
  products: number;
  cloaked: number;
}

export interface IPotentialUserPartner {
  partnerKey: string;
  counts: IPotentialUserCounts;
}

export interface IPotentialUserScanResult {
  counts: IPotentialUserCounts;
  duration: number;
  partners: IPotentialUserPartner[];
}

export interface IPotentialUserScan {
  id: string;
  startedAt: Timestamp;
  finishedAt: Timestamp | null;
  status: ProductScanStatus;
  result: IPotentialUserScanResult | null;
}

export type IPotentialUserStatus =
  | 'LEAD'
  | 'PAYING'
  | 'TRIAL'
  | 'EXPIRED'
  | 'CHURNED';

export interface IPotentialUser extends ITaggable {
  url: string;
  createdAt: Timestamp;
  createdBy: string;
  scan: IPotentialUserScan | null;
  status: IPotentialUserStatus;
}
