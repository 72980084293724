import * as ENV from '../../../env.json';
import React from 'react';

type Props = {
  pathname: string;
  query?: { [key: string]: string };
};

const toQueryString = (q: { [key: string]: string }) => {
  if (!Object.keys(q).length) {
    return '';
  }
  return (
    '?' +
    Object.entries(q)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')
  );
};

export const AppLink: React.FC<Props> = ({
  pathname,
  query = {},
  children
}) => {
  const p = ENV.domains.app;
  const domain = process.env.NODE_ENV === 'development' ? p.local : p.public;
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`${domain}${pathname}${toQueryString(query)}`}
    >
      {children}
    </a>
  );
};
