import { IPubSubInstruction } from '../../../domainTypes/pubsub';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';

/**
 * @deprecated Use publish instruction instead
 */
export const publishTopic = (d: IPubSubInstruction) => {
  return callFirebaseFunction('pubsub-publishJson', d);
};

export const publishInstruction = (d: IPubSubInstruction) => {
  const val = d.payload ? { ...d, payload: { payload: d.payload } } : d;
  return callFirebaseFunction('pubsub-publishJson', val);
};
