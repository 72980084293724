import { IconButton, Paper } from '@material-ui/core';
import { mapValues } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { DownloadCloud, ExternalLink, RefreshCcw } from 'react-feather';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { styled } from '../../../../../emotion';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { useLoadingValue } from '../../../../../hooks/useLoadingValue';
import { Page } from '../../../../../layout/Page';
import { LoadingValueExtended } from '../../../../../services/db';
import {
  downloadFromCloudStorage,
  getStorageRef
} from '../../../../../services/storage';
import {
  formatDateHuman,
  Timestamp,
  tsFromMillis
} from '../../../../../services/time';

type Props = {};

type Data = DebugFile;
type ColumnName =
  | 'name'
  | 'total'
  | 'unknown'
  | 'error'
  | 'createdAt'
  | 'actions';
type Column = IColumn<Data, ColumnName>;

const getPlusMinus = (n: number) => {
  if (n === 0) {
    return '±';
  }
  return n > 0 ? '+' : '-';
};

const Colored = styled<'span', { n: number }>('span')`
  color: ${(p) => {
    if (p.n < 0) {
      return p.theme.custom.colors.success.main;
    }
    if (p.n > 0) {
      return p.theme.custom.colors.error.main;
    }
    return 'inherit';
  }};
`;

const DiffCell = ({ before, after }: { before: number; after: number }) => {
  const diff = after - before;
  return (
    <div>
      {before} / {after}{' '}
      <Colored n={diff}>
        ({getPlusMinus(diff)}
        {Math.abs(diff)})
      </Colored>
    </div>
  );
};

const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => d.name,
    sortable: true,
    align: 'left',
    width: 200,
    flexGrow: 3
  },
  {
    key: 'total',
    head: () => 'Total',
    cell: (d) => d.stats.total,
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'unknown',
    head: () => 'Unknown',
    cell: (d) => (
      <DiffCell before={d.stats.beforeUnknown} after={d.stats.afterUnknown} />
    ),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'error',
    head: () => 'Error',
    cell: (d) => (
      <DiffCell before={d.stats.beforeError} after={d.stats.afterError} />
    ),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'createdAt',
    head: () => 'Created At',
    cell: (d) => formatDateHuman(d.createdAt),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 1
  },

  {
    key: 'actions',
    head: () => 'Actions',
    cell: (d) => {
      return (
        <div>
          <IconButton onClick={() => downloadFromCloudStorage(d.storagePath)}>
            <DownloadCloud size={14} />
          </IconButton>
          <IconButton href={`/linkCheck/debug/${d.name}`} target="_blank">
            <ExternalLink size={14} />
          </IconButton>
        </div>
      );
    },
    sortable: false,
    align: 'right',
    width: 100,
    flexGrow: 1
  }
];

const SORTERS: ItemSorters<Data> = {
  name: {
    key: 'name',
    items: { sort: (d) => '', dir: 'asc' }
  },
  total: {
    key: 'total',
    items: { sort: (d) => '', dir: 'asc' }
  },
  unknown: {
    key: 'unknown',
    items: { sort: (d) => '', dir: 'asc' }
  },
  createdAt: {
    key: 'createdAt',
    items: { sort: (d) => d.createdAt.toMillis(), dir: 'desc' }
  },
  error: {
    key: 'error',
    items: { sort: (d) => 'TODO', dir: 'asc' }
  }
};
const DEFAULT_SORTER = SORTERS.createdAt;

type DebugFile = {
  name: string;
  storagePath: string;
  size: number;
  createdAt: Timestamp;
  stats: {
    total: number;
    beforeUnknown: number;
    beforeError: number;
    afterUnknown: number;
    afterError: number;
  };
};

const getDebugFiles = async (): Promise<DebugFile[]> => {
  const ref = getStorageRef('/linkCheck/debug');
  const all = await ref.listAll();
  return Promise.all(
    all.items.map<Promise<DebugFile>>(async (item) => {
      const metadataRaw = await item.getMetadata();
      return {
        name: item.name,
        storagePath: item.fullPath,
        size: metadataRaw.size as number,
        createdAt: tsFromMillis(new Date(metadataRaw.timeCreated).valueOf()),
        stats: mapValues(metadataRaw.customMetadata, (v) =>
          parseInt(v, 10)
        ) as DebugFile['stats']
      };
    })
  );
};

const useAllDebugFiles = (): LoadingValueExtended<DebugFile[]> => {
  const {
    value,
    loading,
    error,
    setValue,
    setLoading,
    setError
  } = useLoadingValue<DebugFile[]>();
  const getData = () =>
    getDebugFiles()
      .then((files) => setValue(files))
      .catch(setError);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = useCallback(async () => {
    setLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value, loading, error, refresh];
};

export const PageLinkCheckDebug: React.FC<Props> = () => {
  const [files, loading, error, refresh] = useAllDebugFiles();
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  useErrorLogger(error);
  const columns = COLUMNS;
  const rows = files;
  return (
    <Page>
      <TableToolbar>
        <TableToolbarSection></TableToolbarSection>
        <TableToolbarSection>
          <IconButton onClick={() => refresh()}>
            <RefreshCcw />
          </IconButton>
        </TableToolbarSection>
      </TableToolbar>
      <Paper>{loading && !files && <Loader height={500} />}</Paper>
      {rows && (
        <RowsRenderer
          variant="contained"
          columns={columns}
          rows={rows}
          rowToKey={(d) => d.name}
          sorter={sorter || DEFAULT_SORTER}
          sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
          renderHead={true}
          onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
          chunkSize={30}
          rootMargin="400px"
          otherProps={undefined}
          rowHeight={ROW_HEIGHTS.dense}
        />
      )}
    </Page>
  );
};
