import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography
} from '@material-ui/core';
import { first, last, sortBy, sum } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { Chip } from '../../../components/Chip';
import { DeleteButton } from '../../../components/DeletionConfirmation';
import { DialogActionsHeader } from '../../../components/DialogActionsHeader';
import { DialogActionsWithSlots } from '../../../components/DialogActionsWithSlots';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../components/GroupableList';
import { Loader } from '../../../components/Loader';
import { IColumn } from '../../../components/Table/Column';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  EtlProcessPageViewsAndClicksV2,
  ISOString
} from '../../../domainTypes/etl';
import { styled } from '../../../emotion';
import { FlexContainer } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { useStringQueryParam } from '../../../routes';
import { removeDoc, store } from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { ISOTimeRange } from '../../../services/time';
import { Json } from '../../components/Json';
import { LinkExternal } from '../../components/LinkExternal';
import { publishInstruction } from '../../services/pubsub';
import { getCloudStorageLink } from '../../services/storage';

const startTransform2 = async (processId: string) => {
  await publishInstruction({
    topic: 'dataMigrations-pageViewsToClickhouse-transform2Start',
    payload: { processId, chunkSize: 7 }
  });
};

const toEtlProcessDoc = generateToDocFn<EtlProcessPageViewsAndClicksV2>();
const collection = () => store().collection('etlProcessPageViewsAndClicksV2');
const etlStore = createCollectionListenerStore(
  () => new CollectionListener(collection(), toEtlProcessDoc)
);
const useEtlProcesses = () => useCollectionListener(etlStore(''));

const EltStatusChip = ({ d }: { d: Doc<EtlProcessPageViewsAndClicksV2> }) => {
  return (
    <Chip
      size="small"
      variant={d.data.running ? 'outlined' : 'default'}
      label={d.data.step}
      type={
        d.data.step === 'DONE'
          ? 'SUCCESS'
          : d.data.step === 'TRANSFORM1' || d.data.step === 'TRANSFORM2'
          ? 'PENDING'
          : d.data.step === 'ERROR'
          ? 'ERROR'
          : 'NONE'
      }
    />
  );
};

const Monospace = styled('div')((p) => ({
  fontFamily: 'monospace'
}));

const numberFormatter = new Intl.NumberFormat();

type D = Doc<EtlProcessPageViewsAndClicksV2>;
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.id}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 95,
    flexGrow: 0
  },
  {
    key: 'step',
    head: () => 'Step',
    cell: (d) => {
      return <EltStatusChip d={d} />;
    },
    align: 'left',
    width: 75
  },
  {
    key: 'labels',
    head: () => 'Labels',
    cell: (d) => (
      <Typography variant="caption">{d.data.labels.join(', ')}</Typography>
    ),
    align: 'left',
    width: 75
  },
  {
    key: 'rangeStart',
    head: () => 'Start',
    cell: (d) => moment(d.data.range.start).utc().format('YYYY-MM-DD'),
    align: 'left',
    width: 100,
    sortable: true
  },
  {
    key: 'rangeEnd',
    head: () => 'End',
    cell: (d) => moment(d.data.range.end).utc().format('YYYY-MM-DD'),
    align: 'left',
    width: 100
  },
  {
    key: 'events',
    head: () => 'Events',
    cell: (d) => {
      const pvEvents = sum(
        Object.values(d.data.transformFiles.pageViews).map((f) => f.count)
      );
      const clickEvents = sum(
        Object.values(d.data.loadFiles.clicks).map((f) => f.count)
      );
      return [pvEvents, clickEvents]
        .map((n) => numberFormatter.format(n))
        .join('/');
    },
    align: 'left',
    width: 120
  },
  {
    key: 'parts',
    head: () => 'Parts',
    cell: (d) => {
      const extractFiles = Object.values(d.data.extractFiles);
      const transformedFs = extractFiles.filter(
        (f) => f.status === 'TRANSFORMED'
      );
      const transformFiles = Object.values(d.data.transformFiles.pageViews);
      const transformFilesDone = transformFiles.filter(
        (f) => f.status === 'DONE'
      );
      const fs = Object.values(d.data.loadFiles.pageViews);
      const doneFs = fs.filter((f) => f.status === 'DONE');
      return [
        [transformedFs.length, extractFiles.length].join('/'),
        [transformFilesDone.length, transformFiles.length].join('/'),
        [doneFs.length, fs.length].join('/')
      ].join(' - ');
    },
    align: 'left',
    width: 200
  }
];

const SORTERS: ItemSorters<D> = {
  at: {
    key: 'at',
    items: { sort: (d) => new Date(d.data.insertedAt).valueOf(), dir: 'desc' }
  },

  rangeStart: {
    key: 'rangeStart',
    items: { sort: (d) => new Date(d.data.range.start).valueOf(), dir: 'asc' }
  },
  rangeEnd: {
    key: 'rangeEnd',
    items: { sort: (d) => new Date(d.data.range.end).valueOf(), dir: 'asc' }
  }
};
const DEFAULT_SORTER = SORTERS.rangeStart;

const rowToKey = (d: D) => d.id;

const ProcessDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: Doc<EtlProcessPageViewsAndClicksV2>;
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogActionsHeader
        onClose={onClose}
        left={
          <>
            <EltStatusChip d={d} />
            <Typography variant="h6">
              {d.id} - {d.data.range.start} {'->'} {d.data.range.end}
            </Typography>
          </>
        }
      />
      <DialogContent>
        {Object.values(d.data.loadFiles.pageViews).map((f) => {
          if (f.status === 'ERROR') {
            return (
              <div key={f.fileName}>
                <LinkExternal href={getCloudStorageLink(f.fileName)}>
                  {f.fileName} - {f.count}
                </LinkExternal>
              </div>
            );
          }
          return null;
        })}

        <Json
          data={d.data}
          shouldCollapse={(x) =>
            x.name === 'clicks' ||
            x.name === 'pageViews' ||
            x.name === 'transformFiles' ||
            x.name === 'extractFiles' ||
            x.name === 'loadFiles'
          }
        />
      </DialogContent>
      <DialogActionsWithSlots
        left={
          <DeleteButton
            variant="outlined"
            color="secondary"
            onDelete={async () => {
              await removeDoc(d);
            }}
          >
            Delete
          </DeleteButton>
        }
        right={
          <FlexContainer justifyContent="flex-end">
            {d.data.step === 'PRE_TRANSFORM2' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="..."
                onClick={async () => {
                  await startTransform2(d.id);
                }}
              >
                Transform 2
              </ButtonWithPromise>
            )}
            {d.data.step === 'TRANSFORM2' && d.data.running && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="..."
                onClick={async () => {
                  await startTransform2(d.id);
                }}
              >
                Re-transform 2
              </ButtonWithPromise>
            )}
            {d.data.step === 'TRANSFORM1' && d.data.running && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="..."
                onClick={async () => {
                  await publishInstruction({
                    topic:
                      'dataMigrations-pageViewsToClickhouse-transformStart',
                    payload: { processId: d.id }
                  });
                }}
              >
                Re-transform
              </ButtonWithPromise>
            )}
            {d.data.step === 'PRE_LOAD' && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="..."
                onClick={async () => {
                  await publishInstruction({
                    topic: 'dataMigrations-pageViewsToClickhouse-loadStart',
                    payload: { processId: d.id }
                  });
                }}
              >
                Start Load
              </ButtonWithPromise>
            )}

            {d.data.step === 'ERROR' ||
              (d.data.step === 'LOAD' && (
                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  pending="..."
                  onClick={async () => {
                    await publishInstruction({
                      topic: 'dataMigrations-pageViewsToClickhouse-loadStart',
                      payload: { processId: d.id }
                    });
                  }}
                >
                  Re-load
                </ButtonWithPromise>
              ))}
          </FlexContainer>
        }
      />
    </Dialog>
  );
};

const addToIsoDate = (t: ISOString, value: number, unit: 'h' | 'w' | 'd') => {
  return moment(t).utc().add(value, unit).toISOString();
};
const addToRange = (
  range: ISOTimeRange,
  value: number,
  unit: 'h' | 'w' | 'd'
) => {
  return {
    start: addToIsoDate(range.start, value, unit),
    end: addToIsoDate(range.end, value, unit)
  };
};

const StartNewProcess = ({
  value,
  onChange
}: {
  value: ISOTimeRange;
  onChange: (nextValue: ISOTimeRange) => void;
}) => {
  return (
    <FlexContainer direction="column" alignItems="flex-start" fullWidth>
      <FlexContainer fullWidth>
        <TextField
          fullWidth
          label="Start"
          value={value.start}
          onChange={(ev) => onChange({ ...value, start: ev.target.value })}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="End"
          value={value.end}
          onChange={(ev) => onChange({ ...value, end: ev.target.value })}
          variant="outlined"
        />
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending="..."
          onClick={async () => {
            await publishInstruction({
              topic: 'dataMigrations-pageViewsToClickhouse-extract',
              payload: {
                range: value,
                opts: {
                  advanceToNextStep: true
                }
              }
            });
          }}
        >
          GO
        </ButtonWithPromise>
      </FlexContainer>
      <FlexContainer alignItems="flex-start">
        <Button
          variant="outlined"
          size="small"
          onClick={() => onChange(addToRange(value, -4, 'w'))}
        >
          Minus 4 weeks
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => onChange(addToRange(value, -2, 'w'))}
        >
          Minus 2 weeks
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => onChange(addToRange(value, -1, 'w'))}
        >
          Minus 1 week
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

const DAYS_PER_CHUNK = 3;
const AutoRunExtract = ({
  ds,
  autoRunLabel
}: {
  ds: Doc<EtlProcessPageViewsAndClicksV2>[];
  autoRunLabel: string;
}) => {
  const [state, setState] = useState<{
    active: boolean;
  }>({ active: false });

  const ref = useRef({
    extractScheduled: false,
    lastDate:
      first(sortBy(ds, (d) => d.data.range.start))?.data.range.start ||
      '2023-10-20T22:00:00.000Z'
  });

  useEffect(() => {
    if (!state.active) {
      return;
    }
    const isExtracting = !!ds.find(
      (d) => d.data.step === 'INIT' || d.data.step === 'EXTRACT'
    );
    if (!isExtracting && !ref.current.extractScheduled) {
      console.log('SCHEDULE EXTRACT');
      const end = ref.current.lastDate;
      const start = addToIsoDate(end, -DAYS_PER_CHUNK, 'd');
      const range = { start, end };
      ref.current.lastDate = start;
      setTimeout(() => {
        publishInstruction({
          topic: 'dataMigrations-pageViewsToClickhouse-extract',
          payload: {
            range,
            labels: autoRunLabel ? [autoRunLabel] : [],
            opts: {
              advanceToNextStep: true
            }
          }
        }).then(() =>
          console.log(`EXTRACT STARTED - ${range.start} -> ${range.end}`)
        );
      }, 2000); // wait a bit to give some breathing room
      ref.current.extractScheduled = true;
    }

    if (isExtracting) {
      ref.current.extractScheduled = false;
    }
  }, [ds, state, autoRunLabel]);

  return (
    <FlexContainer justifyContent="flex-start">
      {state.active && (
        <>
          <Chip type="SUCCESS" label="EXTRACT RUNNING" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: false }))}
            variant="outlined"
          >
            Deactivate
          </Button>
        </>
      )}
      {!state.active && (
        <>
          <Chip type="NONE" label="EXTRACT STOPPED" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: true }))}
            variant="outlined"
          >
            Activate
          </Button>
        </>
      )}
    </FlexContainer>
  );
};

const AutoRunTransform2 = ({
  ds,
  autoRunLabel
}: {
  ds: Doc<EtlProcessPageViewsAndClicksV2>[];
  autoRunLabel: string;
}) => {
  const [state, setState] = useState<{
    active: boolean;
  }>({ active: false });

  const ref = useRef({
    transform2Scheduled: false
  });

  useEffect(() => {
    if (!state.active) {
      return;
    }
    const isTransforming = !!ds.find((d) => d.data.step === 'TRANSFORM2');

    if (!isTransforming && !ref.current.transform2Scheduled) {
      const oldestWithCurrentLabel = last(
        sortBy(
          ds.filter(
            (d) =>
              d.data.labels.includes(autoRunLabel) &&
              d.data.step === 'PRE_TRANSFORM2' &&
              !d.data.running
          ),
          (d) => d.data.range.end
        )
      );
      if (oldestWithCurrentLabel) {
        console.log('SCHEDULE TRANSFORM2');
        ref.current.transform2Scheduled = true;
        setTimeout(() => {
          startTransform2(oldestWithCurrentLabel.id).then(() =>
            console.log(`TRANSFORM2 STARTED - ${oldestWithCurrentLabel.id}`)
          );
        }, 2000); // wait a bit
      }
    }

    if (isTransforming) {
      ref.current.transform2Scheduled = false;
    }
  }, [ds, state, autoRunLabel]);

  return (
    <FlexContainer justifyContent="flex-start">
      {state.active && (
        <>
          <Chip type="SUCCESS" label="TRANSFORM2 RUNNING" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: false }))}
            variant="outlined"
          >
            Deactivate
          </Button>
        </>
      )}
      {!state.active && (
        <>
          <Chip type="NONE" label="TRANSFORM2 STOPPED" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: true }))}
            variant="outlined"
          >
            Activate
          </Button>
        </>
      )}
    </FlexContainer>
  );
};

const AutoRunLoad = ({
  ds,
  autoRunLabel
}: {
  ds: Doc<EtlProcessPageViewsAndClicksV2>[];
  autoRunLabel: string;
}) => {
  const [state, setState] = useState<{
    active: boolean;
  }>({ active: false });

  const ref = useRef({
    loadScheduled: false
  });

  useEffect(() => {
    if (!state.active) {
      return;
    }
    const isLoading = !!ds.find((d) => d.data.step === 'LOAD');

    if (!isLoading && !ref.current.loadScheduled) {
      const oldestWithCurrentLabel = last(
        sortBy(
          ds.filter(
            (d) =>
              d.data.labels.includes(autoRunLabel) &&
              d.data.step === 'PRE_LOAD' &&
              !d.data.running
          ),
          (d) => d.data.range.end
        )
      );
      if (oldestWithCurrentLabel) {
        const instruction = {
          topic: 'dataMigrations-pageViewsToClickhouse-loadStart',
          payload: {
            processId: oldestWithCurrentLabel.id
          }
        };
        console.log('SCHEDULE LOAD', instruction);
        ref.current.loadScheduled = true;
        setTimeout(() => {
          publishInstruction(instruction).then(() =>
            console.log(`LOAD STARTED - ${oldestWithCurrentLabel.id}`)
          );
        }, 2000); // wait a bit
      }
    }

    if (isLoading) {
      ref.current.loadScheduled = false;
    }
  }, [ds, state, autoRunLabel]);

  return (
    <FlexContainer justifyContent="flex-start">
      {state.active && (
        <>
          <Chip type="SUCCESS" label="LOAD RUNNING" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: false }))}
            variant="outlined"
          >
            Deactivate
          </Button>
        </>
      )}
      {!state.active && (
        <>
          <Chip type="NONE" label="LOAD STOPPED" />
          <Button
            onClick={() => setState((x) => ({ ...x, active: true }))}
            variant="outlined"
          >
            Activate
          </Button>
        </>
      )}
    </FlexContainer>
  );
};

const Body = ({ ds }: { ds: Doc<EtlProcessPageViewsAndClicksV2>[] }) => {
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [selectedProcessId, setSelectedProcessId] = useStringQueryParam(
    'process',
    ''
  );
  const selectedProcess = useMemo(
    () => ds.find((d) => d.id === selectedProcessId) || null,
    [selectedProcessId, ds]
  );

  const [nextRange, setNextRange] = useState(
    sortBy(ds, (d) => d.data.range.start)[0]?.data.range || {
      start: '',
      end: ''
    }
  );

  const [autoRunLabel, setAutoRunLabel] = useState('1st');

  return (
    <Page>
      <Section>
        <StartNewProcess value={nextRange} onChange={setNextRange} />
      </Section>
      <Section>
        <FlexContainer marginBottom={2}>
          <TextField
            size="small"
            variant="outlined"
            label="Auto Run Label"
            value={autoRunLabel}
            onChange={(ev) => setAutoRunLabel(ev.target.value)}
          />
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <AutoRunExtract ds={ds} autoRunLabel={autoRunLabel} />
          <AutoRunTransform2 ds={ds} autoRunLabel={autoRunLabel} />
          <AutoRunLoad ds={ds} autoRunLabel={autoRunLabel} />
        </FlexContainer>
      </Section>
      <Section>
        <RowsRenderer
          variant="contained"
          rows={ds}
          columns={COLUMNS}
          rowToKey={rowToKey}
          sorter={sorter || DEFAULT_SORTER}
          sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
          onHeadClick={(c, d) => setSort([SORTERS[c.key] || DEFAULT_SORTER, d])}
          chunkSize={100}
          rootMargin="400px"
          rowHeight={ROW_HEIGHTS.dense}
          otherProps={undefined}
          onRowClick={(d) => setSelectedProcessId(d.id)}
          renderHead
        />
      </Section>

      {selectedProcess && (
        <ProcessDialog
          open={true}
          onClose={() => setSelectedProcessId('')}
          d={selectedProcess}
        />
      )}
    </Page>
  );
};

export const PageClickhouseSeedPageViewsV2 = () => {
  const [ds] = useEtlProcesses();
  if (!ds) {
    return <Loader height={300} />;
  }
  return <Body ds={ds} />;
};
