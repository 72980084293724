import { Paper } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';
import { Loader } from '../../../../../components/Loader';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { getBillingStatus } from '../../../../../domainTypes/billing';
import { getSpaceDomainNames } from '../../../../../domainTypes/space';
import { SortDirection } from '../../../../../hooks/useSort';
import { Centered } from '../../../../../layout/Centered';
import { formatDatePrecise } from '../../../../../services/time';
import { SpaceLink } from '../../../../components/SpaceLink';
import {
  IAffiliationWithSpaces,
  useAllAffiliates
} from '../../../../services/affiliates';

type SortKey = 'referral' | 'affiliate' | 'plan' | 'status' | 'createdAt';

const COLUMNS: IColumn<IAffiliationWithSpaces, SortKey>[] = [
  {
    key: 'referral',
    head: () => 'Referral',
    cell: (d) => <SpaceLink space={d.referral.data} />,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 300,
    flexGrow: 2
  },
  {
    key: 'affiliate',
    head: () => 'Affiliate',
    cell: (d) => <SpaceLink space={d.affiliate.data} />,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 300,
    flexGrow: 2
  },
  {
    key: 'plan',
    head: () => 'Plan',
    cell: (d) => d.referral.data.billing.activePlan,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => getBillingStatus(d.referral.data, Date.now()),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'createdAt',
    head: () => 'Referral created at',
    cell: (d) => formatDatePrecise(d.affiliation.data.createdAt),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  }
];

const HEIGHT = 800;

const sortFn = (
  rows: IAffiliationWithSpaces[],
  sortBy: SortKey,
  direction: SortDirection
) =>
  orderBy(
    rows,
    (r) => {
      if (sortBy === 'referral') {
        return getSpaceDomainNames(r.referral.data);
      }

      if (sortBy === 'affiliate') {
        return getSpaceDomainNames(r.affiliate.data);
      }

      if (sortBy === 'status') {
        const status = getBillingStatus(r.referral.data, Date.now());
        if (
          status === 'PAYING' ||
          status === 'FREE' ||
          status === 'SUBSCRIPTION_GRACE'
        ) {
          return 0;
        }
        if (status === 'TRIAL_EXPIRED' || status === 'SUBSCRIPTION_EXPIRED') {
          return 2;
        }
        return 1;
      }
      if (sortBy === 'plan') {
        return r.referral.data.billing.activePlan || 'AAA';
      }
      return r.affiliation.data.createdAt.toMillis();
    },
    direction
  );

export const PageAffiliateOverview = () => {
  const [ds, loading, err] = useAllAffiliates();
  if (loading) {
    return <Loader height={HEIGHT} />;
  }
  if (err) {
    console.log(err);
    return <Centered height={HEIGHT}>Something went wrong.</Centered>;
  }
  if (!ds) {
    return null;
  }

  return (
    <Paper>
      <VirtualizedSortableTable
        rows={ds}
        columns={COLUMNS}
        cellProps={undefined}
        height={HEIGHT}
        margin="normal"
        sortFn={sortFn}
        initialSortColumn={COLUMNS[COLUMNS.length - 1]}
        initialSortDirection={'desc'}
      />
    </Paper>
  );
};
