const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min) + min);

export const createPin = (digits = 4) => {
  let pin = '';
  for (let i = 0; i < digits; i++) {
    pin += `${getRandomNumber(0, 10)}`;
  }
  return pin;
};
