import React from 'react';
import { capitalize, orderBy } from 'lodash';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { CustomTooltip } from '../../../../../components/Charts/CustomTooltip';
import { getKnownPartnerForKey } from '../../../../../services/partner';
import { SortDirection } from '../../../../../hooks/useSort';

type PartnerChartStat = {
  partnerKey: string;
  counts: {
    pages: number;
    links: number;
    products: number;
    cloaked: number;
    blogs?: number;
  };
};

export type YField = 'pages' | 'products' | 'links' | 'cloaked' | 'blogs';
export const Y_FIELDS: YField[] = [
  'blogs',
  'pages',
  'products',
  'links',
  'cloaked'
];

const getOptimalScaling = (min: number, max: number): 'linear' | 'sqrt' => {
  if (max / min > 150) {
    return 'sqrt';
  }
  return 'linear';
};

export const PartnerDistributionChart = ({
  ds,
  y,
  maxPartners,
  dir = 'desc'
}: {
  ds: PartnerChartStat[];
  maxPartners: number;
  y: YField;
  dir?: SortDirection;
}) => {
  const data = orderBy(ds, d => d.counts[y] || 0, dir).slice(0, maxPartners);

  return (
    <ResponsiveContainer width="99%" aspect={3}>
      <BarChart data={data} layout="horizontal" maxBarSize={24}>
        <XAxis dataKey="partnerKey" type="category" />
        <YAxis
          dataKey={`counts.${y}`}
          scale={
            data.length
              ? getOptimalScaling(
                  data[data.length - 1].counts[y] || 0,
                  data[0].counts[y] || 0
                )
              : 'auto'
          }
        />
        <Bar isAnimationActive={false} dataKey={`counts.${y}`}>
          {data.map(item => {
            const partner = getKnownPartnerForKey(item.partnerKey);
            return (
              <Cell
                key={item.partnerKey}
                fill={partner ? partner.color : 'lightgray'}
              />
            );
          })}
        </Bar>

        <Tooltip
          separator=": "
          content={<CustomTooltip />}
          cursor={false}
          labelFormatter={label => {
            const partner = getKnownPartnerForKey(label as string);
            return partner ? partner.name : label;
          }}
          formatter={value => [value, capitalize(y)]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
