import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { ArrowUpRight, UserPlus as IconImpersonate } from 'react-feather';
import { PATHS } from '../../../../../../../domainTypes/routes';
import { AppLink } from '../../../../../../components/AppLink';

export const ImpersonationLink: React.FC<{ spaceId: string }> = ({
  spaceId,
  children
}) => {
  return (
    <AppLink pathname={PATHS.dashboard.overview} query={{ spaceId }}>
      {children || 'Impersonate'} <ArrowUpRight size={14} />
    </AppLink>
  );
};

export const ImpersonationLinkIcon: React.FC<{
  spaceId: string;
  size?: number | string;
}> = ({ spaceId, size }) => {
  return (
    <ImpersonationLink spaceId={spaceId}>
      <Tooltip title="Impersonate">
        <IconButton>
          <IconImpersonate size={size} />
        </IconButton>
      </Tooltip>
    </ImpersonationLink>
  );
};
