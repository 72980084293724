import { compact, keyBy } from 'lodash';
import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import {
  COMMISSION_PERCENTAGE,
  IAffiliation
} from '../../../domainTypes/affiliate';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import { ISpace } from '../../../domainTypes/space';
import { store, useMappedLoadingValue } from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  createSingleCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { toSpaceDoc } from '../../../services/space';
import { now } from '../../../services/time';
import { FS } from '../../../versions';
import { useSpaces } from '../space';

export type IReferral = {
  affiliation: Doc<IAffiliation>;
  space: Doc<ISpace>;
};

export const toAffiliationDoc = generateToDocFn<IAffiliation>();

const getAffiliationByAffiliateIdListener = createCollectionListenerStore(
  affiliateId =>
    new CollectionListener(
      store()
        .collection(FS.affiliations)
        .where('affiliateId', '==', affiliateId),
      toAffiliationDoc
    )
);

const toReferrals = (
  affiliations: Doc<IAffiliation>[],
  spaces: Doc<ISpace>[]
): IReferral[] => {
  const spacesById = keyBy(spaces, s => s.id);
  return compact(
    affiliations.map(a => {
      const space = spacesById[a.data.spaceId];
      if (!space) {
        return null;
      }
      return {
        affiliation: a,
        space
      };
    })
  );
};

export const useReferrals = (affiliateId: string) => {
  const [affiliations, loadingAffs, errAffs] = useCollectionListener(
    getAffiliationByAffiliateIdListener(affiliateId)
  );
  const [spaces, loadingSpaces, errSpaces] = useSpaces();
  const referrals =
    affiliations && spaces ? toReferrals(affiliations, spaces) : undefined;
  return [referrals, loadingAffs || loadingSpaces, errAffs || errSpaces] as [
    typeof referrals,
    boolean,
    any
  ];
};

const useAffiliationBySpaceId = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.affiliations)
        .where('spaceId', '==', spaceId)
    ),
    s => {
      if (!s.docs.length) {
        return null;
      }
      if (s.docs.length > 1) {
        console.log(`MULTIPLE AFFILIATIONS FOR SPACE ID ${spaceId} FOUND`);
        return null;
      }
      return toAffiliationDoc(s.docs[0]);
    }
  );
};

const useSpaceByAffiliateId = (affiliateId: string | null) => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.spaces)
        .where('affiliateId', '==', affiliateId)
    ),
    s => {
      if (affiliateId === null) {
        return undefined;
      }
      if (!s.docs.length) {
        return null;
      }
      if (s.docs.length > 1) {
        console.log(`multiple spaces with affiliate id ${affiliateId} found`);
      }
      return toSpaceDoc(s.docs[0]);
    }
  );
};

export const useAffiliate = (spaceId: string) => {
  const [affiliation, loadingAff, errAff] = useAffiliationBySpaceId(spaceId);
  const [space, loadingSpace, errSpace] = useSpaceByAffiliateId(
    affiliation ? affiliation.data.affiliateId : null
  );

  const affiliate: IReferral | null =
    affiliation && space ? { affiliation, space } : null;
  return [affiliate, loadingAff || loadingSpace, errAff || errSpace] as [
    typeof affiliate,
    boolean,
    any
  ];
};

export const createAffiliation = (spaceId: string, affiliateId: string) => {
  const affiliation: IAffiliation = {
    spaceId,
    affiliateId,
    createdAt: now(),
    commissionPercentage: COMMISSION_PERCENTAGE,
    referrer: '',
    userAgent: '',
    clickDate: null
  };

  return store()
    .collection(FS.affiliations)
    .doc(shortid())
    .set(affiliation);
};

const getListener = createSingleCollectionListenerStore(
  () =>
    new CollectionListener(
      store().collection(FS.affiliations),
      toAffiliationDoc
    )
);

export type IAffiliationWithSpaces = {
  affiliation: Doc<IAffiliation>;
  referral: Doc<ISpace>;
  affiliate: Doc<ISpace>;
};

export const useAllAffiliates = () => {
  const [spaces, loadingSpaces, errSpaces] = useSpaces();
  const [affiliations, loadingAffs, errAffs] = useCollectionListener(
    getListener()
  );

  const merge = (): IAffiliationWithSpaces[] | undefined => {
    if (!spaces || !affiliations) {
      return undefined;
    }
    const spacesById = keyBy(spaces, s => s.id);
    const spacesByAffId = keyBy(spaces, s => s.data.affiliateId);

    return compact(
      affiliations.map(a => {
        const referral = spacesById[a.data.spaceId];
        const affiliate = spacesByAffId[a.data.affiliateId];
        if (!referral || !affiliate) {
          return null;
        }
        return { affiliation: a, referral, affiliate };
      })
    );
  };

  const data = merge();

  return [data, loadingSpaces || loadingAffs, errSpaces || errAffs] as [
    typeof data,
    boolean,
    any
  ];
};
