import { Card } from '@material-ui/core';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import React from 'react';
import { styled } from '../../../emotion';
import { useAfterFirstRender } from '../../../hooks/useAfterFirstRender';

const ui = new firebaseui.auth.AuthUI(firebase.auth());

const CONFIG: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        prompt: 'select_account'
      }
    }
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false
  }
};

const ID = 'firebase-ui-login-container';

const LoginPanel = () => {
  useAfterFirstRender(() => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        // we might not be on this screen anymore, but we have also
        // no way to cancel to promise!
        if (document.getElementById(ID)) {
          ui.start(`#${ID}`, CONFIG);
        }
      });
  });
  return <div id={ID} />;
};

const LoginWrapper = styled('div')`
  height: 100vh;
  display: flex;
`;

const RightSide = styled('div')`
  background-color: ${p => p.theme.palette.grey.A100};
  width: 100%;
  padding: ${p => p.theme.spacing() * 5}px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
`;

const RightSideInner = styled('div')`
  margin: 0 auto;
`;

const RightSideContent = styled('div')`
  margin-bottom: ${p => p.theme.spacing() * 3}px;
`;

const SignUpOrLoginText = styled('p')`
  border-bottom: 1px solid ${p => p.theme.palette.grey.A200};
  padding-bottom: ${p => p.theme.spacing() * 3}px;
  margin-bottom: ${p => p.theme.spacing() * 4}px;
  font-weight: bold;
`;

const Logo = styled('span')`
  font-size: ${p => p.theme.custom.fontSize.l}px;
  font-weight: bold;
`;

export const Login = () => {
  return (
    <LoginWrapper>
      <RightSide>
        <RightSideInner>
          <RightSideContent>
            <img src="/favicon.png" width="30px" alt="" />{' '}
            <Logo>Affilimate Admin App</Logo>
            <br />
            <p>Your friendly affiliate link optimization tool</p>
          </RightSideContent>
          <Card style={{ padding: '24px 48px' }}>
            <SignUpOrLoginText>
              Please log in with your admin account!
            </SignUpOrLoginText>
            <LoginPanel />
          </Card>
        </RightSideInner>
      </RightSide>
    </LoginWrapper>
  );
};
