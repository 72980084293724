import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps
} from '@material-ui/core';
import firebase from 'firebase/app';
import { orderBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Loader } from '../../../components/Loader';
import { SearchInput } from '../../../components/SearchInput';
import { TableToolbar } from '../../../components/Table';
import { IColumn } from '../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../domainTypes/document';
import { getSpaceDomainNames, ISpace } from '../../../domainTypes/space';
import { useDialogState } from '../../../hooks/useDialogState';
import { SortDirection } from '../../../hooks/useSort';
import { formatDatePrecise } from '../../../services/time';
import { useSpaces } from '../../services/space';

type SpaceOption = {
  label: string;
  id: string;
  createdAt: firebase.firestore.Timestamp;
  space: Doc<ISpace>;
};

const spaceToOption = (s: Doc<ISpace>): SpaceOption => ({
  id: s.id,
  label: s.data.domains.map((d) => d.url).join(', ') || s.id,
  createdAt: s.data.createdAt,
  space: s
});

type SortKey = 'id' | 'label' | 'createdAt';

const sort = (
  os: SpaceOption[],
  sortBy: SortKey,
  direction: SortDirection
): SpaceOption[] => {
  if (sortBy === 'createdAt') {
    return orderBy(os, (o) => o[sortBy].toMillis(), direction);
  }
  return orderBy(os, (o) => o[sortBy], direction);
};

const COLUMNS: IColumn<SpaceOption, SortKey>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <a href={`/analytics/content/?spaceId=${d.id}`}>{d.id}</a>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'label',
    head: () => 'Domain',
    cell: (d) => d.label,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'createdAt',
    head: () => 'Created',
    cell: (d) => <div>{formatDatePrecise(d.createdAt)}</div>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 4
  }
];

export const SpaceSelector = ({
  spaces,
  height = 500,
  onSelect
}: {
  spaces: Doc<ISpace>[];
  height?: number;
  onSelect: (space: Doc<ISpace>) => void;
}) => {
  const spaceOptions = spaces.map(spaceToOption);
  const [term, setTerm] = useState('');
  const options = useMemo(
    () =>
      term
        ? spaceOptions.filter(
            (o) => o.id.indexOf(term) !== -1 || o.label.indexOf(term) !== -1
          )
        : spaceOptions,
    [spaceOptions, term]
  );

  return (
    <>
      <TableToolbar padding="dense">
        <SearchInput value={term} onChange={setTerm} autoFocus={true} />
      </TableToolbar>
      <VirtualizedSortableTable
        rows={options}
        columns={COLUMNS}
        cellProps={undefined}
        height={height}
        margin="dense"
        sortFn={sort}
        initialSortColumn={COLUMNS[1]}
        onRowClick={(o) => onSelect(o.space)}
      />
    </>
  );
};

export const SpaceSelectorDialog = ({
  open,
  onClose,
  onSelect
}: {
  open: boolean;
  onClose: () => void;
  onSelect: (space: Doc<ISpace>) => void;
}) => {
  const [spaces, loading] = useSpaces();
  const height = 500;

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      <DialogTitle>Select space</DialogTitle>
      <DialogContent style={{ width: '900px' }}>
        {loading || (!spaces && <Loader height={height} />)}
        {!loading && spaces && (
          <SpaceSelector
            spaces={spaces}
            onSelect={(space) => {
              onClose();
              onSelect(space);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const SpaceSelectorField = ({
  value,
  onChange,
  ...other
}: {
  value: Doc<ISpace> | null;
  onChange: (nextValue: Doc<ISpace> | null) => void;
} & Omit<TextFieldProps, 'onChange'>) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  return (
    <>
      <TextField
        variant="outlined"
        {...other}
        value={value ? getSpaceDomainNames(value.data) : ''}
        inputProps={{ readOnly: true }}
        onClick={() => openDialog()}
      />
      <SpaceSelectorDialog
        open={dialogOpen}
        onClose={closeDialog}
        onSelect={onChange}
      />
    </>
  );
};
