import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import { groupBy, mapValues } from 'lodash';
import React, { useMemo, useState } from 'react';
import { AutocompleteSingleFreeSolo } from '../../../components/Autocomplete';
import { styled } from '../../../emotion';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { getLogsLinkForFn } from '../../services/logs';
import LIST from './cf-list.json';

type Cf = {
  name: string;
  group: string;
  fullName: string;
  region: string;
};

const GROUPS_BY_REGION = mapValues(
  groupBy(LIST as Cf[], (x) => x.region),
  (xs) => groupBy(xs, (x) => x.group)
);

const REGIONS = Object.keys(GROUPS_BY_REGION);

const Grid = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: '150px 1fr 2fr 100px',
  gridColumnGap: p.theme.spacing(1),
  width: '100%'
}));

export const PageLogs = () => {
  // It's possible to add more than one selection to this!
  // We can just create OR queries
  const [{ region, group, name }, setState] = useState<{
    region: string;
    group: string;
    name: string;
  }>({
    region: 'us-central1',
    group: '',
    name: ''
  });

  const groupOptions = useMemo(() => {
    if (!region) {
      return [];
    }
    return Object.keys(GROUPS_BY_REGION[region]);
  }, [region]);

  const nameOptions = useMemo(() => {
    if (!region || !group) {
      return [];
    }
    return GROUPS_BY_REGION[region][group]?.map((x) => x.name) || [];
  }, [region, group]);

  return (
    <Page>
      <Section>
        <Typography>Log Link Builder</Typography>
        <br />

        <Grid>
          <Select
            fullWidth
            label="Region"
            variant="outlined"
            value={region}
            onChange={(ev) =>
              setState((x) => ({
                ...x,
                region: ev.target.value as string,
                group: '',
                names: []
              }))
            }
          >
            {REGIONS.map((r) => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
          <AutocompleteSingleFreeSolo
            label="Group"
            key={region}
            fullWidth
            value={group}
            options={groupOptions}
            onChange={(nextGroup) =>
              setState((x) => ({
                ...x,
                group: nextGroup,
                names: []
              }))
            }
          />
          <AutocompleteSingleFreeSolo
            label="Name"
            key={group}
            value={name}
            fullWidth
            options={nameOptions}
            onChange={(nextName) =>
              setState((x) => ({
                ...x,
                name: nextName
              }))
            }
          />
          <Button
            size="small"
            variant="contained"
            target="_blank"
            color="primary"
            disabled={!region || !group || !name}
            href={getLogsLinkForFn(`${group}-${name}`, region)}
          >
            Open Logs
          </Button>
        </Grid>
      </Section>
    </Page>
  );
};
