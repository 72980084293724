import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { AutocompleteProduct } from '../../../../../../components/AutocompleteProduct';
import { Doc } from '../../../../../../domainTypes/document';
import { IProduct } from '../../../../../../domainTypes/product';
import { css } from '../../../../../../emotion';
import {
  findProductsInSpaceByName,
  mergeProducts
} from '../../../../../../services/products';

type Props = {
  spaceId: string;
  open: boolean;
  onClose: () => void;
  initialSrc?: Doc<IProduct>;
  initialTarget?: Doc<IProduct>;
};

const toFindFn = (spaceId: string) => (name: string) =>
  findProductsInSpaceByName(spaceId, name);

export const DialogMerge: React.FC<Props> = ({
  spaceId,
  open,
  onClose,
  initialSrc,
  initialTarget
}) => {
  const [src, setSrc] = useState<Doc<IProduct> | undefined>(initialSrc);
  const [target, setTarget] = useState<Doc<IProduct> | undefined>(
    initialTarget
  );
  const merge = () => {
    if (src && target) {
      return mergeProducts(target, src);
    }
  };
  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <DialogTitle>Merge Products</DialogTitle>
      <DialogContent>
        <AutocompleteProduct
          selected={src}
          onSelect={setSrc}
          findProducts={toFindFn(spaceId)}
        />
        <AutocompleteProduct
          selected={target}
          onSelect={setTarget}
          findProducts={toFindFn(spaceId)}
        />
        {src && target && (
          <div>
            {src.id} {'->'} {target.id}
          </div>
        )}
        <div
          className={css((t) => ({
            minWidth: '400px',
            marginTop: t.spacing() * 6,
            display: 'flex',
            justifyContent: 'center'
          }))}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!src || !target || src.id === target.id}
            className={css((t) => ({ width: '100%' }))}
            onClick={merge}
          >
            Merge
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
