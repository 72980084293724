import React from 'react';
import { capitalize, orderBy } from 'lodash';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { CustomTooltip } from '../../../../../components/Charts/CustomTooltip';
import { SortDirection } from '../../../../../hooks/useSort';
import { ITagPrototype } from '../../../../../domainTypes/tag';
import { getColorDef } from '../../../../../domainTypes/colors';

type TagChartStat = {
  tag: ITagPrototype;
  counts: {
    pages: number;
    links: number;
    products: number;
    cloaked: number;
    blogs?: number;
  };
};

export type YField = 'pages' | 'products' | 'links' | 'cloaked' | 'blogs';
export const Y_FIELDS: YField[] = [
  'blogs',
  'pages',
  'products',
  'links',
  'cloaked'
];

const getOptimalScaling = (min: number, max: number): 'linear' | 'sqrt' => {
  if (max / min > 150) {
    return 'sqrt';
  }
  return 'linear';
};

export const TagDistributionChart = ({
  ds,
  y,
  dir = 'desc'
}: {
  ds: TagChartStat[];
  y: YField;
  dir?: SortDirection;
}) => {
  const data = orderBy(ds, (d) => d.counts[y] || 0, dir);

  return (
    <ResponsiveContainer width="99%" aspect={3}>
      <BarChart
        data={data}
        layout="vertical"
        maxBarSize={24}
        margin={{ left: 24 }}
      >
        <YAxis dataKey="tag.name" type="category" />
        <XAxis
          type="number"
          dataKey={`counts.${y}`}
          scale={
            data.length
              ? getOptimalScaling(
                  data[data.length - 1].counts[y] || 0,
                  data[0].counts[y] || 0
                )
              : 'auto'
          }
        />
        <Bar isAnimationActive={false} dataKey={`counts.${y}`}>
          {data.map((item) => {
            const c = getColorDef(item.tag.color);
            return <Cell key={item.tag.id} fill={c.bgColor} />;
          })}
        </Bar>

        <Tooltip
          separator=": "
          content={<CustomTooltip />}
          cursor={false}
          formatter={(value) => [value, capitalize(y)]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
