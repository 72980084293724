import React from 'react';
import { MomentRange } from '../../services/time';
import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import { DateRangePicker } from '../DateRangePicker';

export const DateRangePickerDialog = ({
  open,
  onClose,
  value,
  onChange,
  title
}: {
  open: boolean;
  onClose: () => void;
  value: MomentRange;
  onChange: (nextValue: MomentRange) => void;
  title?: React.ReactNode;
}) => {
  const [range, setRange] = useState(value);
  useEffect(() => {
    if (open) {
      setRange(value);
    }
  }, [open, value]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DateRangePicker value={range} onChange={setRange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onChange(range);
            onClose();
          }}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
