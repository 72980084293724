import React from 'react';
import { ITagCategory, ITagPrototype } from '../../../domainTypes/tag';
import { AutocompleteMultiWithCreateOption } from '../../Autocomplete';
import { TagPrototypeCreateDialog } from '../PrototypeCreateDialog';
import { getTagPrototypesForCategory } from '../service';
import { Tag } from '../Tag';
import { TagOption } from '../TagOption';

export const AutocompleteTags = ({
  value,
  onChange,
  fullWidth,
  spaceId,
  userId,
  category,
  autoFocus,
  style
}: {
  value: ITagPrototype[];
  onChange: (nextValue: ITagPrototype[]) => void;
  fullWidth?: boolean;
  spaceId: string;
  userId: string;
  category: ITagCategory;
  autoFocus?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <AutocompleteMultiWithCreateOption
      value={value}
      onChange={onChange}
      renderOption={(o: ITagPrototype) => <TagOption p={o} />}
      options={() =>
        getTagPrototypesForCategory(spaceId, category).then(docs =>
          docs.map(d => d.data)
        )
      }
      createDialog={({ open, onClose, inputValue, onCreate }) => (
        <TagPrototypeCreateDialog
          open={open}
          onClose={onClose}
          initialTagName={inputValue}
          onCreate={onCreate}
          spaceId={spaceId}
          userId={userId}
          category={category}
        />
      )}
      renderTags={(tags, getTagProps) =>
        tags.map((t, index) => <Tag t={t} {...getTagProps({ index })} />)
      }
      fullWidth={fullWidth}
      label="Tags"
      autoFocus={autoFocus}
      style={style}
    />
  );
};
