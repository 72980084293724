import React from 'react';
import { ITagPrototype } from '../../../domainTypes/tag';
import { Swatch } from '../../ColorPicker';

export const TagOption = ({ p }: { p: ITagPrototype }) => {
  return (
    <div>
      <Swatch colorName={p.color} size="small" />
      {p.name}
    </div>
  );
};
