import { Button, Dialog, DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import {
  ButtonWithPromise,
  ButtonWithPromiseProps
} from '../../../components/ButtonWithPromise';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { ButtonContainer } from '../ButtonContainer';

type Props<T = any> = {
  onClick: (execute: boolean) => Promise<T>;
} & Omit<ButtonWithPromiseProps<T>, 'onClick' | 'pending'>;

export const SafeExecuteButton = <T extends any>(props: Props<T>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { onClick, children, ...other } = props;

  const execute = (exec: boolean) => {
    const type = exec ? 'Execution' : 'Dry Run';
    return onClick(exec).then(
      (x) => {
        enqueueSnackbar(`${type} successful!`, {
          variant: 'success'
        });
        return x;
      },
      (err) => {
        console.error(err);
        enqueueSnackbar(`${type} failed!`, {
          variant: 'error'
        });
        return Promise.reject(err);
      }
    );
  };

  return (
    <>
      <Button {...other} onClick={() => setOpen(true)}>
        {children}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <ButtonContainer>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <ButtonWithPromise
              variant="contained"
              color="secondary"
              pending="Executing..."
              onClick={() => execute(true)}
            >
              Execute
            </ButtonWithPromise>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending="Executing..."
              onClick={() => execute(false)}
            >
              Dry run
            </ButtonWithPromise>
          </ButtonContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};
