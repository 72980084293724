import { Card } from '@material-ui/core';
import React from 'react';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import {
  saveAutoLabelConfig,
  useAutoLabelConfig
} from '../../../../../../../services/autoLabelConfig';
import { AutoLabellingList } from '../../../../../../../features/PerformanceNew/components/AutoLabellingList';

type Props = {
  spaceId: string;
};

export const AutoLabelling: React.FC<Props> = ({ spaceId }) => {
  const [value, loading] = useAutoLabelConfig(spaceId);
  const onChange = (partners: string[]) => {
    if (value) {
      saveAutoLabelConfig({
        ...value,
        data: {
          ...value.data,
          partners
        }
      });
    }
  };

  return (
    <Section>
      <CanvasBar>Auto Labelling</CanvasBar>
      <Card>
        <AutoLabellingList
          value={value ? value.data.partners : undefined}
          loading={loading}
          onChange={onChange}
        />
      </Card>
    </Section>
  );
};
