import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper
} from '@material-ui/core';
import { keyBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../components/AdditionalActionsMenu';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { DeleteButton } from '../../../../../components/DeletionConfirmation';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { SelectionBox } from '../../../../../components/SelectionBox';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { Doc } from '../../../../../domainTypes/document';
import { EMPTY_OBJ } from '../../../../../domainTypes/emptyConstants';
import { ILinkCheckAppContainer } from '../../../../../domainTypes/linkCheckApp';
import { LinkCheckStatus } from '../../../../../domainTypes/linkCheckV2';
import { getSpaceDomainNames, ISpace } from '../../../../../domainTypes/space';
import { CheckStatusChip } from '../../../../../features/LinkCheck/components/CheckStatusChip';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { useSet } from '../../../../../hooks/useSet';
import { Section } from '../../../../../layout/Section';
import { batchDeleteDocs, removeDoc } from '../../../../../services/db';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import {
  formatDatePrecise,
  msToMinsAndSecs,
  ONE_DAY
} from '../../../../../services/time';
import { FS } from '../../../../../versions';
import { KillSwitch } from '../../../../components/KillSwitch';
import { SafeExecuteButton } from '../../../../components/SafeExecuteButton';
import { SpaceSelectorField } from '../../../../components/SpaceSelector';
import { toFirestoreConsole } from '../../../../services/firebase';
import { getSpaces, useSpaces } from '../../../../services/space';
import { createNewCheck, useAppChecks } from '../../service';

type Props = {};

type Data = Doc<ILinkCheckAppContainer>;
type ColumnName =
  | 'selection'
  | 'space'
  | 'id'
  | 'status'
  | 'total'
  | 'pending'
  | 'unsupported'
  | 'ok'
  | 'warnings'
  | 'errors'
  | 'unknown'
  | 'blocked'
  | 'queuedAt'
  | 'startedAt'
  | 'finishedAt'
  | 'duration'
  | 'actions';

type Column = IColumn<
  Data,
  ColumnName,
  {
    spacesById: { [spaceId: string]: Doc<ISpace> };
    selection: {
      selected: Set<string>;
      areAllSelected: boolean;
      selectAll: () => void;
      selectOne: (id: string, nextState: boolean) => void;
      selectNone: () => void;
    };
  }
>;

const Actions = ({ d }: { d: Data }) => {
  const options: AdditionActionsMenuOption[] = useMemo(() => {
    return [
      {
        key: 'details',
        label: 'Open Details',
        externalHref: `/linkCheck/details/${d.id}`
      },
      {
        key: 'openInFirestore',
        label: 'Open in Firestore console',
        externalHref: toFirestoreConsole(FS.linkCheckApp, d.id)
      },
      {
        key: 'remove',
        label: 'Remove',
        onClick: () => removeDoc(d)
      }
    ];
  }, [d]);
  return <AdditionalActionsMenu options={options} />;
};

const COLUMNS: Column[] = [
  {
    key: 'selection',
    head: (p) => {
      const { areAllSelected, selectNone, selectAll, selected } = p!.selection;
      const onChange = () => (areAllSelected ? selectNone() : selectAll());
      return (
        <SelectionBox
          onChange={onChange}
          indeterminate={!areAllSelected && !!selected.size}
          value={areAllSelected}
        />
      );
    },
    alternateHead: () => 'Selection',
    cell: (d, { selection }) => {
      const isSelected = selection.selected.has(d.id);
      return (
        <SelectionBox
          onChange={() => selection.selectOne(d.id, !isSelected)}
          value={isSelected}
        />
      );
    },
    sortable: false,
    align: 'center',
    width: 50,
    flexGrow: 0
  },
  {
    key: 'space',
    head: () => 'Space',
    cell: (d, { spacesById }) => {
      const { spaceId } = d.data;
      const space = spacesById[spaceId];
      return space ? getSpaceDomainNames(space.data) : spaceId;
    },
    sortable: true,
    align: 'left',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => d.id,
    sortable: true,
    align: 'left',
    width: 90,
    flexGrow: 0
  },
  {
    key: 'status',
    head: () => '',
    alternateHead: () => 'Status',
    cell: (d) => <CheckStatusChip status={d.data.status} size="small" />,
    sortable: true,
    align: 'center',
    width: 90,
    flexGrow: 0
  },
  {
    key: 'total',
    head: () => 'Total',
    cell: (d) => d.data.counts.total,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'unsupported',
    head: () => 'Unsupported',
    cell: (d) => d.data.counts.unsupported,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'pending',
    head: () => 'Pending',
    cell: (d) => d.data.counts.pending,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'ok',
    head: () => 'OK',
    cell: (d) => d.data.counts.results.ok,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'warnings',
    head: () => 'Warnings',
    cell: (d) => d.data.counts.results.warnings,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'errors',
    head: () => 'Errors',
    cell: (d) => d.data.counts.results.errors,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'unknown',
    head: () => 'Unknown',
    cell: (d) => d.data.counts.results.unknown,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'blocked',
    head: () => 'Blocked',
    cell: (d) => d.data.counts.results.blocked,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'queuedAt',
    head: () => 'Queued At',
    cell: (d) => formatDatePrecise(d.data.queuedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'startedAt',
    head: () => 'Started At',
    cell: (d) => d.data.startedAt && formatDatePrecise(d.data.startedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'finishedAt',
    head: () => 'Finished At',
    cell: (d) => d.data.finishedAt && formatDatePrecise(d.data.finishedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'duration',
    head: () => 'Duration',
    cell: (d) =>
      d.data.startedAt &&
      d.data.finishedAt &&
      msToMinsAndSecs(
        d.data.finishedAt.toMillis() - d.data.startedAt.toMillis()
      ),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    sortable: false,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Data> = {
  space: {
    key: 'space',
    items: { sort: (d) => d.data.spaceId, dir: 'asc' }
  },
  id: {
    key: 'id',
    items: { sort: (d) => d.id, dir: 'asc' }
  },
  total: {
    key: 'total',
    items: { sort: (d) => d.data.counts.total, dir: 'desc' }
  },
  unsupported: {
    key: 'unsupported',
    items: { sort: (d) => d.data.counts.unsupported, dir: 'desc' }
  },
  pending: {
    key: 'pending',
    items: { sort: (d) => d.data.counts.pending, dir: 'desc' }
  },
  ok: {
    key: 'ok',
    items: { sort: (d) => d.data.counts.results.ok, dir: 'desc' }
  },
  warnings: {
    key: 'warnings',
    items: { sort: (d) => d.data.counts.results.warnings, dir: 'desc' }
  },
  errors: {
    key: 'errors',
    items: { sort: (d) => d.data.counts.results.errors, dir: 'desc' }
  },
  unknown: {
    key: 'unknown',
    items: { sort: (d) => d.data.counts.results.unknown, dir: 'desc' }
  },
  blocked: {
    key: 'blocked',
    items: { sort: (d) => d.data.counts.results.blocked, dir: 'desc' }
  },
  queuedAt: {
    key: 'queuedAt',
    items: { sort: (d) => d.data.queuedAt.toMillis(), dir: 'desc' }
  },
  startedAt: {
    key: 'startedAt',
    items: { sort: (d) => d.data.startedAt?.toMillis() || 0, dir: 'desc' }
  },
  finishedAt: {
    key: 'finishedAt',
    items: { sort: (d) => d.data.finishedAt?.toMillis() || 0, dir: 'desc' }
  },
  duration: {
    key: 'duration',
    items: {
      sort: (d) =>
        d.data.startedAt && d.data.finishedAt
          ? d.data.finishedAt.toMillis() - d.data.startedAt.toMillis()
          : 0,
      dir: 'desc'
    }
  }
};

const DEFAULT_SORTER = SORTERS.queuedAt;

const DEFAULT_COLUMNS: ColumnName[] = [
  'selection',
  'space',
  'id',
  'status',
  'total',
  'unsupported',
  'pending',
  'ok',
  'warnings',
  'errors',
  'unknown',
  'startedAt',
  'finishedAt',
  'actions'
];

const CreateDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [state, setState] = useState<{
    space: Doc<ISpace> | null;
    createNotifications: boolean;
  }>({
    space: null,
    createNotifications: false
  });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ style: { width: 600 } }}
    >
      <DialogTitle>Create a new link check</DialogTitle>
      <DialogContent>
        <SpaceSelectorField
          label="Space"
          value={state.space}
          onChange={(nextSpace) =>
            setState((s) => ({ ...s, space: nextSpace }))
          }
          fullWidth
        />

        <FormControlLabel
          label="Create Notifications on done"
          control={
            <Checkbox
              checked={state.createNotifications}
              onChange={(ev) => {
                const next = ev.target.checked;
                setState((s) => ({ ...s, createNotifications: next }));
              }}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          disabled={!state.space}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!state.space) {
              return Promise.resolve();
            }
            return createNewCheck(
              state.space.id,
              state.createNotifications
            ).then(() => {
              setState({
                space: null,
                createNotifications: false
              });
              onClose();
            });
          }}
          pending="Starting..."
        >
          Start
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const createForAllActiveSpaces = async (execute: boolean) => {
  const spaces = await getSpaces();
  const active = spaces.filter((s) => s.data.active);
  console.log(active);
  if (execute) {
    await Promise.all(active.map((s) => createNewCheck(s.id, true)));
  }
};

export const PageLinkCheckAppChecks: React.FC<Props> = () => {
  const sel = useSet<string>();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [ds, loading, error] = useAppChecks(100);
  const [spaces] = useSpaces();
  useErrorLogger(error);

  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [columnNames, setColumnNames] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const spacesById = useMemo(
    () => (spaces ? keyBy(spaces, (s) => s.id) : EMPTY_OBJ),
    [spaces]
  );

  const totalPending = (ds || []).reduce(
    (m, s) => m + s.data.counts.pending,
    0
  );

  const longRunningContainers = useMemo(() => {
    if (!ds) {
      return [];
    }
    const oneDayAgo = Date.now() - ONE_DAY;
    return ds.filter(
      (d) =>
        d.data.status === LinkCheckStatus.RUNNING &&
        d.data.startedAt &&
        d.data.startedAt.toMillis() < oneDayAgo
    );
  }, [ds]);

  return (
    <Section>
      {sel.set.size ? (
        <TableToolbar padding="dense" isOnCanvas>
          <TableToolbarSection>{sel.set.size} selected</TableToolbarSection>
          <TableToolbarSection>
            <ButtonWithPromise
              pending="Debugging..."
              variant="contained"
              color="primary"
              onClick={() => {
                return callFirebaseFunction('linkCheckV2-_debug', {
                  containerIds: [...sel.set]
                }).then((r) => {
                  console.log(r);
                  return r;
                });
              }}
            >
              Debug selection
            </ButtonWithPromise>
          </TableToolbarSection>
        </TableToolbar>
      ) : (
        <TableToolbar padding="dense" isOnCanvas>
          <TableToolbarSection>
            <KillSwitch service="linkCheckV2" label="Workers" />
          </TableToolbarSection>
          <TableToolbarSection>
            {!!totalPending && <div>{totalPending} pending</div>}

            <ColumnSelector
              value={columnNames}
              onChange={setColumnNames}
              columns={COLUMNS}
              short
            />
            <SafeExecuteButton
              variant="contained"
              color="secondary"
              onClick={createForAllActiveSpaces}
            >
              Create checks for all active spaces
            </SafeExecuteButton>
            <DeleteButton
              onDelete={() => batchDeleteDocs(longRunningContainers)}
              disabled={!longRunningContainers.length}
            >
              Delete long-running containers{' '}
              {!!longRunningContainers.length &&
                `(${longRunningContainers.length})`}
            </DeleteButton>

            <Button
              variant="contained"
              color="primary"
              onClick={openDialog}
              size="small"
            >
              Create check...
            </Button>
            <CreateDialog open={dialogOpen} onClose={closeDialog} />
          </TableToolbarSection>
        </TableToolbar>
      )}
      <Paper>
        {loading && <Loader height={500} />}
        {ds && (
          <RowsRenderer
            variant="contained"
            columns={columns}
            rows={ds}
            rowToKey={(d) => d.id}
            sorter={sorter || DEFAULT_SORTER}
            sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
            renderHead={true}
            onHeadClick={(c, d) =>
              setSort([SORTERS[c.key] || DEFAULT_SORTER, d])
            }
            chunkSize={30}
            rootMargin="400px"
            otherProps={{
              spacesById,
              selection: {
                selectOne: (id, nextState) =>
                  nextState ? sel.add(id) : sel.rm(id),
                selectNone: () => sel.clear(),
                selectAll: () => sel.replace(new Set(ds.map((d) => d.id))),
                selected: sel.set,
                areAllSelected: sel.set.size === ds.length
              }
            }}
            rowHeight={ROW_HEIGHTS.dense}
          />
        )}
      </Paper>
    </Section>
  );
};
