import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  IPotentialUser,
  IPotentialUserScanResult,
  IPotentialUserStatus,
  IPotentialUserPartner
} from '../../../domainTypes/potentialUser';
import { refreshTimestamp, store, batchUpdate } from '../../../services/db';
import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { FS } from '../../../versions';
import { IDENTITY } from '../../../domainTypes/emptyConstants';
import { ONE_MINUTE } from '../../../services/time';
import {
  getKnownPartnerForKey,
  getPartnerForUrl
} from '../../../services/partner';
import { toPercent } from '../../../components/Number';
import { chunk, compact, groupBy, trim } from 'lodash';
import { asHttps, removeTrailingSlash } from '../../../services/url';
import { wait } from '../../../helpers';

class FakeCollectionListener extends CollectionListener<IPotentialUser> {
  constructor() {
    super(
      // only here to satisfy the super class - it will never be used
      store().collection('DUMMY').where('x', '==', 'x')
    );
  }

  protected init() {
    import('./data.json').then((ds: any) => {
      this.error = undefined;
      this.initialising = false;
      this.value = (ds.data as any[]).map<Doc<IPotentialUser>>((d) => ({
        ...d,
        data: {
          ...d.data,
          createdAt: refreshTimestamp(d.data.createdAt || null),
          finishedAt: refreshTimestamp(d.data.finishedAt || null)
        }
      }));
      this.notify();
    });
  }
}

const LOCAL = false;

const toPotentialUserDoc = generateToDocFn<IPotentialUser>((d) => {
  const x: any = d;
  if (x.finishedAt && x.scan) {
    x.scan.finishedAt = x.finishedAt;
  }
  if (x.status === undefined) {
    x.status = x.paying ? 'PAYING' : 'LEAD';
  }
  if (x.status === null) {
    x.status = 'LEAD';
  }
  if (!d.tagIds) {
    d.tagIds = [];
  }
  if (!d.tags) {
    d.tags = [];
  }
  return d;
});

const getListener = createSingleCollectionListenerStore(() =>
  LOCAL
    ? new FakeCollectionListener()
    : new CollectionListener<IPotentialUser>(
        store().collection(FS.potentialUsers),
        toPotentialUserDoc
      )
);

export const usePotentialUsers = () => {
  return useCollectionListener(getListener());
};

export const updateStatus = (
  docId: string,
  status: IPotentialUserStatus | null
) => store().collection(FS.potentialUsers).doc(docId).update({ status });

export const getScanResult = <T extends unknown>(
  d: Doc<IPotentialUser>,
  fn: (r: IPotentialUserScanResult) => T
): T | null => {
  return d.data.scan && d.data.scan.result ? fn(d.data.scan.result) : null;
};

type ScoreFn = (res: IPotentialUserScanResult, d: IPotentialUser) => number;

const scorePartnerProducts = (
  partnerKey: string,
  scorePerProduct: number
): ScoreFn => (r) => {
  const p = r.partners.find((p) => p.partnerKey === partnerKey);
  return p ? Math.round(p.counts.products * scorePerProduct) : 0;
};

const toPartnerScorer = (partnerKey: string, scorePerProduct: number) => ({
  explanation: `${partnerKey} products`,
  score: scorePartnerProducts(partnerKey, scorePerProduct)
});

const ifMatch = (
  score: number,
  predicate: (res: IPotentialUserScanResult, d: IPotentialUser) => boolean
): ScoreFn => (d, res) => (predicate(d, res) ? score : 0);

const SCORERS: {
  explanation: string;
  score: ScoreFn;
}[] = [
  {
    explanation: 'scans under 8 minutes',
    score: ifMatch(500, (r) => r.duration < ONE_MINUTE * 10)
  },
  {
    explanation: 'time per page under 500ms',
    score: ifMatch(500, (r) => r.duration / r.counts.pages < 500)
  },
  {
    explanation: 'over 800 products',
    score: ifMatch(1000, (r) => r.counts.products >= 800)
  },
  {
    explanation: 'at least 3 known partners with 20+ products',
    score: ifMatch(2000, (r) => {
      let count = 0;
      for (let i = 0; i < r.partners.length; i++) {
        const p = r.partners[i];
        if (!!getKnownPartnerForKey(p.partnerKey) && p.counts.products >= 20) {
          count++;
          if (count >= 3) {
            return true;
          }
        }
      }
      return false;
    })
  },
  {
    explanation: 'at least 5 known partners with 20+ products',
    score: ifMatch(2500, (r) => {
      let count = 0;
      for (let i = 0; i < r.partners.length; i++) {
        const p = r.partners[i];
        if (!!getKnownPartnerForKey(p.partnerKey) && p.counts.products >= 20) {
          count++;
          if (count >= 3) {
            return true;
          }
        }
      }
      return false;
    })
  },
  {
    explanation: '(almost only) amazon',
    score: ifMatch(-2000, (r) => {
      const knownPs = r.partners.filter(
        (p) => !!getKnownPartnerForKey(p.partnerKey)
      );
      const amazon = knownPs.find((p) => p.partnerKey === 'amazon');
      if (amazon) {
        if (knownPs.length === 1) {
          return true;
        }
        const total = knownPs.reduce((m, p) => m + p.counts.links, 0);
        if (toPercent(amazon.counts.links, total) > 0.8) {
          return true;
        }
      }
      return false;
    })
  },
  {
    explanation: '(almost only) booking',
    score: ifMatch(-1500, (r) => {
      const knownPs = r.partners.filter(
        (p) => !!getKnownPartnerForKey(p.partnerKey)
      );
      const amazon = knownPs.find((p) => p.partnerKey === 'booking');
      if (amazon) {
        if (knownPs.length === 1) {
          return true;
        }
        const total = knownPs.reduce((m, p) => m + p.counts.links, 0);
        if (toPercent(amazon.counts.links, total) > 0.8) {
          return true;
        }
      }
      return false;
    })
  },
  toPartnerScorer('amazon', 0.5),
  toPartnerScorer('awin', 1),
  toPartnerScorer('booking', 1),
  toPartnerScorer('cj', 1),
  toPartnerScorer('gyg', 1),
  toPartnerScorer('klook', 0.5),
  toPartnerScorer('hotelscombined', 1),
  toPartnerScorer('partnerize', 0.6),
  toPartnerScorer('pepperjam', 1),
  toPartnerScorer('rstyle', 1),
  toPartnerScorer('shareasale', 1),
  toPartnerScorer('skimlinks', 1),
  toPartnerScorer('worldnomads', 0.6)
];

type Analysis = {
  explanations: { explanation: string; score: number }[];
  score: number;
};

export const analyze = (d: Doc<IPotentialUser>) => {
  return SCORERS.reduce<Analysis>(
    (m, s) => {
      const res = getScanResult(d, IDENTITY);
      if (res) {
        const score = s.score(res, d.data);
        if (score) {
          m.explanations.push({ explanation: s.explanation, score });
          m.score += score;
        }
      }
      return m;
    },
    { explanations: [], score: 0 }
  );
};

// could also go through analysis, but keep this, as it's a tad less wasteful
export const getScore = (d: Doc<IPotentialUser>) => {
  return SCORERS.reduce((m, s) => {
    const res = getScanResult(d, IDENTITY);
    const score = res ? s.score(res, d.data) : 0;
    return m + score;
  }, 0);
};

export const lookupNewPartnersAndUpdate = (docs: Doc<IPotentialUser>[]) => {
  const toUpdate: Doc<Partial<IPotentialUser>>[] = compact(
    docs.map((d) => {
      const { scan } = d.data;
      if (!scan) {
        return null;
      }
      if (!scan.result) {
        return null;
      }
      let updated = false;
      scan.result.partners.forEach((p) => {
        const known = getKnownPartnerForKey(p.partnerKey);
        if (known) {
          return;
        }
        const newPartner = getPartnerForUrl(p.partnerKey.replace(/_/g, '.'));
        if (!newPartner.known) {
          return;
        }
        p.partnerKey = newPartner.key;
        updated = true;
      });

      if (!updated) {
        return null;
      }

      // partners can match several domains. if we found a new one where this holds true,
      // we now need to bring them together. (e.g. Amazon -> amazon.com & amzn.to)
      const grouped = groupBy(scan.result.partners, (p) => p.partnerKey);
      const newPartners = Object.entries(grouped).map(([pK, ps]) => {
        if (ps.length === 1) {
          return ps[0];
        }
        return ps.reduce<IPotentialUserPartner>(
          (m, p) => {
            m.counts.pages += p.counts.pages;
            m.counts.products += p.counts.products;
            m.counts.links += p.counts.links;
            m.counts.cloaked += p.counts.cloaked;
            return m;
          },
          {
            partnerKey: pK,
            counts: {
              pages: 0,
              products: 0,
              links: 0,
              cloaked: 0
            }
          }
        );
      });
      scan.result.partners = newPartners;
      return {
        id: d.id,
        collection: FS.potentialUsers,
        data: {
          scan
        }
      };
    })
  );

  return batchUpdate(FS.potentialUsers, toUpdate);
};

export const rescanAll = async (docs: Doc<IPotentialUser>[]) => {
  const chunks = chunk(docs, 10);
  for (const c of chunks) {
    if (chunks.indexOf(c) !== 0) {
      await wait(2000);
    }
    await batchUpdate<IPotentialUser>(
      FS.potentialUsers,
      c.map((d) => ({
        id: d.id,
        collection: d.collection,
        data: { scan: null }
      }))
    );
  }
};

export const toSafeUrl = (url: string) =>
  removeTrailingSlash(asHttps(trim(url.toLowerCase())));
