import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { capitalize, keyBy, orderBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Loader } from '../../../../../components/Loader';
import {
  autoHeightRow,
  ITableHead,
  SortableHead
} from '../../../../../components/Table';
import { IDeployJobWithNo } from '../../../../../domainTypes/deployment';
import { css, styled } from '../../../../../emotion';
import { useSort } from '../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../layout/Canvas';
import { store, useMappedLoadingValue } from '../../../../../services/db';
import { FS } from '../../../../../versions';
import { BuildNumber } from '../../components/BuildNumber';
import { ServiceName } from '../../components/ServiceName';
import { Sha } from '../../components/Sha';
import { SERVICES, toJobDoc } from '../../service';
import { DeployDistance } from './DeployDistance';

type Props = {};

const formatTs = (ts: number) => moment(ts).format('YYYY/MM/DD HH:mm:ss');

const getJobsQuery = (env: string, name: string) =>
  store()
    .collection(FS._deploymentJobs)
    .where('env', '==', env)
    .where('name', '==', name)
    .where('status', '==', 'SUCCESS')
    .orderBy('startedAt', 'desc')
    .limit(1);

const useSuccessfulJob = (env: string, name: string) => {
  return {
    name,
    data: useMappedLoadingValue(useCollection(getJobsQuery(env, name)), (s) => {
      const d = s.docs[0];
      return d ? toJobDoc(d).data : undefined;
    })
  };
};

const useSuccessfulJobs = (env: string) => {
  const jobs = [
    useSuccessfulJob(env, SERVICES[0]),
    useSuccessfulJob(env, SERVICES[1]),
    useSuccessfulJob(env, SERVICES[2]),
    useSuccessfulJob(env, SERVICES[3]),
    useSuccessfulJob(env, SERVICES[4]),
    useSuccessfulJob(env, SERVICES[5]),
    useSuccessfulJob(env, SERVICES[6]),
    useSuccessfulJob(env, SERVICES[7]),
    useSuccessfulJob(env, SERVICES[8]),
    useSuccessfulJob(env, SERVICES[9]),
    useSuccessfulJob(env, SERVICES[10]),
    useSuccessfulJob(env, SERVICES[11]),
    useSuccessfulJob(env, SERVICES[12]),
    useSuccessfulJob(env, SERVICES[13]),
    useSuccessfulJob(env, SERVICES[14]),
    useSuccessfulJob(env, SERVICES[15]),
    useSuccessfulJob(env, SERVICES[16]),
    useSuccessfulJob(env, SERVICES[17]),
    useSuccessfulJob(env, SERVICES[18]),
    useSuccessfulJob(env, SERVICES[19]),
    useSuccessfulJob(env, SERVICES[20]),
    useSuccessfulJob(env, SERVICES[21]),
    useSuccessfulJob(env, SERVICES[22]),
    useSuccessfulJob(env, SERVICES[23]),
    useSuccessfulJob(env, SERVICES[24]),
    useSuccessfulJob(env, SERVICES[25]),
    useSuccessfulJob(env, SERVICES[26]),
    useSuccessfulJob(env, SERVICES[27]),
    useSuccessfulJob(env, SERVICES[28]),
    useSuccessfulJob(env, SERVICES[29]),
    useSuccessfulJob(env, SERVICES[30]),
    useSuccessfulJob(env, SERVICES[31]),
    useSuccessfulJob(env, SERVICES[32]),
    useSuccessfulJob(env, SERVICES[33]),
    useSuccessfulJob(env, SERVICES[34]),
    useSuccessfulJob(env, SERVICES[35]),
    useSuccessfulJob(env, SERVICES[36]),
    useSuccessfulJob(env, SERVICES[37]),
    useSuccessfulJob(env, SERVICES[38]),
    useSuccessfulJob(env, SERVICES[39]),
    useSuccessfulJob(env, SERVICES[40]),
    useSuccessfulJob(env, SERVICES[41]),
    useSuccessfulJob(env, SERVICES[42]),
    useSuccessfulJob(env, SERVICES[43]),
    useSuccessfulJob(env, SERVICES[44]),
    useSuccessfulJob(env, SERVICES[45]),
    useSuccessfulJob(env, SERVICES[46]),
    useSuccessfulJob(env, SERVICES[47]),
    useSuccessfulJob(env, SERVICES[48]),
    useSuccessfulJob(env, SERVICES[49]),
    useSuccessfulJob(env, SERVICES[50]),
    useSuccessfulJob(env, SERVICES[51]),
    useSuccessfulJob(env, SERVICES[52]),
    useSuccessfulJob(env, SERVICES[53]),
    useSuccessfulJob(env, SERVICES[54]),
    useSuccessfulJob(env, SERVICES[55]),
    useSuccessfulJob(env, SERVICES[56]),
    useSuccessfulJob(env, SERVICES[57]),
    useSuccessfulJob(env, SERVICES[58]),
    useSuccessfulJob(env, SERVICES[59]),
    useSuccessfulJob(env, SERVICES[60])
  ];

  if (SERVICES.length !== jobs.length) {
    console.warn('Services mismatch - list will not render correctly', {
      services: SERVICES.length,
      jobs: jobs.length
    });
  }

  return {
    loading: !!jobs.find((j) => j.data[1]),
    jobs: jobs
  };
};

type SuccessfulJobs = ReturnType<typeof useSuccessfulJobs>;
type SuccessfulJob = ReturnType<typeof useSuccessfulJob>;

type SortKey = 'name' | 'buildNo' | 'finishedAt' | 'sha' | 'distance';

const HEADS: ITableHead<SortKey>[] = [
  {
    key: 'name',
    label: 'Name',
    align: 'left',
    sortable: true,
    defaultDirection: 'desc'
  },
  {
    key: 'buildNo',
    label: 'Build',
    align: 'left',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'finishedAt',
    label: 'Date',
    align: 'left',
    sortable: true,
    defaultDirection: 'desc'
  },
  {
    key: 'sha',
    label: 'SHA',
    align: 'left',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'distance',
    label: '',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  }
];

const ifPresent = (
  j: IDeployJobWithNo | void,
  mapper: (j: IDeployJobWithNo) => React.ReactNode
) => {
  return j ? mapper(j) : '';
};

const getDistance = (x: SuccessfulJob, y: SuccessfulJob) => {
  const a = x.data[0];
  const b = y.data[0];
  if (!a || !b) {
    return null;
  }
  if (a.sha === b.sha) {
    return 0;
  }
  if (!a.finishedAt && !b.finishedAt) {
    return 0;
  }
  if (!a.finishedAt) {
    return -Infinity;
  }
  if (!b.finishedAt) {
    return Infinity;
  }
  return a.finishedAt - b.finishedAt;
};

const HOVER_STYLE: React.CSSProperties = { backgroundColor: '#ebebeb' };

const Jobs: React.FC<{
  env: string;
  data: SuccessfulJobs;
  other: SuccessfulJobs;
  highlighted: string | null;
  onHighlight: (jobName: string | null) => void;
}> = ({ env, data, other, highlighted, onHighlight }) => {
  const { sortBy, direction, setSort } = useSort<SortKey>('finishedAt', 'desc');

  console.log(env, data);

  const autoHeight = autoHeightRow();

  const sortedJobs = orderBy(
    data.jobs,
    (d) => {
      if (sortBy === 'name' || data.loading) {
        return d.name;
      }
      if (sortBy === 'finishedAt') {
        return d.data[0]
          ? d.data[0].finishedAt
          : direction === 'asc'
          ? Infinity
          : -Infinity;
      }
    },
    direction
  );

  const otherJobsByName = keyBy(other.jobs, (o) => o.name);

  return (
    <>
      <CanvasBar>{capitalize(env)}</CanvasBar>
      <Card>
        <Table size="small">
          <TableHead>
            <TableRow classes={autoHeight}>
              {HEADS.map((h) => (
                <SortableHead
                  key={h.key}
                  head={h}
                  setSort={setSort}
                  currentSortBy={sortBy}
                  currentDirection={direction}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.loading &&
              sortedJobs.map((j) => {
                const distance = getDistance(j, otherJobsByName[j.name]);
                return (
                  <TableRow
                    key={j.name}
                    onMouseEnter={() => onHighlight(j.name)}
                    onMouseLeave={() => onHighlight(null)}
                    style={j.name === highlighted ? HOVER_STYLE : undefined}
                  >
                    <TableCell align="left">
                      <ServiceName env={env} name={j.name} />
                      <Typography
                        component="span"
                        className={css((t) => ({
                          fontSize: t.custom.fontSize.s,
                          color: t.palette.text.secondary
                        }))}
                      >
                        {ifPresent(j.data[0], (t) => `(${t.no})`)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {ifPresent(j.data[0], (t) => (
                        <BuildNumber job={t} />
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      {ifPresent(j.data[0], (t) =>
                        t.finishedAt ? formatTs(t.finishedAt) : ''
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {ifPresent(j.data[0], (t) => (
                        <Sha sha={t.sha} />
                      ))}
                    </TableCell>
                    <TableCell>
                      {distance !== null && <DeployDistance d={distance} />}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {data.loading && <Loader height={700} />}
      </Card>
    </>
  );
};

const InfoPopover = styled('div')`
  position: fixed;
  bottom: 12px;
  display: flex;
  justify-content: center;
  width: calc(100% - 256px);
`;

const DeployComparisonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeployComparison = ({
  x,
  y
}: {
  x: SuccessfulJob | undefined;
  y: SuccessfulJob | undefined;
}) => {
  if (!x || !y) {
    return null;
  }
  const d = getDistance(y, x);
  if (d === null) {
    return null;
  }

  const duration = moment.duration(d, 'ms');

  return (
    <Card
      className={css((t) => ({
        width: '800px',
        padding: `${t.spacing(1)}px ${t.spacing(3)}px`,
        backgroundColor: '#18202c',
        border: '2px solid white',
        color: '#fff',
        opacity: 0.93
      }))}
    >
      <DeployComparisonContainer>
        <div style={{ flex: 1 }}>{x.name}</div>
        <div style={{ flex: 2, display: 'flex', justifyContent: 'center' }}>
          <div>
            {ifPresent(x.data[0], (t) =>
              t.finishedAt ? formatTs(t.finishedAt) : ''
            )}
          </div>
          <div style={{ margin: '0 8px' }}>
            <DeployDistance d={d} />
          </div>
          <div>
            {ifPresent(y.data[0], (t) =>
              t.finishedAt ? formatTs(t.finishedAt) : ''
            )}
          </div>
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>
          {d === 0
            ? 'up-to-date'
            : `${duration.humanize()} ${d > 0 ? 'ahead' : 'behind'}`}
        </div>
      </DeployComparisonContainer>
    </Card>
  );
};

export const PageDeploymentServices: React.FC<Props> = () => {
  const dev = useSuccessfulJobs('dev');
  const prod = useSuccessfulJobs('prod');
  const [highlighted, setHighlighted] = useState<string | null>(null);
  return (
    <>
      <Grid container alignItems="stretch" spacing={2}>
        <Grid item md={6} sm={12}>
          <Jobs
            env="dev"
            data={dev}
            other={prod}
            highlighted={highlighted}
            onHighlight={setHighlighted}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <Jobs
            env="prod"
            data={prod}
            other={dev}
            highlighted={highlighted}
            onHighlight={setHighlighted}
          />
        </Grid>
      </Grid>
      {highlighted && (
        <InfoPopover>
          <DeployComparison
            x={dev.jobs.find((j) => j.name === highlighted)}
            y={prod.jobs.find((j) => j.name === highlighted)}
          />
        </InfoPopover>
      )}
    </>
  );
};
