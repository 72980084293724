import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import React from 'react';

export const CheckboxSimple = ({
  label,
  checked,
  onChange,
  color
}: {
  checked: boolean;
  onChange: (nextChecked: boolean) => void;
  label?: React.ReactNode;
  color?: CheckboxProps['color'];
}) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={checked}
          color={color}
          onChange={(ev) => onChange(ev.target.checked)}
        />
      }
    />
  );
};
