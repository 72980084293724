import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../components/AdditionalActionsMenu';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { LinkExternal } from '../../../../../components/LinkExternal';
import { InlineLoader, Loader } from '../../../../../components/Loader';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { Doc, generateToDocFn } from '../../../../../domainTypes/document';
import {
  ILinkCheckReportNotificationRequest,
  ILinkCheckStandaloneContainer
} from '../../../../../domainTypes/linkCheckerStandalone';
import * as ENVS from '../../../../../env.json';
import { CheckStatusChip } from '../../../../../features/LinkCheck/components/CheckStatusChip';
import { ScanStatus } from '../../../../../features/Scan/components/ScanStatus';
import { ScanObserver } from '../../../../../features/Scan/pages/ProductScanDetail/ScanObserver';
import { useScan } from '../../../../../features/Scan/services';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Section } from '../../../../../layout/Section';
import {
  rdb,
  removeDoc,
  store,
  toRef,
  useMappedLoadingValue
} from '../../../../../services/db';
import {
  useScansBySpaceId,
  useScanTracker
} from '../../../../../services/scan';
import {
  formatDate,
  formatDatePrecise,
  msToMinsAndSecs,
  toMoment
} from '../../../../../services/time';
import { FS, RDB } from '../../../../../versions';
import { KillSwitch } from '../../../../components/KillSwitch';
import { toFirestoreConsole } from '../../../../services/firebase';

const toStandloneCheckDoc = generateToDocFn<ILinkCheckStandaloneContainer>();
const toReportNotificationRequestDoc = generateToDocFn<
  ILinkCheckReportNotificationRequest
>();

const SPACE_ID = 'LINK_CHECKER_STANDALONE';

const useStandaloneChecks = (limit: number) => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.linkCheckStandalone)
        .orderBy('queuedAt', 'desc')
        .limit(limit)
    ),
    (s) => s.docs.map(toStandloneCheckDoc)
  );
};

const useNotificationRequest = (id: string) => {
  return useMappedLoadingValue(
    useDocument(
      store()
        .collection(FS.linkCheckerStandaloneReportNotificationRequests)
        .doc(id)
    ),
    (s) => (s.exists ? toReportNotificationRequestDoc(s) : null)
  );
};

type Props = {};

type Data = Doc<ILinkCheckStandaloneContainer>;
type ColumnName =
  | 'url'
  | 'status'
  | 'emails'
  | 'pages'
  | 'total'
  | 'pending'
  | 'ok'
  | 'warnings'
  | 'errors'
  | 'unknown'
  | 'blocked'
  | 'queuedAt'
  | 'startedAt'
  | 'finishedAt'
  | 'duration'
  | 'actions';

type Column = IColumn<Data, ColumnName>;

const Emails = ({ containerId }: { containerId: string }) => {
  const [d, loading] = useNotificationRequest(containerId);
  if (loading) {
    return <InlineLoader />;
  }
  if (!d) {
    return null;
  }
  return <div>{d.data.emailAddresses.join(', ')}</div>;
};

const Pages = ({ containerId }: { containerId: string }) => {
  const [d, loading] = useScanTracker(SPACE_ID, containerId);
  if (loading) {
    return <InlineLoader />;
  }
  if (!d || !d.data || !d.data.pages) {
    return null;
  }
  return <div>{Object.keys(d.data.pages).length}</div>;
};

const ScanInnerDialog = ({
  containerId,
  onClose
}: {
  containerId: string;
  onClose: () => void;
}) => {
  const [scan, loading] = useScan(SPACE_ID, containerId);
  return (
    <>
      <DialogTitle>
        {!!scan && scan.data.targets.map((t) => t.url).join(', ')}
      </DialogTitle>
      <DialogContent>
        {loading && <Loader height="100%" />}
        {!scan && null}
        {scan && <ScanObserver userId="xxx" scan={scan} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
};

const Actions = ({ d }: { d: Data }) => {
  const options: AdditionActionsMenuOption[] = useMemo(() => {
    return [
      {
        key: 'openInLinkChecker',
        label: 'Open in Link Checker',
        externalHref: `${ENVS.domains.linkChecker.public}/results?id=${d.id}`
      },
      {
        key: 'openInFirestore',
        label: 'Open in Firestore console',
        externalHref: toFirestoreConsole(FS.linkCheckApp, d.id)
      },
      {
        key: 'openScan',
        label: 'Open Product Scan in dialog',
        dialog: ({ onClose }) => (
          <ScanInnerDialog containerId={d.id} onClose={onClose} />
        )
      },
      {
        key: 'remove',
        label: 'Remove',
        onClick: () => {
          return Promise.all([
            removeDoc(d),
            rdb().ref(toRef(RDB.productScans, SPACE_ID, d.id)).remove()
          ]);
        }
      }
    ];
  }, [d]);
  return <AdditionalActionsMenu options={options} />;
};

const COLUMNS: Column[] = [
  {
    key: 'url',
    head: () => 'URL',
    cell: (d) => {
      return <LinkExternal href={d.data.title} />;
    },
    sortable: true,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'status',
    head: () => '',
    alternateHead: () => 'Status',
    cell: (d) => <CheckStatusChip status={d.data.status} />,
    sortable: true,
    align: 'center',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'emails',
    head: () => 'Emails',
    cell: (d) => {
      return <Emails containerId={d.id} />;
    },
    sortable: false,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'pages',
    head: () => 'Pages',
    cell: (d) => {
      return <Pages containerId={d.id} />;
    },
    sortable: false,
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'total',
    head: () => 'Total',
    cell: (d) => d.data.counts.total,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'pending',
    head: () => 'Pending',
    cell: (d) => d.data.counts.pending,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'ok',
    head: () => 'OK',
    cell: (d) => d.data.counts.results.ok,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'warnings',
    head: () => 'Warnings',
    cell: (d) => d.data.counts.results.warnings,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'errors',
    head: () => 'Errors',
    cell: (d) => d.data.counts.results.errors,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'unknown',
    head: () => 'Unknown',
    cell: (d) => d.data.counts.results.unknown,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'blocked',
    head: () => 'Blocked',
    cell: (d) => d.data.counts.results.blocked,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'queuedAt',
    head: () => 'Queued At',
    cell: (d) => formatDatePrecise(d.data.queuedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'startedAt',
    head: () => 'Started At',
    cell: (d) => d.data.startedAt && formatDatePrecise(d.data.startedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'finishedAt',
    head: () => 'Finished At',
    cell: (d) => d.data.finishedAt && formatDatePrecise(d.data.finishedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'duration',
    head: () => 'Duration',
    cell: (d) =>
      d.data.startedAt &&
      d.data.finishedAt &&
      msToMinsAndSecs(
        d.data.finishedAt.toMillis() - d.data.startedAt.toMillis()
      ),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    sortable: false,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Data> = {
  url: {
    key: 'url',
    items: { sort: (d) => d.data.title, dir: 'asc' }
  },
  total: {
    key: 'total',
    items: { sort: (d) => d.data.counts.total, dir: 'desc' }
  },
  pending: {
    key: 'pending',
    items: { sort: (d) => d.data.counts.pending, dir: 'desc' }
  },
  ok: {
    key: 'ok',
    items: { sort: (d) => d.data.counts.results.ok, dir: 'desc' }
  },
  warnings: {
    key: 'warnings',
    items: { sort: (d) => d.data.counts.results.warnings, dir: 'desc' }
  },
  errors: {
    key: 'errors',
    items: { sort: (d) => d.data.counts.results.errors, dir: 'desc' }
  },
  unknown: {
    key: 'unknown',
    items: { sort: (d) => d.data.counts.results.unknown, dir: 'desc' }
  },
  blocked: {
    key: 'blocked',
    items: { sort: (d) => d.data.counts.results.blocked, dir: 'desc' }
  },
  queuedAt: {
    key: 'queuedAt',
    items: { sort: (d) => d.data.queuedAt.toMillis(), dir: 'desc' }
  },
  startedAt: {
    key: 'startedAt',
    items: { sort: (d) => d.data.startedAt?.toMillis() || 0, dir: 'desc' }
  },
  finishedAt: {
    key: 'finishedAt',
    items: { sort: (d) => d.data.finishedAt?.toMillis() || 0, dir: 'desc' }
  },
  duration: {
    key: 'duration',
    items: {
      sort: (d) =>
        d.data.startedAt && d.data.finishedAt
          ? d.data.finishedAt.toMillis() - d.data.startedAt.toMillis()
          : 0,
      dir: 'desc'
    }
  }
};

const DEFAULT_SORTER = SORTERS.queuedAt;

const DEFAULT_COLUMNS: ColumnName[] = [
  'url',
  'status',
  'emails',
  'pages',
  'total',
  'pending',
  'ok',
  'warnings',
  'errors',
  'unknown',
  'finishedAt',
  'actions'
];

const LIMIT = 50;
const SCAN_LIMIT = 5;

const Scans = () => {
  const [docs, loading, error] = useScansBySpaceId(SPACE_ID, SCAN_LIMIT);
  if (error) {
    return null;
  }

  console.log(docs);

  const sortedDocs = docs
    ? docs.sort(
        (a, b) => b.data.createdAt.toMillis() - a.data.createdAt.toMillis()
      )
    : [];
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Domain or Page</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="center">Results</TableCell>
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {sortedDocs.map((doc) => {
              return (
                <TableRow key={doc.id}>
                  <TableCell>
                    {doc.data.targets.map((t) => t.url).join(', ')}
                  </TableCell>
                  <TableCell>
                    <ScanStatus status={doc.data.status} />
                  </TableCell>
                  <TableCell>
                    {formatDate(toMoment(doc.data.createdAt))}
                  </TableCell>
                  <TableCell align="center">
                    <Button>View results</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {loading && <Loader height="200px" />}
    </>
  );
};

export const PageLinkCheckStandaloneChecks: React.FC<Props> = () => {
  const [ds, loading, error] = useStandaloneChecks(LIMIT);
  useErrorLogger(error);

  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [columnNames, setColumnNames] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const totalPending = (ds || []).reduce(
    (m, s) => m + s.data.counts.pending,
    0
  );

  return (
    <>
      <Section>
        <TableToolbar padding="dense" isOnCanvas>
          <TableToolbarSection>
            <KillSwitch service="linkCheckV2" label="Workers" />
          </TableToolbarSection>
          <TableToolbarSection>
            {!!totalPending && <div>{totalPending} pending</div>}
            <ColumnSelector
              value={columnNames}
              onChange={setColumnNames}
              columns={COLUMNS}
              short
            />
          </TableToolbarSection>
        </TableToolbar>
        <Paper>
          {loading && <Loader height={500} />}
          {ds && (
            <RowsRenderer
              variant="contained"
              columns={columns}
              rows={ds}
              rowToKey={(d) => d.id}
              sorter={sorter || DEFAULT_SORTER}
              sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
              renderHead={true}
              onHeadClick={(c, d) =>
                setSort([SORTERS[c.key] || DEFAULT_SORTER, d])
              }
              chunkSize={30}
              rootMargin="400px"
              otherProps={undefined}
              rowHeight={ROW_HEIGHTS.dense}
            />
          )}
        </Paper>
      </Section>
      <Section>
        <CanvasBar>Past {SCAN_LIMIT} scans</CanvasBar>
        <Paper>
          <Scans />
        </Paper>
      </Section>
    </>
  );
};
