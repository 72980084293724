import { Switch } from '@material-ui/core';
import { SortDirection } from '@material-ui/core/TableCell';
import { orderBy, uniq } from 'lodash';
import React from 'react';
import { Loader } from '../../../../components/Loader';
import { PartnerLogo } from '../../../../components/PartnerLogo';
import { IColumn } from '../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../components/Table/VirtualizedSortable';
import { IPartner } from '../../../../domainTypes/partners';
import { getAutoLabellingPartners } from '../../../../services/partner';

const HEIGHT = 200;

type Props = {
  value: string[] | void;
  loading: boolean;
  onChange: (nextValue: string[]) => void;
};

type RowData = {
  partner: IPartner;
  enabled: boolean;
};

type SortKey = 'partner' | 'enabled';

const sortFn = (rows: RowData[], sortBy: SortKey, direction: SortDirection) =>
  orderBy(
    rows,
    (r) => {
      if (sortBy === 'enabled') {
        return r.enabled;
      }
      return r.partner.name;
    },
    direction
  );

export const AutoLabellingList: React.FC<Props> = ({
  value,
  loading,
  onChange
}) => {
  if (loading || !value) {
    return <Loader height={HEIGHT} />;
  }
  const partners = getAutoLabellingPartners();
  const rows = partners.map((partner) => ({
    partner,
    enabled: value.indexOf(partner.key) !== -1
  }));

  // this needs to be super tight and eventually extracted to a service
  const setAutoLabelling = (partner: IPartner, enabled: boolean) => {
    let nextValue: string[] = [];
    if (enabled) {
      nextValue = uniq([...value, partner.key]);
    } else {
      nextValue = value.filter((c) => partner.key !== c);
    }
    onChange(nextValue);
  };

  const COLUMNS: IColumn<RowData, SortKey>[] = [
    {
      key: 'partner',
      head: () => 'Partner',
      cell: (d) => (
        <div>
          <PartnerLogo partner={d.partner} /> {d.partner.name}
        </div>
      ),
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 100,
      flexGrow: 3
    },
    {
      key: 'enabled',
      head: () => 'Status',
      cell: (d) => (
        <Switch
          checked={d.enabled}
          onChange={(ev) => setAutoLabelling(d.partner, ev.target.checked)}
        />
      ),
      align: 'right',
      sortable: true,
      defaultDirection: 'asc',
      width: 100,
      flexGrow: 1
    }
  ];

  return (
    <VirtualizedSortableTable
      rows={rows}
      columns={COLUMNS}
      cellProps={undefined}
      height={HEIGHT}
      margin="normal"
      sortFn={sortFn}
      initialSortColumn={COLUMNS[0]}
    />
  );
};
