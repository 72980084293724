import firebase from 'firebase/app';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import {
  DeployStatus,
  IDeployBuildWithNo,
  IDeployJobWithNo
} from '../../../domainTypes/deployment';
import { toDoc } from '../../../domainTypes/document';
import { store, useMappedLoadingValue } from '../../../services/db';
import { FS } from '../../../versions';

export const toBuildDoc = (
  d:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
) => toDoc<IDeployBuildWithNo>(d);

export const toJobDoc = (
  d:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
) => toDoc<IDeployJobWithNo>(d);

export const useBuild = (buildId: string) => {
  return useMappedLoadingValue(
    useDocument(store().collection(FS._deploymentBuilds).doc(buildId)),
    (s) => (s.exists ? toBuildDoc(s) : null)
  );
};

export const useJob = (jobId: string) => {
  return useMappedLoadingValue(
    useDocument(store().collection(FS._deploymentJobs).doc(jobId)),
    (s) => (s.exists ? toJobDoc(s) : null)
  );
};

export const useServiceHistory = (env: string, name: string, limit: number) => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS._deploymentJobs)
        .where('env', '==', env)
        .where('name', '==', name)
        .orderBy('startedAt', 'desc')
        .limit(limit)
    ),
    (s) => s.docs.map(toJobDoc)
  );
};

export const SERVICES = [
  'App',
  'CF - affiliate',
  'CF - admin',
  'CF - amazonApiKeys',
  'CF - analysis',
  'CF - analytics',
  'CF - analytics_v2',
  'CF - api',
  'CF - apispec',
  'CF - billing',
  'CF - bq',
  'CF - cron',
  'CF - dataMigrations',
  'CF - denormalization',
  'CF - events',
  'CF - exchangeRates',
  'CF - files',
  'CF - labelRules',
  'CF - linkCheckV2',
  'CF - linkCheck_app',
  'CF - linkChecker_standalone',
  'CF - link_shortener',
  'CF - mail',
  'CF - monitoring',
  'CF - notifications',
  'CF - pages',
  'CF - pageSummaries',
  'CF - pg',
  'CF - pgDenormalization',
  'CF - playground',
  'CF - potentialUsers',
  'CF - products',
  'CF - pubsub',
  'CF - redirect',
  'CF - redis',
  'CF - reporting',
  'CF - sales',
  'CF - schedules',
  'CF - scraping',
  'CF - screenshot',
  'CF - secret',
  'CF - send',
  'CF - sendeu',
  'CF - space',
  'CF - storage',
  'CF - tags',
  'CF - tools',
  'CF - tracking_v5',
  'CF - trackingConfig',
  'CF - trackingConfig_v2',
  'CF - user',
  'CF - userEngagement',
  'CF - deployment',
  'Injectable script',
  'Cloud Function Scheduler',
  'Website',
  'Admin App',
  'Firestore',
  'Realtime Database',
  'Cloud Storage',
  'Chrome Extension',
  'Go routes'
];

export const statusToVariant = (
  status: DeployStatus
): 'success' | 'pending' | 'error' | 'secondary' => {
  if (status === 'SUCCESS') {
    return 'success';
  }
  if (status === 'PENDING') {
    return 'pending';
  }
  if (status === 'ERROR') {
    return 'error';
  }
  return 'secondary';
};
