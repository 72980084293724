import { Card, IconButton, MenuItem, Select } from '@material-ui/core';
import { compact, keyBy, orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { DownloadCloud as IconDownload } from 'react-feather';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Loader } from '../../../../../components/Loader';
import { PartnerLogo } from '../../../../../components/PartnerLogo';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../domainTypes/document';
import { PARTNERS } from '../../../../../domainTypes/partners';
import { IReport } from '../../../../../domainTypes/performance';
import { ISpace } from '../../../../../domainTypes/space';
import { PreviewButton } from '../../../../../features/PerformanceNew/components/ReportPreviewButton';
import { REPORT_HANDLERS } from '../../../../../features/PerformanceNew/services/handlers';
import { toReportDoc } from '../../../../../features/PerformanceNew/services/report';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { SortDirection } from '../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Section } from '../../../../../layout/Section';
import { useStringQueryParam } from '../../../../../routes';
import { store, useMappedLoadingValue } from '../../../../../services/db';
import { getKnownPartnerForKey } from '../../../../../services/partner';
import { getActiveDomainUrls } from '../../../../../services/space';
import { downloadFromCloudStorage } from '../../../../../services/storage';
import {
  formatDatePrecise,
  formatDateRange,
  HUMAN_DATE
} from '../../../../../services/time';
import { FS } from '../../../../../versions';
import { SpaceLink } from '../../../../components/SpaceLink';
import { useSpaces } from '../../../../services/space';

type Props = {};

type Data = {
  space: ISpace;
  report: Doc<IReport>;
};

const LIMIT = 100;

const useAllReports = (partnerFilter?: string) => {
  const collection = store().collection(FS.reports);
  const query = partnerFilter
    ? collection.where('partnerKey', '==', partnerFilter)
    : collection;

  return useMappedLoadingValue(
    useCollection(query.orderBy('createdAt', 'desc').limit(LIMIT)),
    (s) => s.docs.map(toReportDoc)
  );
};

const useAllReportsWithSpaces = (
  partnerFilter?: string
): [void | Data[], boolean, any] => {
  const reps = useAllReports(partnerFilter);
  const spaces = useSpaces();

  if (reps[0] && spaces[0]) {
    const spacesById = keyBy(spaces[0], (s) => s.id);
    const data = reps[0].map((report) => ({
      space: spacesById[report.data.spaceId].data,
      report
    }));
    return [data, false, undefined];
  }

  const error = reps[2] || spaces[2];

  return error ? [undefined, false, error] : [undefined, true, undefined];
};

const HEIGHT = 600;

type SortKey =
  | 'space'
  | 'partnerKey'
  | 'range'
  | 'salesCount'
  | 'uploadedAt'
  | 'actions';

const Actions = ({ d }: { d: Data }) => {
  return (
    <>
      <IconButton
        onClick={() =>
          downloadFromCloudStorage(d.report.data.source.storagePath)
        }
      >
        <IconDownload size={20} />
      </IconButton>
      <PreviewButton
        d={d.report}
        tz={d.space.config.tz || 'UTC'}
        currency={'USD'}
      />
    </>
  );
};

const COLUMNS: IColumn<Data, SortKey>[] = [
  {
    key: 'space',
    head: () => 'Space',
    cell: (d) => <SpaceLink space={d.space} />,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 140,
    flexGrow: 1
  },
  {
    key: 'partnerKey',
    head: () => 'Platform',
    cell: (d) => {
      const partner = PARTNERS.find((p) => p.key === d.report.data.partnerKey);
      if (!partner) {
        return d.report.data.partnerKey;
      }
      return <PartnerLogo partner={partner} />;
    },
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'range',
    head: () => 'Report timeframe',
    cell: (d) => formatDateRange(d.report.data.start, d.report.data.end),
    align: 'left',
    sortable: false,
    defaultDirection: 'desc',
    width: 140,
    flexGrow: 2
  },
  {
    key: 'salesCount',
    head: () => '# of sales',
    cell: (d) => d.report.data.salesCount,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'uploadedAt',
    head: () => 'Uploaded at',
    cell: (d) => formatDatePrecise(d.report.data.createdAt, HUMAN_DATE),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d) => <Actions d={d} />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  }
];

const sortFn = (rows: Data[], sortBy: SortKey, direction: SortDirection) => {
  return orderBy(
    rows,
    (r) => {
      if (sortBy === 'space') {
        return getActiveDomainUrls(r.space);
      }
      if (sortBy === 'salesCount') {
        return r.report.data.salesCount;
      }
      if (sortBy === 'uploadedAt') {
        return r.report.data.createdAt.toMillis();
      }
      return r.report.data.partnerKey;
    },
    direction
  );
};

const PartnerSelector = ({
  value,
  onChange
}: {
  value: string;
  onChange: (nextValue: string) => void;
}) => {
  const options = useMemo(() => {
    const partners = compact(
      REPORT_HANDLERS.map((h) => {
        const partner = getKnownPartnerForKey(h.partnerKey);
        if (!partner) {
          // can never happen
          return null;
        }
        return {
          value: partner.key,
          label: partner.name
        };
      })
    );

    return [
      {
        value: 'all',
        label: 'All'
      },
      ...partners
    ];
  }, []);

  return (
    <Select
      autoWidth
      variant="outlined"
      value={value || 'all'}
      onChange={(ev) => {
        const nextValue = ev.target.value as string;
        onChange(nextValue === 'all' ? '' : nextValue);
      }}
    >
      {options.map((o, i) => (
        <MenuItem key={i} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export const PagePerformanceReports: React.FC<Props> = () => {
  const [partnerKey, setPartnerKey] = useStringQueryParam('partner', '');
  const [reports, loading, error] = useAllReportsWithSpaces(partnerKey);
  useErrorLogger(error);

  return (
    <>
      <Section>
        <CanvasBar>
          <div>{LIMIT} most recent uploads</div>
          <PartnerSelector value={partnerKey} onChange={setPartnerKey} />
        </CanvasBar>
        <Card>
          {loading || !reports ? (
            <Loader height={HEIGHT} />
          ) : (
            <>
              <VirtualizedSortableTable
                rows={reports}
                columns={COLUMNS}
                cellProps={undefined}
                height={HEIGHT}
                margin="normal"
                sortFn={sortFn}
                initialSortColumn={COLUMNS[4]}
              />
            </>
          )}
        </Card>
      </Section>
    </>
  );
};
