import React from 'react';
import { ITagCategory, ITagPrototype } from '../../../domainTypes/tag';
import { now } from '../../../services/time';
import { Color } from '../../../domainTypes/colors';
import { TagPrototypeFormDialog } from '../PrototypeForm';
import { editTagPrototype } from '../service';

type Values = {
  name: string;
  description: string;
  color: Color;
  category: ITagCategory;
};

export const TagPrototypeEditDialog = ({
  tag,
  open,
  onClose,
  onDone,
  userId
}: {
  tag: ITagPrototype;
  open: boolean;
  onClose: () => void;
  onDone: (tag: ITagPrototype) => void;
  userId: string;
}) => {
  const initialValues: Values = {
    name: tag.name,
    description: tag.description,
    color: tag.color,
    category: tag.category
  };

  const onSubmit = async (vs: Values) => {
    const nextTag: ITagPrototype = {
      ...tag,
      ...vs,
      editedBy: userId,
      editedAt: now()
    };
    await editTagPrototype(nextTag);
    onDone(nextTag);
  };

  return (
    <TagPrototypeFormDialog
      open={open}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      title="Edit tag"
      label="Edit"
      submitComponent="Editing..."
    />
  );
};
