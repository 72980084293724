import { Avatar, Paper } from '@material-ui/core';
import { compact, keyBy, orderBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { Loader } from '../../../../../components/Loader';
import { SearchInput } from '../../../../../components/SearchInput';
import { TableToolbar } from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { ISpace } from '../../../../../domainTypes/space';
import { IFbUserRecord, IUser } from '../../../../../domainTypes/user';
import { styled } from '../../../../../emotion';
import { usePromise } from '../../../../../hooks/usePromise';
import { SortDirection } from '../../../../../hooks/useSort';
import { Section } from '../../../../../layout/Section';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { HUMAN_DATE } from '../../../../../services/time';
import { SpaceLink } from '../../../../components/SpaceLink';
import { useSpaces } from '../../../../services/space';
import { useUsers } from '../../../../services/user';

let userRecords: IFbUserRecord[] | null = null;
const getUserRecords = async () => {
  if (!userRecords) {
    userRecords = await callFirebaseFunction<IFbUserRecord[]>(
      'user-getAllUsers'
    );
  }
  return userRecords;
};

type D = {
  id: string;
  user: IUser;
  spaces: ISpace[];
  record: IFbUserRecord;
  lastSignIn: moment.Moment;
};

const AvatarWithName = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing()}px;
`;

const SpacesCell = styled('div')`
  > :not(:first-child) {
    margin-left: ${(p) => p.theme.spacing(0.5)}px;
  }
`;

type SortKey = 'id' | 'name' | 'email' | 'spaces' | 'lastSignIn';

const COLUMNS: IColumn<D, SortKey>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => d.id,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 0.5
  },
  {
    key: 'name',
    head: () => 'Name',
    cell: (d) => (
      <AvatarWithName>
        <Avatar src={d.record.photoURL} />
        <div>{d.record.displayName || ''}</div>
      </AvatarWithName>
    ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'email',
    head: () => 'Email',
    cell: (d) => d.user.email || '',
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'spaces',
    head: () => 'Spaces',
    cell: (d) => (
      <SpacesCell>
        {d.spaces.map((s, i) => (
          <SpaceLink key={i} space={s} />
        ))}
      </SpacesCell>
    ),
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'lastSignIn',
    head: () => 'Last Sign In',
    cell: (d) => d.lastSignIn.format(HUMAN_DATE),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 1
  }
];

type UserListState = {
  searchTerm: string;
};

const filterUsers = (users: D[], state: UserListState) => {
  const { searchTerm } = state;
  if (!searchTerm) {
    return users;
  }
  const match = (x: string | undefined | null) =>
    x && x.toLowerCase().indexOf(searchTerm) !== -1;
  return users.filter(
    (u) => match(u.record.displayName) || match(u.user.email)
  );
};

const sortUsers = (us: D[], sortBy: SortKey, direction: SortDirection) => {
  return orderBy(
    us,
    (u) => {
      if (sortBy === 'id') {
        return u.id;
      }
      if (sortBy === 'name') {
        return u.record.displayName || 'zzz';
      }
      if (sortBy === 'email') {
        return u.user.email || 'zzz';
      }
      if (sortBy === 'lastSignIn') {
        return u.lastSignIn.valueOf();
      }
      return u.id;
    },
    direction
  );
};

const UserTable = ({
  users,
  state,
  setState
}: {
  users: D[];
  state: UserListState;
  setState: (nextState: UserListState) => void;
}) => {
  const filteredUsers = filterUsers(users, state);
  return (
    <>
      <TableToolbar>
        <SearchInput
          value={state.searchTerm}
          onChange={(t) => setState({ ...state, searchTerm: t })}
          placeholder="Search by name or email"
          width={300}
          autoFocus={true}
        />
      </TableToolbar>
      <VirtualizedSortableTable
        rows={filteredUsers}
        columns={COLUMNS}
        cellProps={undefined}
        height={1000}
        margin="normal"
        sortFn={sortUsers}
        initialSortColumn={COLUMNS[3]}
      />
    </>
  );
};

const useRecords = () => usePromise(getUserRecords, []);

const useUsersWithRecordsAndSpaces = () => {
  const [spaces, loadingSpaces, errorSpaces] = useSpaces();
  const [users, loadingUsers, errorUsers] = useUsers();
  const [records, loadingRecords, errorRecords] = useRecords();

  const ds = useMemo(() => {
    if (!users || !spaces || !records) {
      return;
    }
    const recordsById = keyBy(records, (r) => r.uid);
    const spacesById = keyBy(
      spaces.map((s) => s.data),
      (s) => s.id
    );
    return compact(
      users.map<D | null>((u) => {
        const spaces = compact(u.data.spaces.map((s) => spacesById[s]));
        const record = recordsById[u.id];
        if (!record) {
          console.log('No record found for user: ', u);
          return null;
        }
        return {
          id: u.id,
          user: u.data,
          spaces,
          record,
          lastSignIn: moment(record.metadata.lastSignInTime)
        };
      })
    );
  }, [users, spaces, records]);

  const loading = loadingSpaces || loadingUsers || loadingRecords;
  const error = errorSpaces || errorUsers || errorRecords;
  return [ds, loading, error] as [typeof ds, typeof loading, typeof error];
};

export const PageUserList = () => {
  const [users, loading] = useUsersWithRecordsAndSpaces();
  const [state, setState] = useState<UserListState>({
    searchTerm: ''
  });
  return (
    <Section>
      <Paper>
        {loading && <Loader height={500} />}
        {!loading && users && (
          <UserTable users={users} state={state} setState={setState} />
        )}
      </Paper>
    </Section>
  );
};
