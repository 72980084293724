import React from 'react';
import { getKnownPartnerForKey } from '../../../../../services/partner';
import { useTheme } from '../../../../../themes';
import { SimpleBar } from '../../../../../components/SimpleBar';

type D = { partnerKey: string; counts: { links: number } };

export const getPartnerCoverage = <T extends D>(partners: T[]) => {
  return partners.reduce(
    (m, p) => {
      if (!!getKnownPartnerForKey(p.partnerKey)) {
        m.known += p.counts.links;
      } else {
        m.unknown += p.counts.links;
      }
      return m;
    },
    { known: 0, unknown: 0 }
  );
};

export const CoverageBar = <T extends D>({
  ds,
  width,
  height
}: {
  ds: T[];
  width: number | string;
  height: number | string;
}) => {
  const t = useTheme();
  const counts = getPartnerCoverage(ds);

  return (
    <SimpleBar
      width={width}
      height={height}
      parts={[
        {
          label: 'Known',
          color: t.custom.colors.success.light,
          value: counts.known
        },
        {
          label: 'Unknown',
          color: t.custom.colors.error.light,
          value: counts.unknown
        }
      ]}
    />
  );
};
