import React from 'react';
import { LinkExternal } from '../../../../components/LinkExternal';

type Props = {
  sha: string;
};

export const Sha: React.FC<Props> = ({ sha }) => {
  return (
    <LinkExternal href={`https://github.com/LFDM/affilimate/commit/${sha}`}>
      <code>{sha}</code>
    </LinkExternal>
  );
};
