import React from 'react';
import { ScheduleFormSimple } from '../../../../../../../components/Schedule/SimpleForm';
import { Doc } from '../../../../../../../domainTypes/document';
import {
  isOfScheduleType,
  Schedule
} from '../../../../../../../domainTypes/schedule';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { FS } from '../../../../../../../versions';
import { Json } from '../../../../../../components/Json';
import { LinkExternal } from '../../../../../../components/LinkExternal';
import { toFirestoreConsole } from '../../../../../../services/firebase';

type OnUpdate = (nextDoc: Doc<Schedule>) => Promise<void>;

type Props = {
  doc: Doc<Schedule>;
  onUpdate: OnUpdate;
};

const Data = ({ data }: { data: Doc<Schedule> }) => {
  return (
    <>
      <CanvasBar>
        <div />
        <LinkExternal href={toFirestoreConsole(FS.schedules, data.id)}>
          Open in Firestore console
        </LinkExternal>
      </CanvasBar>
      <Json data={data} />
    </>
  );
};

const GenericDetailsWithForm: React.FC<{
  doc: Doc<Schedule>;
  onUpdate: OnUpdate;
}> = ({ doc, onUpdate }) => {
  return (
    <>
      <ScheduleFormSimple label="Enable scan" doc={doc} onChange={onUpdate} />
      <br />
      <Data data={doc} />
    </>
  );
};

export const Details: React.FC<Props> = ({ doc, onUpdate }) => {
  if (
    isOfScheduleType(doc, 'PRODUCT_SCAN') ||
    isOfScheduleType(doc, 'REPORT')
  ) {
    return <GenericDetailsWithForm doc={doc} onUpdate={onUpdate} />;
  }
  return <Data data={doc} />;
};
