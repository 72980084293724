import React from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { Doc } from '../../../../../domainTypes/document';
import { IPageScreenshotQueueItem } from '../../../../../domainTypes/page';
import { Section } from '../../../../../layout/Section';
import { ButtonContainer } from '../../../../components/ButtonContainer';
import { IStatsBarItem, StatsBar } from '../../../../components/StatsBar';
import {
  countItems,
  requeueErroredItems,
  resumeAllQueues,
  useAllQueues
} from '../../../../services/pageQueue';
import { PageQueueTable } from '../../components/PageQueueTable';

type Props = {};

const toStatsBarItems = (
  items: Doc<IPageScreenshotQueueItem>[] | void
): void | IStatsBarItem[] => {
  if (!items) {
    return;
  }

  const counts = countItems(items);
  return [
    {
      label: 'Running',
      value: counts.running
    },
    {
      label: 'Queued',
      value: counts.queued
    },
    {
      label: 'Done',
      value: counts.done
    },
    {
      label: 'Aborted',
      value: counts.aborted
    },
    {
      label: 'No Script',
      value: counts.noScript
    },
    {
      label: 'Timeout',
      value: counts.timedout
    },
    {
      label: 'Error',
      value: counts.error
    }
  ];
};

export const PageQueueStats: React.FC<Props> = () => {
  const [items, loading, error] = useAllQueues();
  return (
    <div style={{ maxWidth: '1100px', margin: '0 auto' }}>
      <Section>
        <StatsBar
          data={toStatsBarItems(items)}
          loading={loading}
          error={error}
        />
      </Section>
      <Section>
        <PageQueueTable
          items={items}
          loading={loading}
          height={800}
          useFullUrl
        />
      </Section>
      <Section>
        <>
          {items && (
            <ButtonContainer>
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Requeuing..."
                disabled={!items.filter((d) => d.data.errored).length}
                onClick={() => requeueErroredItems(items)}
              >
                Requeue errored items
              </ButtonWithPromise>

              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Resuming..."
                onClick={resumeAllQueues}
              >
                Resume all queues
              </ButtonWithPromise>
            </ButtonContainer>
          )}
        </>
      </Section>
    </div>
  );
};
