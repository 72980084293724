import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { pageCollection, toPageMetadataDoc } from '../../../services/page';

export const getPageMetadataInSpaceQuery = (spaceId: string) => {
  return pageCollection().where('spaceId', '==', spaceId);
};

const getCache = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      getPageMetadataInSpaceQuery(spaceId),
      toPageMetadataDoc
    )
);

export const usePageMetadataInSpace = (spaceId: string) =>
  useCollectionListener(getCache(spaceId));
