import { useCallback, useState } from 'react';

export const useSet = <T>(initialSet?: Set<T>) => {
  const [set, setSet] = useState<Set<T>>(initialSet || new Set());
  const add = useCallback(
    (item: T) =>
      setSet(s => {
        if (s.has(item)) {
          return s;
        }
        const nextSet = new Set(s);
        nextSet.add(item);
        return nextSet;
      }),
    []
  );
  const rm = useCallback(
    (item: T) =>
      setSet(s => {
        if (!s.has(item)) {
          return s;
        }
        const nextSet = new Set(s);
        nextSet.delete(item);
        return nextSet;
      }),
    []
  );
  const clear = useCallback(() => setSet(new Set()), []);
  const replace = useCallback((nextSet: Set<T>) => setSet(nextSet), []);

  return {
    set,
    add,
    rm,
    clear,
    replace
  };
};
