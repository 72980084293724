import {
  Card,
  CardContent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { capitalize, snakeCase, sortBy } from 'lodash';
import React from 'react';
import { Loader } from '../../../components/Loader';
import {
  AffilimateService,
  AFFILIMATE_CONFIG_ID,
  EMPTY_AFFILIMATE_CONFIG,
  IAffilimateConfig
} from '../../../domainTypes/affilimateConfig';
import { generateToDocFn } from '../../../domainTypes/document';
import { CanvasBar } from '../../../layout/Canvas';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import {
  LoadingValue,
  store,
  useMappedLoadingValue
} from '../../../services/db';
import { FS } from '../../../versions';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../services/firecache/documentListener';

const toConfigDoc = generateToDocFn<IAffilimateConfig>();

const configDocRef = () =>
  store()
    .collection(FS.affilimateConfig)
    .doc(AFFILIMATE_CONFIG_ID);

const cache = createDocumentListenerGetter<IAffilimateConfig>(
  () => configDocRef(),
  toConfigDoc,
  EMPTY_AFFILIMATE_CONFIG
);

export const useAffilimateConfig = (): LoadingValue<IAffilimateConfig> => {
  return useMappedLoadingValue(useDocumentListener(cache('')), d => {
    const defaults = EMPTY_AFFILIMATE_CONFIG();
    if (!d) {
      return defaults;
    }
    return {
      ...defaults,
      ...d.data,
      services: {
        ...defaults.services,
        ...d.data.services
      }
    };
  });
};

export const enableService = (key: AffilimateService, value: boolean) => {
  store().runTransaction(async t => {
    const ref = configDocRef();
    const config = await t.get(ref);
    const d = (config.data() as IAffilimateConfig) || EMPTY_AFFILIMATE_CONFIG();
    return t.set(ref, {
      ...d,
      services: {
        ...d.services,
        [key]: value
      }
    });
  });
};

const Services = ({ config }: { config: IAffilimateConfig }) => {
  const options: {
    key: AffilimateService;
    label: string;
    checked: boolean;
  }[] = Object.entries(config.services).map(([key, checked]) => ({
    key: key as AffilimateService,
    checked,
    label: snakeCase(key)
      .split('_')
      .map(capitalize)
      .join(' ')
  }));

  return (
    <Section>
      <CanvasBar>Services</CanvasBar>
      <Card>
        <CardContent>
          <Table>
            <TableBody>
              {sortBy(options, o => o.key).map(o => (
                <TableRow key={o.key}>
                  <TableCell>{o.label}</TableCell>
                  <TableCell align="right">
                    <Switch
                      checked={o.checked}
                      onChange={ev => enableService(o.key, ev.target.checked)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Section>
  );
};

export const PageAffilimateConfig = () => {
  const [config] = useAffilimateConfig();
  return (
    <Page>
      {!config && <Loader />}
      {config && (
        <>
          <Services config={config} />
        </>
      )}
    </Page>
  );
};
