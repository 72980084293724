import { useCollection } from 'react-firebase-hooks/firestore';
import { useMappedLoadingValue } from '../db';
import {
  getSchedulesForSpaceQuery,
  toScheduleDocs,
  collection
} from './helper';

export const useSchedulesBySpaceId = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollection(getSchedulesForSpaceQuery(spaceId)),
    toScheduleDocs
  );
};

export const useAllSchedules = () => {
  return useMappedLoadingValue(useCollection(collection()), toScheduleDocs);
};
