import firebase from 'firebase/app';
import React, { useContext, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useStateObject } from '../../hooks/useStateObject';
import { checkAdminClaim } from '../../services/auth';

type State = {
  adminUser: firebase.User | null;
  loading: boolean;
  error: string | null;
};

export const useAdminLogin = () => {
  const [state, mergeState] = useStateObject<State>({
    adminUser: null,
    loading: false,
    error: null
  });

  const [user, initialising] = useAuthState(firebase.auth());

  useEffect(() => {
    if (initialising) {
      return;
    }

    if (!user) {
      mergeState({ adminUser: null, error: null, loading: false });
      return;
    }

    mergeState({ loading: true });
    checkAdminClaim(user).then((isAdmin) => {
      if (isAdmin) {
        mergeState({ adminUser: user, error: null, loading: false });
      } else {
        mergeState({ adminUser: null, error: 'NO_ADMIN', loading: false });
      }
    });
    // eslint-disable-next-line
  }, [user]);

  const { adminUser, loading, error } = state;
  return {
    adminUser,
    loading: initialising || loading,
    error
  };
};

export const signOut = () => {
  firebase.auth().signOut();
  window.location.assign('/');
};

const CurrentUserContext = React.createContext<{
  userId: string;
}>({ userId: '' });

export const CurrentUserProvider: React.FC<{ userId: string }> = ({
  userId,
  children
}) => {
  return (
    <CurrentUserContext.Provider value={{ userId }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentAdminUser = () => {
  return useContext(CurrentUserContext);
};
