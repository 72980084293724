import React from 'react';
import { SwitchWithLabel } from '../../../components/SwitchWithLabel';
import { AffilimateService } from '../../../domainTypes/affilimateConfig';
import {
  enableService,
  useAffilimateConfig
} from '../../features/AffilimateConfig';

export const KillSwitch = ({
  service,
  label
}: {
  service: AffilimateService;
  label?: string;
}) => {
  const [config] = useAffilimateConfig();
  if (!config) {
    return null;
  }
  const enabled = !!config.services[service];
  return (
    <SwitchWithLabel
      checked={enabled}
      onChange={(checked) => enableService(service, checked)}
      label={`${label || service} ${enabled ? 'enabled' : 'disabled'}`}
    />
  );
};
