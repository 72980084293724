import { capitalize } from 'lodash';
import React from 'react';
import {
  MultiSelector,
  MultiSelectorChip
} from '../../../../../components/MultiSelector';
import { IPotentialUserStatus } from '../../../../../domainTypes/potentialUser';
import {
  queryParamToList,
  setToQueryParam,
  useQueryParam
} from '../../../../../routes';

export const STATUSES: IPotentialUserStatus[] = [
  'LEAD',
  'PAYING',
  'TRIAL',
  'EXPIRED',
  'CHURNED'
];

export const StatusSelector = ({
  value,
  onChange
}: {
  value: Set<IPotentialUserStatus>;
  onChange: (nextValue: Set<IPotentialUserStatus>) => void;
}) => {
  const isApplied = STATUSES.length !== value.size;
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      legend="Filter by status"
      options={STATUSES.map(s => ({
        label: capitalize(s),
        value: s
      }))}
      allOption={<strong>All</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={isApplied}
        onDelete={() => onChange(new Set(STATUSES))}
        label="Filter by status"
        appliedLabel="Filtered by status"
      />
    </MultiSelector>
  );
};

export const useStatusQueryParam = (param: string) =>
  useQueryParam(
    param,
    p => new Set(p ? queryParamToList<IPotentialUserStatus>(p) : STATUSES),
    ss => (ss.size === STATUSES.length ? undefined : setToQueryParam(ss))
  );
