import {
  createSingleCollectionListenerStore,
  CollectionListener,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { FS } from '../../../versions';
import { toUserDoc } from '../../../services/user';
import { store } from '../../../services/db';

const getListener = createSingleCollectionListenerStore(
  () => new CollectionListener(store().collection(FS.users), toUserDoc)
);

export const useUsers = () => useCollectionListener(getListener());

export const getAllUserIds = () =>
  getListener()
    .get()
    .then(us => us.map(u => u.id));
