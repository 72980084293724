import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tooltip
} from '@material-ui/core';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../components/AdditionalActionsMenu';
import { Chip, ChipProps } from '../../../../../components/Chip';
import { DateRangePickerDialog } from '../../../../../components/DateRangePickerDialog';
import { ExportDialogInner } from '../../../../../components/Exporter';
import { IExportColumn } from '../../../../../components/Exporter/service';
import {
  applySorterOnItems,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip
} from '../../../../../components/MultiSelector';
import { Number, toPercent } from '../../../../../components/Number';
import { SearchInput } from '../../../../../components/SearchInput';
import { SelectorChip } from '../../../../../components/SelectorChip';
import {
  SingleSelector,
  SingleSelectorOption
} from '../../../../../components/SingleSelector';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import { TaggableEntityEditDialogBody } from '../../../../../components/Tags/EntityEditDialog';
import { TagPrototypeSelector } from '../../../../../components/Tags/PrototypeSelector';
import { TagListAsync } from '../../../../../components/Tags/TagList';
import { DAY_FORMAT } from '../../../../../domainTypes/analytics';
import { Doc } from '../../../../../domainTypes/document';
import { EMPTY_ARR, NOOP } from '../../../../../domainTypes/emptyConstants';
import { PARTNERS } from '../../../../../domainTypes/partners';
import {
  IPotentialUser,
  IPotentialUserPartner,
  IPotentialUserStatus,
  SPACE_ID
} from '../../../../../domainTypes/potentialUser';
import { ProductScanStatus } from '../../../../../domainTypes/productScan';
import { styled } from '../../../../../emotion';
import { ScanStatus } from '../../../../../features/Scan/components/ScanStatus';
import { ScanObserver } from '../../../../../features/Scan/pages/ProductScanDetail/ScanObserver';
import { getProgressFromTracker } from '../../../../../features/Scan/pages/ProductScanDetail/service';
import { useScan } from '../../../../../features/Scan/services';
import { withStoppedPropagation } from '../../../../../helpers';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { Section } from '../../../../../layout/Section';
import {
  queryParamToList,
  setToQueryParam,
  useDayRangeQueryParam,
  useNullableStringSetQueryParam,
  useQueryParam,
  useStringQueryParam
} from '../../../../../routes';
import { store } from '../../../../../services/db';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { pluralize } from '../../../../../services/pluralize';
import { useScanTracker } from '../../../../../services/scan';
import {
  formatDatePrecise,
  isBetweenMs,
  MomentRange,
  msToMinsAndSecs
} from '../../../../../services/time';
import { CF, FS } from '../../../../../versions';
import { LinkExternal } from '../../../../components/LinkExternal';
import { IStatsBarItem, StatsBar } from '../../../../components/StatsBar';
import { useCurrentAdminUser } from '../../../../services/auth';
import { CoverageBar, getPartnerCoverage } from '../../components/CoverageBar';
import {
  StatusSelector,
  useStatusQueryParam
} from '../../components/StatusSelector';
import {
  analyze,
  getScanResult,
  getScore,
  lookupNewPartnersAndUpdate,
  rescanAll,
  updateStatus,
  usePotentialUsers
} from '../../service';
import { CreateDialog } from './CreateDialog';
import { Partners } from './Partners';
import { RetagDialog } from './RetagDialog';

const DateRangeSelector = ({
  value,
  onChange
}: {
  value: MomentRange | null;
  onChange: (nextValue: MomentRange | null) => void;
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  return (
    <>
      <ButtonBase onClick={openDialog}>
        <SelectorChip
          label="Filter by date…"
          noArrow={true}
          isApplied={!!value}
          onDelete={() => onChange(null)}
          appliedLabel={
            value
              ? `${value.start.format(DAY_FORMAT)} - ${value.end.format(
                  DAY_FORMAT
                )}`
              : ''
          }
        />
      </ButtonBase>
      <DateRangePickerDialog
        open={dialogOpen}
        onClose={closeDialog}
        title="Pick a date range"
        value={value || { start: moment(), end: moment().add(1, 'd') }}
        onChange={onChange}
      />
    </>
  );
};

type Data = Doc<IPotentialUser>;
type ColumnName =
  | 'tags'
  | 'url'
  | 'score'
  | 'scanStatus'
  | 'partners'
  | 'coverage'
  | 'pages'
  | 'products'
  | 'links'
  | 'cloaked'
  | 'duration'
  | 'createdAt'
  | 'finishedAt'
  | 'status'
  | 'actions';

type OtherProps = { onShowScan: (scanId: string) => void };
type Column = IColumn<Data, ColumnName, OtherProps>;

const Actions = ({ d, o }: { d: Data; o: OtherProps }) => {
  const { userId } = useCurrentAdminUser();
  const remove = () => store().collection(FS.potentialUsers).doc(d.id).delete();

  const options: AdditionActionsMenuOption[] = [
    {
      key: 'details',
      label: 'Open scan in dialog',
      onClick: () => d.data.scan && o.onShowScan(d.data.scan.id)
    },
    {
      key: 'redo',
      label: 'Re-scan',
      onClick: () => rescanAll([d])
    },
    {
      key: 'delete',
      label: 'Delete',
      onClick: () => remove()
    },
    {
      key: 'abort',
      label: 'Abort',
      onClick: () =>
        d.data.scan &&
        callFirebaseFunction(CF.scraping.abortScan, {
          spaceId: SPACE_ID,
          scanId: d.data.scan.id
        }),
      disabled: !d.data.scan || d.data.scan.status !== 'PENDING'
    },
    {
      key: 'editTags',
      label: 'Edit tags',
      dialog: ({ onClose }) => (
        <TaggableEntityEditDialogBody
          d={d}
          onClose={onClose}
          spaceId={SPACE_ID}
          userId={userId}
          category="BLOG_TOPIC"
        />
      )
    }
  ];

  return (
    <div onClick={withStoppedPropagation(NOOP)}>
      <AdditionalActionsMenu options={options} />
    </div>
  );
};
const getChipType = (status: IPotentialUserStatus): ChipProps['type'] => {
  if (status === 'PAYING') {
    return 'SUCCESS';
  } else if (status === 'TRIAL') {
    return 'PENDING';
  } else if (status === 'EXPIRED') {
    return 'ERROR';
  } else if (status === 'CHURNED') {
    return 'NONE';
  }
  return 'ABORTED';
};

export const StatusChip = ({ status }: { status: IPotentialUserStatus }) => {
  return <Chip label={status[0]} type={getChipType(status)} />;
};

const ScanProgress = ({
  spaceId,
  scanId
}: {
  spaceId: string;
  scanId: string;
}) => {
  const [scan] = useScanTracker(spaceId, scanId);

  if (!scan || scan.data) {
    return null;
  }

  const stats = getProgressFromTracker(scan.data);

  return (
    <Number
      n={toPercent(stats.completed, stats.total)}
      format="percent"
      digits={0}
    />
  );
};

const STATUS_SELECTOR_OPTIONS: SingleSelectorOption<IPotentialUserStatus>[] = [
  {
    label: 'Paying',
    searchValue: 'Paying',
    value: 'PAYING'
  },
  {
    label: 'Trial',
    searchValue: 'Trial',
    value: 'TRIAL'
  },
  {
    label: 'Expired',
    searchValue: 'Expired',
    value: 'EXPIRED'
  },
  {
    label: 'Churned',
    searchValue: 'Churned',
    value: 'CHURNED'
  },
  {
    label: 'Lead',
    searchValue: 'Lead',
    value: 'LEAD'
  }
];

const COLUMNS: Column[] = [
  {
    key: 'tags',
    head: () => '',
    alternateHead: () => 'Tags',
    cell: (d) => <TagListAsync tagIds={d.data.tagIds} size="small" short />,
    sortable: false,
    align: 'left',
    width: 70,
    flexGrow: 0
  },
  {
    key: 'url',
    head: () => 'URL',
    cell: (d) => <LinkExternal href={d.data.url} />,
    sortable: true,
    align: 'left',
    width: 200,
    flexGrow: 1
  },
  {
    key: 'score',
    head: () => 'Score',
    cell: (d) => {
      const analysis = analyze(d);
      const tt = sortBy(analysis.explanations, (e) => -e.score)
        .map((e) => `${e.explanation}: ${e.score}`)
        .join('\n');
      return (
        <Tooltip title={tt}>
          <div>{analysis.score}</div>
        </Tooltip>
      );
    }, // colorize eventually
    sortable: true,
    align: 'right',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'scanStatus',
    head: () => 'Scan',
    cell: (d) =>
      d.data.scan ? <ScanStatus status={d.data.scan.status} short /> : null,
    sortable: true,
    align: 'center',
    width: 60,
    flexGrow: 0
  },
  {
    key: 'partners',
    head: () => 'Partners',
    cell: (d) => <Partners d={d} />,
    align: 'left',
    width: 200,
    flexGrow: 3
  },
  {
    key: 'coverage',
    head: () => 'Coverage',
    cell: (d) => {
      const partners = getScanResult(d, (r) => r.partners);
      if (!partners) {
        return null;
      }
      return <CoverageBar ds={partners} width={100} height={20} />;
    },
    sortable: true,
    align: 'center',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'pages',
    head: () => 'Pages',
    cell: (d) => getScanResult(d, (r) => r.counts.pages),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'products',
    head: () => 'Products',
    cell: (d) => getScanResult(d, (r) => r.counts.products),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'cloaked',
    head: () => 'Cloaked',
    cell: (d) => getScanResult(d, (r) => r.counts.cloaked),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'links',
    head: () => 'Links',
    cell: (d) => getScanResult(d, (r) => r.counts.links),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'duration',
    head: () => 'Duration',
    cell: (d) => {
      if (d.data.scan && d.data.scan.status === 'PENDING') {
        return <ScanProgress spaceId={SPACE_ID} scanId={d.data.scan.id} />;
      }
      return getScanResult(d, (r) => msToMinsAndSecs(r.duration || 0));
    },
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'createdAt',
    head: () => 'Created At',
    cell: (d) => formatDatePrecise(d.data.createdAt),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 1
  },

  {
    key: 'finishedAt',
    head: () => 'Finished At',
    cell: (d) =>
      d.data.scan &&
      d.data.scan.finishedAt &&
      formatDatePrecise(d.data.scan.finishedAt),
    sortable: true,
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => (
      <div onClick={withStoppedPropagation(NOOP)}>
        <SingleSelector
          value={d.data.status}
          options={STATUS_SELECTOR_OPTIONS}
          onChange={(nextStatus) => updateStatus(d.id, nextStatus)}
          legend="Status"
        >
          <StatusChip status={d.data.status} />
        </SingleSelector>
      </div>
    ),
    sortable: true,
    align: 'center',
    width: 80,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d, o) => <Actions d={d} o={o} />,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Data> = {
  url: {
    key: 'url',
    items: { sort: (d) => d.data.url, dir: 'asc' }
  },
  score: {
    key: 'score',
    items: { sort: (d) => getScore(d), dir: 'desc' }
  },
  scanStatus: {
    key: 'scanStatus',
    items: { sort: (d) => (d.data.scan ? d.data.scan.status : 0), dir: 'asc' }
  },
  coverage: {
    key: 'coverage',
    items: {
      sort: (d) => {
        const partners = getScanResult(d, (r) => r.partners);
        if (!partners) {
          return 0;
        }
        const counts = getPartnerCoverage(partners);
        return toPercent(counts.known, counts.known + counts.unknown);
      },
      dir: 'desc'
    }
  },
  pages: {
    key: 'pages',
    items: {
      sort: (d) => getScanResult(d, (r) => r.counts.pages) || 0,
      dir: 'desc'
    }
  },
  products: {
    key: 'products',
    items: {
      sort: (d) => getScanResult(d, (r) => r.counts.products) || 0,
      dir: 'desc'
    }
  },
  links: {
    key: 'links',
    items: {
      sort: (d) => getScanResult(d, (r) => r.counts.links) || 0,
      dir: 'desc'
    }
  },
  cloaked: {
    key: 'cloaked',
    items: {
      sort: (d) => getScanResult(d, (r) => r.counts.cloaked) || 0,
      dir: 'desc'
    }
  },
  duration: {
    key: 'duration',
    items: {
      sort: (d) => getScanResult(d, (r) => r.duration) || 0,
      dir: 'desc'
    }
  },
  createdAt: {
    key: 'createdAt',
    items: { sort: (d) => d.data.createdAt.toMillis(), dir: 'desc' }
  },
  finishedAt: {
    key: 'finishedAt',
    items: {
      sort: (d) =>
        d.data.scan && d.data.scan.finishedAt
          ? d.data.scan.finishedAt.toMillis()
          : 0,
      dir: 'desc'
    }
  },
  status: {
    key: 'status',
    items: {
      sort: (d) => {
        switch (d.data.status) {
          case 'CHURNED':
            return 1;
          case 'EXPIRED':
            return 2;
          case 'TRIAL':
            return 3;
          case 'PAYING':
            return 4;
          default:
            return 0;
        }
      },
      dir: 'desc'
    }
  }
};

const DEFAULT_COLUMNS: ColumnName[] = [
  'tags',
  'url',
  'score',
  'scanStatus',
  'partners',
  'coverage',
  'pages',
  'products',
  'links',
  'duration',
  'actions'
];

const ScanDialog = ({
  scanId,
  open,
  onClose
}: {
  scanId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [scan, loading] = useScan(SPACE_ID, scanId);
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        {!!scan && scan.data.targets.map((t) => t.url).join(', ')}
      </DialogTitle>
      <DialogContent>
        {loading && <Loader height="100%" />}
        {!scan && null}
        {scan && <ScanObserver userId="xxx" scan={scan} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const SCAN_STATUS_UI = {
  INITIALISING: 'Initialising',
  SPEED_TEST: 'Speed Test',
  DONE: 'Done',
  PENDING: 'Running',
  ABORTED: 'Aborted',
  ERROR: 'Errored'
};

const toStatsBarData = (
  docs: void | Doc<IPotentialUser>[],
  setStatus: (nextStatus: ProductScanStatus) => void
): void | IStatsBarItem[] => {
  if (!docs) {
    return;
  }
  const counts = docs.reduce<{
    done: number;
    running: number;
    aborted: number;
    errored: number;
  }>(
    (m, p) => {
      const { scan } = p.data;
      if (scan) {
        if (scan.status === 'DONE') {
          m.done++;
        }
        if (scan.status === 'PENDING') {
          m.running++;
        }
        if (scan.status === 'ABORTED') {
          m.aborted++;
        }
        if (scan.status === 'ERROR') {
          m.errored++;
        }
      }
      return m;
    },
    { done: 0, running: 0, aborted: 0, errored: 0 }
  );
  return [
    {
      label: SCAN_STATUS_UI.DONE,
      value: counts.done,
      onClick: () => setStatus('DONE')
    },
    {
      label: SCAN_STATUS_UI.PENDING,
      value: counts.running,
      onClick: () => setStatus('PENDING')
    },
    {
      label: SCAN_STATUS_UI.ABORTED,
      value: counts.aborted,
      onClick: () => setStatus('ABORTED')
    },
    {
      label: SCAN_STATUS_UI.ERROR,
      value: counts.errored,
      onClick: () => setStatus('ERROR')
    }
  ];
};

const SCAN_SCATUSES: ProductScanStatus[] = [
  'DONE',
  'PENDING',
  'ABORTED',
  'ERROR'
];

const ScanStatusSelector = ({
  value,
  onChange
}: {
  value: Set<ProductScanStatus>;
  onChange: (nextValue: Set<ProductScanStatus>) => void;
}) => {
  const isApplied = SCAN_SCATUSES.length !== value.size;
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      legend="Filter by scan status"
      options={SCAN_SCATUSES.map((s) => ({
        label: SCAN_STATUS_UI[s],
        value: s
      }))}
      allOption={<strong>All</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={isApplied}
        onDelete={() => onChange(new Set(SCAN_SCATUSES))}
        label="Filter by scan status"
        appliedLabel="Filtered by scan status"
      />
    </MultiSelector>
  );
};

type ExportColumnKey = 'domain' | 'score' | 'noOfPages' | 'noOfLinks';
type ExportColumn = IExportColumn<
  ExportColumnKey,
  Doc<IPotentialUser>,
  undefined
>;
const EXPORT_COLUMNS: ExportColumn[] = [
  {
    key: 'domain',
    head: () => 'Domain',
    cell: (d) => d.data.url
  },
  {
    key: 'score',
    head: () => 'Score',
    cell: (d) => analyze(d).score
  },
  {
    key: 'noOfLinks',
    head: () => 'Links',
    cell: (d) => getScanResult(d, (r) => r.counts.links)
  },

  {
    key: 'noOfLinks',
    head: () => 'Pages',
    cell: (d) => getScanResult(d, (r) => r.counts.pages)
  }
];

const PartnerSelector = ({
  value,
  onChange
}: {
  value: Set<string> | null;
  onChange: (nextValue: Set<string> | null) => void;
}) => {
  return (
    <MultiSelector
      value={value || new Set(PARTNERS.map((p) => p.key))}
      onChange={(nextValue) =>
        onChange(nextValue.size === PARTNERS.length ? null : nextValue)
      }
      legend="Filter by partner"
      options={sortBy(PARTNERS, (p) => p.name).map((p) => ({
        label: p.name,
        value: p.key
      }))}
      allOption={<strong>All partners</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={!!value}
        onDelete={() => onChange(null)}
        label="Filter by partner"
        appliedLabel={getAppliedLabel(
          'partner',
          !!value
            ? PARTNERS.filter((p) => value.has(p.key)).map((p) => p.name)
            : []
        )}
      />
    </MultiSelector>
  );
};

const hasAllPartners = (
  partners: IPotentialUserPartner[],
  partnersToFind: Set<string>
) => {
  const set = new Set(partners.map((p) => p.partnerKey));
  for (const p of partnersToFind) {
    if (!set.has(p)) {
      return false;
    }
  }
  return true;
};

const RowCounter = styled('div')`
  text-align: right;
  padding: 0 ${(p) => p.theme.spacing(2)}px;
  min-width: 90px;
`;

export const PageAcquisitionPotentialUsersList = () => {
  const [docs, loading, error] = usePotentialUsers();
  useErrorLogger(error);
  const {
    dialogOpen: createDialogOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog
  } = useDialogState();
  const {
    dialogOpen: retagDialogOpen,
    openDialog: openRetagDialog,
    closeDialog: closeRetagDialog
  } = useDialogState();
  const [columnNames, setColumnNames] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const [search, setSearch] = useStringQueryParam('q');
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  const [scanStatuses, setScanStatuses] = useQueryParam(
    'scanStatus',
    (p) => new Set(p ? queryParamToList<ProductScanStatus>(p) : SCAN_SCATUSES),
    (ss) => (ss.size === SCAN_SCATUSES.length ? undefined : setToQueryParam(ss))
  );
  const [partners, setPartners] = useNullableStringSetQueryParam('partners');
  const [tags, setTags] = useNullableStringSetQueryParam('tags');

  const [statuses, setStatuses] = useStatusQueryParam('status');
  const [createdAtRange, setCreatedAtRange] = useDayRangeQueryParam(
    'createdAt'
  );
  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const [observedScanId, setObservedScanId] = useStringQueryParam('scan', '');

  const rows = useMemo(() => {
    if (!docs) {
      return;
    }
    return docs.filter((d) => {
      if (!statuses.has(d.data.status)) {
        return false;
      }
      if (d.data.scan && !scanStatuses.has(d.data.scan.status)) {
        return false;
      }
      if (search && d.data.url.indexOf(search) === -1) {
        return false;
      }

      if (
        createdAtRange &&
        !isBetweenMs(
          d.data.createdAt.toMillis(),
          createdAtRange.start.valueOf(),
          createdAtRange.end.valueOf()
        )
      ) {
        return false;
      }
      if (partners) {
        if (
          !d.data.scan ||
          !d.data.scan.result ||
          !hasAllPartners(d.data.scan.result.partners, partners)
        ) {
          return false;
        }
      }
      if (tags) {
        if (!d.data.tagIds.find((tId) => tags.has(tId))) {
          return false;
        }
      }

      // Comment back in to get everything that's untagged
      // if (d.data.tags.length) {
      //   return false;
      // }

      return true;
    });
  }, [search, docs, scanStatuses, statuses, createdAtRange, partners, tags]);

  const stats = useMemo(
    () => toStatsBarData(docs, (s) => setScanStatuses(new Set([s]))),
    [docs, setScanStatuses]
  );

  return (
    <>
      <Section>
        <StatsBar data={stats} loading={loading} error={error} />
        <TableToolbar padding="dense" sticky offset={48} isOnCanvas>
          <SearchInput value={search} onChange={setSearch} />
          <TableToolbarSection>
            <StatusSelector value={statuses} onChange={setStatuses} />{' '}
            <ScanStatusSelector
              value={scanStatuses}
              onChange={setScanStatuses}
            />{' '}
            <PartnerSelector value={partners} onChange={setPartners} />
            <TagPrototypeSelector
              value={tags}
              onChange={setTags}
              short
              spaceId={SPACE_ID}
              category="BLOG_TOPIC"
            />
            <DateRangeSelector
              value={createdAtRange}
              onChange={setCreatedAtRange}
            />
            <ColumnSelector
              value={columnNames}
              onChange={setColumnNames}
              columns={COLUMNS}
              short
            />{' '}
            <RowCounter>
              {pluralize('row', (rows || []).length, true)}
            </RowCounter>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={openCreateDialog}
            >
              Add
            </Button>
            <AdditionalActionsMenu
              options={[
                {
                  key: 'updateParnters',
                  label: 'Look for new partners',
                  disabled: !docs,
                  onClick: () => {
                    if (!docs) {
                      return;
                    }
                    return lookupNewPartnersAndUpdate(docs);
                  }
                },
                {
                  key: 'retag',
                  label: 'Retag',
                  disabled: !docs,
                  onClick: () => openRetagDialog()
                },
                {
                  key: 'export',
                  label: 'Export...',
                  disabled: !docs,
                  dialog: ({ onClose }) => {
                    const s = sorter || SORTERS.url;
                    const rows = applySorterOnItems(
                      s,
                      docs || EMPTY_ARR,
                      dir || s.items.dir
                    );
                    return (
                      <ExportDialogInner
                        onClose={onClose}
                        title="Export"
                        fileName="acquisition_list"
                        rows={rows}
                        columns={EXPORT_COLUMNS}
                        otherProps={undefined}
                      />
                    );
                  }
                }
              ]}
            />
          </TableToolbarSection>
        </TableToolbar>
        {loading || !rows ? (
          <Paper>
            <Loader height={800} />
          </Paper>
        ) : (
          <RowsRenderer
            variant="contained"
            columns={columns}
            rows={rows}
            rowToKey={(d) => d.id}
            sorter={sorter || SORTERS.createdAt}
            sortDirection={dir}
            renderHead={true}
            headProps={{ sticky: true, offset: 48 + 64 }}
            onHeadClick={(c, d) => setSort([SORTERS[c.key] || SORTERS.url, d])}
            chunkSize={30}
            rootMargin="400px"
            rowHeight={ROW_HEIGHTS.airy}
            otherProps={{ onShowScan: (sId) => setObservedScanId(sId) }}
            rowToHref={(d) =>
              d.data.scan && `/acquisition/scan/${d.data.scan.id}`
            }
          />
        )}
      </Section>
      <CreateDialog
        open={createDialogOpen}
        onClose={closeCreateDialog}
        knownEntries={docs}
      />
      <RetagDialog
        open={retagDialogOpen}
        onClose={closeRetagDialog}
        docs={docs}
      />
      {observedScanId && (
        <ScanDialog
          open={true}
          scanId={observedScanId}
          onClose={() => setObservedScanId('')}
        />
      )}
    </>
  );
};
