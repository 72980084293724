import { Card, IconButton } from '@material-ui/core';
import { compact, keyBy, maxBy, sum } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import {
  Activity as IconSessions,
  Clock as IconLastSeen,
  ExternalLink as IconExternalLink
} from 'react-feather';
import { InlineLoader, Loader } from '../../../../../components/Loader';
import {
  hasTrialExpired,
  isInTrialGracePeriod,
  isInTrialIncludingGracePeriod
} from '../../../../../domainTypes/billing';
import { Doc } from '../../../../../domainTypes/document';
import { getSpaceDomainNames, ISpace } from '../../../../../domainTypes/space';
import { IUser } from '../../../../../domainTypes/user';
import { FullStoryMetadata } from '../../../../../domainTypes/userEngagement';
import { styled } from '../../../../../emotion';
import { usePromise } from '../../../../../hooks/usePromise';
import { Section } from '../../../../../layout/Section';
import {
  combineLoadingValues,
  store,
  useMappedLoadingValue
} from '../../../../../services/db';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { ModuleIcons } from '../../../../../services/moduleIcons';
import { pluralize } from '../../../../../services/pluralize';
import { formatDateHuman } from '../../../../../services/time';
import { onlyHostname } from '../../../../../services/url';
import { FS } from '../../../../../versions';
import { getUserIdsInSpace, useSpaces } from '../../../../services/space';
import { useUsers } from '../../../../services/user';
import { ImpersonationLinkIcon } from '../../../Spaces/pages/Details/components/ImpersonationLink';

type Test = {
  icon: React.ReactNode;
  label: string;
  passes: (d: SpaceWithUsers) => Promise<boolean>;
};

const ICON_SIZE = 16;

const TESTS: Test[] = [
  {
    icon: <ModuleIcons.Products size={ICON_SIZE} />,
    label: 'imported products',
    passes: (d) =>
      store()
        .collection(FS.products)
        .where('spaceId', '==', d.space.id)
        .limit(1)
        .get()
        .then((s) => !s.empty)
  },
  {
    icon: <ModuleIcons.Performance size={ICON_SIZE} />,
    label: 'uploaded reports',
    passes: (d) =>
      store()
        .collection(FS.reports)
        .where('spaceId', '==', d.space.id)
        .limit(1)
        .get()
        .then((s) => !s.empty)
  }
];

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${(p) => p.theme.spacing(6)}px;
  align-items: center;
`;

const Toolbar = styled('div')``;

type SpaceWithUsers = {
  space: Doc<ISpace>;
  users: Doc<IUser>[];
};

const useTrialingSpacesWithUsers = () => {
  return useMappedLoadingValue(
    combineLoadingValues(useSpaces(), useUsers()),
    ([spaces, users]) => {
      const n = Date.now();
      const usersById = keyBy(users, (u) => u.id);
      return spaces
        .filter(
          (s) =>
            isInTrialIncludingGracePeriod(s.data.billing, n) ||
            hasTrialExpired(s.data.billing, n)
        )
        .sort(
          (a, b) =>
            (a.data.billing.trialUntil || 0) - (b.data.billing.trialUntil || 0)
        )
        .map<SpaceWithUsers>((s) => ({
          space: s,
          users: compact(getUserIdsInSpace(s.data).map((uId) => usersById[uId]))
        }));
    }
  );
};

const StyledCard = styled<typeof Card, { color: string }>(Card)`
  border-left: ${(p) => p.theme.spacing()}px solid ${(p) => p.color};
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing()}px;
`;

const Name = styled('div')`
  font-weight: bolder;
  font-size: ${(p) => p.theme.custom.fontSize.l}px;
`;

const Users = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const getBorderColor = (s: ISpace) => {
  const n = Date.now();
  if (isInTrialGracePeriod(s.billing, n)) {
    return '#ffe15c';
  }
  if (hasTrialExpired(s.billing, n)) {
    return '#ff6d6d';
  }
  return '#59c8ff';
};

const Passed = styled('div')`
  color: ${(p) => p.theme.custom.colors.success.main};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Failed = styled('div')`
  color: ${(p) => p.theme.custom.colors.error.main};
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TestIcon = ({
  icon,
  passes,
  loading
}: {
  icon: React.ReactNode;
  passes: void | boolean;
  loading: boolean;
}) => {
  const SIZE = 18;
  if (loading) {
    return <InlineLoader size={SIZE} />;
  }
  if (passes) {
    return <Passed>{icon}</Passed>;
  }
  return <Failed>{icon}</Failed>;
};

const Row = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > :not(:last-child) {
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }
`;

const TestComp = ({ d, t }: { d: SpaceWithUsers; t: Test }) => {
  const [passes, loading] = usePromise(() => t.passes(d), []);
  return (
    <Row>
      <TestIcon icon={t.icon} passes={passes} loading={loading} />
      <div>{t.label}</div>
    </Row>
  );
};

const useFullStoryMetadata = (userIds: string[]) => {
  return useMappedLoadingValue(
    usePromise(
      () =>
        Promise.all(
          userIds.map((userId) =>
            callFirebaseFunction<FullStoryMetadata | null>(
              'userEngagement-getFullStoryUserMetadata',
              { userId }
            )
          )
        ),
      [userIds.join('-')]
    ),
    (t) => {
      const metadata = compact(userIds.map((u, i) => t[i])).map((d) => ({
        userId: d.uid,
        numSessions: parseInt(d.numSessions, 10),
        lastSeen: d.lastSeen ? moment(d.lastSeen) : null
      }));
      return {
        numSessions: sum(metadata.map((m) => m.numSessions)),
        lastSeen:
          maxBy(compact(metadata.map((m) => m.lastSeen)), (d) => d.valueOf) ||
          null,
        byUser: keyBy(metadata, (m) => m.userId)
      };
    }
  );
};

const Sessions = ({ d }: { d: SpaceWithUsers }) => {
  const [metadata, loading] = useFullStoryMetadata(d.users.map((u) => u.id));
  const loader = (
    <>
      <InlineLoader size={ICON_SIZE} />
      <div>...</div>
    </>
  );
  return (
    <>
      <Row>
        {loading || !metadata ? (
          loader
        ) : (
          <>
            <IconSessions size={ICON_SIZE} />
            <div>{pluralize('session', metadata.numSessions, true)}</div>
          </>
        )}
      </Row>
      <Row>
        {loading || !metadata ? (
          loader
        ) : (
          <>
            <IconLastSeen size={ICON_SIZE} />
            <div>
              {metadata.lastSeen
                ? formatDateHuman(metadata.lastSeen)
                : 'never seen'}
            </div>
          </>
        )}
      </Row>
    </>
  );
};

export const MonitoringCard = ({ d }: { d: SpaceWithUsers }) => {
  return (
    <StyledCard color={getBorderColor(d.space.data)}>
      <Header>
        <Name>{onlyHostname(getSpaceDomainNames(d.space.data))}</Name>
        <div>
          <ImpersonationLinkIcon spaceId={d.space.id} size={16} />
          <IconButton href={`/spaces/${d.space.id}`} target="_blank">
            <IconExternalLink size={16} />
          </IconButton>
        </div>
      </Header>
      <Users>{d.users.map((u) => u.data.email || '').join(', ')}</Users>
      {TESTS.map((t) => (
        <TestComp key={t.label} t={t} d={d} />
      ))}
      <Sessions d={d} />
    </StyledCard>
  );
};

export const PageAcquisitionTrialMonitor = () => {
  const [spaces, loading] = useTrialingSpacesWithUsers();
  console.log(spaces);
  return (
    <Section>
      <Toolbar></Toolbar>
      {loading || (!spaces && <Loader height={500} />)}
      {spaces && (
        <Grid>
          {spaces.map((d) => (
            <MonitoringCard key={d.space.id} d={d} />
          ))}
        </Grid>
      )}
    </Section>
  );
};
