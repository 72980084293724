import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { AlertBox } from '../../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { Loader } from '../../../../../components/Loader';
import { Doc } from '../../../../../domainTypes/document';
import { IProductScan } from '../../../../../domainTypes/productScan';
import { ISpace, getSpaceDomainNames } from '../../../../../domainTypes/space';
import { styled } from '../../../../../emotion';
import { useLoadingValue } from '../../../../../hooks/useLoadingValue';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { mapProductScan } from '../../../../../services/scan';
import { Json } from '../../../../components/Json';
import { getScanRaw, migrateScan } from '../../../../services/scan';
import { useSpaces } from '../../../../services/space';
import { List } from '../../components/List';

type ResultProps = {
  doc: Doc<IProductScan> | undefined | null;
  loading: boolean;
};

const SearchContainer = styled('div')`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  margin-top: ${(p) => p.theme.spacing(4)}px;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
`;

export const MigrateDialog: React.FC<{
  doc: Doc<IProductScan>;
  open: boolean;
  onClose: () => void;
}> = ({ doc, open, onClose }) => {
  const [spaces, loading] = useSpaces();
  const [spaceToMigrateTo, setSpaceToMigrateTo] = useState<Doc<ISpace> | null>(
    null
  );

  const [selectOpen, setSelectOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [migratedDoc, setMigratedDoc] = useState<Doc<IProductScan> | null>(
    null
  );

  const migrate = async () => {
    if (spaceToMigrateTo) {
      return migrateScan(doc, spaceToMigrateTo.id).then(
        (nextDoc) => {
          setMigratedDoc(nextDoc);
        },
        (err) => {
          console.error(err);
          enqueueSnackbar('Migratin failed!', { variant: 'error' });
        }
      );
    }
    return;
  };

  const close = () => {
    onClose();
    setMigratedDoc(null);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Choose a new space</DialogTitle>
      <DialogContent style={{ minWidth: 500 }}>
        {migratedDoc && (
          <>
            <AlertBox variant="success">Scan successfully migrated!</AlertBox>
            <Json data={mapProductScan(migratedDoc)} />
          </>
        )}
        {spaces && !migratedDoc && (
          <FormControl>
            <Select
              fullWidth={true}
              open={selectOpen}
              onOpen={() => setSelectOpen(true)}
              onClose={() => setSelectOpen(false)}
              value={spaceToMigrateTo ? spaceToMigrateTo.id : ''}
              onChange={(ev) =>
                setSpaceToMigrateTo(
                  spaces.find((s) => s.id === ev.target.value)!
                )
              }
            >
              {sortBy(
                spaces,
                (s) => getSpaceDomainNames(s.data) || 'ZZZZZ'
              ).map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {getSpaceDomainNames(s.data)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!spaces && <Loader />}
      </DialogContent>
      <DialogActions>
        {migratedDoc && (
          <Button variant="contained" color="primary" onClick={close}>
            Close
          </Button>
        )}
        {!migratedDoc && (
          <>
            <Button onClick={close}>Cancel</Button>
            <ButtonWithPromise
              onClick={migrate}
              disabled={loading || !spaceToMigrateTo}
              pending="Migrating..."
              variant="contained"
              color="secondary"
            >
              Migrate
            </ButtonWithPromise>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const Results: React.FC<ResultProps> = ({ doc, loading }) => {
  const [open, setOpen] = useState(false);

  return (
    <Section>
      <Card>
        <List
          docs={(doc ? [doc] : []).map(mapProductScan)}
          loading={loading}
          actionCell={{
            title: '',
            component: () => (
              <Button onClick={() => setOpen(true)}>Migrate...</Button>
            )
          }}
        />
      </Card>
      {doc && (
        <MigrateDialog doc={doc} open={open} onClose={() => setOpen(false)} />
      )}
    </Section>
  );
};

export const PageScanLookup: React.FC = () => {
  const [spaceId, setSpaceId] = useState('4oF26UAIvKcsv6YOxO7gtvrifrn2');
  const [scanId, setScanId] = useState('');

  const {
    value: rawDoc,
    loading,
    setValue,
    setLoading,
    setError
  } = useLoadingValue<Doc<IProductScan> | null>(undefined, false);

  const doc = rawDoc ? mapProductScan(rawDoc) : rawDoc;

  const lookup = () => {
    setLoading(true);
    getScanRaw(spaceId, scanId).then(setValue, setError);
  };

  return (
    <Page>
      <Section>
        <SearchContainer>
          <TextField
            value={spaceId}
            fullWidth={true}
            placeholder="Space id"
            label="Space ID"
            variant="outlined"
            onChange={(ev) => setSpaceId(ev.target.value)}
          />
          <TextField
            value={scanId}
            fullWidth={true}
            placeholder="Scan id"
            variant="outlined"
            label="Scan ID"
            onChange={(ev) => setScanId(ev.target.value)}
          />
          <Button
            color="secondary"
            variant="contained"
            disabled={!spaceId || !scanId}
            onClick={lookup}
          >
            Look up scan
          </Button>
        </SearchContainer>
      </Section>

      <Section>
        <Results doc={rawDoc} loading={loading} />
      </Section>

      {doc && (
        <Section>
          <Card>
            <Json data={doc} />
          </Card>
        </Section>
      )}
    </Page>
  );
};
