import React from 'react';
import { styled } from '../../../emotion';

const DataGridContainer = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  gridColumnGap: p.theme.spacing(1),
  gridRowGap: p.theme.spacing(0.5)
}));

export const DataGrid = ({ items }: { items: [string, React.ReactNode][] }) => {
  return (
    <DataGridContainer>
      {items.map(([left, right]) => (
        <React.Fragment key={left}>
          <strong>{left}</strong>
          {right}
        </React.Fragment>
      ))}
    </DataGridContainer>
  );
};
