import React from 'react';
import { DeployStatus } from '../../../../../domainTypes/deployment';
import { styled } from '../../../../../emotion';
import { statusToVariant } from '../../service';

type Props = {
  status: DeployStatus;
};

const S = styled<
  'div',
  { variant: 'success' | 'pending' | 'error' | 'secondary' }
>('div')`
  height: ${p => p.theme.custom.fontSize.m}px;
  width: ${p => p.theme.custom.fontSize.m}px;
  background-color: ${p => p.theme.custom.colors[p.variant].main};
  border-radius: 50%;
  display: inline-block;
`;

export const Status: React.FC<Props> = ({ status }) => {
  return <S variant={statusToVariant(status)} />;
};
