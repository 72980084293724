import { Paper } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../components/AdditionalActionsMenu';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { Doc, generateToDocFn } from '../../../../../domainTypes/document';
import { LinkCheckType } from '../../../../../domainTypes/linkCheckV2';
import { ILinkCheckWorker } from '../../../../../domainTypes/linkCheckV2/worker';
import { useErrorLogger } from '../../../../../hooks/useErrorLogger';
import { Section } from '../../../../../layout/Section';
import { store, useMappedLoadingValue } from '../../../../../services/db';
import { formatDatePrecise } from '../../../../../services/time';
import { FS } from '../../../../../versions';
import { KillSwitch } from '../../../../components/KillSwitch';
import { toFirestoreConsoleFromDoc } from '../../../../services/firebase';
import { publishTopic } from '../../../../services/pubsub';

type Props = {};

const toWorkerDoc = generateToDocFn<ILinkCheckWorker>();

const useWorkers = () => {
  return useMappedLoadingValue(
    useCollection(store().collection(FS.linkCheckWorkers)),
    (s) => s.docs.map(toWorkerDoc)
  );
};

type Data = Doc<ILinkCheckWorker>;
type ColumnName =
  | 'id'
  | 'type'
  | 'status'
  | 'packageId'
  | 'startedAt'
  | 'finishedAt'
  | 'actions';

type Column = IColumn<Data, ColumnName>;

const Actions = ({ d }: { d: Data }) => {
  const options: AdditionActionsMenuOption[] = useMemo(() => {
    return [
      {
        key: 'openInFirestore',
        label: 'Open in Firestore console',
        externalHref: toFirestoreConsoleFromDoc(d)
      }
    ];
  }, [d]);
  return <AdditionalActionsMenu options={options} />;
};

const COLUMNS: Column[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => d.id,
    sortable: true,
    align: 'left',
    width: 70,
    flexGrow: 1
  },
  {
    key: 'type',
    head: () => 'Type',
    cell: (d) => d.data.type,
    sortable: true,
    align: 'center',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => d.data.status,
    sortable: true,
    align: 'center',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'packageId',
    head: () => 'Package',
    cell: (d) => d.data.packageId,
    sortable: true,
    align: 'center',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'startedAt',
    head: () => 'Started At',
    cell: (d) => d.data.startedAt && formatDatePrecise(d.data.startedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'finishedAt',
    head: () => 'Finished At',
    cell: (d) => d.data.finishedAt && formatDatePrecise(d.data.finishedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    sortable: false,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Data> = {
  spaceId: {
    key: 'id',
    items: { sort: (d) => d.id, dir: 'asc' }
  },
  type: {
    key: 'type',
    items: { sort: (d) => d.data.type, dir: 'asc' }
  },
  status: {
    key: 'status',
    items: { sort: (d) => d.data.status, dir: 'desc' }
  },
  createdAt: {
    key: 'createdAt',
    items: { sort: (d) => d.data.startedAt?.toMillis() || 0, dir: 'desc' }
  },
  finishedAt: {
    key: 'finishedAt',
    items: { sort: (d) => d.data.finishedAt?.toMillis() || 0, dir: 'desc' }
  }
};

const DEFAULT_SORTER = SORTERS.type;

export const PageLinkCheckWorkers: React.FC<Props> = () => {
  const [ds, loading, error] = useWorkers();
  useErrorLogger(error);

  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);
  return (
    <Section>
      <TableToolbar padding="dense" isOnCanvas>
        <TableToolbarSection>
          <KillSwitch service="linkCheckV2" label="Workers" />
        </TableToolbarSection>
        <TableToolbarSection>
          <ButtonWithPromise
            size="small"
            variant="contained"
            color="primary"
            onClick={() =>
              Promise.all(
                [LinkCheckType.AMAZON].map((type) =>
                  publishTopic({
                    topic: 'linkCheckV2-checkWorkerQueue',
                    payload: { type }
                  })
                )
              )
            }
            pending="Checking..."
          >
            Check queues
          </ButtonWithPromise>
        </TableToolbarSection>
      </TableToolbar>
      <Paper>
        {loading && <Loader height={500} />}
        {ds && (
          <RowsRenderer
            variant="contained"
            columns={COLUMNS}
            rows={ds}
            rowToKey={(d) => d.id}
            sorter={sorter || DEFAULT_SORTER}
            sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
            renderHead={true}
            onHeadClick={(c, d) =>
              setSort([SORTERS[c.key] || DEFAULT_SORTER, d])
            }
            chunkSize={30}
            rootMargin="400px"
            otherProps={undefined}
            rowHeight={ROW_HEIGHTS.dense}
          />
        )}
      </Paper>
    </Section>
  );
};
