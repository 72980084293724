import { SortDirection } from '@material-ui/core/TableCell';
import { keyBy, orderBy } from 'lodash';
import React from 'react';
import { Ctr } from '../../../../../components/Ctr';
import { Loader } from '../../../../../components/Loader';
import { Number } from '../../../../../components/Number';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { ICounter } from '../../../../../domainTypes/analytics';
import { ISpace } from '../../../../../domainTypes/space';
import { useRouter } from '../../../../../hooks/useRouter';
import { getClickRatio, getCpm } from '../../../../../services/analytics';
import { useMappedLoadingValue } from '../../../../../services/db';
import { getActiveDomainUrls } from '../../../../../services/space';
import { useSpaces } from '../../../../services/space';

type ListRow = {
  space: ISpace;
  counts: ICounter;
};
type ListKey = 'name' | 'pageViews' | 'served' | 'clicked' | 'ctr' | 'cpm';

const COLUMNS: IColumn<ListRow, ListKey>[] = [
  {
    key: 'name',
    head: () => 'Space',
    cell: (d) => getActiveDomainUrls(d.space).join(', '),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 3
  },
  {
    key: 'pageViews',
    head: () => 'PVs',
    cell: (d) => <Number n={d.counts.pageViews} />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'served',
    head: () => 'Served',
    cell: (d) => <Number n={d.counts.served} />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'clicked',
    head: () => 'Clicks',
    cell: (d) => <Number n={d.counts.clicked} />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'ctr',
    head: () => 'CTR',
    cell: (d) => (
      <Number n={getClickRatio(d.counts)} format="percent" digits={2} />
    ),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'cpm',
    head: () => 'CPM',
    cell: (d) => (
      <Ctr
        steps={[0, 50, 150, 200, 300, 400, 500]}
        rate={getCpm(d.counts)}
        format={(item) => `${Math.round(item)}`}
      />
    ),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  }
];

const getSortValue = (row: ListRow, sortBy: ListKey) => {
  if (sortBy === 'pageViews') {
    return row.counts.pageViews;
  }
  if (sortBy === 'served') {
    return row.counts.served;
  }
  if (sortBy === 'clicked') {
    return row.counts.clicked;
  }
  if (sortBy === 'ctr') {
    return getClickRatio(row.counts);
  }
  if (sortBy === 'cpm') {
    return getCpm(row.counts);
  }

  return getActiveDomainUrls(row.space)[0];
};

const sort = (
  rows: ListRow[],
  sortBy: ListKey,
  direction: SortDirection
): ListRow[] => orderBy(rows, (r) => getSortValue(r, sortBy), direction);

const HEIGHT = 450;

export const MonitoringList = ({
  data
}: {
  data: { [spaceId: string]: ICounter };
}) => {
  const { history } = useRouter();
  const [spacesById, loading] = useMappedLoadingValue(useSpaces(), (ds) =>
    keyBy(ds, (d) => d.id)
  );
  if (loading || !spacesById) {
    return <Loader height={HEIGHT} />;
  }
  return (
    <VirtualizedSortableTable
      columns={COLUMNS}
      rows={Object.entries(data).map<ListRow>(([spaceId, counts]) => ({
        space: spacesById[spaceId].data,
        counts
      }))}
      cellProps={undefined}
      height={HEIGHT}
      margin="dense"
      initialSortColumn={COLUMNS[1]}
      sortFn={sort}
      onRowClick={(d) => history.push(`/spaces/${d.space.id}`)}
    />
  );
};
