import { Card } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Loader } from '../../../../../components/Loader';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../domainTypes/document';
import { Event } from '../../../../../domainTypes/event';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Centered } from '../../../../../layout/Centered';
import { Section } from '../../../../../layout/Section';
import { formatDatePrecise, fromMoment } from '../../../../../services/time';
import { FilterBar } from '../../components/FilterBar';
import { EventsFilterArgs, useEventsFiltered } from '../../service';

type Props = {
  events: void | Doc<Event>[];
  loading: boolean;
  error: any;
};

const HEIGHT = 500;

type SortKey = 'type' | 'spaceId' | 'createdAt' | 'createdBy' | 'actions';

const COLUMNS: IColumn<Doc<Event>, SortKey>[] = [
  {
    key: 'type',
    head: () => 'Type',
    cell: (d) => d.data.type,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 70,
    flexGrow: 1
  },
  {
    key: 'spaceId',
    head: () => 'Space',
    cell: (d) => d.data.spaceId,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 70,
    flexGrow: 1
  },
  {
    key: 'createdAt',
    head: () => 'created at',
    cell: (d) => formatDatePrecise(d.data.createdAt),
    align: 'left',
    sortable: false,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'createdBy',
    head: () => 'created by',
    cell: (d) => d.data.createdBy,
    align: 'left',
    sortable: false,
    defaultDirection: 'asc',
    width: 70,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d) => null,
    align: 'right',
    sortable: false,
    defaultDirection: 'asc',
    width: 70,
    flexGrow: 1
  }
];

const EventsTable = ({ events, loading, error }: Props) => {
  if (loading) {
    return <Loader height={HEIGHT} />;
  }
  if (error || !events) {
    console.log(error);
    return <Centered height={500}>Something went wrong.</Centered>;
  }

  if (!events.length) {
    return <Centered height={500}>No events found.</Centered>;
  }

  return (
    <VirtualizedSortableTable
      rows={events}
      columns={COLUMNS}
      cellProps={undefined}
      height={HEIGHT}
      margin="normal"
      sortFn={(t) => t}
      initialSortColumn={COLUMNS[2]}
    />
  );
};

export const PageEventsList: React.FC = () => {
  const [filters, setFilters] = useState<EventsFilterArgs>({
    spaceId: null,
    start: fromMoment(moment().startOf('d')),
    end: fromMoment(moment().endOf('d')),
    category: null,
    type: null
  });
  const [events, loading, error] = useEventsFiltered(filters);

  return (
    <Section>
      <CanvasBar>Events</CanvasBar>
      <Card>
        <FilterBar value={filters} onChange={setFilters} />
        <EventsTable events={events} loading={loading} error={error} />
      </Card>
    </Section>
  );
};
