import { sortBy } from 'lodash';
import {
  Card,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader';
import { SelectSimple } from '../../../../../components/SelectSimple';
import { autoHeightRow } from '../../../../../components/Table';
import { IDeployBuildWithNo } from '../../../../../domainTypes/deployment';
import { Doc } from '../../../../../domainTypes/document';
import { styled } from '../../../../../emotion';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Centered } from '../../../../../layout/Centered';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { TopNav } from '../../../../layout/TopNav';
import { Duration } from '../../components/Duration';
import { LogViewer } from '../../components/LogViewer';
import { ServiceName } from '../../components/ServiceName';
import { Sha } from '../../components/Sha';
import { Status } from '../../components/Status';
import { useBuild } from '../../service';

type Props = {
  buildId: string;
};

const Header = styled('h2')`
  display: flex;
  align-items: center;

  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const formatTs = (ts: number) => moment(ts).format('YYYY/MM/DD HH:mm:ss');

const Build: React.FC<{ build: Doc<IDeployBuildWithNo> }> = ({ build }) => {
  const autoHeight = autoHeightRow();
  const [selectedJobId, setSelectedJobId] = useState(
    sortBy(build.data.jobs, (j) => j.status === 'ERROR')[0].id
  );
  const selectedJob = build.data.jobs.find((j) => j.id === selectedJobId)!;
  return (
    <>
      <TopNav>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Header>
              <Link to="/deployments/builds">Builds ({build.data.env})</Link>
              <ChevronRight />
              <div>#{build.data.no}</div>
            </Header>
          </Grid>
        </Grid>
      </TopNav>
      <Page style={{ marginTop: 50 }}>
        <Section>
          <Card>
            <Table>
              <TableHead>
                <TableRow classes={autoHeight}>
                  <TableCell>Status</TableCell>
                  <TableCell>Executor</TableCell>
                  <TableCell>SHA</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Status status={build.data.status} />
                  </TableCell>
                  <TableCell>{build.data.executor}</TableCell>
                  <TableCell>
                    <Sha sha={build.data.sha} />
                  </TableCell>
                  <TableCell>
                    <span>
                      {formatTs(build.data.finishedAt || build.data.startedAt)}
                    </span>{' '}
                    -{' '}
                    <Duration
                      a={build.data.startedAt}
                      b={build.data.finishedAt}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Section>
        <Section>
          <CanvasBar>Jobs</CanvasBar>
          <Card>
            <Table>
              <TableHead>
                <TableRow classes={autoHeight}>
                  <TableCell>Status</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {build.data.jobs.map((j) => (
                  <TableRow key={j.id}>
                    <TableCell>
                      <Status status={j.status} />
                    </TableCell>
                    <TableCell>
                      <ServiceName env={j.env} name={j.name} />
                    </TableCell>
                    <TableCell>{j.no}</TableCell>
                    <TableCell>
                      <span>{formatTs(j.finishedAt || j.startedAt)}</span> -{' '}
                      <Duration a={j.startedAt} b={j.finishedAt} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </Section>

        <Section>
          <CanvasBar>
            <div>Logs</div>
            <FormControl>
              <SelectSimple
                selected={selectedJob}
                setSelected={(j) => setSelectedJobId(j.id)}
                options={build.data.jobs}
                toLabel={(job) => job.name}
              />
            </FormControl>
          </CanvasBar>
          <Paper>
            <LogViewer job={selectedJob} />
          </Paper>
        </Section>
      </Page>
    </>
  );
};

export const PageBuildDetails: React.FC<Props> = ({ buildId }) => {
  const [build, loading] = useBuild(buildId);

  if (loading) {
    return <Loader height={700} />;
  }
  if (!build) {
    return <Centered>Build not found.</Centered>;
  }

  return <Build build={build} />;
};
