import { TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { styled } from '../../../emotion';
import { FlexContainer } from '../../../layout/Flex';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { publishTopic } from '../../services/pubsub';

const Row = styled('div')((p) => ({
  marginBottom: p.theme.spacing(2)
}));

export const PagePubSub = () => {
  const [topic, setTopic] = useState('');
  const [payload, setPayload] = useState('{\n  \n}');
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Page>
      <Section>
        <Row>
          <TextField
            label="Topic"
            fullWidth
            spellCheck="false"
            value={topic}
            onChange={(ev) => setTopic(ev.target.value)}
            variant="outlined"
          />
        </Row>

        <Row>
          <TextField
            label="Payload (JSON)"
            key="query"
            fullWidth
            spellCheck={false}
            inputProps={{
              style: {
                fontFamily: 'monospace'
              }
            }}
            value={payload}
            onChange={(ev) => {
              setPayload(ev.target.value);
            }}
            multiline
            rows={25}
            variant="outlined"
          />
        </Row>
        <FlexContainer justifyContent="flex-end">
          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending="Publishing..."
            onClick={async () => {
              try {
                await publishTopic({
                  topic,
                  payload: payload ? JSON.parse(payload) : undefined
                });
                enqueueSnackbar('Published successfully');
              } catch (err) {
                console.log('ERR', err);
                enqueueSnackbar(`Something went wrong - ${err?.message}`, {
                  variant: 'error'
                });
              }
            }}
          >
            Publish
          </ButtonWithPromise>
        </FlexContainer>
      </Section>

      <Section>
        <ButtonWithPromise
          pending="running"
          onClick={async () => {
            const limit = 5;
            let count = 0;
            const call = (start: string | null): Promise<string[]> => {
              return callFirebaseFunction<string[]>(
                'dataMigrations-reseedNewLinks',
                {
                  start,
                  limit,
                  execute: true
                }
              )
                .then(async (res) => {
                  console.log('RESULT', res);
                  count += res.length;
                  if (res.length === limit) {
                    return call(res[res.length - 1]);
                  }
                  return [];
                })
                .catch((err) => {
                  console.log('ERR', err);
                  return [];
                });
            };

            await call(null);
            console.log(count);
          }}
        >
          Run re-dist
        </ButtonWithPromise>
      </Section>
    </Page>
  );
};
